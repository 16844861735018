/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: PasswordRules
 *
 * Purpose: show the password rules
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 07Feb2024 craig  file created.
 * 21May2024 sasjxa change font size to be more inline with current app
 \******************************************************************************/
import React from "react";
import styled from "styled-components";
import {FONT_SIZES} from "../styled/StyleConstants";

const Styled_LI = styled.li`
   font-size: ${FONT_SIZES.px14};
`;

export const PasswordRules: React.FC = () => {

    return (
        <ul>
            <Styled_LI>Passwords must be 12 - 15 characters and must contain all of the following:
                <ul>
                    <Styled_LI>Uppercase alpha characters (A - Z)</Styled_LI>
                    <Styled_LI>Lowercase alpha characters (a - z)</Styled_LI>
                    <Styled_LI>Numbers (0 - 9)</Styled_LI>
                    <Styled_LI>Non-alphanumeric special characters (@, !, $, #, %, etc.)</Styled_LI>
                </ul>
            </Styled_LI>
            <Styled_LI>
                Passwords cannot contain:
                <ul>
                    <Styled_LI>User account, first or last name</Styled_LI>
                    <Styled_LI>Leading or trailing blanks</Styled_LI>
                    <Styled_LI>All or part of a previous password; for example, if "StrongPassword1" was used before, "StrongPassword2" cannot be a new password</Styled_LI>
                </ul>
            </Styled_LI>
            <li>
                Additional Password rules:
                <ul>
                    <Styled_LI>User accounts will be locked out after five (5) unsuccessful login attempts; users can attempt to reset their password after 30 minutes</Styled_LI>
                    <Styled_LI>No password changes on consecutive days</Styled_LI>
                </ul>
            </li>
        </ul>
    )
}