/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: InfoDialog
 *
 * Purpose: Header for PDS application
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 22Jun2023 sasjxa  file created.
 \******************************************************************************/
import {Button, Modal} from "react-bootstrap";
import React from "react";
import {IInfoDialog} from "../../data/infoDialog";
import parse from "html-react-parser";
import {DialogContent, DialogHeader } from "../styled/StyledComponents";

interface DialogProps {
    title: string,
    body: React.ReactNode,
    fileTypes: IInfoDialog[],
    show: boolean,
    showHide: any
}

export const InfoDialog: React.FC<DialogProps> = ({title, body, fileTypes, show, showHide}: DialogProps) => {
    return (
        <Modal show={show}>
            <Modal.Header closeButton={true} onClick={showHide}>
                <DialogHeader>{title}</DialogHeader>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {
                        fileTypes.map((value: IInfoDialog) => {
                            return <DialogContent>
                                {parse(value.fileTypeValue)} </DialogContent>
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"secondary"} onClick={showHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
