/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Resources
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 22Feb2023 craig  file created.
 * 02Jun2023 sasjxa move styled components out of FC
 * 04Aug2023 sasjxa layout changes
 \******************************************************************************/
import React, {Fragment, useState} from "react";
import {
    AsideSection,
    AsideWrapper,
    ContentPage,
    PageWrapper,  PDS_H5,
    PDS_P
} from "../../components/styled/StyledComponents";
import {COLORS} from "../../components/styled/StyleConstants";
// @ts-ignore
import styled from 'styled-components';
import {
    CYTEL_VIDEO_YOU_TUBE,
    DATA_PROVIDERS,
    EXTERNAL_LINK_TEXT,
    GENERAL_RESOURCES,
    IResourceLink,
    SAS_TOOLS
} from "../../data/resources/resources";

import parse from "html-react-parser";
import {Link} from "react-router-dom";
import {ABOUT_RESOURCES, CONTACT_US, NEED_HELP, REQUIRED_FIELDS} from "../../data/aside/aside";
import {Col} from "react-bootstrap";
import {Aside} from "../../components/Aside";

const ResourcesContainer = styled.div`      
   display: flex;
   flex-direction: column;
   
`;

const ResourcesSection = styled.div`      
   padding-right: 0px;
   margin-top: 2%;
   width: 100%;  
     
   &:not(:last-child) {
      margin-bottom: 5%;
   }
     
   p.externalLinks {
      margin-top:0;      
   }
      
   @media (min-width: 1024px){             
      p {
         margin-top: 0;       
      }
         
      &:not(:last-child) {
         margin-bottom: 1%;
      }
   }
`;

const ResourcesRow = styled.div`      
   margin-bottom: 1%;
      
   @media (min-width: 768px){             
      p {
         margin-top: 0;       
      }
   }
`;

const ResourcesLink = styled(Link)` 
   font-size: 1rem;
   font-weight: bold;   
   color: ${COLORS.dark_grey_blue};     
`;

const VideoContainer = styled.div`     
   p:first-of-type {
      margin-top: 0;     
   }
   p:last-of-type {
      margin-bottom: 1%;     
   }     
`;

const InnerVideoContainer = styled.div` 
   display: flex;  
   justify-content: center;
   margin-top: 2%;
      
   @media (min-width: 1024px){ 
      margin-bottom: 2%; 
   }
`;


export const Resources: React.FC = () => {
    return (
        <Fragment>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>

                        <ResourcesContainer className={"resources-container"}>
                            <ResourcesSection className={"resources-section"}>
                                <PDS_H5>General Resources</PDS_H5>
                                {
                                    GENERAL_RESOURCES.map((resourceLink: IResourceLink, index:number) => {
                                        return <ResourcesRow key={index}>
                                            <ResourcesLink to={resourceLink.link} target={"_blank"} rel={"noopener noreferrer"}>
                                                {parse(resourceLink.title)} </ResourcesLink>
                                        </ResourcesRow>

                                    })
                                }

                            </ResourcesSection>
                            <ResourcesSection className={"resources-section"}>
                                <PDS_H5>SAS&reg; Tools</PDS_H5>
                                {
                                    SAS_TOOLS.map((resourceLink: IResourceLink, index:number) => {
                                        return <ResourcesRow key={index}>
                                            <ResourcesLink to={resourceLink.link} target={"_blank"} rel={"noopener noreferrer"}>
                                                {parse(resourceLink.title)} </ResourcesLink>
                                        </ResourcesRow>

                                    })
                                }

                            </ResourcesSection>
                            <ResourcesSection className={"resources-section"}>
                                <PDS_H5>Data Providers</PDS_H5>
                                {
                                    DATA_PROVIDERS.map((resourceLink: IResourceLink,index:number) => {
                                        return <ResourcesRow key={index}>
                                            <ResourcesLink to={resourceLink.link} target={"_blank"} rel={"noopener noreferrer"}>
                                                {parse(resourceLink.title)} </ResourcesLink>
                                        </ResourcesRow>

                                    })
                                }

                            </ResourcesSection>
                            <ResourcesSection className={"resources-section"}>
                                <PDS_H5>External Links</PDS_H5>
                                <PDS_P className={"externalLinks"}>
                                    {EXTERNAL_LINK_TEXT}
                                </PDS_P>
                            </ResourcesSection>
                            <VideoContainer className={"video-container"}>
                                <PDS_P>{parse(CYTEL_VIDEO_YOU_TUBE.videoText)}</PDS_P>
                                <PDS_P>Read more from the published article, {parse(CYTEL_VIDEO_YOU_TUBE.videoLink)}
                                </PDS_P>
                                <InnerVideoContainer className={"inner-video-container"}>
                                    <iframe allowFullScreen={true}
                                            src="https://www.youtube.com/embed/I0FewQzLzkg" width="550px"
                                            frameBorder="0" id="cytel_video"
                                            title="Synthetic Controls in Clinical Trials" className="video"
                                            height="250px"></iframe>

                                </InnerVideoContainer>
                            </VideoContainer>
                        </ResourcesContainer>

                    <Col md={4} lg={3}>
                        <Aside  aboutResources={true}/>
                    </Col>

                </PageWrapper>
            </ContentPage>
        </Fragment>
    )
}