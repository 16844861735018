/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AccessDataStudyInfo
 *
 * Purpose: component to show access data studies
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 29Feb2024 sasjxa file created
 * 11Apr2024 sasjxa fix formatting issues with Clinical Trials Gov feed (MPM-5466)
 * 14Apr2024 craig  cleaned up some errors that show up in the console
 * 11Jun2024 craig  removed user prop to AccessDataDetailsAndFiles
 * 12Jun2024 sasjxa add parsing to clin trial id
 * 20Jun2024 sasjxa use common function for determing whether donation has nct id
 * 21Aug2024 sasjxa add spacing for multiple clin trial ids
 * 03Oct2024 craig  fixed key so that "unique key" message goes away
 * 16Oct2024 sasjxa add style to div for nct ids to display inline
 * 12Oct2024 sasjxa remove Study Phase title for Real World Data
 * \******************************************************************************/
import React, {useState} from "react";

import {Col} from "react-bootstrap";
import styled from "styled-components";
import {COLORS, FONT_SIZES} from "../styled/StyleConstants";
import {IAccessData} from "../../model/donations/IAccessData";
import {AccessDataDetailsAndFiles} from "./AccessDataDetailsAndFiles";
import parse from "html-react-parser";
import {hasClinGovId} from "../../constants/CommonFunctions";


const DonationContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;

    /*  max-width: 1440px; */
`;

const AccessDatItemSpaceBetweenRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;


    a.clinTrial {
        color: ${COLORS.dark_grey_blue};
    }

`;
const ResultHeader = styled(AccessDatItemSpaceBetweenRow)`
    width: 100%;

    span.study-title {
        font-size: ${FONT_SIZES.px16};
    }
`;

const CancerTypeCol = styled.div`
    width: 72%;
    background-color: ${COLORS.dark};

    span {
        font-size: ${FONT_SIZES.px14};
    }
`;

const StudyPhaseCol = styled.div`
    width: 12%;

    span {
        font-size: ${FONT_SIZES.px14};
    }
`;

const CompletionCol = styled.div`
    width: 16%;

    span {
        font-size: ${FONT_SIZES.px14};
    }
`;

const DownloadCol = styled.div`
    width: 28%;
    background-color: ${COLORS.filter_gray};

    span.access-title {
        font-size: ${FONT_SIZES.px12};
        text-align: right;
        color: ${COLORS.dark_grey_blue};
    }
`;

const NCINumbers = styled.div`
   display:inline;
`;

const NCTCol = styled.div`
    width: 72%;
    background-color: ${COLORS.filter_gray_medium};   

    span.access-title {
        display: inline;
        font-size: ${FONT_SIZES.px12};
        font-weight: bold;
        color: ${COLORS.dark_grey_blue};
    }

    span.upload {
        font-weight: 400;
    }
`;

interface AccessDataStudiesProps {
    donation: IAccessData
    isAccessData: boolean
}

export const AccessDataStudyInfo: React.FC<AccessDataStudiesProps> = ({
                                                                          donation, isAccessData
                                                                      }: AccessDataStudiesProps) => {

    const [dashboardMsg, setDashboardMsg] = useState<string>("");

    const AVAILABLE_NCI: string = "Available via NCI by Request";
    const PDS_DOWNLOAD: string = "Available for Download";
    const CLIN_GOV_URL: string = "https://clinicaltrials.gov/ct2/show/";
    const REAL_WORLD_DATA_VALUE = "Real World Data"

    const getAvailableMessage = (donation: IAccessData) => {
        if (donation.isNci) {
            return AVAILABLE_NCI;
        } else {
            return PDS_DOWNLOAD;
        }
    }

    const formatUploadedDate = (donation: IAccessData) => {
        if (donation.subHeader?.uploaded != null) {
            {
                return (
                    new Date(donation.subHeader?.uploaded).toLocaleDateString('en-us', {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric"
                    }).replace(/\//g, '-')
                )
            }
        }
    }

    const getTrialStartYear = (donation: IAccessData) => {
        if (donation.header?.trialStartYears !== null && donation.header?.trialStartYears.length > 0) {
            return (
                new Date(donation.header.trialStartYears[0]).toLocaleDateString('en-us', {
                    month: "long",
                    year: "numeric"
                })

            )
        }
    }

    const isRealWorldData = (studyPhases: string[]) => {
        if (!studyPhases?.length || studyPhases.length > 1){
            return false;
        }
        const firstValue = studyPhases[0];
        if (firstValue.trim() == REAL_WORLD_DATA_VALUE){
            return true;
        }
        else return false;
    }


    const buildStudyInfoRow = (donation: IAccessData) => {
        return <ResultHeader>
            <CancerTypeCol className="ps-3 pe-2 pt-1 pb-1">
                <span className="share access-title">Types(s) of Cancer</span>
                <span
                    className="study-title share  share-header">{parse(donation.header?.tumorTypes?.join(", "))}</span>
            </CancerTypeCol>




            <StudyPhaseCol className=" study-phase ps-2 pe-2 pt-1 pb-1 ">

                {!isRealWorldData(donation.header?.cancerPhases) && (
                    <span className=" share access-title">Study Phase</span>
                )}


                <span
                    className="study-title share share-header">{parse(donation.header?.cancerPhases?.join(" "))}</span>
            </StudyPhaseCol>

            <CompletionCol className=" completion-date ps-2 pt-1 pb-1">
                <span className="share access-title">Completion Date</span>
                <span
                    className="study-title share share-header">{getTrialStartYear(donation)}</span>
            </CompletionCol>
        </ResultHeader>
    }

    const getHref = (clinTrialId: string) => {
        return CLIN_GOV_URL + clinTrialId
    }

    const getClinGovId = (donation: IAccessData) => {
        return (
            donation.subHeader.clinicalGovId.map((clinTrialId: string) => {
                return <NCINumbers key={clinTrialId} className="">
                    <a href={getHref(clinTrialId)}  target={"_blank"} title={clinTrialId}
                       className="clinTrial study-contents   result-info-link">{parse(clinTrialId.trim())}</a>
                    <div className={"spacer"}>          </div>
                </NCINumbers>

            })
        )
    }

    const buildClinTrialRow = (donation: IAccessData) => {
        return (
            <ResultHeader className="">
                <NCTCol className=" study-name  pt-1 pb-1 ps-3 pe-2">
                    <ResultHeader className=" ps-0 pe-0 ">

                        {!hasClinGovId(donation) && (
                            <span
                                className=" access-title"></span>
                        )}

                        {hasClinGovId(donation) && isAccessData && (
                            <span
                                className=" access-title">
                                {getClinGovId(donation)}
                            </span>
                        )}

                        {hasClinGovId(donation) && !isAccessData && (
                            <span
                                className=" access-title">{parse(donation.subHeader?.clinicalGovId?.join(" "))}</span>
                        )}

                        <span className="access-title upload">Uploaded on {formatUploadedDate(donation)}</span>
                    </ResultHeader>
                </NCTCol>

                <DownloadCol className=" study-phase pt-1 pb-1  ps-2 pe-2 ">
                    <span className="share access-title">{getAvailableMessage(donation)}</span>

                </DownloadCol>
            </ResultHeader>

        )
    }

    const buildDonationRow = (donation: IAccessData) => {
        return (
            <AccessDataDetailsAndFiles donation={donation} isAccessData={isAccessData}/>
        )
    }

    const buildResults = () => {
        return (
            <>
                <DonationContainer className={"mb-1"} key={"results-donations"}>
                    {buildStudyInfoRow(donation)}
                    {buildClinTrialRow(donation)}
                    {buildDonationRow(donation)}
                </DonationContainer>
            </>
        )
    }

    return (
        <>
            <Col className="" name={"formLayoutColumn"} key={"results-column"}>
                {buildResults()}
            </Col>
        </>

    );

}
