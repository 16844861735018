/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Share
 *
 * Purpose: NCI Upload Approval
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 20Jun2023 sasjxa file created
 * 22Jan2024 sasjxa add ability to upload file and submit request
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 04Apr2024 craig  switched to react-error-boundary
 * 11Jun2024 sasja  correct link for back button
 * 01Nov2024 craig  cleaned up warnings
 \******************************************************************************/
import React, {useMemo, useState} from "react";
import {INciRequestFullData} from "../../model/user/IUser";
import {
    BackButton,
    ContentPage,
    FormLayoutColumn,
    LinkButton,
    PageWrapper,
    PDS_H5,
    PDS_P,
    PDSSubmitButton,
    ShareDataButtonContainer,
    ShareDataButtonGroup
} from "../../components/styled/StyledComponents";
import {Col, Container, Form, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
// @ts-ignore
import styled from "styled-components";
import {ErrorMessage, Formik} from "formik";
import {HEADER_NCI, RESPONSE_NCI} from "../../data/formList";
import * as Yup from "yup";
import {Aside} from "../../components/Aside";
import {useRequest} from "../../helper/useRequest";
import {ConfirmDialog} from "../../components/dialogs/ConfirmDialog";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";
import {InfoDialog} from "../../components/dialogs/InfoDialog";
import {IInfoDialog, NCI_UPLOAD} from "../../data/infoDialog";

const ButtonSpacingRow = styled(Row)`
    padding-bottom: 2%;
`;


const SharedHeader = styled(PDS_H5)`
    margin-top: 2%;
`;

export const NCIUploadApproval: React.FC = () => {
    const navigate = useNavigate();
    const {requestId} = useParams();
    const { showBoundary } = useErrorBoundary();
    const [showSubmitNCIRequest, setSubmitNCIRequest] = useState<boolean>(false);
    const [showFileTypes, setShowFileTypes] = useState<boolean>(false);
    const [fileTypeArray, setFileTypeArray] = useState<IInfoDialog[]>([]);
    const [fileUploadSuccess, setFileUploadSuccess] = useState<boolean>(false);
    const [nciRequestData, setNciRequestData] = useState<INciRequestFullData>();

        // toggleDialog - show/hide submit dataset dialog
    const toggleSubmitNCIRequestDialog = () => {
        setSubmitNCIRequest(!showSubmitNCIRequest);
    }


    /**
     * handleSubmitDatasetDialog - user selects submit in the confirm dialog
     */
    const handleSubmitNCIDialog = () => {
        setSubmitNCIRequest(!showSubmitNCIRequest);
        if (fileUploadSuccess) {
            setSubmitAction();
        }
    }


    /**
     * request section for page load
     */
    const [requestState, setRequestAction] = useRequest({
        url: process.env.REACT_APP_API_URL + "/api/nci/requests/processRequest/" + requestId,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
    });
    const {isLoading, data, error} = requestState;

    /**
     * on page load, get user cart items from api
     */
    useMemo(() => {
        if (requestId !== undefined) {
            setRequestAction();
        }
    }, []);

    useMemo(() => {
        if (data != null) {
            setNciRequestData(data);
        }
    }, [data]);

    /*
     * request to handle file upload
     */
    const handleUploadSuccess = () => {
        setFileUploadSuccess(true);
    }

    const handleUploadError = (error: object) => {
        showBoundary(error);
    }
    const actionURL = process.env.REACT_APP_API_URL + "/api/nci/requests/oaUpload/" + requestId;
    const [requestActionState, setUpdateNCIAction] = useRequest({
        url: actionURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        headers: {
            'Accept': "application/json",
            'Content-Type': "multipart/form-data",
            'Access-Control_Allow-Origin': "*",
            'Access-Control-Allow-Credentials': "true",
        },
        onError: handleUploadError,
        onSuccess: handleUploadSuccess
    })


    /*
     * request for submit
     */
    const handleSubmitSuccess = () => {
        navigate(BASE_PATH + 'SubmissionResponse', {state: {header: HEADER_NCI, response: RESPONSE_NCI}});
    }

    const handleSubmitError = (error: object) => {
        showBoundary(error);
    }
    const submitActionURL = process.env.REACT_APP_API_URL + "/api/nci/requests/submit/" + requestId;
    const [requestSubmitState, setSubmitAction] = useRequest({
        url: submitActionURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleSubmitError,
        onSuccess: handleSubmitSuccess
    })

    const schema = Yup.object().shape({
        fileUploaded: Yup.mixed().required('Please select a file'),
    });


    const toggleFileTypesDialog = () => {
        setShowFileTypes(!showFileTypes);
    }


    const handleFileTypeDialog = () => {
        setFileTypeArray(NCI_UPLOAD);
        setShowFileTypes(true);
    };


    const buildForm = () => {
        return (
            <Formik
                validationSchema={schema}
                onSubmit={() => {
                }}
                initialValues={{
                    fileUploaded: null,
                }}

                validateOnChange={false}
                validateOnBlur={false}>
                {({
                      handleSubmit,
                      touched,
                      errors,
                      setFieldValue
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn lg={12}>
                            <FormLayoutColumn lg={8}>
                                <Row className="mx-auto">
                                    <Row className="mx-auto mb-3">

                                        <Row className="mb-3 ">
                                            <Form.Group as={Col} lg={12} required className="mb-3 "
                                                /*controlId="formFileUpload"*/>
                                                <Form.Label className="required" column="sm">
                                                    <LinkButton type="button"
                                                                className="btn btn-link bold-label required"
                                                                onClick={() => {
                                                                    handleFileTypeDialog();
                                                                }}>
                                                        Entity Approval (See Valid File Types)

                                                    </LinkButton>
                                                </Form.Label>


                                                <Form.Control id="fileUploaded " type="file" size="sm"
                                                              name={"fileUploaded"}
                                                              accept=".pdf,.doc,.docx"
                                                              value={undefined}
                                                              onChange={(event: any) => {
                                                                  setFieldValue("fileUploaded", event.currentTarget.files[0]);
                                                                  if ( event.currentTarget.files[0]!== null) {
                                                                      let data: FormData = new FormData();
                                                                      data.append("file", event.currentTarget.files[0]);
                                                                      //console.log(values.fileUploaded);
                                                                      setUpdateNCIAction(data);
                                                                  }
                                                              }}
                                                              isValid={touched.fileUploaded && !errors.fileUploaded}
                                                />
                                            </Form.Group>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a subject.
                                            </Form.Control.Feedback>
                                            <ErrorMessage name={"fileUploaded"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>


                                        </Row>

                                    </Row>
                                </Row>
                            </FormLayoutColumn>
                        </FormLayoutColumn>
                        <ButtonSpacingRow>
                            <ShareDataButtonContainer>
                                <BackButton variant="secondary" className="btn-submit" type="button" onClick={() => {
                                    navigate(BASE_PATH + "nciDownloadApproval/" + requestId)
                                }}>
                                    Back
                                </BackButton>
                                <ShareDataButtonGroup>
                                    <PDSSubmitButton variant="success" className="btn-submit" type="button" disabled={!fileUploadSuccess}
                                                     onClick={() => {
                                                         toggleSubmitNCIRequestDialog();
                                                     }}>
                                        Submit
                                    </PDSSubmitButton>
                                </ShareDataButtonGroup>

                            </ShareDataButtonContainer>
                        </ButtonSpacingRow>


                    </Form>

                )}
            </Formik>
        )
    }


    return (
        <Container className="form-container" fluid>
           <InfoDialog title={"Valid File Types"} body={<p>pdf</p>} fileTypes ={fileTypeArray}
                       show={showFileTypes} showHide={toggleFileTypesDialog}/>
            <ConfirmDialog title={"Submit NCI Request"} body={<p>Are you ready to submit?</p>}
                           show={showSubmitNCIRequest} showHide={toggleSubmitNCIRequestDialog}
                           submitAction={handleSubmitNCIDialog} primaryButtonText={"Yes"}
                           secondaryButtonText={"No"}/>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <Col md={8} lg={9} name={"shareDataColumn"}>
                        <SharedHeader>Upload Organization Approval</SharedHeader>
                        <PDS_P>Please write in your name on the line for name of Data Requestor and your Entity's name
                            on
                            the line for name of Entity
                            at the top of the first page of your printed copy of the National Cancer Institute
                            Organization
                            Approval and have the form manually signed by the person you have identified as your
                            Authorized
                            Representative in this data request. Then create an electronic copy of the signed Entity
                            Approval
                            and upload the electronic copy to complete your data request. PLEASE NOTE THAT IF YOU SIGN
                            THE
                            ENTITY APPROVAL, YOU WILL BE PERSONALLY LIABLE FOR ANY CLAIMS AND CONCERNS THAT YOUR ENTITY
                            MAY RAISE IN CONNECTION WITH YOUR ACCESS TO AND USE OF THE NCI DATASETS THAT YOU HAVE
                            REQUESTED. The electronic copy of the signed Entity Approval can be uploaded using the
                            button
                            below.
                        </PDS_P>

                        {nciRequestData?.oaUploaded && (
                            <PDS_P>You have already uploaded your signed copy of the Entity Approval. Please press
                                "Submit" to
                                submit your NCI Request.</PDS_P>

                        )}

                        {isLoading && <p>Loading ...</p>}
                        {error && <p>Error</p>}
                        {data && buildForm()}


                    </Col>

                    <Col md={4} lg={3}>
                        <Aside/>
                    </Col>
                </PageWrapper>
            </ContentPage>
       </Container>
    )
}