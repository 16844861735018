/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: DisabledContactUs
 *
 * Purpose: handle a disabled user's contact us
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 23Aug2024 craig File created.
 \******************************************************************************/
import React, {useMemo, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useErrorBoundary} from "react-error-boundary";
import {BASE_PATH} from "../../constants";
import {
    CONTACT_US_REASONS,
    FormList,
    HEADER_DISABLED_CONTACT_US, HEADER_DISABLED_CONTACT_US_FAILED,
    RESPONSE_DISABLED_CONTACT_US, RESPONSE_DISABLED_CONTACT_US_FAILED,
    SUBMIT
} from "../../data/formList";
import {useRequest} from "../../helper/useRequest";
import {Col, Container, Form, Row} from "react-bootstrap";
import {ContentPage, FormLayoutColumn, PageWrapper, PDS_H5} from "../../components/styled/StyledComponents";
import {ErrorMessage, Formik, FormikProps} from "formik";
import {Email} from "../../components/forms/Email";
import {ClearAndSubmitButtonContainer} from "../../components/buttonContainer/ClearAndSubmitButtonContainer";
import styled from "styled-components";

const DisabledHeader = styled(PDS_H5)`
    margin-top: 2%;
`;

export const DisabledContactUs: React.FC = () => {

    const {email, status} = useParams();
    const navigate = useNavigate();
    const {showBoundary} = useErrorBoundary();
    const formikRef = useRef<FormikProps<any>>(null);

    const [emailAddr, setEmailAddr] = useState<string | undefined>("");
    const [message, setMessage] = useState<string>("");
    const [subject, setSubject] = useState<string>("");
    const [subjectOther, setSubjectOther] = useState<string>("");

    useMemo(() => {
        setEmailAddr(email);
        setSubject("Other");
        setSubjectOther("Disabled Account");
        setMessage(" My account is disabled. Please reset the account for user: " + email);
    }, []);

    /**
     * handle error - unsuccessful save of data
     *
     * @param error - error message
     */
    const handleError = (error: object) => {
        navigate(BASE_PATH + 'SubmissionResponse', {
            state: {
                header: HEADER_DISABLED_CONTACT_US_FAILED,
                response: RESPONSE_DISABLED_CONTACT_US_FAILED
            }
        });
    }

    /**
     * handle successful submission of request
     */
    const handleSuccess = () => {
        navigate(BASE_PATH + 'SubmissionResponse', {
            state: {
                header: HEADER_DISABLED_CONTACT_US,
                response: RESPONSE_DISABLED_CONTACT_US
            }
        });
    }

    /**
     * // build the select list
     * @param listType
     */
    const buildSelectList = (listType: FormList[]) => {
        let optionTemplate = listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
        return optionTemplate;
    }

    /**
     * submit the request
     */
    const [requestState, setContactUs] = useRequest({
        url: process.env.REACT_APP_API_URL + "/api/public/users/contactus/disabled",
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })

    const buildForm = () => {
        return (
            <Formik innerRef={formikRef}
                    enableReinitialize
                    onSubmit={async (values) => {
                        //console.log(values);
                        setContactUs(JSON.stringify({
                            email: values.email,
                            subject: values.subject,
                            subjectOther: values.subjectOther,
                            userComment: values.message
                        }));
                    }}
                    initialValues={{
                        email: emailAddr ?? '',
                        subject: subject ?? '',
                        subjectOther: subjectOther ?? '',
                        message: message ?? '',
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      resetForm,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn lg={12}>
                            <FormLayoutColumn lg={6}>
                                <Row className="mt-3 mb-3">
                                    <Email fieldName={"email"} label={"Email"} value={values.email}
                                           disabled={true} touched={touched}
                                           errors={errors} size={6} handleChange={handleChange}/>
                                </Row>

                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col}
                                                controlId="formSubject">
                                        <Form.Label className="required" column="sm">Subject </Form.Label>
                                        <Form.Select size="sm"
                                                     name={"subject"}
                                                     value={values.subject}
                                                     disabled={true}
                                                     isValid={touched.subject && !errors.subject}
                                                     onChange={e => {
                                                         handleChange(e);
                                                     }}>
                                            {buildSelectList(CONTACT_US_REASONS)}
                                        </Form.Select>
                                        <ErrorMessage name={"subject"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>
                                <Row className=" mt-3 mb-3 ">
                                    <Form.Group as={Col}
                                                controlId="">
                                        <Form.Label className="required" column="sm">Other Subject </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"subjectOther"}
                                                      type="text"
                                                      disabled={true}
                                                      value={values.subjectOther}
                                                      onChange={e => {
                                                          handleChange(e);
                                                      }}
                                                      isValid={touched.subjectOther && !errors.subjectOther}/>
                                        <ErrorMessage name={"subjectOther"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>
                                <Row className="mt-3 mb-5 ">
                                    <Form.Group as={Col}
                                                controlId="formMessage">
                                        <Form.Control as="textarea" rows={5} size="sm" required
                                                      name={"message"}
                                                      disabled={true}
                                                      type="text"
                                                      defaultValue={values.message}
                                                      isValid={touched.message && !errors.message}
                                                      onChange={e => {
                                                          handleChange(e);
                                                      }}/>
                                        <ErrorMessage name={"message"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                            </FormLayoutColumn>
                        </FormLayoutColumn>
                        <ClearAndSubmitButtonContainer enableClear={false} submitButtonText={SUBMIT}/>
                    </Form>

                )}
            </Formik>

        )
    }

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <Col md={8} lg={9} name={"disabledUserColumn"}>
                        <DisabledHeader>Your account is disabled. Please submit to get re-enabled</DisabledHeader>
                        <FormLayoutColumn>
                            {buildForm()}
                        </FormLayoutColumn>
                    </Col>
                </PageWrapper>
            </ContentPage>
        </Container>
    );
}