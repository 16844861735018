/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Footer
 *
 * Purpose: footer for PDS application
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 22Feb2023 craig File created.
 * 02Jun2023 sasjxa move styled components out of FC
 * 08Mar2024 craig  Added basepath
 * 21Aug2024 craig   moved to useContext(PDSContext) for user session
 \******************************************************************************/
import React, {useContext} from "react";
import {Col, Row, Image} from "react-bootstrap";
import {Link} from "react-router-dom";
// @ts-ignore
import styled from 'styled-components';
import {PDSGroup} from "../model/user/IUser";
import {COLORS, FONT_SIZES, SIZES} from "./styled/StyleConstants";
import {BASE_PATH} from "../constants";
import {PDSContext, usePDSContext} from "../context/PDSContext";


const FooterWrapper = styled.div`
   width: 100%;
   margin-left: 0;
   margin-right: 0;    
   padding-top: 2%;
   background-color: ${COLORS.light_grey};
   box-shadow: 0 50vh 0 50vh #F1F1F1;
      
   @media (min-width: 768px){         
     width: 100%;    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
   
    padding-top: 2%;
    box-shadow: 0 50vh 0 50vh #F1F1F1;
   } 

`;

const FooterBody = styled.div`
  max-width:  ${SIZES.MAX_WIDTH}; 
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 2%;
    padding-left: 2%;
    padding-bottom: 1%; 
`;

const FooterMain = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column; 
    
`;



const FooterSection = styled.div`
   width: 100%;
   display: flex;
   flex-direction: row;      
   padding: 0;
    margin: 0; 
`;

const FooterTop = styled.div`
  width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 1rem;
    justify-content: space-between;
`;



const FooterInfo = styled(Row)`
   @media (min-width: 1024px){
      width:100%;
      display: flex; 
      justify-content: space-between;
      margin: 0;
      padding: 0;
   }
    
`;

const FooterNav = styled.div`
   @media (min-width: 1024px){
      width:50%;
      display: flex; 
      justify-content: space-between;
      margin: 0;
      padding: 0;
      
     
   }
    
`;

const CEOInfo = styled(Col)`
    margin-top: 3%; 
    
     @media (min-width: 1024px){  
       margin-top: 1%;
       a {         
         padding: 0;
         margin: 0;
       }
     }   
`;

const CEOText = styled.div`
   width: 90%;
   margin-bottom: 3%;
      
   @media (min-width: 1024px){           
      padding: 0;
      margin: 0;
      width: 40%;   
      span {
        padding-left: 0;
      }
   } 
`;

const CopyrightSection = styled(Col)`
   align-items: center;
      
   @media (min-width: 768px){ 
      display: flex;
      flex-direction: column;
      margin-top: 3%;
   }
      
   @media (min-width: 1024px){ 
      width: 100%;
      justify-content: flex-end; 
      margin-left: auto;
      align-items: flex-start;
      padding-right: 0;
   }
`;

const CopyrightText = styled.span`
   margin-bottom: 3%;
      
   @media (min-width: 768px){ 
      margin-left: auto;
      margin-bottom: 0;
   }
`;

const TermsPrivacy = styled(Col)`
    margin-bottom: 1%;
        
    @media (min-width: 768px){ 
       width: 60%;         
       display: flex;
       justify-content: space-between;
        margin-left: auto;
    }
      
    @media (min-width: 1024px){ 
       width: 30%; 
    }
`;

const FooterLink = styled(Link)`
   :first-child {
      padding-right: 30px;
   }
      
   font-weight: bold;
   color: black;
   padding-top: 10px;
   font-size: 12px;
`;

const NavMenuItem = styled(Link)`
   padding-left: 0;
   padding-right: 0;

   :hover {
      color: #483D8B;
   }
      
   text-decoration: none;
   font-size: ${FONT_SIZES.px16};
   font-weight: 400;
   color: ${COLORS.black};
`;

const NavMenuItemGreen = styled(NavMenuItem)`
   color: ${COLORS.turtle_green};
   font-weight: bold;
`;

const SocialLogo = styled(Image)`
   height: 25px;
`;

const SocialCol = styled.div`     
   display: flex;        
   padding: 0;
      
   @media (min-width: 768px){ 
      display: flex;
      justify-content: flex-end;
      
   }
      
   @media (min-width: 1024px){ 
      img:first-of-type{
         padding-right: 20px;
      }
      img:last-of-type{
         padding-right: 0px;
      }
   }
`;

const SocialColContainer = styled(Row)` 
   display: flex;
   justify-content: flex-end;
   padding: 0;        
   margin-left:auto; 
      
   @media (min-width: 768px){       
      justify-content: flex-end;
      width: 15%;
        
      a:last-of-type {
          margin-left:10px; 
      }
   }   
      
   @media (min-width: 1024px){       
      justify-content: space-between;
        width: 6%;
    }
`;

export const Footer: React.FC = (props) => {
    const {sessionUser} = usePDSContext();

    const buildNavBar = () => {
        return <FooterTop className={"footer-top"}>
            <FooterNav>
            <NavMenuItem to={BASE_PATH + "home"}>Home</NavMenuItem>
            <NavMenuItem to={BASE_PATH + "access"}>Access Data</NavMenuItem>
            <NavMenuItem to={BASE_PATH + "share"}>Share Data</NavMenuItem>
            <NavMenuItem to={BASE_PATH + "resources"}>Resources</NavMenuItem>
            <NavMenuItem to={BASE_PATH + "contactUs"}>Contact Us</NavMenuItem>

                {sessionUser.pdsGroup === PDSGroup.Guest  &&(
                    <>
            <NavMenuItem to={BASE_PATH + "login"}>Sign In</NavMenuItem>

                <NavMenuItemGreen to={BASE_PATH + "registration"}>Registration</NavMenuItemGreen>
                    </> )}
            </FooterNav>
            <SocialCol>
                <Link  className = "pe-4"to={"https://twitter.com/ProjDataSphere"} target={"_blank"} rel={"noopener noreferrer"}><SocialLogo
                    src={"/images/icons/twitter.png"}/></Link>
                <Link to={"https://www.linkedin.com/company/project-data-sphere-llc/"} target={"_blank"} rel={"noopener noreferrer"}><SocialLogo
                    src={"/images/icons/linkedin.png"}/></Link>
            </SocialCol>
        </ FooterTop>
    }



    const buildSocialMedia = () => {
        return <SocialColContainer className="mt-lg-5" >
            <SocialCol className={"social-col"}>
                <Link to={"https://twitter.com/ProjDataSphere"} target={"_blank"} rel={"noopener noreferrer"}><SocialLogo
                    src={"/images/icons/twitter.png"}/></Link>
                <Link to={"https://www.linkedin.com/company/project-data-sphere-llc/"} target={"_blank"} rel={"noopener noreferrer"}><SocialLogo
                    src={"/images/icons/linkedin.png"}/></Link>
            </SocialCol></SocialColContainer>
    }

    return <FooterWrapper className={"footer-wrapper"}>
        <FooterBody className={"footer-body"}>
            <FooterMain className={"footer-main"}>

                {buildNavBar()}


            <FooterSection>
                <FooterInfo name={"footer-info"}>
                    <CEOInfo name={"ceo-info"}>
                        <Row >
                            <Link to={"https://www.ceoroundtableoncancer.org/"} target={"_blank"}>
                                <Image src={"/images/ceort-logo-transp.png"} height={"70px"}/>
                            </Link>
                        </Row>
                        <Row>
                            <CEOText>
                                <span>The purpose of PDS is to support and at all times operate exclusively for the
                                benefit of the CEO Roundtable.</span>
                            </CEOText>
                        </Row>
                    </CEOInfo>
                    <CopyrightSection name={"Copyright-section"}>
                        <CopyrightText>&copy; {new Date().getFullYear()} Project Data
                            Sphere</CopyrightText><CopyrightText>All rights reserved</CopyrightText>
                        <TermsPrivacy name={"terms-privacy"}>
                            <FooterLink to={BASE_PATH + "privacy"}>Privacy Policy</FooterLink>
                            <FooterLink to={BASE_PATH + "terms"}>Terms of Use</FooterLink>
                        </TermsPrivacy>
                    </CopyrightSection>
                </FooterInfo>
            </FooterSection>
            </FooterMain>
            </FooterBody>

    </FooterWrapper>

}