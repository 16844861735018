/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: NCI Request
 *
 * Purpose: NCI Data Request
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 25May2023 sasjxa file created
 * 31May2023 sasjxa add values to select components
 * 02Jun2023 sasjxa support for displaying other fields
 * 15Jun2023 sasjxa use yup.when for other field validation
 * 23Jun2023 sasjxa refactor to use new aside component
 * 18Ded2023 craig  Added calls to API to populate and save
 * 17Jan2024 sasjxa add onSuccess and onError methods
 * 23Jan2024 sasjxa display nct numbers under introductory text
 * 26Feb2024 sasjxa add clear button functionality (MPM-5425)
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 12Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 04Apr2024 craig  Switched to react-error-boundary
 * 11Apr2024 craig  Changed handleSubmitSuccess to navigate to the returned requestId
 * 30Apr2024 sasjxa switched to react-error-boundary
 * 10Jun2024 sasjxa add address fields to validation schema
 * 11Jun2024 craig  fix trial number list bug
 * 11Jun2024 sasjxa correct initial value for entity type
 * 14Jun2024 craig  Fixed auth user problem clearing out fields on losing focus
 * 21Aug2024 craig   moved to useContext(PDSContext) for user session
 * 31Oct2024 craig  Added isWaitingForResponse to prevent new submit before response
 * 01Nov2024 craig  Added length check for form variables
 \***************************************************************************************/
import React, {useMemo, useRef, useState} from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import * as Yup from 'yup';
import {ErrorMessage, FieldArray, FieldArrayRenderProps, Formik, FormikProps} from "formik";

import {
    ContentPage,
    FormLayoutColumn,
    FormSectionTitle,
    PageWrapper,
    PDS_H5,
    PDS_P,
    TextAreaCountRow,
    UserAgreementRow,
    UserAgreementTitle
} from "../../components/styled/StyledComponents";
import "../forms/forms.css";
import {NCIAttestation} from "../../components/NCIAttestation";
import {COUNTRY, FormList, NCI_ENTITY_TYPE, OTHER_OPTION, SUBMIT} from "../../data/formList";
import {useNavigate, useParams} from "react-router-dom";
import {Aside} from "../../components/Aside";
import {useRequest} from "../../helper/useRequest";
import {INciAuthUsers, INciDataRequestTrialNumbers, INciRequestCart, INciRequestFullData} from "../../model/user/IUser";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";
import {getEncodedValue} from "../../constants/CommonFunctions";
import {ClearAndSubmitButtonContainer} from "../../components/buttonContainer/ClearAndSubmitButtonContainer";
import {usePDSContext} from "../../context/PDSContext";


export const NCIRequest: React.FC = () => {
    const navigate = useNavigate();
    //constants
    const {requestId} = useParams();
    const {showBoundary} = useErrorBoundary();
    const {sessionUser} = usePDSContext();
    const [nciRequestDataById, setNciRequestDataById] = useState<INciRequestFullData>();
    const [researchPlanDescription, setResearchPlanDescription] = useState("");
    const [researchPlanTitle, setResearchPlanTitle] = useState("");
    const [entity, setEntity] = useState("");
    const [entityType, setEntityType] = useState("");
    const [entityTypeOther, setEntityTypeOther] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [country, setCountry] = useState("");
    const [authUsers, setAuthUsers] = useState<INciAuthUsers[]>([{
        name: "",
        email: ""
    }]);
    const [authorizedRepresentative, setAuthorizedRepresentative] = useState("");
    const [authorizedRepEmail, setAuthorizedRepEmail] = useState("");
    const [researchPlanDescriptionCount, setResearchPlanDescriptionCount] = useState(0);
    const [showEntityTypeOther, setShowEntityTypeOther] = useState(false);
    const [isCheckedTermsOfUse, setIsCheckedTermsOfUse] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const TERMS_OF_USE: string = "I have read the NCI Supplemental Terms " +
        "agreement in full.I agree to comply with the conditions as stated in the agreement.";
    const maxResearchPlanDescriptionCount = 1000;
    const formikRef = useRef<FormikProps<any>>(null);

    //this will disable the submit button while waiting for the response after submit
    const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);

    /**
     * handle error (showBoundary)
     *
     * @param error - error object
     */
    const handleError = (error: object) => {
        showBoundary(error);
        setIsWaitingForResponse(false);
    }

    /**
     * build the save url
     */
    const getSaveUrl = () => {
        const baseUrl: string = "/api/nci/requests/save";
        if (requestId !== undefined) {
            return baseUrl + "/" + requestId;
        } else {
            return baseUrl;
        }
    }

    /**
     * handle successful save of data
     * @param data - request id
     */
    const handleSubmitSuccess = (data: number) => {
        setIsWaitingForResponse(false);
        navigate(BASE_PATH + "nciDownloadApproval/" + data);
    }

    /**
     * handleSuccess to get request data
     *
     * @param nciRequestData - request data
     */
    const handleSuccess = (nciRequestData: INciRequestFullData) => {
        setIsWaitingForResponse(false);
        if (nciRequestData !== null) {
            setNciRequestDataById(nciRequestData);
        }
    }

    // get data
    const [requestActionState, setRequestAction] = useRequest({
        url: process.env.REACT_APP_API_URL + "/api/nci/requests/processRequest/" + requestId,
        method: "get",
        withCredentials: true,
        initialIsLoading: false,
        onError: handleError,
        onSuccess: handleSuccess
    });
    const {data, error} = requestActionState;

    // save data
    const submitUrl = process.env.REACT_APP_API_URL + getSaveUrl();
    const [requestState, setNciRequestData] = useRequest({
        url: submitUrl,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSubmitSuccess
    })

    /**
     * useMemo for page load (get user cart
     */
    useMemo(() => {
        if (requestId !== undefined) {
            setRequestAction();
        }
    }, []);

    /**
     * useMemo for data changes
     */
    useMemo(() => {
        if (data != null) {
            setNciRequestDataById(data);
        }
    }, [data])

    /**
     * usememo for nciRequestDataById changes
     */
    useMemo(() => {
        if (nciRequestDataById != null) {
            setResearchPlanDescription(nciRequestDataById.researchPlanDescription);
            setResearchPlanTitle(nciRequestDataById.researchPlanTitle);
            setEntity(nciRequestDataById.entity);
            setEntityType(nciRequestDataById.entityType);
            setEntityTypeOther(nciRequestDataById.entityTypeOther);
            setAddress(nciRequestDataById.address);
            setCity(nciRequestDataById.city);
            setState(nciRequestDataById.state);
            setPostalCode(nciRequestDataById.postalCode);
            setCountry(nciRequestDataById.country);
            setAuthorizedRepresentative(nciRequestDataById.authorizedRepresentative);
            setAuthorizedRepEmail(nciRequestDataById.authorizedRepEmail);
            setIsCheckedTermsOfUse(nciRequestDataById.assentSupplementalTerms);
            setAuthUsers(nciRequestDataById.authUsers);
            if (nciRequestDataById.entityTypeOther !== null && nciRequestDataById.entityTypeOther !== "") {
                setShowEntityTypeOther(true);
            }
            if (nciRequestDataById?.submitted) {
                setDisabled(true);
            }

        }
    }, [nciRequestDataById])

    /**
     * validation schema
     */
    const schema = Yup.object().shape({
        address: Yup.string().required("Please provide a street address.")
            .max(100, "Address should be no longer then 100 characters"),
        city: Yup.string().required("Please provide a city.")
            .max(50, "Address should be no longer then 50 characters."),
        state: Yup.string()
            .required("Please provide a state.")
            .max(50, "State should be no more then 50 characters."),
        postalCode: Yup.string().required("Please provide a postal code.")
            .max(12, "Address should be no longer then 12 characters."),
        country: Yup.string().required("Please select a country."),
        researchPlanTitle: Yup.string().required("Please enter a title for your research.")
            .max(150,"Title should be no longer then 150 characters."),
        researchPlanDescription: Yup.string().required("Please enter a description of your research.")
            .max(1500,"Description should be no longer then 1500 characters."),
        entity: Yup.string().required("Please enter an entity name.")
            .max(60, "Entity should be no longer then 60 characters"),
        entityType: Yup.string().required("Please select an entity type."),
        entityTypeOther: Yup.string().when(['entityType'], {
            is: (entityType: string) => {
                return entityType === OTHER_OPTION; // for all other cases proData is not required
            },
            then: (schema) =>
                schema
                    .required("Please enter an entity type.")
        }).max(40, "Entity Other should be no longer then 40 characters"),
        assentSupplementalTerms: Yup
            .boolean()
            .test('terms of use', '', function (value) {
                if (isCheckedTermsOfUse) {
                    return true;
                }
                return this.createError({message: " Please indicate that you have read the NCI Supplemental Terms."})
            }),
        authorizedRepresentative: Yup.string().max(200, "Authorized representative should be no longer than 200 characters."),
        authorizedRepEmail: Yup.string().max(255, "Authorized representative email should be no longer than 200 characters."),
    });


    /**
     * build the NCI numbers for this request
     */
    const buildNCINumbers = () => {
        if (requestId != null) {
            return (
                <>
                    <ul>
                        {
                            nciRequestDataById?.trialNumbers?.map((trialNumber: INciDataRequestTrialNumbers) => {
                                return <li key={trialNumber.nctTrialNumber}
                                           className="inline">{trialNumber.nctTrialNumber}</li>
                            })
                        }
                    </ul>
                </>
            )
        } else {
            return (
                <ul>

                    {sessionUser.nciRequestCart?.map(((c: INciRequestCart) => (
                        <li key={c.nctTrialNumber} className="inline">{c.nctTrialNumber}</li>
                    )))}
                </ul>
            )
        }
    }

    /**
     * build the select list
     *
     * @param listType - list of items
     */
    const buildSelectList = (listType: FormList[]) => {
        return listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
    }

    /**
     * handle Entity change - show other field when 'Other" selected from list
     *
     * @param value - value of field
     */
    const handleEntityChanged = (value: string) => {
        if (value === OTHER_OPTION) {
            setShowEntityTypeOther(true);
        } else if (showEntityTypeOther) {
            setShowEntityTypeOther(false);
        }
    }

    /**
     * toggle terms of use
     *
     * @param value - value to set to
     */
    const handleTermsOfUseChanged = (value: boolean) => {
        setIsCheckedTermsOfUse(value);
    }

    /**
     * handle reset of form
     */
    const handleReset = () => {
        if (formikRef != null && formikRef.current) {
            formikRef.current.resetForm();
            reinitializeValues();
        }
    }

    /**
     * reinitializeValues - reset the values
     */
    const reinitializeValues = () => {
        setResearchPlanDescription("");
        setResearchPlanTitle("");
        setEntity("");
        setEntityType("");
        setEntityTypeOther("");
        setAddress("");
        setCity("");
        setState("");
        setPostalCode("");
        setCountry("");
        setAuthorizedRepresentative("");
        setAuthorizedRepEmail("");
        setIsCheckedTermsOfUse(false);
        setShowEntityTypeOther(false);

        if (nciRequestDataById?.submitted) {
            setDisabled(true);
        }

    }

    // encode authorized username
    /**
     * encode authorized users
     *
     * @param authUsers - list of auth users
     */
    const encodeAuthorizedUsers = (authUsers: INciAuthUsers[]) => {
        if (authUsers != null && authUsers.length > 0) {
            let encodedUsers: INciAuthUsers[] = [];
            authUsers.forEach(user => {
                let encodedUser = {
                    name: getEncodedValue(user.name),
                    email: user.email
                };
                encodedUsers.push(encodedUser);
            })
            return encodedUsers;
        } else {
            return authUsers;
        }
    }

    /**
     * handle adding/changing a auth user
     *
     * @param index - index in array that has changed/added
     * @param field - is this the name or email field
     * @param event - change event
     */
    const handleAuthUsersChange = (index: number, field: string, event: React.ChangeEvent<HTMLInputElement>) => {
        let newAuthUsers: INciAuthUsers[];
        if (index > (authUsers.length - 1)) {
            newAuthUsers = [...authUsers, {name: '', email: ''}]
        } else {
            newAuthUsers = [...authUsers];
        }
        if (field === 'name') {
            newAuthUsers[index].name = event.target.value;
        } else if (field === 'email') {
            newAuthUsers[index].email = event.target.value;
        }
        setAuthUsers(newAuthUsers);

    }

    /**
     * build the form
     */
    const buildForm = () => {
        return (
            <Formik innerRef={formikRef}
                    enableReinitialize
                    validationSchema={schema}
                    onSubmit={(values) => {
                        setIsWaitingForResponse(true);
                        setNciRequestData(JSON.stringify({
                            researchPlanTitle: getEncodedValue(values.researchPlanTitle),
                            researchPlanDescription: getEncodedValue(values.researchPlanDescription),
                            entity: getEncodedValue(values.entity),
                            entityType: values.entityType,
                            entityTypeOther: getEncodedValue(values.entityTypeOther),
                            address: getEncodedValue(values.address),
                            city: getEncodedValue(values.city),
                            state: getEncodedValue(values.state),
                            country: values.country,
                            postalCode: values.postalCode,
                            authorizedRepresentative: values.authorizedRepresentative,
                            authorizedRepEmail: values.authorizedRepEmail,
                            authUsers: encodeAuthorizedUsers(values.authUsers ?? [{name: "", email: ""}]),
                            assentSupplementalTerms: values.assentSupplementalTerms,
                        }))
                    }}
                    initialValues={{
                        researchPlanTitle: researchPlanTitle ?? '',
                        researchPlanDescription: researchPlanDescription ?? '',
                        entity: entity ?? '',
                        entityType: entityType ?? '',
                        entityTypeOther: entityTypeOther ?? '',
                        address: address ?? '',
                        city: city ?? '',
                        state: state ?? '',
                        postalCode: postalCode ?? '',
                        country: country ?? '',
                        authorizedRepresentative: authorizedRepresentative ?? '',
                        authorizedRepEmail: authorizedRepEmail ?? '',
                        assentSupplementalTerms: isCheckedTermsOfUse,
                        authUsers: authUsers ?? [{name: "", email: ""}]
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      touched,
                      errors,
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn lg={12}>
                            <FormLayoutColumn lg={8}>
                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col} controlId="formResearchPlanTitle">
                                        <Form.Label className="required" column="sm">Research Plan
                                            Title </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"researchPlanTitle"}
                                                      type="text"
                                                      value={researchPlanTitle}
                                                      disabled={disabled}
                                                      aria-describedby="researchPlanTitleHelpBlock"
                                                      isValid={touched.researchPlanTitle && !errors.researchPlanTitle}
                                                      onChange={e => {
                                                          setResearchPlanTitle(e.target.value);
                                                          handleChange(e);
                                                      }}/>
                                        <Form.Text id="researchPlanTitleHelpBlock" muted>
                                            Create a title describing the research intended for the requested
                                            datasets.
                                        </Form.Text>
                                        <ErrorMessage name={"researchPlanTitle"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>
                                <Row className="mt-3 mb-5">
                                    <Form.Group as={Col} controlId="formResearchGoals">
                                        <TextAreaCountRow>
                                            <Form.Label className="required" column="sm">Research Description &
                                                Goals </Form.Label>

                                            {!disabled && (
                                                <span>{researchPlanDescriptionCount} of {maxResearchPlanDescriptionCount} characters</span>
                                            )}


                                        </TextAreaCountRow>
                                        <Form.Control as="textarea" rows={5} size="sm" required
                                                      name={"researchPlanDescription"}
                                                      type="text"
                                                      value={researchPlanDescription}
                                                      disabled={disabled}
                                                      aria-describedby="researchPlanDescriptionBlock"
                                                      isValid={touched.researchPlanDescription && !errors.researchPlanDescription}
                                                      onChange={e => {
                                                          setResearchPlanDescription(e.target.value);
                                                          setResearchPlanDescriptionCount(e.target.value.length);
                                                          handleChange(e);
                                                      }}/>
                                        {!disabled && (
                                            <Form.Text id="researchPlanDescriptionBlock" muted>
                                                Describe the research intended with the requested datasets.
                                            </Form.Text>
                                        )}
                                        <ErrorMessage name={"researchPlanDescription"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>
                                <FormSectionTitle>Entity Information</FormSectionTitle>
                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col} controlId="formEntity">
                                        <Form.Label className="required" column="sm">Entity </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"entity"}
                                                      type="text"
                                                      value={entity}
                                                      disabled={disabled}
                                                      aria-describedby="entityHelpBlock"
                                                      isValid={touched.entity && !errors.entity}
                                                      onChange={e => {
                                                          setEntity(e.target.value);
                                                          handleChange(e);
                                                      }}/>
                                        {!disabled && (
                                            <Form.Text id="entityHelpBlock" muted>
                                                List the name of your Entity, whose approval you must obtain and
                                                provide
                                                as
                                                part of your data request. Your Entity is any
                                                organization/institution/employer which you are employed by or
                                                affiliated
                                                with, and the research being conducted with the dataset(s) you are
                                                requesting is within the scope of your employment or affiliation.
                                            </Form.Text>
                                        )}
                                        <ErrorMessage name={"entity"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>
                                {!disabled && (
                                    <PDS_P>NOTE: If you are requesting the dataset(s) for purposes outside the scope
                                        of
                                        your
                                        employment or affiliation with an Entity, you may list the name as N/A and
                                        leave
                                        the
                                        address blank; however, if you do so, you may be contacted by the NCI
                                        regarding
                                        your
                                        data request.</PDS_P>
                                )}
                                <Row className="mt-3 mb-3 ">
                                    <Form.Group as={Col} lg={6}
                                                controlId="formEntityType">
                                        <Form.Label className="required" column="sm">Entity Type </Form.Label>
                                        {!disabled && (
                                            <Form.Select size="sm" required
                                                         name={"entityType"}
                                                         isValid={touched.entityType && !errors.entityType}
                                                         value={entityType}
                                                         onChange={e => {
                                                             setEntityType(e.target.value);
                                                             handleEntityChanged(e.target.value);
                                                             handleChange(e);
                                                         }}>
                                                {buildSelectList(NCI_ENTITY_TYPE)}
                                            </Form.Select>
                                        )}
                                        {disabled && (
                                            <Form.Control size="sm"
                                                          name={"entityType"}
                                                          type="text"
                                                          disabled={disabled}
                                                          defaultValue=""
                                            />
                                        )}
                                        <ErrorMessage name={"entityType"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>
                                {showEntityTypeOther && (
                                    <Row className="mt-3 mb-3 ">
                                        <Form.Group as={Col} className={"otherFieldGroup"} lg={6}
                                                    controlId="formEntityTypeOther">
                                            <Form.Label className="required" column="sm">Other Entity
                                                Type </Form.Label>
                                            <Form.Control size="sm" required
                                                          name={"entityTypeOther"}
                                                          value={entityTypeOther}
                                                          type="text"
                                                          disabled={disabled}
                                                          isValid={touched.entityTypeOther && !errors.entityTypeOther}
                                                          onChange={e => {
                                                              setEntityTypeOther(e.target.value);
                                                              handleChange(e);
                                                          }}/>

                                            <ErrorMessage name={"entityTypeOther"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>
                                )}
                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col}
                                                controlId="formAddress">
                                        <Form.Label className="required" column="sm">Street Address </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"address"}
                                                      type="text"
                                                      value={address}
                                                      disabled={disabled}
                                                      isValid={touched.address && !errors.address}
                                                      onChange={e => {
                                                          setAddress(e.target.value);
                                                          handleChange(e);
                                                      }}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a street address.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"address"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>
                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col} lg={5} controlId="formCity">
                                        <Form.Label className="required" column="sm">City </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"city"}
                                                      type="text"
                                                      value={city}
                                                      disabled={disabled}
                                                      isValid={touched.city && !errors.city}
                                                      onChange={e => {
                                                          setCity(e.target.value);
                                                          handleChange(e);
                                                      }}/>
                                        <ErrorMessage name={"city"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                    <Form.Group as={Col} lg={3} controlId="formState">
                                        <Form.Label className="required" column="sm">State </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"state"}
                                                      type="text"
                                                      value={state}
                                                      disabled={disabled}
                                                      isValid={touched.state && !errors.state}
                                                      onChange={e => {
                                                          setState(e.target.value);
                                                          handleChange(e);
                                                      }}/>
                                        <ErrorMessage name={"state"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                    <Form.Group as={Col} lg={4} controlId="formPostalCode">
                                        <Form.Label className="required" column="sm">Postal Code </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"postalCode"}
                                                      type="text"
                                                      value={postalCode}
                                                      disabled={disabled}
                                                      isValid={touched.postalCode && !errors.postalCode}
                                                      onChange={e => {
                                                          setPostalCode(e.target.value);
                                                          handleChange(e);
                                                      }}/>
                                        <ErrorMessage name={"postalCode"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>
                                <Row className="mt-3 mb-5">
                                    <Form.Group as={Col} lg={6}
                                                controlId="formCountry">
                                        <Form.Label className="required" column="sm">Country </Form.Label>
                                        {!disabled && (
                                            <Form.Select size="sm" required
                                                         name={"country"}
                                                         value={country}
                                                         isValid={touched.country && !errors.country}
                                                         onChange={e => {
                                                             setCountry(e.target.value);
                                                             handleChange(e);
                                                         }}>
                                                {buildSelectList(COUNTRY)}
                                            </Form.Select>
                                        )}
                                        {disabled && (
                                            <Form.Control size="sm"
                                                          name={"country"}
                                                          type="text"
                                                          disabled={disabled}
                                                          defaultValue=""
                                            />
                                        )}
                                        <ErrorMessage name={"country"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>
                                <FormSectionTitle>Other Authorized Users</FormSectionTitle>
                                <PDS_P>List the name and email address of each individual who will require access to
                                    the
                                    datasets you have requested in order to conduct the research you have described
                                    in this data request. Each such individual <em>MUST</em> be an Authorized User
                                    of
                                    the
                                    Project Data Sphere Cancer Research Platform <em>AND</em> must complete and
                                    submit
                                    his or her own data request.</PDS_P>
                                <FieldArray name={"authUsers"}>
                                    {(props: FieldArrayRenderProps) => (
                                        <>
                                            {props.form.values.authUsers.map((authUser: INciAuthUsers, index: number) =>
                                                <Row key={`authUsers.${index}`} className="mt-3 mb-3">
                                                    <Form.Group as={Col} lg={6} controlId={"formAuthUsers." + index}>
                                                        <Form.Label column="sm">Name </Form.Label>
                                                        <Form.Control size="sm"
                                                                      name={`authUsers.${index}.name`}
                                                                      type="text"
                                                                      value={authUser.name}
                                                                      disabled={disabled}
                                                                      onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                                          handleAuthUsersChange(index, 'name', event);
                                                                          handleChange(event);
                                                                      }}
                                                        />
                                                        <Form.Text className="text-muted"></Form.Text>
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={6} controlId={"formEmail." + index}>
                                                        <Form.Label column="sm">Email </Form.Label>
                                                        <Form.Control size="sm"
                                                                      name={`authUsers.${index}.email`}
                                                                      type="email"
                                                                      value={authUser.email}
                                                                      disabled={disabled}
                                                                      onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                                          handleAuthUsersChange(index, 'email', event);
                                                                          handleChange(event);
                                                                      }}/>
                                                        <Form.Text className="text-muted"></Form.Text>
                                                    </Form.Group>
                                                </Row>)}
                                            <Row className="mt-3 mb-3">
                                                <Button onClick={() => {
                                                    props.push({name: "", email: ""});
                                                    setAuthUsers([...authUsers, {name: '', email: ''}]);
                                                }}>Add User</Button>
                                            </Row>
                                        </>
                                    )}
                                </FieldArray>
                                <FormSectionTitle>Authorized Representative</FormSectionTitle>
                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col} controlId="formAuthorizedRepresentative">
                                        <Form.Label column="sm">Name of Authorized
                                            Representative </Form.Label>
                                        <Form.Control size="sm"
                                                      name={"authorizedRepresentative"}
                                                      type="text"
                                                      value={authorizedRepresentative}
                                                      disabled={disabled}
                                                      aria-describedby="authorizedRepresentativeHelpBlock"
                                                      isValid={touched.authorizedRepresentative && !errors.authorizedRepresentative}
                                                      onChange={e => {
                                                          console.log("rep " + nciRequestDataById?.authUsers);
                                                          setAuthorizedRepresentative(e.target.value);
                                                          handleChange(e);
                                                      }}/>
                                        <Form.Text id="authorizedRepresentativeHelpBlock" muted>
                                            List the name of your Authorized Representative. As part of your data
                                            request,
                                            you must submit a form, signed by your Authorized Representative, that
                                            attests
                                            to your Entity's approval of the Project Data Sphere Cancer Research
                                            Platform
                                            Agreement and the NCI Supplemental Terms to which you are required to
                                            assent
                                            before you are given access to the dataset(s) that you have requested.
                                            Your
                                            Authorized Representative is someone who is delegated signature
                                            authority
                                            for
                                            that approval. The Authorized Representative is usually located in your
                                            Entity's Technology Transfer Office, Sponsored Research Programs Office,
                                            Office of General Counsel orBusiness Development Office.
                                        </Form.Text>
                                    </Form.Group>
                                    {disabled && (
                                        <PDS_P>If you listed N/A above as the name of your Entity, you must list
                                            your own name as the Authorized Representative.</PDS_P>
                                    )}
                                </Row>
                                <Row className="mt-3 mb-5">
                                    <Form.Group as={Col} controlId="formAuthorizedRepEmail">
                                        <Form.Label column="sm">Authorized Representative's
                                            Email </Form.Label>
                                        <Form.Control size="sm"
                                                      name={"authorizedRepEmail"}
                                                      type="email"
                                                      value={authorizedRepEmail}
                                                      disabled={disabled}
                                                      isValid={touched.authorizedRepEmail && !errors.authorizedRepEmail}
                                                      onChange={e => {
                                                          setAuthorizedRepEmail(e.target.value);
                                                          handleChange(e);
                                                      }}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                    </Form.Group>
                                </Row>
                                <UserAgreementTitle>Assent to NCI Supplemental Terms</UserAgreementTitle>
                                <UserAgreementRow className="mb-3">
                                    <NCIAttestation/>
                                </UserAgreementRow>
                                {!disabled && (
                                    <Row className="mt-3 mb-3 ">
                                        <Form.Group as={Col} controlId="formAssentSupplementalTerms">
                                            <Form.Check className="bold_label" required type={"checkbox"}
                                                        name={"assentSupplementalTerms"}
                                                        checked={isCheckedTermsOfUse}
                                                        label={"Please read and assent, by clicking I accept, to the NCI Supplemental Terms, which, together the term, " +
                                                            "covenants and conditions of the Project Data Sphere Cancer Research Platform Agreement to which you have already assented, govern your use of the NCI dataset(s) that you have requested."}
                                                        isValid={touched.assentSupplementalTerms && !errors.assentSupplementalTerms}
                                                        onChange={e => {
                                                            handleTermsOfUseChanged(!isCheckedTermsOfUse);
                                                            handleChange(e);
                                                        }}>
                                            </Form.Check>


                                            <ErrorMessage name={"assentSupplementalTerms"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>

                                        </Form.Group>
                                    </Row>
                                )}
                                {disabled && (
                                    <Row className="mt-3 mb-3 ">
                                        <Form.Group as={Col} controlId="formAssentSupplementalTerms">
                                            <Form.Control as="textarea" rows={2} size="sm"
                                                          name={"assentSupplementalTerms"}
                                                          type="text"
                                                          disabled={disabled}
                                                          defaultValue={"Yes." + TERMS_OF_USE}/>
                                        </Form.Group>
                                    </Row>
                                )}

                            </FormLayoutColumn>
                        </FormLayoutColumn>
                        {!disabled && (
                            <ClearAndSubmitButtonContainer handleReset={handleReset} enableClear={true}
                                                           isSubmitting={isWaitingForResponse}
                                                           submitButtonText={SUBMIT}/>
                        )}
                    </Form>
                )}
            </Formik>
        )
    }

    /**
     * return the page
     */
    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn md={8} lg={9} name={"formLayoutColumn"}>
                        <PDS_H5>Data Request Information</PDS_H5>
                        <PDS_P>Please complete the form below to request access to the following datasets:</PDS_P>
                        {(sessionUser.nciRequestCart?.length > 0 || nciRequestDataById != null) && (
                            buildNCINumbers()
                        )}
                        {error && <p>Error</p>}
                        {(data || sessionUser.nciRequestCart?.length > 0) && buildForm()}
                    </FormLayoutColumn>
                    <Col md={4} lg={3}>
                        <Aside needHelp={true} requiredFields={true}/>
                    </Col>

                </PageWrapper>
            </ContentPage>

        </Container>
    );
}