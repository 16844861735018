/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: PDSEditor
 *
 * Purpose: component to wrap around ckeditor
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 30Nov2023 craig File created.
 * 10Aug2024 craig Added width and min-width to style to fix scrolling issue
 \******************************************************************************/
import React, {useImperativeHandle} from "react";
//import {WordCount} from "@ckeditor/ckeditor5-word-count";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import {Container, Row} from "react-bootstrap";


interface PDSEditorProps {
    reference:any,
    editorLoaded: boolean,
    disabled: boolean,
    name: string,
    value: string
}

function makeId() {
    const len = 5;
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < len; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

/*

div.ck-word-count {
    align-self: end;
    text-align: right;
    display: flex;
    flex: column;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: end;
}
 */
const editorConfig = {

    language: 'en',
    wordCount: {
        displayCharacters: true,
        displayWords: true,
        onUpdate: (stats:any) => {
            //console.log(stats.words + " " + stats.characters);
        }
    }
}


export const PDSEditor: React.FC<PDSEditorProps> = ({
                                                        reference,
                                                        editorLoaded,
                                                        disabled,
                                                        name,
                                                        value
                                                    }: PDSEditorProps) => {
    let tmpeditor: Editor;

    useImperativeHandle(reference, () => ({
        getData() {
            return tmpeditor?.getData();
        }
    }))



    const buildEditor = () => {
        return (
            <Container>
                <Row>
                    <CKEditor ref={reference}
                        onReady={(editor: any) => {
                            editor.editing.view.change((writer: any) => {
                                writer.setStyle(
                                    "height",
                                    "250px",
                                    editor.editing.view.document.getRoot()
                                );
                                writer.setStyle(
                                    "width",
                                    "615px",
                                    editor.editing.view.document.getRoot()
                                );
                                writer.setStyle(
                                    "min-width",
                                    "200px",
                                    editor.editing.view.document.getRoot()
                                );
                            })
                            tmpeditor = editor;
                        }}
                        id={name + "-" + makeId()}
                        config={editorConfig}
                        disabled={disabled}
                        editor={Editor}
                        data={value}></CKEditor>
                </Row>

            </Container>
        )
    }

    return (
        <div>
            {editorLoaded ? buildEditor() : <div>Editor loading</div>}
        </div>
    )
}