/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ClinDrugAdditivityProject
 *
 * Purpose: special PDS project
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 02Aug2024 sasjxa file created
 * 25Aug2024 sasjxa use new component for therapy selectors
 * 27Aug2024 sasjxa add check for multiple files before initial form display
 * 29Aug2024 sasjxa add support for deleting files
 * 08Oct2024 sasjxa remove correlation since not used in calculations
 \******************************************************************************/
import React, {useEffect, useRef} from "react";
import * as d3 from "d3";
// @ts-ignore
import styled from 'styled-components';
import {IClinicalDrugProjectRecords} from "../../model/clinicalDrug/IClinicalDrugProjectRecords";


interface ClinicalDrugAdditivityKaplanMeierProps {
    combinationPredictedAdd: IClinicalDrugProjectRecords[]/*,
    combinationPredictedInd:IClinicalDrugProjectRecords[],
    experimentalClean:IClinicalDrugProjectRecords[],
    controlClean:IClinicalDrugProjectRecords[]
*/
}

export const ClinicalDrugKaplanMeierCurve: React.FC<ClinicalDrugAdditivityKaplanMeierProps> = ({
                                                                                                   combinationPredictedAdd/*,
                                                                                                   combinationPredictedInd,experimentalClean,
                                                                                                   controlClean*/
                                                                                               }: ClinicalDrugAdditivityKaplanMeierProps) => {


    const svgRef = useRef<SVGSVGElement>(null);

    useEffect(() => {
        if (combinationPredictedAdd!= null && combinationPredictedAdd.length > 0) {

            const svg = d3.select(svgRef.current);

            // Set up margins and dimensions
            const margin = { top: 20, right: 20, bottom: 30, left: 50 };
            const width = 600 - margin.left - margin.right;
            const height = 400 - margin.top - margin.bottom;


            // Create scales
            // const xScale = d3.scaleLinear()
            //     .domain([d3.min(combinationPredictedAdd, d => d.time)!,
            //         d3.max(combinationPredictedAdd, d => d.time)!])
            //     .range([0, 800]);

            const xScale = d3.scaleLinear()
                .domain([0,
                    d3.max(combinationPredictedAdd, d => d.time)!])
                .range([0, 800]);

            const yScale = d3.scaleLinear()
                .domain([0, 1])
                .range([400, 0]);




            // Create axes
            const xAxis = d3.axisBottom(xScale);
            const yAxis = d3.axisLeft(yScale);


            // Create the line generator
            const line = d3.line<IClinicalDrugProjectRecords>()
                .x(d => xScale(d.time))
                .y(d => yScale(d.survival))
                .curve(d3.curveStepAfter);


            // Append the SVG elements
            svg.append('g')
                .attr('transform', `translate(${margin.left}, ${margin.top})`)
                .append('path')
                .datum(combinationPredictedAdd)
                .attr('d', line)
                .attr('stroke', 'blue')
                .attr('fill', 'none');

            // Add axes
            svg.append('g')
                .attr('transform', `translate(${margin.left}, ${height + margin.top})`)
                .call(xAxis);

            svg.append('g')
                .attr('transform', `translate(${margin.left}, ${margin.top})`)
                .call(yAxis);
        }
    }, [combinationPredictedAdd]);


    // useEffect(() => {
    //     if (combinationPredictedAdd != null && combinationPredictedAdd.length > 0 ) {
    //
    //         const svg = d3.select(svgRef.current);
    //
    //         // Calculate Kaplan-Meier estimates
    //         const km = d3.curveStepAfter;
    //         const survival = d3.cumsum(combinationPredictedAdd, d => d.survival);
    //
    //         // Create scales
    //         const xScale = d3.scaleLinear()
    //             .domain([0, d3.max(combinationPredictedAdd, d => d.time)!])
    //             .range([0, 800]);
    //
    //         const yScale = d3.scaleLinear()
    //             .domain([0, 1])
    //             .range([400, 0]);
    //
    //         // Draw axes
    //         const xAxis = d3.axisBottom(xScale);
    //         const yAxis = d3.axisLeft(yScale);
    //
    //         svg.append("g").attr("transform", "translate(0, 400)").call(xAxis);
    //         svg.append("g").call(yAxis);
    //
    //         const line = d3.line<IClinicalDrugProjectRecords>()
    //             .x(d => xScale(d.time))
    //             .y(d => yScale(d.survival));
    //
    //         // Draw the Kaplan-Meier curve
    //         svg.append("path")
    //             .datum(combinationPredictedAdd)
    //             .attr("fill", "none")
    //             .attr("stroke", "black")
    //             .attr('d', line.curve(km).x(d => xScale(d.time)).y(d => yScale(d.survival)));
    //
    //     }
    //
    // }, [combinationPredictedAdd]);


    return (
        <svg ref={svgRef} width={600} height={400}></svg>
    );


}