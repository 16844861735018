/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: UserInfo
 *
 * Purpose: Read only user information
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 27Aug2023 sasjxa File created.
 \******************************************************************************/
import React from "react";
import {Col, Form, Row} from "react-bootstrap";


interface UserInfoProps {

    showUserFullName:any
    userEmail: any,
    userPhone: any,
}

export const UserInfo: React.FC<UserInfoProps> = ({showUserFullName, userEmail,userPhone}) => {
    return (
        <Row className="mt-3 mb-3  ">
            <Form.Group as={Col} lg={4} controlId="formName">
                <Form.Label className="bold-label" column="sm"> Name </Form.Label>
                <Form.Control className="read-only" size="sm"
                              name={"firstAndLast"}
                              type="text"
                              placeholder=""
                              disabled={true}
                              defaultValue={showUserFullName}
                  />
                <Form.Text className="text-muted"></Form.Text>
            </Form.Group>

            <Form.Group as={Col} lg={5} className="email"
                        controlId="formEmail">
                <Form.Label className="bold-label" column="sm">Email
                    Address </Form.Label>
                <Form.Control className="read-only" size="sm"
                              name={"email"}
                              type="text"
                              placeholder=""
                              disabled={true}
                              defaultValue= {userEmail}
                />
                <Form.Text className="text-muted"></Form.Text>
            </Form.Group>

            <Form.Group as={Col} lg={3} controlId="formPhone">
                <Form.Label className="bold-label" column="sm"> Phone </Form.Label>
                <Form.Control size="sm"
                              name={"phone"}
                              type="text"
                              disabled={true}
                              defaultValue= {userPhone}
                              />
                <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
        </Row>
    )

}