/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Access
 *
 * Purpose: Access Data page
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 22Feb2023 craig  file created.
 * 10Jan2024 sasjxa add code for obtaining dynamic filter values
 * 30Jan2024 craig  first pass at filters
 * 31Jan2024 sasjxa add logic for resetting filters
 * 09Feb2024 sasjxa add filter code for linked data
 * 13Feb2024 sasjxa new method for handling reset all
 * 04Apr2024 craig  Switched to react-error-boundary
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 14Apr2024 craig  cleaned up some errors that show up in the console
 * 30Apr2024 sasjxa refactoring of methods and styled componentsb
 * 10Jun2024 sasjxa fix issue with checkbox alignment
 * 11Jun2024 craig  removed user prop to AccessDataDetailsAndFiles
 * 20Jun2024 sasjxa replace error boundary with console log
 * 25Jun2024 sasjxa fix filter checkbox alignment
 * 09Jul2024 sasjxa remove references to showBoundary
 * 21Aug2024 craig  remove session user (unused)
 * 03Oct2024 craig  fixed search text so that it clears on reset
 * 07Nov2024 craig  Added ability to save selected filters in session to maintain state
 \******************************************************************************/
import React, {useMemo, useState} from "react";
// @ts-ignore
import styled from 'styled-components';
import {Accordion, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {ContentPage, FormLayoutColumn, PageWrapper,} from "../../components/styled/StyledComponents";
import {Formik} from "formik";

import {AccessDataAside} from "../../components/AccessDataAside";
import AccordionItem from "react-bootstrap/AccordionItem";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import AccordionBody from "react-bootstrap/AccordionBody";
import {COLORS, FONT_SIZES} from "../../components/styled/StyleConstants";

import {AccessDataStudies} from "../../components/access/AccessDataStudies";
import {IAccessCheckbox, IAccessSearchParams} from "../../model/access/IAccessSearchParams";
import {useRequest} from "../../helper/useRequest";

let accordion_key = 12345;

const handleReinitialize = () => accordion_key++;


const FilterAccordion = styled(Accordion)`
    background-color: ${COLORS.filter_gray};
    font-size:  ${FONT_SIZES.px13};
   
    padding-right: 0;
    padding-left: 0;
    width: 100%;
`
const FilterAccordionItem = styled(AccordionItem)`
    background-color: ${COLORS.filter_gray};
    border: 0;
    width: 100%;
`

const FilterAccordionBody = styled(AccordionBody)`
    padding: 0;
    width: 100%;  

    div.form-check {
        padding-top: 1%;
        border-top: 1px solid ${COLORS.middle_grey};  
   
        input {
            padding-top: 3%;
            padding-bottom: 2%;
            line-height: 2;            
            border: 1px solid ${COLORS.middle_grey};
            text-align: center;
           
        }

        label {
            padding-top: 1%;
            padding-bottom: 2%;
            padding-left: 2%;
           
            font-size: ${FONT_SIZES.px12} !important;
            font-weight: 400;
            text-align: left;           
        }
    }

`

const FilterAccordionHeader = styled(AccordionHeader)`
    padding-left: 0;
    border-top: 1px solid ${COLORS.middle_grey};
    width: 100%;
`;

const FilterRowsContainer = styled(Col)`
    padding-left: 1%;
    padding-right: 1%;
    background-color: ${COLORS.filter_gray};
    border: 1px solid ${COLORS.middle_grey};
`;

const FilterCol = styled(Col)`
    font-size: ${FONT_SIZES.px13};
    height: 36px;
    padding: 5% 0;
    align-items: center;
    background-color: ${COLORS.filter_gray};
    color: ${COLORS.dark_grey_blue};
`;

const FilterColContent = styled(FilterCol)`
    font-weight: bold;
    padding-left: 1%;
`;

const FilterColInfo = styled(FilterCol)`
    text-align: right;
    padding-right: 1%;
    font-size: ${FONT_SIZES.px14};
`;

const FilterRow = styled(Row)`
    padding: 0;
    margin: 0;
    border-top: 1px solid ${COLORS.filter_gray_medium};
`;

const FilterDataRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    border-top: 1px solid ${COLORS.filter_gray_medium};
`;

const ResetButton = styled(Button)`
    width: 100%;
    font-size: ${FONT_SIZES.px14};
    text-align: right;
    padding-right: 2%;
    margin-right: 0;
    padding-top: 5%;


    color: ${COLORS.white};

    :hover, :active:focus {
        background-color: ${COLORS.cerulean} !important;
        color: ${COLORS.white} !important;
    }

`;

export const Access: React.FC = () => {
    //need this to tell when page is loading so we don't overwrite session
    const [loading, setLoading] = useState<boolean>(true);
    //default initial values of filters
    const [searchParams, setSearchParams] = useState<IAccessSearchParams>({
        searchText: "",
        searchSort: "Most Recent",
        access: [],
        arm: [],
        dataProvider: [],
        sponsor: [],
        studyPhase: [],
        tumorType: [],
        linkedData: []
    });

    const ANYALL: string = "Any / All";
    const ALL: string = "All";
    const ACCESS_FILTER_NAME: string = "Access";
    const ARM_FILTER_NAME: string = "Arm";
    const DATA_PROVIDER: string = "Data Provider";
    const SPONSOR: string = "Sponsor";
    const STUDY_PHASE: string = "Study Phase";
    const TUMOR_TYPE: string = "Tumor Type";
    const LINKED_DATA_FILTER_NAME: string = "Linked Data";

    const FILTER_NAME = "pds-search-filters";

    /**
     * reset the search params
     */
    const resetSearchParams = () => {
        const accessSection = resetSectionCheckboxes(searchParams?.access);
        const armSection = resetSectionCheckboxes(searchParams?.arm);
        const dataProviderSection = resetSectionCheckboxes(searchParams?.dataProvider);
        const sponsorSection = resetSectionCheckboxes(searchParams?.sponsor);
        const studyPhaseSection = resetSectionCheckboxes(searchParams?.studyPhase);
        const tumorTypeSection = resetSectionCheckboxes(searchParams?.tumorType);
        const linkedDataSection = resetSectionCheckboxes(searchParams?.linkedData);

        setSearchParams({
            ...searchParams,
            searchText: "",
            access: accessSection,
            arm: armSection,
            dataProvider: dataProviderSection,
            sponsor: sponsorSection,
            studyPhase: studyPhaseSection,
            tumorType: tumorTypeSection,
            linkedData: linkedDataSection

        });

        handleReinitialize();
    }

    //----------------------------------------------------------------------------
    //set up the call to backend to get the filters
    //----------------------------------------------------------------------------
    const filterURL = process.env.REACT_APP_API_URL + "/api/accessdata/filters";
    const handleError = (error: object) => {
        //console.log(error);
    }

    const [requestState, getFilters] = useRequest({
        url: filterURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })

    const {isLoading, data} = requestState;


    /**
     * check for checked items in the filter - need to determine if we expand accordion
     *
     * @param filter - filter items
     */
    const checkForChecked = (filter: IAccessCheckbox[]|undefined): boolean => {
        let isChecked:boolean = false;
        //we need to skip the first (the "All" variable which will be check by default) with slice(1)
        filter?.slice(1).map(f => {
            if (f.isChecked) {
                isChecked = true;
            }
        })
        return isChecked;
    }

    /**
     * called on page load, it gets the filters from the api
     */
    useMemo(() => {
        //resetSearchParams();
        getFilters();
    }, [])

    /**
     * called when getFilters returns with the data
     */
    useMemo(() => {
        if (data != null) {
            //setSearchParams(data);
            //get the filter from session
            const searchParams:string | null = sessionStorage.getItem(FILTER_NAME);
            if (searchParams !== null) {
                //if we have filters in the session, populate the search params
                setSearchParams(JSON.parse(searchParams));
            } else {
                //otherwise use what was returned from api
                setSearchParams(data);
            }
        }
    }, [data]);

    /**
     * called when the search params are changed, just to set the session
     */
    useMemo( () => {
        //check if this is the page load, don't set session because it will overwrite
        if (!loading) {
            sessionStorage.setItem(FILTER_NAME, JSON.stringify(searchParams));
        }
        //turn off the loading flag
        setLoading(false);
    },[searchParams]);


    /**
     * process the entry
     *
     * @param event - event
     * @param filterName - name of the filter
     * @param checkboxItem - item in the filter
     */
    const processEntry = (event: React.ChangeEvent<HTMLInputElement>, filterName: string, checkboxItem: IAccessCheckbox) => {
        //console.log(checkboxItem + " " + valueSelected);
        if (filterName === ARM_FILTER_NAME) {
            const filterSection = buildSectionCheckboxes(searchParams?.arm, checkboxItem, event.target.checked);
            if (filterSection !== undefined) {
                setSearchParams({
                    ...searchParams,
                    arm: filterSection
                });
            }
        } else if (filterName === ACCESS_FILTER_NAME) {
            const filterSection = buildSectionCheckboxes(searchParams?.access, checkboxItem, event.target.checked);
            if (filterSection !== undefined) {
                setSearchParams({
                    ...searchParams,
                    access: filterSection
                });
            }
        } else if (filterName === DATA_PROVIDER) {
            const filterSection = buildSectionCheckboxes(searchParams?.dataProvider, checkboxItem, event.target.checked);
            if (filterSection !== undefined) {
                setSearchParams({
                    ...searchParams,
                    dataProvider: filterSection
                });
            }
        } else if (filterName === SPONSOR) {
            const filterSection = buildSectionCheckboxes(searchParams?.sponsor, checkboxItem, event.target.checked);
            if (filterSection !== undefined) {
                setSearchParams({
                    ...searchParams,
                    sponsor: filterSection
                });
            }
        } else if (filterName === STUDY_PHASE) {
            const filterSection = buildSectionCheckboxes(searchParams?.studyPhase, checkboxItem, event.target.checked);
            if (filterSection !== undefined) {
                setSearchParams({
                    ...searchParams,
                    studyPhase: filterSection
                });
            }
        } else if (filterName === TUMOR_TYPE) {
            const filterSection = buildSectionCheckboxes(searchParams?.tumorType, checkboxItem, event.target.checked);
            if (filterSection !== undefined) {
                setSearchParams({
                    ...searchParams,
                    tumorType: filterSection
                });
            }
        } else if (filterName === LINKED_DATA_FILTER_NAME) {
            const filterSection = buildSectionCheckboxes(searchParams?.linkedData, checkboxItem, event.target.checked);
            if (filterSection !== undefined) {
                setSearchParams({
                    ...searchParams,
                    linkedData: filterSection
                });
            }
        }
    }

    /**
     * reset the checkboxes in filter passed in
     *
     * @param filter - filter to be reset
     */
    const resetSectionCheckboxes = (filter: IAccessCheckbox[]): IAccessCheckbox[] => {
        filter.map(f => {
            if (f.label === 'All') {
                f.isChecked = true;
            } else {
                f.isChecked = false;
            }
        })
        return filter;
    }

    /**
     * build the selection checkboxes
     *
     * @param filter - filter with the checkboxes
     * @param checkboxItem - checkbox item in the filter
     * @param checked - is it checked?
     */
    const buildSectionCheckboxes = (filter: IAccessCheckbox[] | undefined, checkboxItem: IAccessCheckbox, checked: boolean): IAccessCheckbox[] | undefined => {
        //see if this is the all checkbox
        if (checkboxItem.label === ALL) {
            //check if "on", if not on, don't do anything
            if (checked) {
                //uncheck all other values
                filter?.map(i => {
                    i.isChecked = false;
                });
                checkboxItem.isChecked = true;
            }
        } else {
            //must be a different item
            //make sure that all is off
            if (checked) {
                //turn off "all"
                if (filter !== undefined && filter[0].label === ALL) {
                    filter[0].isChecked = false;
                }
                //now set the checkbox item to checked
                checkboxItem.isChecked = true;

            } else {
                checkboxItem.isChecked = false;
                //now check how many checked we have, if 0, then set all to true
                let numChecked: number = 0;
                filter?.map(i => {
                    if (i.isChecked) {
                        numChecked++;
                    }
                });
                if (numChecked <= 0) {
                    if (filter !== undefined) {
                        filter[0].isChecked = true;
                    }
                }
            }
        }
        return filter;
    }

    /**
     * build the filter header
     *
     * @param filterName - filter name to use
     * @param filterType - filter type to use
     */
    const buildFilterHeader = (filterName: string, filterType: string) => {
        return (
            <FilterAccordionHeader>
                <FilterColContent className={""}>{filterName}</FilterColContent>
                <FilterColInfo className={""}>{filterType}</FilterColInfo>
            </FilterAccordionHeader>
        )
    }

    /**
     * build filter with values
     *
     * @param filterData - filter data to build
     * @param filterName - name of the filter
     * @param filterType - type of the filter
     * @param eventKey - event key to set
     */
    const buildFilterEntryWithDynamicValue = (filterData: IAccessCheckbox[] | undefined, filterName: string, filterType: string, eventKey: string) => {
        //check if there are any items checked, if yes, we will expand accordion
        const expand:boolean = checkForChecked(filterData);
        return (
            <FilterAccordion defaultActiveKey={expand ? eventKey : 0} key={accordion_key} flush>
                <FilterAccordionItem eventKey={eventKey}>
                    {buildFilterHeader(filterName, filterType)}

                    <FilterAccordionBody className={""}>
                        {filterData?.map((filterValue: IAccessCheckbox) => (
                            <Form.Check type={"checkbox"} checked={filterValue.isChecked}
                                        label={filterValue.label}
                                        key={filterValue.id}
                                        id={filterValue.id}
                                        onChange={(event) => processEntry(event, filterName, filterValue)}/>

                        ))}
                    </FilterAccordionBody>
                </FilterAccordionItem>
            </FilterAccordion>
        )
    }

    /**
     * handle a change in the filters
     *
     * @param event - react event
     */
    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (searchParams != null) {
            setSearchParams({
                ...searchParams,
                searchText: event.target.value
            });
        }
    }

    /**
     * build the filter form
     */
    const buildForm = () => {
        return (
            <Formik
                enableReinitialize
                initialValues={{
                    firstAndLast: '',

                }}
                onReset={() => console.log('reset')}

                onSubmit={(values) => {

                }}

                validateOnChange={false}
                validateOnBlur={false}>
                {({
                      handleSubmit,
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn className="mt-4 mb-5  " lg={12}>
                            <FilterRowsContainer className="mt-4 mb-5  ">
                                <FilterRow className="w-100  ">
                                    <Form.Group as={Col} className="w-100 pe-0 ps-1"
                                                controlId="">
                                        <Form.Control
                                            type="text"
                                            value={searchParams.searchText}
                                            placeholder="Search"
                                            className="search-box"
                                            aria-label="Search"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeSearch(e)}
                                        />
                                    </Form.Group>
                                </FilterRow>

                                <FilterDataRow className="w-100 ">
                                    <Form.Group as={Col} className="filter-align-left ps-1"
                                                controlId="">
                                        <Form.Label className="filter-data-text pe-0 ps-0" column="sm">Filter
                                            Data </Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} className="filter-reset "
                                                controlId="">

                                        <ResetButton type="button"
                                                     className="btn btn-link bold-label pt-2 "
                                                     onClick={() => {
                                                         resetSearchParams()
                                                     }}>
                                            Reset All

                                        </ResetButton>
                                    </Form.Group>
                                </FilterDataRow>


                                <FilterRow className="">
                                    {buildFilterEntryWithDynamicValue(searchParams?.access, ACCESS_FILTER_NAME,
                                        "PDS or NCI", "1")}
                                </FilterRow>

                                <FilterRow className="">
                                    {buildFilterEntryWithDynamicValue(searchParams?.arm, ARM_FILTER_NAME,
                                        ANYALL, "2")}
                                </FilterRow>

                                <FilterRow className="">
                                    {buildFilterEntryWithDynamicValue(searchParams?.dataProvider, DATA_PROVIDER,
                                        ANYALL, "3")}
                                </FilterRow>

                                <FilterRow className="">
                                    {buildFilterEntryWithDynamicValue(searchParams?.sponsor, SPONSOR,
                                        ANYALL, "4")}
                                </FilterRow>

                                <FilterRow className="">
                                    {buildFilterEntryWithDynamicValue(searchParams?.studyPhase, STUDY_PHASE,
                                        ANYALL, "5")}
                                </FilterRow>

                                <FilterRow className="">
                                    {buildFilterEntryWithDynamicValue(searchParams?.tumorType, TUMOR_TYPE,
                                        ANYALL, "6")}
                                </FilterRow>

                                <FilterRow className="">
                                    {buildFilterEntryWithDynamicValue(searchParams?.linkedData, LINKED_DATA_FILTER_NAME,
                                        ANYALL, "7")}
                                </FilterRow>
                            </FilterRowsContainer>
                        </FormLayoutColumn>

                    </Form>

                )}
            </Formik>
        )
    }

    /**
     * build the form and results and aside
     */
    const buildResults = () => {
        return (
            <>
                <Col md={4} lg={2}>
                    {buildForm()}
                </Col>

                <Col md={6} lg={8} name={"registrationColumn"}>
                    <AccessDataStudies searchParams={searchParams} isAccessData={true}/>
                </Col>
                <Col md={4} lg={2}>
                    <AccessDataAside/>
                </Col>
            </>
        )
    }

    /**
     * return the access page
     */
    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    {isLoading &&
                        <Spinner className="spinner-center" animation={"border"} variant={"primary"}  role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>}
                    {data && buildResults()}
                </PageWrapper>
            </ContentPage>
        </Container>
    )
}