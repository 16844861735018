/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ClinDrugAdditivity
 *
 * Purpose: special PDS project
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 08Nov2023 sasjxa file created
 * 25Aug2024 sasjxa file cleanup
 * 27Aug2024 sasjxa restructure to have project creation panel always displayed
 * 29Aug2024 sasjxa add project check before displaying table
 * 07Oct2024 sasjxa add paragraph description and link to full description
 \******************************************************************************/
import React, {Fragment, useMemo, useRef, useState} from "react";
import {Col, Container, Form, Row, Spinner, Table} from "react-bootstrap";
import {FormikProps} from "formik";
// @ts-ignore
import styled from 'styled-components';
import {
    ContentPage,
    FormLayoutColumn, NavMenuItem,
    PageWrapper,
    PDS_H5,
    PDS_P,
    PDS_P_BOLD
} from "../../components/styled/StyledComponents";
import "../forms/forms.css";

import {COLORS, FONT_SIZES} from "../../components/styled/StyleConstants";
import {useRequest} from "../../helper/useRequest";
import {CreateClinicalDrugProjectDialog} from "../../components/dialogs/CreateClinicalDrugProjectDialog";

import {DeleteClinicalDrugProjectDialog} from "../../components/dialogs/DeleteClinicalDrugProjectDialog";
import {IClinicalDrugProject} from "../../model/clinicalDrug/IClinicalDrug";
import {ClinicalDrugAdditivityProject} from "../../components/clinicalDrug/ClinicalDrugAdditivityProject";
import {BASE_PATH} from "../../constants";


const ClinicalDrugColumn = styled(FormLayoutColumn)`
    background-color: ${COLORS.light_grey};
    margin-top: 1px;
`;

const ClinicalDrugFormColumn = styled(Col)`
    width: 98%;
    margin: 0 auto;
    background-color: ${COLORS.white};
`;

const ClinicalDrugInnerColumn = styled(Col)`
    width: 95%;
    margin: 0 auto;
    background-color: ${COLORS.white};
`;

const ProjectsColumn = styled(Col)`
    background-color: ${COLORS.white};
    border-right: 2px solid ${COLORS.access_data_gray};
    margin-left: 0;
    padding-left: 0;
`;

const ProjectActionRow = styled.div`
    display: flex;
    justify-content: space-between;

    width: 60%;
    margin-left: 1%;

    span {
        display: inline;
    }
`;

const ProjectRow = styled.div`

    display: flex;
    justify-content: space-between;

`;

const ImgContainer = styled.div`
    display: flex;
    align-items: center;

    span {
        margin-left: 5px;

        :hover {
            text-decoration: underline;
        }
    }
`;

const DeleteImgContainer = styled.div`
    display: flex;
    align-items: start;
`;

const LabelName = styled.div`
    font-size: ${FONT_SIZES.px14};
`;

const FootNote = styled.div`
    span, p {
        font-size: ${FONT_SIZES.px13};
    }
`;

export const ClinicalDrugAdditivity: React.FC = () => {
    const [item, setItem] = useState({kindOfProject: "", another: "another"});
    const {kindOfProject} = item;
    const IMG_PATH: string = "/images/icons/";
    const [projects, setProjects] = useState<IClinicalDrugProject[]>();
    const [selectedProject, setSelectedProject] = useState<IClinicalDrugProject>();
    const [showCreateProject, setShowCreateProject] = useState<boolean>(false);
    const [showDeleteProject, setShowDeleteProject] = useState<boolean>(false);
    const [selectedProjectName, setSelectedProjectName] = useState("");
    const [selectedProjectId, setSelectedProjectId] = useState<number>(-1);
    const formikRef = useRef<FormikProps<any>>(null);
    const DOI_URL: string = "https://doi.org/10.1038/s43018-023-00667-z";


    const handleError = (error: object) => {
        console.log(error);
    }

    // close dialog, get project list
    const handleNewProjectSave = () => {
        setShowCreateProject(!showCreateProject);
        getUserProjects();
    }

    // close dialog, get project list
    const handleDeleteProject = () => {
        setShowDeleteProject(!showDeleteProject);
        // setSelectedProjectId(-1);
        // setSelectedProjectName("");
        // setSelectedP
        getUserProjects();
    }

    const handleDeleteDialog = (projectId: number, projectName: string) => {
        setSelectedProjectId(projectId);
        setSelectedProjectName(projectName);
        setShowDeleteProject(true);
    };

    const toggleCreateProjectDialog = () => {
        setShowCreateProject(!showCreateProject);
    }

    const toggleDeleteProjectDialog = () => {
        setShowDeleteProject(!showDeleteProject);
    }

    const handleProjectChanged = (project: IClinicalDrugProject) => {
        let name = project.projectName
        setItem(prevState => ({
            ...prevState,
            kindOfProject: name
        }));
        setSelectedProjectName(name);
        setSelectedProjectId(project.id);
        setSelectedProject(project);
    }


    // get data
    const getProjectsUrl = process.env.REACT_APP_API_URL + "/api/clinicaldrug/getUserProjects";
    const [requestState, getUserProjects] = useRequest({
        url: getProjectsUrl,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
    })
    const {isLoading, data, error} = requestState;

    // save data
    const saveProjectUrl = process.env.REACT_APP_API_URL + "/api/clinicaldrug/saveProject";
    const [requestActionState, saveProjectsData] = useRequest({
        url: saveProjectUrl,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
    })

    useMemo(() => {
        getUserProjects();
    }, [])

    useMemo(() => {
        if (data?.length) {
            setProjects(data);
            handleProjectChanged(data[0]);
            setSelectedProjectName(data[0].projectName);
            setSelectedProjectId(data[0].id);
            setSelectedProject(data[0]);
        }
    }, [data])


    const createEmptyProject = () => {
        if (!projects?.length) {
            return (
                <Table bordered>
                    <thead>
                    <tr>
                        <th>File name</th>
                        <th>Upload Date</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                </Table>
            )
        }
    }

    const createProjectLabel = (project: IClinicalDrugProject) => {
        return (
            <>
                <LabelName>{project.projectName}</LabelName>
                <LabelName className="inline">
                    {formatDate(project.createdAt)}
                </LabelName>
            </>
        )
    }

    const formatDate = (date: Date) => {
        return (
            new Date(date).toLocaleDateString('en-us', {
                month: "2-digit",
                day: "2-digit",
                year: "numeric"
            })
        )
    }

    const buildProject = (project: IClinicalDrugProject) => {
        const projectName: string = project.projectName;
        return (
            <ProjectRow className="w-100 ">
                <Form.Check
                    value={projectName}
                    type="radio"
                    aria-label="radio 1"
                    label={createProjectLabel(project)}
                    onChange={e => {
                        handleProjectChanged(project);
                        if (formikRef != null && formikRef.current) {
                            formikRef.current.handleChange(e);
                        }
                    }}
                    checked={kindOfProject === projectName}
                />
                <DeleteImgContainer>
                    <img className="mx-auto pe-2"
                         src={IMG_PATH + "delete.png"} title="Delete"
                         onClick={() => {
                             {
                                 handleDeleteDialog(project.id, project.projectName);
                             }
                         }}/>
                </DeleteImgContainer>
            </ProjectRow>
        )
    }

    const buildProjects = () => {
        return (
            <Form.Group as={Col} lg={12} controlId="kindOfProject" className="ps-2">
                {projects?.map((project: IClinicalDrugProject) => (
                    buildProject(project)
                ))}
            </Form.Group>
        )
    }

    const buildProjectCreation = () => {
        return (
            <>
                <PDS_P_BOLD>Projects</PDS_P_BOLD>
                <ProjectActionRow className=" mt-3 mb-3">
                    <ImgContainer>
                        <img className="mx-auto "
                             src={IMG_PATH + "newStarburst.png"}
                             onClick={() => {
                                 {
                                     toggleCreateProjectDialog()
                                 }
                             }}/>
                        <span onClick={() => {
                            {
                                toggleCreateProjectDialog()
                            }
                        }}
                        >Create New</span>
                    </ImgContainer>
                </ProjectActionRow>

            </>
        )
    }

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <CreateClinicalDrugProjectDialog title={"Create Project"}
                                                     show={showCreateProject} showHide={toggleCreateProjectDialog}
                                                     handleSave={handleNewProjectSave}
                    />
                    <DeleteClinicalDrugProjectDialog projectName={selectedProjectName} projectId={selectedProjectId}
                                                     show={showDeleteProject} showHide={toggleDeleteProjectDialog}
                                                     handleDelete={handleDeleteProject}
                    />
                    <ClinicalDrugColumn md={8} lg={12} className="pt-2 pb-2">
                        <PDS_H5 className=" me-2 ms-2">Clinical Drug Additivity Calculations</PDS_H5>
                        <PDS_P className=" me-2 ms-2">The “Palmer Algorithm” is an approach which has been shown to
                            reliably
                            predict which combinations of cancer drugs are likely to perform well as measured by
                            Progression Free Survival (PFS) [1]. This application interface uploads several key
                            parameters
                            and two PFS data sets (‘Experiment’ and ‘Control’) in .csv format provided by the user.
                            It returns data for two PFS curves for the projected effect of the combination
                            (Highest Single Agent (HSA) and Additivity) and illustrates these outcomes graphed on a
                            single plot.
                            From this data, the user can obtain an estimate of the efficacy of the two-drug combination
                            prior
                            to performing a formal clinical trial. For more detailed information on the algorithm,
                            please
                            <NavMenuItem to={BASE_PATH + "clinicalDrugAdditivityDescription"}>
                                click here
                            </NavMenuItem>

                        </PDS_P>
                        <FootNote className="mt-3 mb-5">
                            <p className=" mb-0">
                                [1] Hwangbo, H., Patterson, S.C., Dai, A. et al. Additivity predicts the efficacy of
                                most
                                approved combination therapies for advanced cancer. Nat Cancer 4, 1693–1704 (2023).
                            </p>
                            <span className="mt-0  ">
                            <a href={DOI_URL} target="_blank"
                               rel={"noopener noreferrer"}>https://doi.org/10.1038/s43018-023-00667-z</a>
                             </span>
                        </FootNote>
                        <ClinicalDrugFormColumn className="mt-4 mb-2  b " lg={12}>
                            <ClinicalDrugInnerColumn className="mt-2 ">
                                <Row>
                                    <Form.Group as={ProjectsColumn} lg={3} className="mt-2  ">
                                        {buildProjectCreation()}

                                        {isLoading &&
                                            <Spinner className="spinner-center" animation={"border"} variant={"primary"}
                                                     role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>}
                                        {error && <p>Error</p>}
                                        {projects && buildProjects()}
                                    </Form.Group>

                                    {projects && (
                                        <Form.Group as={Col} lg={9} className="">
                                            <ClinicalDrugAdditivityProject projectId={selectedProjectId}/>
                                        </Form.Group>
                                    )}

                                </Row>
                            </ClinicalDrugInnerColumn>
                        </ClinicalDrugFormColumn>
                    </ClinicalDrugColumn>
                </PageWrapper>
            </ContentPage>
        </Container>
    );

}