/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Admin PROData
 *
 * Purpose: Admin Patient Reported Outcomes for Share Data
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 06Nov2023 sasjxa file created
 \******************************************************************************/
import React from "react";
import {Col, Form,  Row} from "react-bootstrap";

interface CascadedProps {
    fieldlabel1: string,
    fieldName1: string,
    fieldValue1: any,
    fieldlabel2: string,
    fieldName2: string,
    fieldValue2: any
    fieldlabel3: string,
    fieldName3: string,
    fieldValue3: any,

}


export const AdminCascadedData: React.FC<CascadedProps> =({
                                                        fieldlabel1,fieldName1,fieldValue1,
                                                        fieldlabel2,fieldName2,fieldValue2,
                                                        fieldlabel3,fieldName3,fieldValue3,


                                                    }: CascadedProps) => {



    return (
        <>
            <Row className="mt-3 mb-5 ">
                <Row className="mt-3  ">
                    <Form.Group as={Col} >
                        <Form.Label className="bold" column="sm">{fieldlabel1} </Form.Label>
                        <Form.Control size="sm"
                                      name={fieldName1}
                                      type="text"
                                      defaultValue={fieldValue1}
                                      disabled={true}/>
                    </Form.Group>
                </Row>

                {fieldValue2 != null  && (
                    <Row className="mt-3 ">
                    <Form.Group as={Col} >
                        <Form.Label className="bold" column="sm">{fieldlabel2} </Form.Label>
                        <Form.Control size="sm"
                                      name={fieldName2}
                                      type="text"
                                      defaultValue={fieldValue2}
                                      disabled={true}/>
                    </Form.Group>
                    </Row>
                )}

                {fieldValue3 != null  && (
                    <Row className="mt-3 ">
                        <Form.Group as={Col} >
                            <Form.Label className="bold" column="sm">{fieldlabel3} </Form.Label>
                            <Form.Control size="sm"
                                          name={fieldName3}
                                          type="text"
                                          defaultValue={fieldValue3}
                                          disabled={true}/>
                        </Form.Group>
                    </Row>
                )}

            </Row>

        </>

    )

}