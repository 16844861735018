/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: DeleteClinicalDrugFileDialog
 *
 * Purpose: Dialog for delete of clinical drug file
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 28Aug2024 sasjxa file created
 \******************************************************************************/
import {Button, Modal} from "react-bootstrap";
import React from "react";
import {DialogHeader} from "../styled/StyledComponents";
// @ts-ignore
import styled from "styled-components";
import {useRequest} from "../../helper/useRequest";

interface DialogProps {
    projectId: number,
    fileName: string,
    fileId: number,
    show: boolean,
    handleDelete: any,
    showHide: any,
}

export const DeleteClinicalDrugFileDialog: React.FC<DialogProps> = ({ projectId, fileName,
                                                                           fileId,
                                                                           show,
                                                                           handleDelete,
                                                                           showHide
                                                                       }: DialogProps) => {

    const handleError = (error: object) => {
        console.log(error);
    }

    // delete the file
    const [requestDeleteActionState, setDeleteFileAction] = useRequest({
        url: process.env.REACT_APP_API_URL + "/api/clinicaldrug/deleteProjectFile/" + projectId + "/"  + fileId,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleDelete
    });

    return (
        <Modal show={show}>
            <Modal.Header closeButton={true} onClick={showHide}>
                <DialogHeader>Delete File: {fileName}</DialogHeader>
            </Modal.Header>
            <Modal.Body>
               Do you want to delete the file?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" className="btn-submit" type="button"
                                 onClick={() => {
                                     setDeleteFileAction();
                                 }}>
                    Yes
                </Button>
                <Button variant={"secondary"} onClick={showHide}>
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
