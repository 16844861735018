/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: TimeoutDialog
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 05Mar2024 craig File created.
 * 23Aug2024 craig Moved all the idleTimer code from layout.tsx to here
 * 28Aug2024 craig Fix prompt timeout bug and remaining time bug
 \******************************************************************************/
import React, {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {useIdleTimer} from "react-idle-timer";
import {usePDSContext} from "../../context/PDSContext";

interface DialogProps {
    logout: () => void;
}

export const TimeoutDialog: React.FC<DialogProps> = ({logout}: DialogProps) => {
    const {sessionUser} = usePDSContext();
    const [idleModal, setIdleModal] = useState<boolean>(false);
    const [remaining, setRemaining] = useState<number>(0);


    let idlePrompt:number = 1000 * 60 * 5;  //5 minutes
    let idleLogout:number = 1000 * 60 * 30; //60 Minutes

    useEffect(() => {
        const interval = setInterval(() => {
            //console.log(getRemainingTime());
            setRemaining(getRemainingTime());
        }, 1000);
    },[]);

    const onIdle = () => {
        setIdleModal(false);
        if (sessionUser?.userID > 0) {
            logout();
        } else {
            //don't really care, so just reset the timer
            reset();
        }
    }

    /**
     * onActive hook - will call when active
     */
    const onActive = () => {
        if (sessionUser?.userID > 0) {
            setIdleModal(false);
        }
        reset();
    }

    /**
     * handleKeepAlive - just do a reset at this point
     */
    const handleKeepAlive = () => {
        setIdleModal(false);
        reset();
    }

    const handleLogoff = () => {

    }

    /**
     * onPrompt hook.  Will call 5 min before end of session
     */
    const onPrompt = () => {
        //only show timeout dialog for logged in users (other
        const min = Math.floor((getRemainingTime()/1000/60) << 0);
        const sec = Math.floor((getRemainingTime()/1000) % 60);
        console.log("remaining time = " + min + ":" + sec);
        if (sessionUser?.userID > 0) {
            setIdleModal(true);
        } else {
            setIdleModal(false);
        }
    }

    const getTimeLeft = ():string => {
        const totalSeconds = Math.floor(remaining / 1000);
        const minutes:number = Math.floor(totalSeconds / 60);
        const seconds:number = totalSeconds % 60;

        // Add leading zero if necessary
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

        return `${formattedMinutes}:${formattedSeconds}`;
    }


    const {
        reset,
        getRemainingTime
    } = useIdleTimer({
        onPrompt,
        onIdle,
        onActive,
        timeout: idleLogout,
        promptBeforeIdle: idlePrompt,
        events: [
            'keydown',
            'mousedown',
            'mousemove',
            'scroll'
        ],
        throttle: 500,
        eventsThrottle: 200,
    })

    return (
        <Modal show={idleModal} keyboard={false} backdrop={"static"} >
            <Modal.Header closeButton={false} >
                <Modal.Title>Session Timeout!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Your session is about to expire in {getTimeLeft()} minutes due to inactivity.
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"secondary"} onClick={logout}>
                    Logout
                </Button>
                <Button variant={"secondary"} onClick={handleKeepAlive}>
                    Stay Logged In
                </Button>
            </Modal.Footer>
        </Modal>
    )
}