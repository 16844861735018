/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminUserLoginInfoDialog
 *
 * Purpose: Admin dialog for displaying login info about a user
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 31Oct2024 sasjxa  file created
 \******************************************************************************/
import {Button, Col, Modal, Row, Table} from "react-bootstrap";
import React from "react";
import {DialogHeader} from "../styled/StyledComponents";
import {IActiveUser} from "../../model/admin/dashboard/IAdminDashboardCounts";
import {IFullUser} from "../../model/user/IFullUser";
import {IAdminSamUserInfo, IAdminSamUserLogins} from "../../model/admin/requests/IAdminSamUserInfo";
import styled from "styled-components";
import {COLORS, FONT_SIZES} from "../styled/StyleConstants";

interface UserLoginInfoDialogProps {
    userInfo: IAdminSamUserInfo | undefined,
    show: boolean,
    showHide: any
}

const BorderCol = styled(Col)`
    font-size: ${FONT_SIZES.px16};
    border: 1px solid ${COLORS.middle_grey};
`;

const LoginCol = styled(Col)`
    font-size: ${FONT_SIZES.px13};
`;


const LoginColHeader = styled(LoginCol)`
    font-weight: bold;
`;

const HeaderRow = styled(Row)`
    font-weight: bold;

    span {
        text-align: center;
    }
`;


export const UserLoginInfoDialog: React.FC<UserLoginInfoDialogProps> = ({
                                                                            userInfo,
                                                                            show,
                                                                            showHide
                                                                        }: UserLoginInfoDialogProps) => {


    const buildLoginHeader = () => {
        if (userInfo?.userLogins?.length) {
            return (
                <>
                <HeaderRow className="mt-3 w-100 text-center"><span>Last 5 Logins</span></HeaderRow>
            <Row className="mt-3 w-100">

                <LoginColHeader lg={6} className={"ps-4 "}>Login time</LoginColHeader>
                <LoginColHeader lg={3}>Browser</LoginColHeader>
                <LoginColHeader lg={3}>OS</LoginColHeader>
            </Row>
                </>
            )
        }
        else {
            return (
                <HeaderRow className="mt-3 w-100 text-center"><span>No Logins for this user</span></HeaderRow>
            )
        }

    }

    const buildRow = (fieldName: string, fieldValue: string | undefined) => {
        return (
            <Row className={"w-100 pb-0"}>
                <Col lg={5} className={"ps-4  "}>
                    {fieldName}
                </Col>
                <Col lg={6}>
                    {fieldValue}
                </Col>
            </Row>
        )
    }
    return (
        <Modal show={show} >
            <Modal.Header closeButton={true} onClick={showHide}>
                <DialogHeader>User Information</DialogHeader>
            </Modal.Header>
            <Modal.Body>

                <BorderCol className="mb-3">
                    {buildRow("Name:", userInfo?.name)}
                    {buildRow("Email:", userInfo?.email)}
                    {buildRow("Role:", userInfo?.role)}
                    {buildRow("AD User Id:", userInfo?.adUserId)}
                    {buildRow("AD User Status:", userInfo?.adStatus)}
                </BorderCol>
                <BorderCol className="mb-3">
                    {buildLoginHeader()}

                {
                    userInfo?.userLogins?.map((login: IAdminSamUserLogins) => {
                        return <Row className="mt-1 w-100">

                            <LoginCol lg={6} className={"ps-4 "}>{new Date(login.sessionStart).toLocaleDateString('en-us', {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                timeZoneName: "short"

                            })}</LoginCol>
                            <LoginCol lg={3}>{login.browserName}</LoginCol>
                            <LoginCol lg={3}>{login.os}</LoginCol>
                        </Row>
                    })

                }
                </BorderCol>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"primary"} onClick={showHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
