/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Attestation
 *
 * Purpose: PDS user registration page
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 02May2023 sasjxa file created
 * 02Jun2023 sasjxa move styled components out of FC
 \******************************************************************************/
import React from "react";
import {Col} from "react-bootstrap";

// @ts-ignore
import styled from 'styled-components';
import {
    PDS_P, PDS_P_BOLD
} from "./styled/StyledComponents";

import parse from "html-react-parser";

import {
    ATTESTATION,
    ATTESTATION_DESCRIPTION,
    ATTESTATION_TITLE,
    ATTESTATION_VERSION,
    IAttestation
} from "../data/attestation/attestation";

const UserAgreementContainer = styled(Col)` 
      padding-left: 0;
      padding-right: 0;  
      margin-right: 0;
      margin-left: 0;        
      height: 250px;
      border: 1px solid #7b828e;
      
    
      p {
        padding-left: 5px;
        padding-right: 5px;
      } 
      
      p.center-text {
        text-align: center;
        margin-top: 5px;
      }
      
      p.no-margin-bottom{
        margin-bottom: 3px;
      }
      
      p.add-margin {
       margin-top: 1%;
      }
    
      p.indent{
        margin-left: 2%;
        margin-bottom: 2px;
        line-height: 1.2;
      }
      
      p.extra-text{
        margin-top: 10px;
        padding-left: 0;
        padding-right: 0;
      }
      
      
      
      li {
        margin-top: 10px;
      }
    
      li.no-marker {
        text-decoration: none;
      }
      
      ol.lower-roman-list{
        list-style-type: none; 
      }  
          
      li.roman-list-1:before {
        content: "{i}";
      }
              
      li.roman-list-2:before {
       content: "{ii}";             
      }
          
      li.roman-list-3:before {
        content: "{iii}";
      } 
            
      li.roman-list-4:before {
        content: "{iv}";
      }  
          
      li.roman-list-5:before {
        content: "{v}";
      }   
            
      li.roman-list-6:before {
        content: "{vi}";
      }   
    
      ul.no-decoration {
        list-style-type: none;
      }
      
      ul.asterisk-list{
        list-style-type: none;
                            
        li.asterisk-list:before {
          content: "*";             
        } 
      }             
    
      h6 {
        font-weight: 600;
        text-align: center;
        margin-top: 1%;
        margin-bottom: 0;
      }
    
      img {
        margin: auto;
        display: block;
        width: 350px;
        height: 154px;
      }
    
      @media (min-width: 380px){ 
        overflow: auto;    
      }
    
      @media (min-width: 768px){ 
          overflow-x: hidden;        
      }
    `;

export const Attestation: React.FC = () => {



    return (
            <UserAgreementContainer   className="mt-3 mb-3 ">

                    <h6>{ATTESTATION_TITLE}</h6>
                    <PDS_P className="center-text">{ATTESTATION_VERSION}</PDS_P>
                    <p>{parse(ATTESTATION_DESCRIPTION)}</p>

                   {
                       ATTESTATION.map((attestation: IAttestation) => {
                           return <Col key={attestation.id}>
                                <PDS_P_BOLD className="spacing">{parse(attestation.title)}</PDS_P_BOLD>
                                <PDS_P>{parse(attestation.sectionText)}</PDS_P>
                           </Col>
                        })
                   }

            </UserAgreementContainer>
    )

}
