/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Share Data Supplemental Information
 *
 * Purpose: Share Data Supplemental Information page
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 11May2023 sasjxa file created
 * 23May2023 sasjxa add character count for text area
 * 31May2023 sasjxa add values to select components
 * 12Jun2023 sasjxa add logic for toggling PRO and custom formats
 * 15Jun2023 sasjxa use yup.when for validation of PRO and custom formats
 * 23Jun2023 sasjxa refactor to use new aside component
 * 21Aug2023 craig  add back end calls
 * 10Oct2023 sasjxa fix back navigate
 * 20Oct2023 sasjxa use custom components for PRO and Custom Formats
 * 23Feb2024 sasjxa add clear button functionality (MPM-5425)
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 12Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 02Jul2024 craig  moved decode to api
 * 04Nov2024 sasjxa added yup max to text fields
 \******************************************************************************/
import React, {useMemo, useRef, useState} from "react";
import {Col, Container, Form, Spinner} from "react-bootstrap";
import * as Yup from 'yup';
import {Formik, FormikProps} from "formik";
// @ts-ignore
import styled from 'styled-components';

import {
    ContentPage,
    FormLayoutColumn,
    FormSectionTitle,
    PageWrapper,
    PDS_H5,
    PDS_P
} from "../../components/styled/StyledComponents";

import "../forms/forms.css";
import {useNavigate, useParams} from "react-router-dom";
import {FormList, max600Count, NO_OPTION, YES_OPTION} from "../../data/formList";
import {Aside} from "../../components/Aside";
import {IDonationsSupplementalData} from "../../model/donations/IDonations";
import {useRequest} from "../../helper/useRequest";
import {PROData} from "../../components/share/PROData";
import {CustomFormats} from "../../components/share/CustomFormats";
import {BackClearAndSubmitButtonContainer} from "../../components/buttonContainer/BackClearAndSubmitButtonContainer";
import {TextFieldRequired} from "../../components/forms/TextFieldRequired";
import {TextAreaRequired} from "../../components/forms/TextAreaRequired";
import {TextAreaOptional} from "../../components/forms/TextAreaOptional";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";
import {enableClearButton, getEncodedValue} from "../../constants/CommonFunctions";
import {domainVarsDesc, efficacyDesc, patientDesc} from "../../constants/ShareDataFieldsText";

export const ShareDatasetSuppInfo: React.FC = () => {
    const {donationId} = useParams();
    const navigate = useNavigate();
    const { showBoundary } = useErrorBoundary();
    const [disabled, setDisabled] = useState(false);
    const [suppData, setSuppData] = useState<IDonationsSupplementalData>();
    const [uniquePatientIdentifier, setUniquePatientIdentifier] = useState("");
    const [dateTimeVars, setDateTimeVars] = useState("");
    const [efficacyEndpoints, setEfficacyEndpoints] = useState("");
    const [domainVars, setDomainVars] = useState("");
    const [patientBreakdown, setPatientBreakdown] = useState("");
    const [efficacyEndpointsCount, setEfficacyEndpointsCount] = useState(0);
    const [dateTimeVariablesCount, setDateTimeVariablesCount] = useState(0);
    const [domainsCount, setDomainsCount] = useState(0);
    const [patientBreakdownCount, setPatientBreakdownCount] = useState(0)
    const [proFilesCount, setProFilesCount] = useState(0);


    const [customFormatsDecodedState, setCustomFormatsDecodedState] = useState(false);
    const [customFormatsUploadedState, setCustomFormatsUploadedState] = useState(false);

    const [PROProvidedState, setPROProvidedState] = useState(false);
    const [PROFilesState, setPROFilesState] = useState(false);

    const [PROCaptured, setPROCaptured] = useState("");
    const [PROProvided, setPROProvided] = useState("");
    const [PROFiles, setPROFiles] = useState("");
    const [customFormatsUsed, setCustomFormatsUsed] = useState("");
    const [customFormatsDecoded, setCustomFormatsDecoded] = useState("");
    const [customFormatsUploaded, setCustomFormatsUploaded] = useState("");
    const maxTextAreaCount = 600;
    const formikRef = useRef<FormikProps<any>>(null);

    // handle error - unsuccessful retrieve or save of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    // handle successful save of data
    const handleSuccess = () => {
        //go back to share page
        navigate(BASE_PATH + 'share');
    }

    // get  data
    const suppDataUrl = process.env.REACT_APP_API_URL + "/api/share/shareDatasetSuppData/" + donationId;
    const [requestState, getSuppData] = useRequest({
        url: suppDataUrl,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })
    const {isLoading, data, error} = requestState;

    // save data
    const [requestActionState, setSuppDataset] = useRequest({
        url: suppDataUrl,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })

    useMemo(() => {
        if ((donationId !== undefined && Number(donationId) > 0)) {
            getSuppData();
        }
    }, [])
    useMemo(() => {
        if (data != null) {
            setSuppData(data);
        }
    }, [data])

    useMemo(() => {
        if (suppData != null) {
            setUniquePatientIdentifier(suppData.uniquePatientIdentifier);
            setDateTimeVars(suppData.dateTimeVars);
            setEfficacyEndpoints(suppData.efficacyEndpoints);
            setDomainVars(suppData.efficacyEndpointsDomainVars);
            setPatientBreakdown(suppData.patientBreakdown);
            setPROCaptured(suppData.patientReportedOutcomesCaptured);
            setPROProvided(suppData.patientReportedOutcomesProvided);
            setPROFiles(suppData.patientReportedOutcomesFiles);
            setCustomFormatsUsed(suppData.customFormatsUsed);
            setCustomFormatsDecoded(suppData.customFormatsDataDecoded);
            setCustomFormatsUploaded(suppData.customFormatsUploaded);


            // initialize PRO cascading selects
            if (suppData.patientReportedOutcomesCaptured != null && (suppData.patientReportedOutcomesCaptured === YES_OPTION)) {
                setPROProvidedState(true);
            }
            if (suppData.patientReportedOutcomesProvided != null && suppData.patientReportedOutcomesProvided === YES_OPTION) {
                setPROFilesState(true);
            }

            // initialize custom formats cascading selects
            if (data.customFormatsUsed != null && (data.customFormatsUsed === YES_OPTION)) {
                setCustomFormatsDecodedState(true);
            }
            if (data.customFormatsDataDecoded != null && (data.customFormatsDataDecoded === NO_OPTION)) {
                setCustomFormatsUploadedState(true);
            }
            setSuppData(data);
        }
    }, [suppData])

    // validation schema
    const schema = Yup.object().shape({
        uniquePatientIdentifier: Yup.string().required("Please enter a unique patient identifier.").max(200, "Unique patient identifier should be no longer than 200 characters."),
        efficacyEndpoints: Yup.string().required("Please enter the efficacy endpoints."),
        efficacyEndpointsDomainVars: Yup.string().required("Please enter the domains and variables."),
        patientReportedOutcomesCaptured: Yup.string().required("Please indicate whether PRO data was captured."),
        patientReportedOutcomesProvided: Yup.string().when(['patientReportedOutcomesCaptured'], {
            is: (patientReportedOutcomesCaptured: string) => {
                if (patientReportedOutcomesCaptured === YES_OPTION) {
                    return true // validate and go to then function
                } else return false // for all other cases is not required
            },
            then: (schema) =>
                schema
                    .required("Please select a value for custom formats provided")
        }),
        patientReportedOutcomesFiles: Yup.string().when(['patientReportedOutcomesProvided'], {
            is: (patientReportedOutcomesProvided: string) => {
                if (patientReportedOutcomesProvided === YES_OPTION) {
                    return true // validate and go to then function
                } else return false // for all other cases is not required
            },
            then: (schema) =>
                schema
                    .required("Please select a value for custom formats files")
        }),
        customFormatsUsed: Yup.string().required("Please indicate whether this dataset uses custom formats."),
        customFormatsDataDecoded: Yup.string().when(['customFormatsUsed'], {
            is: (customFormatsUsed: string) => {
                if (customFormatsUsed === YES_OPTION) {
                    return true // validate and go to then function
                } else return false // for all other cases is not required
            },
            then: (schema) =>
                schema
                    .required("Please select a value for custom formats decoded")
        }),
        customFormatsUploaded: Yup.string().when(['customFormatsDataDecoded'], {
            is: (customFormatsDataDecoded: string) => {
                if (customFormatsDataDecoded === NO_OPTION) {
                    return true // validate and go to then function
                } else return false // for all other cases is not required
            },
            then: (schema) =>
                schema
                    .required("Please select a value for custom formats decoded")
        }),

    });

    /// clear form fields
    const handleReset = () => {
        console.log(error);
        if (formikRef != null && formikRef.current) {
            formikRef.current.resetForm();
            reinitializeValues();
        }
    }

    // re-initialize values after clear
    const reinitializeValues = () => {
        setUniquePatientIdentifier("");
        setDateTimeVars("");
        setEfficacyEndpoints("");
        setDomainVars("");
        setPatientBreakdown("");
        setPROCaptured("");
        setPROProvided("");
        setPROFiles("");
        setCustomFormatsUsed("");
        setCustomFormatsDecoded("");
        setCustomFormatsUploaded("");
        setPROProvidedState(false);
        setPROFilesState(false);
        setCustomFormatsDecodedState(false);
        setCustomFormatsUploadedState(false);
    }

    // build select list
    const buildSelectList = (listType: FormList[]) => {
        let optionTemplate = listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
        return optionTemplate;
    }

    const buildForm = () => {
        return (
            <Formik innerRef={formikRef}
                    enableReinitialize
                    initialValues={{
                        uniquePatientIdentifier: uniquePatientIdentifier ?? '',
                        dateTimeVars: dateTimeVars ?? '',
                        efficacyEndpoints: efficacyEndpoints ?? '',
                        efficacyEndpointsDomainVars: domainVars ?? '',
                        patientReportedOutcomesCaptured: PROCaptured ?? '',
                        patientReportedOutcomesProvided: PROProvided ?? '',
                        patientReportedOutcomesFiles: PROFiles ?? '',
                        customFormatsUsed: customFormatsUsed ?? '',
                        customFormatsDataDecoded: customFormatsDecoded ?? '',
                        customFormatsUploaded: customFormatsUploaded ?? '',
                        patientBreakdown: patientBreakdown ?? '',
                    }}
                    validationSchema={schema}
                    onSubmit={(values) => {
                        setSuppDataset(JSON.stringify({
                            uniquePatientIdentifier: getEncodedValue(values.uniquePatientIdentifier),
                            dateTimeVars:  getEncodedValue(values.dateTimeVars),
                            efficacyEndpoints:  getEncodedValue(values.efficacyEndpoints),
                            efficacyEndpointsDomainVars:  getEncodedValue(values.efficacyEndpointsDomainVars),
                            patientReportedOutcomesCaptured: values.patientReportedOutcomesCaptured,
                            patientReportedOutcomesProvided: values.patientReportedOutcomesProvided,
                            patientReportedOutcomesFiles: values.patientReportedOutcomesFiles,
                            customFormatsUsed: values.customFormatsUsed,
                            customFormatsDataDecoded: values.customFormatsDataDecoded,
                            customFormatsUploaded: values.customFormatsUploaded,
                            patientBreakdown:  getEncodedValue(values.patientBreakdown)
                        }))
                    }}

                    validateOnChange={false}
                    validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      resetForm,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn className="mt-5 mb-5" lg={12}>
                            <FormLayoutColumn lg={8}>
                                <TextFieldRequired fieldName={"uniquePatientIdentifier"}
                                                   label="Unique Patient Identifier Field"
                                                   value={uniquePatientIdentifier}
                                                   valueHandler={setUniquePatientIdentifier} disabled={disabled}
                                                   touched={touched}
                                                   errors={errors} size={12}/>
                                <TextAreaOptional fieldName={"dateTimeVars"} label={"Date/Time Variables"}
                                                  value={dateTimeVars} valueHandler={setDateTimeVars}
                                                  currentCharCount={dateTimeVariablesCount}
                                                  maxTextAreaCount={max600Count}
                                                  countHandler={setDateTimeVariablesCount}
                                                  disabled={disabled} handleChange={handleChange}/>

                                <FormSectionTitle>Descriptive Information</FormSectionTitle>
                                <TextAreaRequired fieldName={"efficacyEndpoints"} label={"Efficacy Endpoints"}
                                                  value={efficacyEndpoints} valueHandler={setEfficacyEndpoints}
                                                  currentCharCount={efficacyEndpointsCount}
                                                  maxTextAreaCount={maxTextAreaCount}
                                                  countHandler={setEfficacyEndpointsCount} disabled={disabled}
                                                  touched={touched} errors={errors}
                                                  handleChange={handleChange} formText={efficacyDesc}/>
                                <TextAreaRequired fieldName={"efficacyEndpointsDomainVars"}
                                                  label={"Domains and Variables"}
                                                  value={domainVars} valueHandler={setDomainVars}
                                                  currentCharCount={domainsCount} maxTextAreaCount={maxTextAreaCount}
                                                  countHandler={setDomainsCount} disabled={disabled} touched={touched}
                                                  errors={errors}
                                                  handleChange={handleChange} formText={domainVarsDesc}/>
                                <PROData proCaptured={PROCaptured} proCapturedHandler={setPROCaptured}
                                         proProvided={PROProvided}
                                         proProvidedValueHandler={setPROProvided} proProvidedState={PROProvidedState}
                                         proProvidedStateHandler={setPROProvidedState}
                                         proFilesState={PROFilesState} proFiles={PROFiles}
                                         proFilesValueHandler={setPROFiles}
                                         proFilesStateHandler={setPROFilesState}
                                         maxTextAreaCount={maxTextAreaCount}
                                         proFilesCountHandler={setProFilesCount}
                                         selectListHandler={buildSelectList} disabled={false}
                                         handleChange={handleChange} touched={touched} errors={errors}/>

                                <CustomFormats customFormatsUsed={customFormatsUsed}
                                               customFormatsUsedHandler={setCustomFormatsUsed}
                                               customFormatsDecodedState={customFormatsDecodedState}
                                               customFormatsDecoded={customFormatsDecoded}
                                               customFormatsDecodedStateHandler={setCustomFormatsDecodedState}
                                               customFormatsDecodedValueHandler={setCustomFormatsDecoded}
                                               customFormatsUploaded={customFormatsUploaded}
                                               customFormatsUploadedValueHandler={setCustomFormatsUploaded}
                                               customFormatsUploadedState={customFormatsUploadedState}
                                               customFormatsUploadedStateHandler={setCustomFormatsUploadedState}
                                               selectListHandler={buildSelectList} disabled={false}
                                               handleChange={handleChange} touched={touched} errors={errors}/>

                                <TextAreaOptional fieldName={"patientBreakdown"} label={"Patient Breakdown"}
                                                  value={patientBreakdown} valueHandler={setPatientBreakdown}
                                                  currentCharCount={patientBreakdownCount}
                                                  maxTextAreaCount={max600Count}
                                                  countHandler={setPatientBreakdownCount}
                                                  disabled={disabled} handleChange={handleChange}
                                                  formText={patientDesc}/>

                            </FormLayoutColumn>
                        </FormLayoutColumn>
                        <BackClearAndSubmitButtonContainer handleReset={handleReset} enableClear={enableClearButton(donationId)}
                                                           backNavigate={"ShareDataset/" + donationId}/>
                    </Form>

                )}
            </Formik>
        )
    }


    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn md={8} lg={9} name={"formLayoutColumn"}>
                        <PDS_H5>Dataset Information - Dataset Form - Page 3 of 3</PDS_H5>
                        <PDS_P>While each contribution requires a supporting data dictionary, PDS will verify the
                            structure and content. Please confirm the critical details which define these data including
                            unique keys and definitions used within this data collection.
                        </PDS_P>
                        {isLoading &&
                            <Spinner  className ="spinner-center" animation={"border"} variant={"primary"} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>}
                        {error && <p>Error</p>}
                        {suppData && buildForm()}
                    </FormLayoutColumn>
                    <Col md={4} lg={3}>
                        <Aside needHelp={true} requiredFields={true}/>
                    </Col>
                </PageWrapper>
            </ContentPage>


        </Container>
    );

}
