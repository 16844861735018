/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ShareDataFieldsText
 *
 * Purpose: text displayed as labels and descriptions for share data fields
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 30Apr2024 sasjxa file created
 \********************************************************************************/
export const comparatorDesc:string = "Please Enter the number of patients enrolled only" +
    "in the Comparator (Control) Arm that is being provided as part of this submission.";

export const activeDesc:string = "\"Please Enter the number of patients enrolled only" +
    "in the Experimental (Active) Arm that is being provided as part of this submission.";

export const objectivesDesc:string = " If this is clinical trial data, please copy in the primary and secondary objectives" +
    "                            captured in protocol. For other data contributions please include primary and secondary" +
    "                            objectives which best explain the data contribution and patient population - 'why was" +
    "                            it collected and how was it used?'";

export const dataSummaryDesc:string =" Please provide a concise summary of the data provided. For example, \"Control Arm data\n" +
    "                            files include raw data sets on safety, efficacy, and demographics.";

export const TOTAL_ENROLLED_ERROR_MSG: string = "Total Study Enrolled Patients must be a non-zero integer value";
export const COMPARATOR_ENROLLED_ERROR_MSG: string = "Comparator (Control) Arm Enrolled Patients must be a non-zero integer value";
export const EXPERIMENTAL_ENROLLED_ERROR_MSG: string = "Experimental (Active) Arm Enrolled Patients must be a non-zero integer value";

export const TOTAL_ENROLLED_ERROR_RWD_MSG: string = "Total Patients must be a non-zero numeric value";

export const trialSummaryDesc:string ="Please provide a concise summary of all relevant information about the design and rationale.";

export const outcomesDesc:string="Please provide a concise summary of the outcomes documented in the study protocol or study" +
    "                            report summary documents."

export const populationDesc:string = "If there are natural groups of patient populations based on region, condition, or treatment please explain or designate.";

export const institutionDesc:string ="Please confirm if this data is from a single institution or collection pool, if from multiple\n" +
    "                            please offer any description or designation of such. If you are not disclosing the sources\n" +
    "                            then any descriptions of how the data breakdown across sites is helpful.";

export const dataCollectionDesc:string="Please list all instruments making up this data collection (radiology, etc.).";

export const therapyDesc:string="If this data has a primary or secondary line of therapy or drug, please include these specifics.";

export const efficacyDesc: string = "Please explain how the efficacy endpoints are defined in the data.";
export const domainVarsDesc: string = " Please describe the domains and/or variables (e.g. death flag)" +
    "                            that capture the information relating to efficacy endpoints.";
export const patientDesc: string = "Please provide a breakdown of the patients that are included in the uploaded" +
    "                            data (e.g. demographics, participant flow, etc.).";



