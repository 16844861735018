/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Terms
 *
 * Purpose: terms and conditions for the site
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 27Feb2023 craig  file created
 * 21Aug2024 craig  removed sessionUser (unused)
 * 25Sep2024 sasjxa fixed header alignment
 \******************************************************************************/
import React, {Fragment} from "react";
// @ts-ignore
import styled from 'styled-components';
import {PageHeaderWithBackground, PDS_P, PDS_P_BOLD, Ul_disc} from "../../components/styled/StyledComponents";
import {COLORS} from "../../components/styled/StyleConstants";
import {IFaq} from "../../data/resources/faqData";
import parse from "html-react-parser";
import {GENERAL_TEXT, PRIVACY_STATEMENT} from "../../data/privacy/privacy";

export const Terms: React.FC = () => {
    const FaqPage = styled.div`
        margin-right: 5%;
        margin-left: 6%;
        margin-bottom: 3%;
        padding: 0;
        margin-top: 1%;
    `;

    const SectionContainer = styled.div`
        margin-bottom: 1rem;
        margin-top: 1rem;

        p:nth-of-type(1) {

            padding-bottom: 0px;
            margin-bottom: 0;

        }

        p:nth-of-type(2) {
            margin-top: 2px;
        }

        li {
            font-size: .8125rem;
            font-weight: 400;
            line-height: 1.5;
            color: ${COLORS.dark_grey_blue};
        }

        p.address {
            margin-bottom: 0px;
            margin-top: 1px;

        }
    `;

    const Ul_number = styled.ul`
        list-style-type: decimal;
    `;

    const Ul_character = styled.ul`
        list-style-type: upper-alpha;
    `;

    const TermsSectionHeader = styled(PDS_P)`
        font-weight: 600;
        margin-bottom: 3px;
        margin-top: 0;
    `;

    return (
        <>
            <FaqPage className={"faq-page"}>
                <PageHeaderWithBackground className={"privacy-header"}>
                    <h2>Terms of Use</h2>
                </PageHeaderWithBackground>
                <SectionContainer>
                    <PDS_P>These Terms of Use cover your access to and use of this website. Upon notice to you, which
                        notice may
                        be provided by posting such new changes on the website and, if you are a registered user, by
                        email sent to the email
                        address set forth in your application to be a registered user, <em>Project Data Sphere</em>
                        (<em>Project Data Sphere</em>) may change these Terms of Use. You must adhere to and abide by
                        these
                        Terms of Use and other guidelines provided to you by <em>Project Data Sphere</em> from time to
                        time. <em>Project Data Sphere</em> reserves the right to terminate or limit use of this website
                        without notice in the event of any unauthorized access to or use of the website.</PDS_P>
                </SectionContainer>
                <SectionContainer>
                    <TermsSectionHeader>Scope of Use</TermsSectionHeader>
                    <PDS_P><em>Project Data Sphere</em> invites you to view, use and download a single copy of the
                        publicly accessible portions of this website for your informational, non-commercial use. You
                        acknowledge that certain
                        portions of this website are available only to registered users, and the website includes a user
                        registration
                        application. No part of any content or software that is intended for access only by registered
                        users may be accessed,
                        used, copied, downloaded or stored in a retrieval system by anyone who is not a registered user.</PDS_P>
                    <PDS_P>You may not:</PDS_P>
                    <Ul_disc>
                        <li>use the website to misuse or abuse any third-party property (including, without limitation,
                            software, equipment,
                            networks, and network devices); or
                        </li>
                        <li>make any unauthorized use of or interfere with any property of SAS Institute, Inc. ("SAS"),
                            which hosts the
                            website for <em>Project Data Sphere</em>, or of any customer of SAS; or
                        </li>
                        <li>engage in any activity that is in violation of the law or aids or assists any criminal
                            activity in connection
                            with your access or use of the website; or
                        </li>
                        <li>impair or disrupt any connections to the website; or</li>
                        <li>upload any software or application(s) to the website.</li>
                    </Ul_disc>


                    <PDS_P>You may not use the website:</PDS_P>
                    <Ul_disc>
                        <li>to send unsolicited marketing material or communications in any form (commonly referred to
                            as "SPAM"); or
                        </li>
                        <li>to engage in any activities or actions that infringe or misappropriate the intellectual
                            property rights of others,
                            including, but not limited to, using third party copyrighted materials without appropriate
                            permission, using third party
                            trademarks without appropriate permission or attribution, or using or distributing third
                            party information protected as
                            trade secret information in violation of a duty of confidentiality; or
                        </li>
                        <li>to engage in any activities or actions that would violate the personal privacy rights of
                            others; or
                        </li>
                        <li>to send or transmit harassing, abusive, libelous, or obscene materials or assist in any
                            similar related activities; or
                        </li>
                        <li>to intentionally omit, delete, forge, or misrepresent transmission information, including
                            headers, return mailing,
                            and Internet protocol addresses; or
                        </li>
                        <li>to engage in any activities or actions intended to misuse or abuse other users' passwords or
                            other confidential
                            information; or
                        </li>
                        <li>for any illegal purpose, in violation of any applicable laws or regulations, or in violation
                            of the rules of any
                            other service provider engaged by or for your benefit; or
                        </li>
                        <li>to assist or permit any person to engage in any of the activities described above.</li>
                    </Ul_disc>

                    <PDS_P>You understand that <em>Project Data Sphere</em> may discontinue or change the website at any
                        time,
                        without notice. You also understand that <em>Project Data Sphere</em> may discontinue or
                        restrict your use of
                        this website for any reason without notice.</PDS_P>
                </SectionContainer>
                <SectionContainer>
                    <TermsSectionHeader>No Warranties</TermsSectionHeader>
                    <PDS_P>ALL CONTENT ON THIS WEBSITE IS PROVIDED TO YOU ON AN "AS IS" "AS AVAILABLE" BASIS WITHOUT
                        WARRANTY OF ANY KIND EITHER
                        EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                        FITNESS FOR A PARTICULAR PURPOSE,
                        AND NON-INFRINGEMENT. <em>PROJECT DATA SPHERE</em> MAKES NO WARRANTY AS TO THE ACCURACY,
                        COMPLETENESS, CURRENCY,
                        OR RELIABILITY OF ANY CONTENT AVAILABLE THROUGH THIS WEBSITE. YOU ARE RESPONSIBLE FOR VERIFYING
                        ANY INFORMATION BEFORE RELYING
                        ON IT. USE OF THIS WEBSITE AND THE CONTENT AVAILABLE ON THE WEBSITE IS AT YOUR SOLE
                        RISK.</PDS_P>
                    <PDS_P><em>PROJECT DATA SPHERE</em> MAKES NO REPRESENTATIONS OR WARRANTIES THAT USE OF THIS WEBSITE
                        WILL
                        BE UNINTERRUPTED OR ERROR-FREE. YOU ARE RESPONSIBLE FOR TAKING ALL NECESSARY PRECAUTIONS TO
                        ENSURE THAT ANY CONTENT YOU MAY
                        OBTAIN FROM THE WEBSITE IS FREE OF VIRUSES.</PDS_P>
                </SectionContainer>
                <SectionContainer>
                    <TermsSectionHeader>Limitation of Liability</TermsSectionHeader>
                    <PDS_P><em>PROJECT DATA SPHERE</em>, ON BEHALF OF ITSELF, EACH OF ITS LICENSORS, ITS HOSTING SERVICE
                        PROVIDER
                        AND EACH DATA PROVIDER DISCLAIMS ANY LIABILITY, WHETHER BASED IN CONTRACT, TORT, STRICT
                        LIABILITY OR OTHERWISE, FOR ANY DIRECT,
                        INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED
                        WITH ACCESS TO OR USE OF THIS WEBSITE,
                        EVEN IF ANY OF THE FOREGOING HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING BUT
                        NOT LIMITED TO RELIANCE BY ANY PARTY
                        ON ANY CONTENT OBTAINED THROUGH THE USE OF THE WEBSITE, OR THAT ARISES IN CONNECTION WITH
                        MISTAKES OR OMISSIONS IN, OR DELAYS IN
                        TRANSMISSION OF, INFORMATION TO OR FROM THE USER, INTERRUPTIONS IN TELECOMMUNICATIONS
                        CONNECTIONS TO THE WEBSITE OR VIRUSES, WHETHER
                        CAUSED IN WHOLE OR IN PART BY NEGLIGENCE, ACTS OF GOD, TELECOMMUNICATIONS FAILURE, THEFT OR
                        DESTRUCTION OF, OR UNAUTHORIZED ACCESS
                        TO THE WEBSITE, OR RELATED INFORMATION OR COMPUTER PROGRAMS.</PDS_P>
                </SectionContainer>
                <SectionContainer>
                    <TermsSectionHeader>Trademarks</TermsSectionHeader>
                    <PDS_P><em>PROJECT DATA SPHERE</em> and the accompanying logo are service marks of
                        <em>Project Data Sphere</em>. Unauthorized use of any <em>Project Data Sphere</em>
                        service mark or logo may be a violation of federal and state trademark laws.</PDS_P>
                </SectionContainer>
                <SectionContainer>
                    <TermsSectionHeader>Copyright</TermsSectionHeader>
                    <PDS_P>This website is protected by U.S. copyright laws and international treaties. Except for your
                        informational, personal,
                        non-commercial use as authorized above, you may not modify, reproduce or distribute any content
                        that is accessible on this website
                        without written authorization by <em>Project Data Sphere</em>.</PDS_P>
                </SectionContainer>
                <SectionContainer>
                    <TermsSectionHeader>Compliance with the Digital Millennium Copyright Act of 1998.</TermsSectionHeader>
                    <PDS_P>As a courtesy, <em>Project Data Sphere</em> is providing the following information regarding
                        its
                        understanding of the Digital Millennium Copyright Act of 1998 ("DMCA") concerning notice of
                        claimed online copyright
                        infringement. <em>Project Data Sphere</em> is not providing legal advice. Any persons wishing to
                        assert
                        or oppose a claim under the DMCA should consult their own legal adviser concerning these
                        matters.</PDS_P>
                    <PDS_P>The DMCA provides recourse for owners of copyrighted material who believe that their rights
                        under
                        U.S. copyright laws have been infringed online. Under the DMCA, copyright owners may contact the
                        authorized agent of
                        an Internet service provider to report alleged infringements based on unauthorized use of their
                        protected works appearing
                        on web pages hosted by the service provider. Upon receipt of properly filed complaints
                        satisfying the requirements of the
                        DMCA, <em>Project Data Sphere</em> will remove or block access to the allegedly infringing
                        material posted
                        by third party users of the <em>Project Data Sphere</em> website. If a person who provided such
                        material
                        to <em>Project Data Sphere</em> believes in good faith that a notice of copyright infringement
                        has
                        been wrongly filed, that person may submit a counter-notice to assert a private dispute with the
                        claimed copyright
                        owner. <em>Project Data Sphere</em> will not be a party to such disputes over alleged copyright
                        infringement.</PDS_P>
                </SectionContainer>
                <SectionContainer>
                    <TermsSectionHeader>Notification of Claimed Copyright Infringement.</TermsSectionHeader>
                    <PDS_P>If you believe in good faith that information posted by a user on this website violates your
                        rights under U.S.
                        copyright law, you must give <em>Project Data Sphere</em> written notification of your claim in
                        accordance
                        with the requirements of the DMCA, which are summarized below. Written notification of your
                        claim must be submitted to
                        the following designated agent to receive notification of claimed infringement:</PDS_P>
                    <PDS_P className="address"><em>Project Data Sphere</em></PDS_P>
                    <PDS_P className="address">1204 Village Market Pl. Suite 288</PDS_P>
                    <PDS_P className="address">Morrisville, NC 27560</PDS_P>
                    <PDS_P className="address">Attn: Nicole Williford Hayes</PDS_P>
                    <PDS_P className="address">E-mail: nicole.hayes@projectdatasphere.org </PDS_P>
                    <PDS_P>To be effective, the notification must include the following:</PDS_P>
                    <Ul_number>
                        <li>A physical or electronic signature of a person authorized to act on behalf of the owner of
                            an exclusive right that
                            is allegedly infringed.
                        </li>
                        <li>Identification of the copyrighted work claimed to have been infringed, or if multiple
                            copyrighted works at a
                            single online website, are covered by a single notification, a representative list of works
                            at that site.
                        </li>
                        <li>Identification of the material that is claimed to be infringing or to be the subject of
                            infringing activity
                            and that is to be removed or access to which is to be disabled, and information reasonably
                            sufficient to
                            permit <em>Project Data Sphere</em> to locate the material.
                        </li>
                        <li>Information reasonably sufficient to permit <em>Project Data Sphere</em> to contact the
                            complaining
                            party, such as an address, telephone number, and if available, an e-mail address at which
                            the complaining party may be contacted.
                        </li>
                        <li>A statement that the complaining party has a good faith belief that use of the material in
                            the manner complained of
                            is not authorized by the copyright owner, its agent, or the law.
                        </li>
                        <li>A statement that the information in the notification is accurate, and under penalty of
                            perjury, that the
                            complaining party is authorized to act on behalf of the owner of an exclusive right that is
                            allegedly infringed.
                        </li>
                    </Ul_number>


                    <PDS_P>Upon receipt of the written notification containing the information as outlined in clauses
                        (1) through (6) above:</PDS_P>
                    <Ul_character>
                        <li><em>Project Data Sphere</em> shall remove or disable access to the material that is alleged
                            to be infringing and shall forward the written notification to the person or entity who
                            provided the material at
                            issue to <em>Project Data Sphere</em>; and
                        </li>
                        <li><em>Project Data Sphere</em> shall take reasonable steps to promptly notify the alleged
                            infringer that it has removed or disabled access to the material.
                        </li>
                    </Ul_character>

                    <PDS_P>You should be aware that U.S. copyright law provides substantial penalties for false claims
                        of copyright
                        infringement. According to the DMCA, claimants who make misrepresentations concerning copyright
                        infringement may be
                        liable for damages incurred as a result of the removal or blocking of the material, court costs,
                        and attorney's fees.</PDS_P>
                </SectionContainer>
                <SectionContainer>
                    <TermsSectionHeader>Responding to a Claim of Copyright Infringement</TermsSectionHeader>
                    <PDS_P>A person who receives a notification of alleged copyright infringement, and believes that the
                        claim is
                        erroneous, may submit a counter notification to <em>Project Data Sphere</em>'s designated agent
                        referenced above within thirty (30) days of the date the material was removed from
                        the <em>Project Data Sphere</em> website.</PDS_P>
                    <PDS_P>To be effective, a counter notification must be a written communication that includes
                        substantially the following:</PDS_P>
                    <Ul_number>
                        <li>A physical or electronic signature of the person submitting the counter notification.</li>
                        <li>Identification of the material that has been removed or to which access has been disabled
                            and
                            the location at which the material appeared before it was removed or access to it was
                            disabled.
                        </li>
                        <li>A statement under penalty of perjury that the person submitting the counter notification
                            has a good faith belief that the material was removed or disabled as a result of mistake or
                            misidentification of the material to be removed or disabled.
                        </li>
                        <li>The person's name, address, and telephone number, and a statement that he or she consents
                            to the jurisdiction of the federal district court for the judicial district in which the
                            address
                            is located, or if the person's address is outside of the United States, for any judicial
                            district in
                            which <em>Project Data Sphere</em> may be found, and that the person will accept
                            service of process from the complaining party or an agent of such person.
                        </li>
                    </Ul_number>

                    <PDS_P>Upon receipt of a counter notification containing the information as outlined in clauses (1)
                        through (4) above:</PDS_P>
                    <Ul_character>
                        <li><em>Project Data Sphere</em> shall promptly provide the complaining party
                            with a copy of the counter notification; and
                        </li>
                        <li><em>Project Data Sphere</em> shall inform the complaining party that it will
                            replace the removed material or cease disabling access to it within ten (10) business days.
                        </li>
                    </Ul_character>

                    <PDS_P><em>Project Data Sphere</em> shall replace the removed material or cease disabling access to
                        the material within ten (10) to fourteen (14) business days following receipt of the counter
                        notification,
                        provided <em>Project Data Sphere</em>'s designated agent has not received notice from the
                        complaining party that an action has been filed seeking a court order to restrain the alleged
                        infringer from engaging
                        in infringing activity on the <em>Project Data Sphere</em> website.</PDS_P>

                </SectionContainer>
                <SectionContainer>
                    <TermsSectionHeader>Links</TermsSectionHeader>
                    <PDS_P>This website may contain links to websites operated by other parties as a convenience to
                        users. The linked sites are not under the control of <em>Project Data Sphere</em>,
                        and <em>Project Data Sphere</em> is not responsible for the content available on any
                        other website that is linked to the website. Such links do not
                        imply <em>Project Data Sphere</em>'s endorsement of material on any other site,
                        and access to any other Internet sites linked to this website is at your own
                        risk. <em>Project Data Sphere</em> disclaims all liability with regard to your
                        access to such linked websites.</PDS_P>
                    <PDS_P>Unless otherwise set forth in a written agreement between you
                        and <em>Project Data Sphere</em>, you must adhere
                        to <em>Project Data Sphere</em>'s linking policy as follows: (i) any link to this
                        website must be a text only link clearly marked "<em>Project Data Sphere</em>," (ii) the
                        appearance, position and other aspects of the link may not be such as to damage or dilute the
                        goodwill
                        associated with <em>Project Data Sphere</em>'s name and service marks, (iii) the link must
                        "point" to the URL www.projectdatasphere.org or data.projectdatasphere.org and not to other
                        pages within this Website, (iv) the appearance,
                        position and other attributes of the link may not create the false appearance that your
                        organization or entity
                        is sponsored by, affiliated with, or associated with <em>Project Data Sphere</em>, (v) when
                        selected by a user, the link must display this website on full-screen and not within a "frame"
                        on the linking
                        website, and (vi) <em>Project Data Sphere</em> reserves the right to revoke its consent to
                        the link at any time and in its sole discretion.</PDS_P>
                </SectionContainer>
                <SectionContainer>
                    <TermsSectionHeader>Use of Information</TermsSectionHeader>
                    <PDS_P>The information provided on this website is not intended for distribution to, or use by, any
                        person or
                        entity in any jurisdiction or country where such distribution or use would be contrary to law or
                        regulation or which
                        would subject <em>Project Data Sphere</em> or its affiliates to any registration requirement
                        within
                        such jurisdiction or country.</PDS_P>
                </SectionContainer>
                <SectionContainer>
                    <TermsSectionHeader>Location</TermsSectionHeader>
                    <PDS_P>This website is operated for <em>Project Data Sphere</em> from its hosting service
                        provider's facility the state of North Carolina USA, and these Terms of Use shall be governed
                        and construed in
                        accordance with the laws of the state of North Carolina USA. <em>Project Data Sphere</em> makes
                        no representation that the information in the website is appropriate or available for use in any
                        other jurisdictions,
                        and access to this website from any jurisdiction to which the export of United States data is
                        prohibited or any
                        jurisdiction where the content of the website may be illegal is prohibited. Those who choose to
                        access this
                        website from other jurisdictions do so on their own initiative and are responsible for
                        compliance with applicable laws.</PDS_P>
                </SectionContainer>
                <SectionContainer>
                    <TermsSectionHeader>Violations and Additional Policies</TermsSectionHeader>
                    <PDS_P><em>Project Data Sphere</em> reserves the right to seek all remedies available at law
                        and in equity for violations of these Terms of Use, including the right to block access from a
                        particular Internet
                        address to the website.</PDS_P>
                </SectionContainer>
                <PDS_P_BOLD>Privacy Policy</PDS_P_BOLD>
                <p> {parse(GENERAL_TEXT)}</p>
                {
                    PRIVACY_STATEMENT.map((faq: IFaq) => {
                        return <SectionContainer>
                            <TermsSectionHeader>
                                {parse(faq.question)}
                            </TermsSectionHeader>
                            {parse(faq.answer)}

                        </SectionContainer>
                    })
                }
            </FaqPage>
        </>
    )
}