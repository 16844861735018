/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: DataDisplayType
 *
 * Purpose: Data Display Type for Share Data
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 23Oct2023 sasjxa file created
 * 19Jun2024 sasjxa remove labelClassName parameter
 \******************************************************************************/
import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import {ErrorMessage} from "formik";
import {DATA_DISPLAY_PAGE} from "../../data/formList";
import {labelIfReadOnly} from "../../constants/CommonFunctions";


interface DataStandardProps {
    value: any,
    valueHandler: any,
    listHandler: any,
    disabled: boolean,
    handleChange: any,
    touched: any,
    errors: any,
}


export const DataDisplayType: React.FC<DataStandardProps> =
    ({
          value,  valueHandler, listHandler, disabled,
          touched, errors
     }) => {
        return (
            <Row className="mt-3 mb-3 ">
                <Form.Group as={Col} lg={6} className="dataDisplayType"
                            controlId="formDataDisplayType">
                    <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Should both datasets
                        be displayed on the same page, or should they be displayed
                        separately? </Form.Label>
                    <Form.Select
                        as="select" size="sm" required
                        name={"dataDisplayType"}
                        aria-describedby="datasetsDisplayedHelpBlock"
                        disabled = {disabled}
                        value={value}
                        isValid={touched.dataDisplayType && !errors.dataDisplayType}
                        onChange={(e) => {valueHandler(e.target.value)}}>
                        {listHandler(DATA_DISPLAY_PAGE)}
                    </Form.Select>
                    <Form.Text id="datasetsDisplayedHelpBlock" muted>
                        If both datasets are displayed on the same page, it will be clear to
                        Project Data Sphere users that the data you are uploading is two arms
                        from the same trial. Contact info@projectdatasphere.org for more
                        information.
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                        Please select how the datasets should be displayed.
                    </Form.Control.Feedback>
                    <ErrorMessage name={"dataDisplayType"}
                                  render={msg => <div
                                      className={"form-error-msg"}>{msg}</div>}/>
                </Form.Group>
            </Row>

        )

    }