/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ServiceTickets
 *
 * Purpose: Displays a user's open/closed service tickets
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 18Apr2024 sasjxa file created
 * 25Apr2024 sasjxa refactoring of methods and styled components
 * 21May2024 sasjxa add paragraph if user has no service tickets
 * 23May2024 sasjxa add paragraph if user has no open tickets
 \*********************************************************************************/
import React, {useMemo, useState} from "react";
import {useRequest} from "../../../helper/useRequest";
import {useErrorBoundary} from "react-error-boundary";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {
    AdminTableHeader,
    ContentPage,
    FormLayoutColumn,
    PageWrapper, PDS_P, PDS_P_BOLD,
    SectionHeader
} from "../../../components/styled/StyledComponents";
import {ITicket} from "../../../model/tickets/ITickets";
import {CLOSED, OPEN} from "../../../data/formList";
import {BASE_PATH} from "../../../constants";
import {getTicketEventStatusDate, getTicketStatus, getTicketSubject} from "../../../constants/CommonFunctions";


export const ServiceTickets: React.FC = () => {
    const {showBoundary} = useErrorBoundary();
    const [tickets, setTickets] = useState<ITicket[]>([]);

    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    // handle successful retrieve of data
    const handleSuccess = () => {
        console.log('ok');
    }

    // get data
    const [requestActionState, getTickets] = useRequest({
        url: process.env.REACT_APP_API_URL + "/api/user/tickets/serviceTickets",
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onSuccess: handleSuccess,
        onError: handleError
    });
    const {isLoading, data, error} = requestActionState;

    useMemo(() => {
        getTickets();
    }, [])

    useMemo(() => {
        if (data != null) {
            setTickets(data);
        }
    }, [data])

    const buildTickets = (tickets: ITicket[], requestType: string) => {
        return (
            <>
                <Row className="w-75">
                    <Col md={3} lg={3} className="bold-label">Status</Col>
                    <Col md={2} lg={2} className="bold-label">Date</Col>
                    <Col md={7} lg={7} className="bold-label">Subject</Col>
                </Row>
                {tickets?.map((ticket: ITicket) => (
                    <Row className="w-75 ">
                        <Col md={3} lg={3}>
                            <a
                                href={BASE_PATH + "serviceTicketAction/" + ticket.id + "/" + requestType}
                            >{getTicketStatus(ticket.status)}</a></Col>
                        <Col md={2} lg={2}>{getTicketEventStatusDate(ticket)}</Col>
                        <Col md={7} lg={7}>{getTicketSubject(ticket)}</Col>
                    </Row>
                ))}
            </>
        )

    }

    const buildSection = () => {
        if (tickets !== undefined && tickets?.length > 0) {
            const openTickets: ITicket[] | undefined =
                tickets.filter((ticket) =>
                    ticket.status != CLOSED);
            const closedTickets: ITicket[] | undefined =
                tickets.filter((ticket) =>
                    ticket.status == CLOSED);
            return (
                <>
                    <Row><SectionHeader className="mt-3 mb-2">Service Tickets</SectionHeader></Row>
                    <Row><AdminTableHeader className="mt-5">Open Tickets</AdminTableHeader></Row>
                    {openTickets != null && openTickets.length > 0 && (
                        <>
                            <Col className="mb-3">
                                {buildTickets(openTickets, OPEN)}
                            </Col>
                        </>
                    )}
                    {openTickets == null || openTickets.length == 0 && (
                        <>
                            <p>You do not have any open Service Tickets. </p>
                        </>
                    )}

                    <Row><AdminTableHeader className="mt-5">Closed Tickets</AdminTableHeader></Row>
                    {closedTickets != null && closedTickets.length > 0 && (
                        <>
                            <Col className="mb-3">
                                {buildTickets(closedTickets, CLOSED)}
                            </Col>
                        </>
                    )}
                    {closedTickets == null || closedTickets.length == 0 && (
                        <>
                            <p>You do not have any closed Service Tickets. </p>
                        </>
                    )}
                </>
            )

        } else {
            return (
                <>
                    <Row><SectionHeader className="mt-3 ">Service Tickets</SectionHeader></Row>
                    <PDS_P>You do not have any Service Tickets. </PDS_P>
                </>
            )        }
    }

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn name={"registrationColumn"}>
                        {isLoading &&
                            <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>}
                        {error && <p>Error</p>}
                        {tickets && buildSection()}
                    </FormLayoutColumn>
                </PageWrapper>
            </ContentPage>
        </Container>
    )
}