/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Share
 *
 * Purpose: Share data page
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 22Feb2023 craig  file created.
 * 02Jun2023 sasjxa move styled components out of FC
 * 23Jun2023 sasjxa refactor to use new aside component
 * 04Aug2023 sasjxa layout changes
 * 08Mar2024 craig  added base_path
 * 21Aug2024 craig  moved to useContext(PDSContext) for user session
 * 25Aug2024 craig  change href call to <NavLink>
 * \******************************************************************************/
import React from "react";
import {ContentPage, PageWrapper, PDS_H5, PDS_P} from "../../components/styled/StyledComponents";
import {Col, Container, Row} from "react-bootstrap";

import {COLORS} from "../../components/styled/StyleConstants";
import {NavLink} from "react-router-dom";
// @ts-ignore
import styled from "styled-components";
import {Aside} from "../../components/Aside";
import {ShareDataDashboard} from "../../components/share/ShareDataDashboard";
import {PDSGroup} from "../../model/user/IUser";
import {BASE_PATH} from "../../constants";
import {usePDSContext} from "../../context/PDSContext";

const DSAButtonRow = styled(Row)`      
   color: ${COLORS.dark_grey_blue};
   margin: 3% 0;
   justify-content:center;
`;

const MenuItemDSA = styled(NavLink)`
   display: flex;
   align-items: center;
   justify-content: center; 
   font-size: 1rem;
   font-weight: 700;     
   height: 2rem;
   line-height: 2rem; 
   text-decoration: none;
     
   :hover {
          background-color: ${COLORS.hover_green};
          color: ${COLORS.white};
   }
        
   background-color: ${COLORS.turtle_green};
   color: ${COLORS.white};
           
`;

const SharedHeader = styled(PDS_H5)`  
   margin-top: 2%;
`;


export const Share: React.FC = () => {
    const {sessionUser} = usePDSContext();
    const canShareData = () => {
        return sessionUser.pdsGroup === PDSGroup.Approver ||
            sessionUser.pdsGroup === PDSGroup.Provider ||
            sessionUser.pdsGroup === PDSGroup.Reviewer ||
            sessionUser.pdsGroup === PDSGroup.Admin;
    }
    const createShareInformation = () => {
        if (canShareData()) {
           return  createShareInformationProvider();
        }
        else if (sessionUser.pdsGroup === PDSGroup.Consumer) {
          return  createShareInformationConsumer();
        }
        else {
          return createShareInformationGuest();
        }
    }

    const createShareInformationProvider = () => {
        return (
            <Col md={8} lg={9} name={"shareDataColumn"} className="">
                <SharedHeader>Share New Dataset</SharedHeader>
                <PDS_P>Prior to completing the online Data Sharing Agreement and data upload forms, please visit
                    the <a
                        href="/resources" className="linkstyle">Resources</a> page of the website
                    for tip sheets outlining the necessary information to complete these forms.</PDS_P>
                <PDS_P>Complete and submit the Project Data Sphere Data Sharing Agreement online application form by
                    clicking the "Start New Data Sharing Agreement" button below.
                    A completed Data Sharing Agreement including these details will be emailed to you by
                    Project Data Sphere. Review and return the signed agreement to Project Data Sphere for
                    execution.</PDS_P>
                <PDS_P>Upon receiving your copy of the fully executed agreement, complete
                    the data upload forms and provide the datasets to be shared.</PDS_P>
                <PDS_P>The platform will not allow a dataset to be shared until all steps above are fully
                    completed.</PDS_P>
                <DSAButtonRow>
                    <Col className="justify-content-center" md={8} lg={5}>
                        <MenuItemDSA to={BASE_PATH + "dataShareAgreement"}>Start New Data Sharing Agreement</MenuItemDSA>
                    </Col>
                </DSAButtonRow>
                <ShareDataDashboard userId={sessionUser.userID} />
            </Col>
        )
    }

    const createShareInformationConsumer = () => {
        return (
            <Col md={8} lg={9} name={"shareDataColumn"} >
                <PDS_P>The data provider process allows organizations to manage data sharing through a step-by-step process.</PDS_P>
                <PDS_P>You must first apply, on behalf of the data owner, to become a data provider.</PDS_P>
                <PDS_P>When your request to become a data provider is accepted, you will have the ability to submit
                    descriptive trial information concerning the data that you wish to share through the upload utility.</PDS_P>
                <PDS_P>The <NavLink to={BASE_PATH + "resources"}>Resources</NavLink> page of the website contains how-to guides and other information
                    to help you provide and access data.</PDS_P>
                <DSAButtonRow>
                    <Col className="justify-content-center" md={8} lg={5}>
                        <MenuItemDSA to={BASE_PATH + "dataProviderRegistration"}>Become a Data Provider</MenuItemDSA>
                    </Col>
                </DSAButtonRow>
            </Col>
        )
    }

    const createShareInformationGuest = () => {
        return (

            <Col md={8} lg={9} name={"shareDataColumn"}>
                <PDS_P>To contribute data to the Data Sphere Project, you must first be an Enrolled User.</PDS_P>
                <PDS_P>You must first apply to be a registered user of the Project Data Sphere database.
                   Once you are a registered  user, you can then apply, on behalf of the data owner, to be a data provider.</PDS_P>
                <PDS_P>When your request to become a data provider is accepted, you will have the ability to submit
                    descriptive trial information concerning the data that you wish to share through the upload utility.</PDS_P>
                <PDS_P>The <NavLink to={BASE_PATH + "resources"}>Resources</NavLink> page of the website contains how-to guides and other information
                    to help you provide and access data.</PDS_P>
                <DSAButtonRow>
                    <Col className="justify-content-center" md={8} lg={5}>
                        <MenuItemDSA to={BASE_PATH + "registration"}>Become a Registered User</MenuItemDSA>
                    </Col>
                </DSAButtonRow>
            </Col>

        )
    }

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                {createShareInformation()}

                <Col md={4} lg={3}>
                    <Aside needHelp={true}/>
                </Col>
                </PageWrapper>
            </ContentPage>
        </Container>
    )
        ;
}