/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: TextAreaRequired
 *
 * Purpose: Common Component for Required TextArea
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 21Nov2023 sasjxa file created
 \******************************************************************************/
import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import {ErrorMessage} from "formik";
import {TextAreaCountRow} from "../styled/StyledComponents";


interface  TextAreaRequiredProps {
    fieldName: string,
    label: string,
    value: any,
    valueHandler: any,
    currentCharCount: number,
    maxTextAreaCount: number,
    countHandler: any,
    disabled: boolean,
    touched: any,
    errors: any,
    handleChange: any
    formText?:string
}


export const TextAreaRequired: React.FC<TextAreaRequiredProps> =
    ({
         fieldName,
         label, value,  valueHandler, currentCharCount, maxTextAreaCount,
         countHandler, touched, errors, handleChange, disabled, formText

     }) => {
        return (
            <>
                <Row className="mt-3 mb-3 ">
                    <Form.Group as={Col}  lg ={12}className=""
                                controlId="">
                        <TextAreaCountRow>
                            <Form.Label className="required" column="sm">{label} </Form.Label>
                            <span>{currentCharCount} of {maxTextAreaCount} characters</span>
                        </TextAreaCountRow>
                        <Form.Control as="textarea" rows={5} size="sm" required
                                      name={fieldName}
                                      type="text"
                                      value={value}
                                      disabled={disabled}
                                      maxLength={maxTextAreaCount}
                                      isValid={touched.fieldName && !errors.fieldName}
                                      onChange={e => {
                                          valueHandler(e.target.value);
                                          countHandler(e.target.value.length);
                                          handleChange(e);
                                      }}/>
                        {formText != null && (
                            <Form.Text id="" muted>
                                {formText}
                            </Form.Text>
                        )}
                        <ErrorMessage name={fieldName}
                                      render={msg => <div
                                          className={"form-error-msg"}>{msg}</div>}/>
                    </Form.Group>
                </Row>

            </>
        )

    }