/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminDataFieldEdit
 *
 * Purpose: Edit dataset fields
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 03Oct2023 sasjxa  file created.
 * 04Apr2024 craig   switched to react-error-boundary
 * 09Jul2024 sasjxa  remove references to showBoundary
 \******************************************************************************/
import React, {useMemo, useState} from "react";

import {ContentPage, PageWrapper, TextAreaCountRow} from "../../components/styled/StyledComponents";
import {Col, Container, Form, Row} from "react-bootstrap";


// @ts-ignore
import styled from "styled-components";
import {ErrorMessage, Formik} from "formik";

import * as Yup from "yup";
import {COLORS} from "../styled/StyleConstants";
import {useRequest} from "../../helper/useRequest";
import {AdminDataFieldEditButtonContainer} from "./AdminDataFieldEditButtonContainer";

const EditRow = styled.div` 
  display: flex;
  align-items:center;
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
  width: 100%; 
  margin-top: 0;
 
  
  
  font-size: .875rem;
  
  span {
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
  
  span.bold {
    font-weight: bold;
  }
  
  button {
   padding-top: 0;
   padding-left: 1%;
   padding-bottom: 0;
   color: ${COLORS.cerulean};
   font-weight: bold;
  }
  
  

  
`;

const EditableRow = styled(EditRow)` 
background-color: ${COLORS.light_grey} !important;
`;

const DisplayCol = styled(Col)` 
     background-color: ${COLORS.white} !important;
     margin: 0;
     padding: 0;
`;

interface AdminDataFieldProps {
    label: string,
    fieldName: string,
    initialValue: any,
    donationId: any,
    adminEditForm: boolean

};


export const AdminDataFieldEdit: React.FC<AdminDataFieldProps> = ({
                                                                      label, fieldName, donationId,
                                                                      initialValue, adminEditForm
                                                                  }: AdminDataFieldProps) => {


    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const [adminEditable, setAdminEditable] = useState<boolean>(adminEditForm);
    const [commentsCount, setCommentsCount] = useState(0);
    const [tmpFieldName, setTmpFieldName] = useState<string>(initialValue);

    const maxCommentCount = 1000;

    const saveAdminEditURL = process.env.REACT_APP_API_URL + "/api/admin/adminEdit/" + donationId + "/" + fieldName;

    const handleSuccess = () => {
        toggleShowEditForm();
    }


    const handleError = (error: object) => {
        console.log(error);
    }

    const [requestActionState, setAdminEdit] = useRequest({
        url: saveAdminEditURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })


    const toggleShowEditForm = () => {
        setShowEditForm(!showEditForm);
    }

    useMemo(() => {
        setAdminEditable(adminEditForm);
    }, [adminEditForm])

    const schema = Yup.object().shape({
        changeComments: Yup.string().required('Please enter comments'),

    });

    const buildForm = () => {
        return (
            <Formik
                enableReinitialize
                validationSchema={schema}
                onSubmit={(values) => {
                    setAdminEdit(JSON.stringify({
                        fieldName: tmpFieldName,
                        changeComments: null,
                    }));
                }}
                initialValues={{
                    fieldName: tmpFieldName,
                    changeComments: "",
                }}

                validateOnChange={false}
                validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      resetForm,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                      setFieldValue,
                      setFieldTouched
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <Row className="mt-3 mb-3  ">
                            {showEditForm && adminEditable && (
                                <>
                                    <EditableRow className="w-100 ps-2 pe-2">
                                        <Form.Group as={Col} lg={8} controlId="">
                                            <Form.Label className={"required"}
                                                        column="sm">{label} </Form.Label>
                                            <Form.Control size="sm" required
                                                          name={fieldName}
                                                          type="text"
                                                          defaultValue={initialValue}
                                                          isValid={touched.fieldName && !errors.fieldName}
                                                          onChange={(event) => setTmpFieldName(event.target.value)}
                                            />
                                            <Form.Text className="text-muted"></Form.Text>

                                            <ErrorMessage name={fieldName}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </EditableRow>
                                    <EditableRow className=" pb-3 ps-2 pe-2">
                                        <Form.Group as={Col} controlId="">
                                            <TextAreaCountRow>
                                                <Form.Label className="required"
                                                            column="sm">Comments </Form.Label>

                                            </TextAreaCountRow>
                                            <Form.Control as="textarea" rows={5} size="sm" required
                                                          name={"changeComments"}
                                                          type="text"
                                                          maxLength={maxCommentCount}
                                                          isValid={touched.changeComments && !errors.changeComments}
                                                          onChange={e => {
                                                              setCommentsCount(e.target.value.length);
                                                              handleChange(e);
                                                          }}/>


                                            <ErrorMessage name={"changeComments"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </EditableRow>
                                    <AdminDataFieldEditButtonContainer toggleMethod={toggleShowEditForm}/>
                                </>
                            )}
                            {!showEditForm && (
                                <DisplayCol className=" mb-4">
                                    <EditRow>
                                        <span className="bold">{label}</span>
                                        {adminEditable && (
                                            <button onClick={toggleShowEditForm}
                                                    className="btn btn-link select-files-link">
                                                edit
                                            </button>
                                        )}
                                    </EditRow>
                                    <EditRow>
                                        <span>{initialValue}</span>
                                    </EditRow>
                                </DisplayCol>

                            )}
                        </Row>
                    </Form>
                )}
            </Formik>
        )
    }

    return (
        <>
            <Container className="form-container" fluid>
                <ContentPage name={"content-page"}>
                    <PageWrapper name={"page-wrapper"}>
                        <Col md={10} lg={12} name={"shareDataColumn"}>
                            {buildForm()}
                        </Col>
                    </PageWrapper>
                </ContentPage>
            </Container>
        </>
    );

}
