/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: PDSError
 *
 * Purpose: error boundary component
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 04Apr2024 craig File created.
 * 24May2024 craig Style a little better
 * 03Aug2024 craig clean up some error stuff
 \******************************************************************************/
import {FallbackProps} from "react-error-boundary";
import {ContentPage, PageWrapper, PDS_H5, PDS_P} from "../styled/StyledComponents";
import {Container, Row} from "react-bootstrap";
import styled from "styled-components";

const ErrorHeader = styled(PDS_H5)`
    margin-top: 2%;
`;

type ServerError = { errorMessage: string };

export function PDSError(props: FallbackProps) {
    const {error} = props;
    //const [errorHeader, setErrorHeader] = useState<string>("A error has occurred");
    //const [errorMsg, setErrorMsg] = useState<string>("");

    //const sessionUser: IUser = getSessionCookie();

/*    useEffect(() => {
        console.log(error.response.data);
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            if (serverError?.response?.status === 403) {
                setErrorHeader("You are not allowed to access the page you requested.")
                setErrorMsg("If you think this is a mistake, your session might have expired.  Please log off and back on");
            } else {
                setErrorMsg(serverError?.message);
            }
            //if (error.response.data.status === 500 && error.response.data.title.includes("Could not get user session")) {
            /!*
                        if (serverError?.response?.status === 500 && serverError?.response?.data?.errorMessage.includes("Could not get user session")) {
                            PDSLogoff(sessionUser);
                        }
            *!/

        }
    }, [error, sessionUser])*/

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <Container>
                        <ErrorHeader>Error</ErrorHeader>
                        <Row>{error.message}</Row>
                    </Container>
                </PageWrapper>
            </ContentPage>
        </Container>

    )
}