/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminCountsHeader
 *
 * Purpose: component for individual admin dashboard headers
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 10Jan2024 sasjxa file created
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 09Jul2024 sasjxa remove references to showBoundary
 * 18Aug2024 sasjxa fix count for new requests to include CRMI and RMI New Token
 * 29Sep2024 sasjxa add parenthesis for zero count
 \********************************************************************************/
import React, {useMemo, useState} from "react";
import {Button, ButtonGroup, Row, Spinner} from "react-bootstrap";
import {useRequest} from "../../helper/useRequest";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {CRMI, IAdminCountsHeaderList, NEW_MIXED, RMI_NEW_TOKEN} from "../../data/formList";
import {IStatusCounts} from "../../model/admin/dashboard/IAdminDashboardCounts";


const AdminButton = styled(Button)`
    width: 30%;
    color: #0694D5;
    font-weight: bold;
    background-color: white !important;
    border: 2px solid #B2B2B2;

    &:focus {
        //border-color: #0694D5;
        color: #0694D5;
    }

    &:hover {
        color: #0694D5;
    }
`;


interface AdminCountsHeaderProps {
    countsList: IAdminCountsHeaderList[],
    requestType: any,
    countsUrl: string
    isUserAccounts?: boolean

}


export const AdminCountsHeader: React.FC<AdminCountsHeaderProps> = ({
                                                                        countsList,
                                                                        requestType,
                                                                        countsUrl,
                                                                        isUserAccounts
                                                                    }: AdminCountsHeaderProps) => {

    const navigate = useNavigate();
    const [statusCounts, setStatusCounts] = useState<IStatusCounts[]>();

    // handle error - unsuccessful retrieve of data
    const handleHeaderError = (error: object) => {
        console.log(error);
    }

    //get the counts from api
    const countsURL: string = process.env.REACT_APP_API_URL + countsUrl;
    const [requestState, getCounts] = useRequest({
        url: countsURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleHeaderError
    })
    const {isLoading, data, error} = requestState;

    useMemo(() => {
        getCounts();
    }, [])

    useMemo(() => {
        if (data != null) {
            setStatusCounts(data);
        }
    }, [data])

    const getButtonClass = (type: string, currentClassName: string) => {
        if (type === requestType) {
            return " border-active " + currentClassName;
        } else {
            return "border-inactive " + currentClassName;
        }
    }

    const getItemCount = (status: string) => {
        if (status == NEW_MIXED || status == CRMI || status == RMI_NEW_TOKEN) {
            let item: IStatusCounts[] | undefined = statusCounts?.filter(s => s.status === CRMI
                || s.status === NEW_MIXED || s.status === RMI_NEW_TOKEN);
            if (item !== undefined && item.length > 0) {
                let finalCount: number = 0;
                for (let i = 0; i < item.length; i++) {
                    finalCount = finalCount + item[i].count;
                }
                return "(" + finalCount + ")";
            } else {
                return "(0)";
            }
        } else {

            let item: IStatusCounts | undefined = statusCounts?.find(s => s.status === status);
            if (item !== undefined) {
                return "(" + item?.count + ")";
            } else {
                return "(0)";
            }
        }
    }

    const buildHeader = () => {
        return (
            <ButtonGroup className={"w-100  "}>
                {
                    countsList.map((header: IAdminCountsHeaderList) => {
                        return (
                            <AdminButton className={getButtonClass(header.type, header.className)} onClick={() => {
                                navigate(header.navigateUrl)
                            }} variant="light">{header.label} {getItemCount(header.type)}
                                <img className={"ms-3 text-center"} src={header.image} alt={header.alt}
                                     width="24" height="24"/>
                            </AdminButton>
                        )
                    })
                }
            </ButtonGroup>
        )


    }


    return (
        <>
            <Row className={"mt-3 "}></Row>
            {isLoading &&
                <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}
            {error && <p>Error</p>}
            {data && buildHeader()}
        </>

    );

}
