/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ContributionContents
 *
 * Purpose: Display study results for individual donation
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 26Feb2024 sasjxa file created
 * 04Apr2024 craig  switched to react-error-boundary
 * 11Jun2024 craig  removed user prop to AccessDataDetailsAndFiles
 * 28Jun2024 sasjxa removed showBoundary
 * 21Aug2024 craig  cleaned up
 \******************************************************************************/
import React, {useMemo, useState} from "react";

import {Col, Container} from "react-bootstrap";
import {ContentPage, PageWrapper,} from "../../components/styled/StyledComponents";

import {AccessDataAside} from "../../components/AccessDataAside";
import {useRequest} from "../../helper/useRequest";
import {useParams} from "react-router-dom";
import {IAccessData} from "../../model/donations/IAccessData";
import {ContributionContentStudies} from "../../components/access/ContributionContentsStudies";


export const ContributionContents: React.FC = () => {
    const {donationId} = useParams();
    const [donation, setDonation] = useState<IAccessData>();
    const donationURL = process.env.REACT_APP_API_URL + "/api/accessdata/results/" + donationId;
    ;

    const handleError = (error: object) => {
        console.log(error);
    }

    const [requestState, getDonation] = useRequest({
        url: donationURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })

    const {isLoading, data, error} = requestState;

    useMemo(() => {
        if (data !== null) {
            setDonation(data);
        }
    }, [data])

    useMemo(() => {
        getDonation();
    },[])


    const buildResults = () => {
        return (
            <>
                <Col className="" md={4} lg={2}>
                </Col>

                {donation !== undefined && (
                    <Col className="mt-4 me-4" md={6} lg={8} name={"registrationColumn"}>
                        <ContributionContentStudies donation={donation}/>

                    </Col>
                )}

                <Col md={4} lg={2}>
                    <AccessDataAside/>
                </Col>
            </>
        )
    }

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    {isLoading && <p>Loading ...</p>}
                    {error && <p>Error</p>}
                    {data && buildResults()}
                </PageWrapper>
            </ContentPage>
        </Container>
    )
}