/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ProcContentsDialog
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 16Aug2024 craig File created.
 * 18Aug2024 craig include ProcContents.css because parse does not parse the css
 *                 returned
 \******************************************************************************/
import React from "react";
import {Button, Modal} from "react-bootstrap";
import parse from "html-react-parser";
import "./ProcContents.css";
interface DialogProps {
    title: string,
    body: string,
    show: boolean,
    showHide: any
}

export const ProcContentsDialog: React.FC<DialogProps> = ({title, body, show, showHide}: DialogProps) => {
    return (
        <Modal show={show} keyboard={false} backdrop={"static"} >
            <Modal.Header closeButton={true} onClick={showHide}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {parse(body)}
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"secondary"} onClick={showHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}