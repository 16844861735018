/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminDatasetInfo
 *
 * Purpose: display dataset info
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 05Nov2024 sasjxa  file created
 * 02Nov2023 craig   Added call to API
 * 12Mar2024 sasjxa  encode/decode special chars (MPM-5389)
 * 04Apr2024 craig  Switched to react-error-boundary
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 25Apr2024 sasjxa refactoring of methods and styled components
 * 02Jul2024 craig  removed decoding (moved to api)
 \******************************************************************************/
import React, {useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {useRequest} from "../../helper/useRequest";
import {Button, Col, Container, Row, Spinner} from "react-bootstrap";
import {FormLayoutColumn, NavMenuItem, roundFileSize, UserInfoCol,} from "../../components/styled/StyledComponents";
import {useErrorBoundary} from "react-error-boundary";
import {IAdminAccessDataDashboard} from "../../model/admin/requests/IAdminAccessDataDashboard";
import styled from "styled-components";
import {COLORS, FONT_SIZES} from "../../components/styled/StyleConstants";
import {IAdminRequestUser} from "../../model/admin/requests/IAdminRequestUser";
import {AdminUserLoginsInfo} from "../../components/admin/AdminUserLoginsInfo";
import {BASE_PATH} from "../../constants";
import {IShareDataDashboard} from "../../model/share/IShare";
import {AdminUserRequestDetails} from "../../components/admin/AdminIUserRequestDetails";
import {IFile} from "../../model/donations/IAccessData";
import {PDS_Button} from "../../components/styled/Styles";

const AdminTableHeader = styled.h6`
    font-weight: bold;
    color: ${COLORS.dark_grey_blue};
`;




const GridRow = styled.div`
    display: flex;
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    margin-top: .5%;
    
    font-size: ${FONT_SIZES.px14};
   

    a {
        color: ${COLORS.dark_grey_blue};
    }

    a.approval {
        color: ${COLORS.cerulean};
    }

`;

const GridHeaderRow = styled(GridRow)`
    font-weight: bold;
    background-color: ${COLORS.filter_gray};
    padding: .5% 0;
    margin-top: 0;
`;

const GridCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
    margin-left: 2%;
    padding-right: 0;
    padding-left: 0;


    span {
        color: ${COLORS.dark_grey_blue};
    }


}
`;

const GridColLarger = styled(GridCol)`
    width: 15%;
    margin-left: 0 ;
`;

const GridColTitle = styled.div`
    display: flex;
    flex-direction: column;
    color: ${COLORS.dark_grey_blue};
    width: 30%;

    span {
        display: block;
    }
`;


export const AdminDatasetFileInfo: React.FC = () => {
    const {showBoundary} = useErrorBoundary();
    const {donationId} = useParams();

    const [dashboard, setDashboard] = useState<IShareDataDashboard>();

    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);
    }



    // get data
    const metadataURL = process.env.REACT_APP_API_URL + "/api/admin/donations/datasets/" + donationId;
    const [requestState, getDonations] = useRequest({
        url: metadataURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,

    })
    const {isLoading, data, error} = requestState;

    useMemo(() => {
        getDonations();
    }, [])

    useMemo(() => {
        if (data != null) {
            setDashboard(data);
        }
    }, [data])

    const buildHeaders = () => {
        if (dashboard != null) {
            return (
                <>
                    <AdminTableHeader className="pb-4 w-100 ">{dashboard.title}</AdminTableHeader>
                    <GridRow className=" ">
                        <Col className="grid-header grid-entry space-3">Filename</Col>
                        <Col className="grid-header grid-entry ">Size</Col>
                        <Col className="grid-header grid-entry space-3">File Type</Col>
                        <Col className="grid-header grid-entry space-2">Description</Col>
                        <Col className="grid-header grid-entry">Replace</Col>
                        <Col className="grid-header grid-entry">Delete</Col>
                    </GridRow>
                        {dashboard.files?.map((file: IFile) => (
                            <GridRow className=" ">
                                <Col className=" grid-entry space-3">{file.filename}</Col>
                                <Col className=" grid-entry ">{roundFileSize(file.fileSize)}</Col>
                                <Col className=" grid-entry space-3">{file.fileType}</Col>
                                <Col className=" grid-entry space-2">{file.description}</Col>
                                <Col className=" grid-entry">Replace</Col>
                                <Col className=" grid-entry">Delete</Col>
                            </GridRow>
                        ))}

                    <Button variant={"success"}>Add File to Contribution</Button>

                </>
            )
        }
    }

    const buildSection = () => {
        if (dashboard != null) {
            return (
                <>
                    <Col className=" ">
                        {buildHeaders()}
                    </Col>


                </>
            )
        }
    }



    return (
        <Container className={"w-100 "}>
            <Row className={"mt-3  "}></Row>

            {isLoading &&
                <Spinner  className ="spinner-center" animation={"border"} variant={"primary"} role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}
            {error && <p>Error</p>}
            {data && buildSection()}
        </Container>
    )
}

