/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: SigninDropdownOptionsDialog
 *
 * Purpose: Dialog for change password and email preferences
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 17Apr2024 sasjxa file created
 \******************************************************************************/
import {Button, Modal} from "react-bootstrap";
import React from "react";
import {DialogHeader} from "../styled/StyledComponents";

interface DialogProps {
    title: string,
    body: React.ReactNode,
    show: boolean,
    showHide: any
}

export const SigninDropdownOptionsDialog: React.FC<DialogProps> = ({title, body, show, showHide}: DialogProps) => {
    return (
        <Modal show={show}>
            <Modal.Header closeButton={true} onClick={showHide}>
                <DialogHeader>{title}</DialogHeader>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"secondary"} onClick={showHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
