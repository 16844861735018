/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ClinicalDrugAdditivityTherapySelector
 *
 * Purpose: component for ClinicalDrugAdditivity
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 25Aug2024 sasjxa file created
 * 30Aug2024 sasjxa add prop values for scanTime
 * 24Sep2024 sasjxa change therapy naming to experimental and control
 \******************************************************************************/
import React, {useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {ErrorMessage} from "formik";
import {IClinicalDrugProject} from "../../model/clinicalDrug/IClinicalDrug";
import {CLIN_DRUG, EMPTY_OPTION_LABEL, EMPTY_OPTION_VALUE, FormList} from "../../data/formList";
import {PDS_P, PDS_P_BOLD} from "../styled/StyledComponents";
import styled from "styled-components";

const PredictionTitle = styled(PDS_P_BOLD)`
    margin-top: 0;
    height: 30px;
`;

interface ClinicalDrugAdditivityProjectProps {

    project: IClinicalDrugProject | undefined,

    scanTime1: string,
    scanTime2: string
    valueHandlerTherapy1: any,
    valueHandlerTherapy2: any,
    scanTimeTherapy1Handler : any,
    scanTimeTherapy2Handler : any,
    handleChange: any,
    touched: any,
    errors: any,

}

export const ClinicalDrugAdditivityTherapySelector: React.FC<ClinicalDrugAdditivityProjectProps> = ({
                                                                                                        project, scanTime1, scanTime2,
                                                                                                        valueHandlerTherapy1,
                                                                                                        valueHandlerTherapy2,
    handleChange, scanTimeTherapy1Handler, scanTimeTherapy2Handler, touched, errors


                                                                                                    }: ClinicalDrugAdditivityProjectProps) => {


    const [therapy, setTherapy] = useState({kindOfTherapy: "", another: "another"});




    const buildTherapySelectorList = () => {
        let formListEntries = [];
        let formListData = {} as FormList;
        formListData.id = 1;
        formListData.value = EMPTY_OPTION_VALUE;
        formListData.label = EMPTY_OPTION_LABEL;
        formListEntries.push(formListData);


        if (project?.files != null && project.files.length > 0) {
            for (let i = 0; i < project.files.length; i++) {
                let formListData = {} as FormList;
                formListData.id = i + 1;
                formListData.value = project.files[i].filename;
                formListData.label = project.files[i].filename;
                formListEntries.push(formListData);
            }
        }

        let optionTemplate = formListEntries.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));

        return optionTemplate;
    }

    const buildTherapySelector1 = () => {
        return (
            <>
                <Form.Group>
                    <Form.Label className="required"
                                column="sm">Experiment </Form.Label>
                    <Form.Select size="sm" required
                                 name={"therapy1"}
                                 value={project?.parameters?.therapy1}
                                 isValid={touched.therapy1 && !errors.therapy1}
                                 onChange={(e) => {
                                     valueHandlerTherapy1(e.target.value)
                                     // handleChange(e)
                                 }}
                    >
                        {buildTherapySelectorList()}
                    </Form.Select>
                    <ErrorMessage name={"therapy1"}
                                  render={msg => <div
                                      className={"form-error-msg"}>{msg}</div>}/>
                </Form.Group>

                <Form.Group as={Row} className="mt-4 mb-3 border-blue">
                    <Form.Label column lg={4}>
                        1st Radiology Scan
                    </Form.Label>
                    <Col lg={5}>
                        <Form.Control size="sm" required
                                      name={"therapy1ScanTime"}
                                      value={scanTime1}
                                      type="text"
                                      onChange={(e) => {
                                          scanTimeTherapy1Handler(e.target.value)
                                          // handleChange(e)
                                      }} />
                        <ErrorMessage name={"therapy1ScanTime"}
                                      render={msg => <div
                                          className={"form-error-msg"}>{msg}</div>}/>

                    </Col>
                    <Col lg={3}>
                        <Form.Select size="sm" required
                                     className="clin-drug-select" name={"therapy1ScanInterval"}
                                     value={project?.parameters?.therapy1ScanInterval}
                        >
                            {buildSelectList(CLIN_DRUG)}
                        </Form.Select>
                    </Col>
                </Form.Group>
            </>
        )
    }

    const buildTherapySelector2 = () => {
        return (
            <>
                <Form.Group>
                    <Form.Label className="required"
                                column="sm">Control </Form.Label>
                    <Form.Select size="sm" required name={"therapy2"} value={project?.parameters?.therapy2}
                                 isValid={touched.therapy2 && !errors.therapy2}
                                 onChange={(e) => {
                                     valueHandlerTherapy2(e.target.value)
                                     // handleChange(e)
                                 }} >
                        {buildTherapySelectorList()}
                    </Form.Select>
                    <ErrorMessage name={"therapy2"}
                                  render={msg => <div
                                      className={"form-error-msg"}>{msg}</div>}/>
                </Form.Group>

                <Form.Group as={Row} className="mt-4 mb-3" controlId="">
                    <Form.Label column lg={4}>
                        1st Radiology Scan
                    </Form.Label>
                    <Col lg={5}>
                        <Form.Control size="sm"
                                      name={"therapy2ScanTime"}
                                      value={scanTime2}
                                      type="text"
                                      onChange={(e) => {
                                          scanTimeTherapy2Handler(e.target.value)
                                          // handleChange(e)
                                      }} />
                        <ErrorMessage name={"therapy2ScanTime"}
                                      render={msg => <div
                                          className={"form-error-msg"}>{msg}</div>}/>
                    </Col>
                    <Col lg={3}>
                        <Form.Select size="sm" required
                                     className="clin-drug-select" name={"therapy2ScanInterval"}
                                     value={project?.parameters?.therapy2ScanInterval}>
                            {buildSelectList(CLIN_DRUG)}
                        </Form.Select>
                    </Col>
                </Form.Group>
            </>
        )
    }


    const createTherapySelectors = () => {
        return (
            <>
                <PredictionTitle className=" mt-5 mb-0">Therapy Selection</PredictionTitle>
                <PDS_P className=" mt-0">Both Therapy 1 and Therapy 2 can only be associated with one file name
                    in your
                    project. In addition,
                    the same file name cannot be used for Therapy 1 and Therapy 2. Both Therapy 1 and
                    Therapy 2 must be selected.</PDS_P>
                <Row className="mt-2 mb-5 ">
                    <Col lg={6}>
                        {buildTherapySelector1()}
                    </Col>
                    <Col lg={6}>
                        {buildTherapySelector2()}
                    </Col>
                </Row>
            </>
        )
    }


    const buildSelectList = (listType: FormList[]) => {
        let optionTemplate = listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
        return optionTemplate;
    }


    return (
        <>
            {createTherapySelectors()}
        </>


    );

}