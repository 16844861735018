/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: DataZip
 *
 * Purpose: component for building the zip files section
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 28Jun2024 sasjxa file created
 * 16Aug2024 craig  Passed correct file to proc contents for zip data file
 * 03Oct2024 craig  Added key for column to get rid of "unique key" warning
 \******************************************************************************/
import React, {useState} from "react";
import {IAccessData, IFile} from "../../model/donations/IAccessData";
import styled from "styled-components";
import {COLORS, FONT_SIZES} from "../styled/StyleConstants";
import {Col} from "react-bootstrap";
import {ProcContents} from "./ProcContents";
import {SecureDownload} from "./SecureDownload";
import Collapse from "react-bootstrap/Collapse";
import {getFileImage, isSASFile} from "../../constants/CommonFunctions";


const ZipDataHeader = styled.span`
    font-weight: bold;
    font-size: ${FONT_SIZES.px14};
    color: ${COLORS.dark_grey_blue};
    padding-left: 3%;
`;

const ZipDataContainer = styled(Col)`
    padding-bottom: 3rem;
    padding-left: 4%;
    border-top: 0px;
    margin: 0;
    background-color: ${COLORS.filter_gray};

    span {
        display: block;
        color: ${COLORS.dark_grey_blue};
    }   
`;

const DetailsRow = styled.div`
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: row;
    align-items: center;

    a.showHide {
        font-size: ${FONT_SIZES.px13};
        color: ${COLORS.cerulean};
        padding-top: 4px;
    }
`;


interface DataZipProps {
    donation: IAccessData,
    file: IFile
}

export const DataZip: React.FC<DataZipProps> = ({
                                                    donation,
                                                    file

                                                }: DataZipProps) => {
    const [showZipFiles, setShowZipFiles] = useState(true);
    const HIDE_FILES = "Hide Files";
    const SHOW_FILES = "Show Files"

    // text for show/hide link
    const getShowHideText = () => {
        return (showZipFiles? HIDE_FILES:SHOW_FILES);
    }

    // toggleZipFiles - Toggle the zip files section
    const toggleZipFiles = () => {
        setShowZipFiles(!showZipFiles);
    }

    // build the file section
    const buildFile = () => {
        if (file !== null) {
            return (
                <>
                    <DetailsRow key={file.id} className={"ps-4 pe-4 pb-4 "}>
                        <Col lg={1}>
                            <img className=""
                                 src={getFileImage(file.filename)}
                                 alt="Waiting icon"
                                 width="48" height="48"/>
                        </Col>

                        <Col>
                            <span>{file.fileType}</span>
                            <span className={"fileType"}>{file.filename}</span>
                        </Col>
                        <a className="showHide pe-4" onClick={() => toggleZipFiles()}>{getShowHideText()}</a>
                        {isSASFile(file.filename) && (
                            <ProcContents donation={donation} file={file}/>
                        )}
                        <SecureDownload donation={donation} file={file}/>
                    </DetailsRow>
                </>
            )
        }
    }

    // build the individual zipped files
    const buildZippedDataFile = () => {
        return (
            <ZipDataContainer key={file.id + "Zip"} className={"pb-4 "} lg={12}>

                <DetailsRow className={"pb-4 "}>
                    <ZipDataHeader>{file.filename} Contents</ZipDataHeader>
                </DetailsRow>
                {file?.zipFiles?.map((zipLinkage: IFile) => {
                    return (
                        <Col key={zipLinkage.id + "-Col"}>
                            <DetailsRow key={zipLinkage.id} className={"ps-4 pe-4 pb-4"}>
                                <Col lg={1}>
                                    <img className=""
                                         src={getFileImage(zipLinkage.filename)}
                                         alt="Waiting icon"
                                         width="48" height="48"/>
                                </Col>
                                <Col>
                                    <span className={"fileType"}>{zipLinkage.filename}</span>
                                </Col>
                                {isSASFile(zipLinkage.filename) && (
                                    <ProcContents donation={donation} file={zipLinkage}/>
                                )}
                                <SecureDownload donation={donation} file={zipLinkage}/>
                            </DetailsRow>
                        </Col>
                    )
                })}
            </ZipDataContainer>
        )
    }

    return (
        <>
            {buildFile()}
            <Collapse in={showZipFiles}>
                <div>
                    {buildZippedDataFile()}
                </div>
            </Collapse>

        </>
    )
}