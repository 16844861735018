/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ActiveUsersDialog
 *
 * Purpose: Admin Dashboard dialog for displaying info on active users
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 23Apr2024 sasjxa  file created.
 \******************************************************************************/
import {Button, Col, Modal, Row} from "react-bootstrap";
import React from "react";
import {DialogHeader} from "../styled/StyledComponents";
import {IActiveUser} from "../../model/admin/dashboard/IAdminDashboardCounts";

interface ActiveUsersDialogProps {
    activeUsers: IActiveUser[]| undefined,
    show: boolean,
    showHide: any
}

export const ActiveUsersDialog: React.FC<ActiveUsersDialogProps> = ({activeUsers, show, showHide}: ActiveUsersDialogProps) => {
    return (
        <Modal show={show} size="xl">
            <Modal.Header closeButton={true} onClick={showHide}>
                <DialogHeader>Active Accounts</DialogHeader>
            </Modal.Header>
            <Modal.Body>

                <Row className="mt-3 w-100">
                    <Col lg={3} className="bold-label">User</Col>
                    <Col lg={3} className="bold-label">Group</Col>
                    <Col lg={3} className="bold-label">Start</Col>
                    <Col lg={1} className="bold-label">Browser</Col>
                    <Col lg={2} className="bold-label">OS</Col>
                </Row>
                {
                     activeUsers?.map((activeUser: IActiveUser) => {
                         return <Row className="mt-3 w-100">
                             <Col lg={3}>{activeUser.username}</Col>
                             <Col lg={3}>{activeUser.group}</Col>
                             <Col lg={3}>{new Date(activeUser.start).toLocaleDateString('en-us', {
                                 month: "numeric",
                                 day: "numeric",
                                 year: "numeric",
                                 hour: "numeric",
                                 minute:"numeric",
                                 second:"numeric"
                             })}</Col>
                             <Col lg={1}>{activeUser.browser}</Col>
                             <Col lg={2}>{activeUser.os}</Col>
                              </Row>
                     })
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"secondary"} onClick={showHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
