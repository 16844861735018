/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Home
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 09Feb2023 craig File created.
 * 02Jun2023 sasjxa move styled components out of FC
 * 27Nov2023 sasjxa change api for platform announcements
 * 08Mar2024 craig  add BASE_PATH constant to urls (MPM-5430)
 * 08Mar2024 sasjxa change links for external PDS sites (MPM-5431)
 * 14Apr2024 craig  cleaned up console errors *
 * 25Apr2024 sasjxa refactoring of methods and styled components
 * 21Aug2024 craig   moved to useContext(PDSContext) for user session
 * 23Oct2024 craig  removed env stuff that was for debugging
 * 29Oct2024 craig  fixed some styling issues
 \******************************************************************************/
import React, {Fragment, useContext, useEffect, useState} from "react";
import "./Home.css";
import {Col, Container, Row} from "react-bootstrap";
import {ContentWrapper, PDS_P} from "../../components/styled/StyledComponents";
import {COLORS, FONT_SIZES} from "../../components/styled/StyleConstants";
// @ts-ignore
import styled from 'styled-components';
import {Link} from "react-router-dom";
import parse from "html-react-parser";
import {IPdsPlatformAnnouncement} from "../../model/announcements/IPdsPlatformAnnouncement";
import {useRequest} from "../../helper/useRequest";
import {BASE_PATH} from "../../constants";
import {PDSContext, usePDSContext} from "../../context/PDSContext";

const HomeHeaderBackground = styled.div`
   background-color: ${COLORS.dark_grey_blue};
   width: 100%;
`;

const CoreAttributes = styled.div`
   margin: 2% 0;    
   width: 100%;         
`;

const MainHeader = styled.h1`
   font-weight: 600;
   font-size: 2.25rem;  
   color: ${COLORS.lightblue};           
   text-align: center;      
`;

const MissionHeader = styled(MainHeader)`       
   color: ${COLORS.dark_grey_blue};
   margin-top: 2%;
   margin-bottom: 0;
`;

const SubHeader = styled(PDS_P)`
   font-size: ${FONT_SIZES.px18};
   color: ${COLORS.white};
   width: 75%;
   margin: 1% auto; 
   text-align: center;
   text-align-last: center;
   margin-bottom: 3%;
          
   @media (min-width: 768px){
      width: 85%; 
      text-align: left; 
    }   
       
    @media (min-width: 1024px){
       width: 50%;     
    }
`;

const MissionSection = styled(PDS_P)`      
   color: ${COLORS.dark_grey_blue};
   margin-bottom: 3%;
                    
   @media (min-width: 768px){
      text-align-last: center;     
   }
`;

const InfoBoxesContainer = styled.div`
   display: flex;
   flex-direction: column;    
   width: 90%;
   margin: 3% auto;    
            
   @media (min-width: 1024px){
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 auto;          
   }   
`;

const InfoBox = styled.div`      
   background-color: ${COLORS.white};
   margin-bottom: 5%;        
       
   &:last-child {
      margin-bottom: 0;        
   }      
       
   @media (min-width: 1024px){
      width: 30%;
      margin-bottom: 0;
   }    
`;

const InfoBoxHeader = styled(PDS_P)`
   color: ${COLORS.cerulean};
   font-size: ${FONT_SIZES.px18};
   font-weight: bolder;
   padding-left: 3%; 
`;

const InfoBoxContent = styled(PDS_P)`
    padding: 0px 3%;
    font-size: ${FONT_SIZES.px14};
    font-weight: 400;
    color: ${COLORS.dark_grey_blue};
    a {
        padding: 0 5px;
    }
`;

const InfoBoxAnnouncements = styled.div`
    padding: 0px 3%;
    font-size: ${FONT_SIZES.px14};
    font-weight: 400;
    color: ${COLORS.dark_grey_blue};

    a {
        padding: 0 5px;
    }
`;

const HrHome = styled.hr`
    border-style:  solid; 
    border-width: 1px;
    border-color:  ${COLORS.dark_grey_blue};
    width: 100%;
    opacity: 1;
    margin: 0;      
`;

const SectionContainer = styled.div`
   display: flex;
   flex-direction: column; 
   justify-content: center;      
   width: 85%;
   margin: 1% auto;        
       
   a {
     padding: 0 5px;        
   }
      
   @media (min-width: 768px){         
      width: 90%;
      margin: 1% auto;          
   }
   
   @media (min-width: 1024px){           
      width: 50%;
      margin: 0 auto;
   }        
`;

const ImagesContainer = styled.div`
   display: flex;
   flex-direction: column;
      
   @media (min-width: 768px){             
      display: flex;
      flex-direction: row;
      justify-content: space-between;         
      margin-top: 3%;
      margin-bottom: 1%;     
      width: 100%;           
    }
        
    @media (min-width: 1024px){         
       margin: 0 auto;         
    }
`;

const ImageAndTextContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   align-items: center; 
       
   &:first-child {
     margin-top: 0;
   }
       
   p.image-count {
     font-weight: 600;
     font-size: 2.5rem;
     text-align: center;
     padding-bottom: 0;
     margin-bottom: 0;
   } 
       
   p.image-text {
     margin-top: 2px;
     font-weight: bold;
   }
       
   @media (min-width: 1024px){     
      margin-top: 2%;
      width: 55%;
        
      p.image-count {
        margin: 0;
        padding: 0;
        font-size: 3rem;
      }
        
      p.image-text {         
        margin: 0;  
        padding: 0;     
      }
   }     
`;

const HomePageImage = styled.img`
   width: 100px;
   text-align: center;
       
   @media (min-width: 1024px){ 
      width: 125px;
   }
`;

const SASToolsContainer = styled.div`
       display: flex;
       flex-direction: column;
       margin-top: 5%;
       
       img {
         width:174px;
         height: 85px;       
       }
       
       @media (min-width: 1024px){  
          display: flex;
          flex-direction: row;        
        
          img {
            width:195px;
            height: 95px;
          }
       }
    `;

const AnalyticTools = styled.div`
      margin-top: 1.5%;        
      
      h2 {
        font-weight: bold;
        font-size: 1.375rem;
        color: ${COLORS.dark_grey_blue};
        margin-bottom: 0;
      }
      
      p {
        margin-top: 1%;
        padding: 0;
      }
      
      a {
        font-weight: bold;
        color: ${COLORS.black};
        text-decoration: none;
        margin-bottom: 0;
        padding: 0;
      }
      
      @media (min-width: 768px){             
         p {
           margin-top: 0;       
         }
       }
      
      @media (min-width: 1024px){        
          margin-left: 5%; 
       }
    `;

const InitiativeContainer = styled.div`
       display: flex;
       justify-content: center;
       margin-top: 0;
       margin-bottom: 0;
    
       @media (min-width: 768px){            
         margin-top: 4%;
         margin-bottom: 5%;
       }
    `;

const VideoContainer = styled.div`
       height: 200px;    
       width: 100%;      
      
       iframe {
         width:100%;
         height: 100%;
       }
      
       @media (min-width: 768px){        
         height: 300px;   
       }
    `;


export const Home: React.FC = () => {
    const MARKETING_WEBSITE_URL: string = "https://www.ceoroundtableoncancer.org/project-data-sphere/";
    const MARKETING_RESEARCH_PROGRAMS_URL: string = "https://www.ceoroundtableoncancer.org/project-data-sphere/research-programs/";
    const PUBLICATIONS_MARKETING_WEBSITE: string = " https://www.ceoroundtableoncancer.org/project-data-sphere/publications/";
    const MEPS: string = "https://www.ahrq.gov/data/meps.html";
    const DATASET_COUNT: string = ">205";
    const PATIENT_LIVES_COUNT: string = ">260k";
    const DATA_PROVIDERS_COUNT: string = ">18";
    const PUBLICATIONS_COUNT: string = ">135";

    const appDataURL = process.env.REACT_APP_API_URL + "/api/public/announcements/pdsannouncements";

    const [pdsAnnouncements, setPdsAnnouncements] = useState<IPdsPlatformAnnouncement[]>();
    const [requestState, getPDSAnnouncements] = useRequest({url: appDataURL, initialIsLoading: true,})
    const {isLoading, data, error} = requestState;
    const {sessionUser} = usePDSContext();

    /**
     * call the backend to get the team comments data
     */
    useEffect(() => {
            getPDSAnnouncements();
        }, []
    )

    /**
     * if team comments data changes, set it
     */
    useEffect(() => {
            if (data != null) {
                setPdsAnnouncements(data);
            }
        }, [data]
    )


    const buildPDSAnnouncements = () => {
        if (pdsAnnouncements === undefined || pdsAnnouncements.length === 0) {
            return <div>No Announcements</div>
        } else {
            let result = pdsAnnouncements.map((announcement:IPdsPlatformAnnouncement, index:number) => {
                return <Row key={index}><Col>{parse(announcement.message)}</Col></Row>
            })
            return <Container>{result}</Container>
        }
    }


    return (
        <Fragment>
            <ContentWrapper>
                <HomeHeaderBackground>
                    <CoreAttributes>
                        <MainHeader>Project Data Sphere's Data Sharing Platform</MainHeader>
                        <SubHeader className={"subTitle"}>
                            Welcome to the leading oncology open access data sharing
                            platform. We host
                            de-identified patient-level data contributed by industry, academia, and PDS research
                            programs.
                        </SubHeader>
                        <InfoBoxesContainer className={"infoBoxContainer"}>
                            <InfoBox>
                                <InfoBoxHeader>Register for Access</InfoBoxHeader>
                                <HrHome></HrHome>
                                <InfoBoxContent>Register now for full access to our datasets and powerful SAS® tools, or
                                    to share your data.
                                </InfoBoxContent>
                                <InfoBoxContent>Registration is quick and easy. There are no fees, and no research
                                    proposal is required.
                                </InfoBoxContent>
                            </InfoBox>
                            <InfoBox>
                                <InfoBoxHeader>Learn More</InfoBoxHeader>
                                <HrHome></HrHome>
                                <InfoBoxContent>
                                    For more information about Project Data Sphere, our talented team, and ongoing
                                    <a href={MARKETING_RESEARCH_PROGRAMS_URL} target="_blank" rel={"noopener noreferrer"}>research initiatives</a>
                                    visit our
                                    <a href={MARKETING_WEBSITE_URL} target={"_blank"} rel={"noopener noreferrer"}>parent site.</a>
                                </InfoBoxContent>
                                <InfoBoxContent>
                                    To learn more about how our data has been used to advance science
                                    visit our listed of peer-reviewed journal
                                    <a href={PUBLICATIONS_MARKETING_WEBSITE} target="_blank" rel={"noopener noreferrer"}>publications.</a>
                                </InfoBoxContent>
                            </InfoBox>
                            <InfoBox>
                                <InfoBoxHeader>PDS Platform Announcements</InfoBoxHeader>
                                <HrHome></HrHome>
                                <InfoBoxAnnouncements>
                                    {isLoading && <p>Loading ...</p>}
                                    {error && <p>Error</p>}
                                    {data && buildPDSAnnouncements()}
                                </InfoBoxAnnouncements>
                            </InfoBox>
                        </InfoBoxesContainer>
                    </CoreAttributes>
                </HomeHeaderBackground>

                <SectionContainer className={"sectionContainer"}>
                    <MissionHeader>Our Mission</MissionHeader>
                    <MissionSection className={"missionSection"}>
                        Improve outcomes for cancer patients by openly sharing data,
                        convening world-class experts,
                        and collaborating across industry and regulators to catalyze new scientific insights
                        that accelerate effective treatments to patients.
                    </MissionSection>
                    <MissionHeader>Our Data</MissionHeader>

                    <ImagesContainer>
                        <ImageAndTextContainer className={"imagesAndTextContainer"}>
                            <HomePageImage src={"/images/icons/data-sets.png"}></HomePageImage>
                            <PDS_P className={"image-count"}>{DATASET_COUNT}</PDS_P>
                            <PDS_P className={"image-text"}>Patient Level Datasets</PDS_P>
                        </ImageAndTextContainer>
                        <ImageAndTextContainer>
                            <HomePageImage src={"/images/icons/patient-lives.png"}></HomePageImage>
                            <PDS_P className={"image-count"}>{PATIENT_LIVES_COUNT}</PDS_P>
                            <PDS_P className={"image-text"}>Patient Lives</PDS_P>
                        </ImageAndTextContainer>
                        <ImageAndTextContainer>
                            <HomePageImage src={"/images/icons/data-providers-2.png"}></HomePageImage>
                            <PDS_P className={"image-count"}>{DATA_PROVIDERS_COUNT}</PDS_P>
                            <PDS_P className={"image-text"}>Data Providers</PDS_P>
                        </ImageAndTextContainer>
                        <ImageAndTextContainer>
                            <HomePageImage src={"/images/icons/publications-2.png"}></HomePageImage>
                            <PDS_P className={"image-count"}>{PUBLICATIONS_COUNT}</PDS_P>
                            <PDS_P className={"image-text"}>Peer-Review Publications</PDS_P>
                        </ImageAndTextContainer>
                    </ImagesContainer>

                    <PDS_P className={"image"}>Data is being used to drive new research and treatment methods, develop
                        innovative technology
                        (ML/AI models), and more efficiently plan and design clinical trials. Data contained
                        on the PDS platform is made available through contributions from industry and key partnerships
                        in academia, research, and government</PDS_P>

                    <PDS_P>This data is de-identified, patient-level, randomized clinical trial data and linked or
                        enriched data sets. To learn more about how researchers leverage the data on our platform,
                        please
                        visit our list of peer-reviewed journal
                        <a href={PUBLICATIONS_MARKETING_WEBSITE} target="_blank" rel={"noopener noreferrer"}>publications.</a>
                    </PDS_P>
                    <PDS_P className={"image"}>Through an active funding partnership with the Robert Wood Johnson
                        Foundation, PDS has been
                        able to offer enriched randomized clinical trial data containing socioeconomic information
                        prepared by RTI International and made available by the
                        <a href={MEPS} target="_blank" rel={"noopener noreferrer"}>Agency for Healthcare Research and Quality's
                            Medical Expenditure Panel Survey information.</a>
                    </PDS_P>
                    <SASToolsContainer>
                        <img alt={"SAS"} src={"/images/sas.png"}></img>
                        <AnalyticTools>
                            <h2>SAS&reg; Analytics Tools</h2>
                            <PDS_P>Registration includes access to powerful SAS analytics tools at no cost.</PDS_P>
                            <Link to={BASE_PATH + "lsafVideos"} target={"_blank"} rel={"noopener noreferrer"}>Life Sciences Analytics Framework
                                (LSAF) </Link>
                            <PDS_P>Securely analyze datasets
                                using Base SAS&reg;, SAS/STAT&reg;, and SAS/GRAPH&reg;.</PDS_P>
                            <Link to={"/resources_vdmml_getting_started.pdf"} target={"_blank"}  rel={"noopener noreferrer"}>Visual Data Mining
                                and Machine Learning (VDMML) </Link>
                            <PDS_P>Scalable, in-memory processing environment combines data wrangling, exploration,
                                statistical,
                                data mining and machine learning techniques.</PDS_P>
                        </AnalyticTools>
                    </SASToolsContainer>

                    <InitiativeContainer>
                        <VideoContainer>
                            <iframe title={"pds"} allowFullScreen={true}
                                    src="https://player.vimeo.com/video/74386825?title=0&amp;byline=0&amp;portrait=0&amp;api=1&amp;player_id=intro_video"
                                    frameBorder="0" id="intro_video" className="video"></iframe>
                        </VideoContainer>
                    </InitiativeContainer>

                </SectionContainer>
            </ContentWrapper>
        </Fragment>
    )
}