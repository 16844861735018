/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Expired Token Registration
 *
 * Purpose: Display for expired registration token
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 20Mar2024 sasjxa file created
 * 23May2024 sasjxa correct submission response url
 * 14Jun2024 sasjxa correct navigation for successful submit
 \******************************************************************************/
import React from "react";
import {Button, Col, Container} from "react-bootstrap";
// @ts-ignore
import styled from 'styled-components';

import {ContentPage, FormLayoutColumn, PageWrapper, PDS_P} from "../../components/styled/StyledComponents";
import "../forms/forms.css";
import {HEADER_RMI_TOKEN_REISSUE, RESPONSE_RMI_TOKEN_REISSUE} from "../../data/formList";
import {useNavigate, useParams} from "react-router-dom";
import {useRequest} from "../../helper/useRequest";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";


export const ExpiredTokenRegistration: React.FC = () => {
    const {token} = useParams();
    const navigate = useNavigate();
    const {showBoundary} = useErrorBoundary();

    // handle error - unsuccessful save of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    const handleSuccess = () => {
        navigate(BASE_PATH + 'SubmissionResponse', {
            state: {
                header: HEADER_RMI_TOKEN_REISSUE,
                response: RESPONSE_RMI_TOKEN_REISSUE,
            }
        });
    }


    const reactivateTokenURL = process.env.REACT_APP_API_URL + "/api/public/users/registration/rmi/reissue/" + token;

    // save data
    const [requestActionState, setReactivateToken] = useRequest({
        url: reactivateTokenURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })

    // expired token message
    const expiredTokenMessage = () => {
        if (token !== null) {
            return (
            <Col>

                <PDS_P>Your link for responding to Request For More Information has expired. Please press Submit
                    to generate a new link.</PDS_P>

                <Button variant="success" className="btn-submit" type="submit"
                             onClick={() => {
                                setReactivateToken()
                             }}>
                    Submit
                </Button>

            </Col>
            )
        }
    }

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn md={8} lg={9} name={"formLayoutColumn"}>
                        {expiredTokenMessage()}
                    </FormLayoutColumn>
                </PageWrapper>
            </ContentPage>
        </Container>
    );

}
