/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: DeleteDialog
 *
 * Purpose: Dialog for requesting NCI data
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 26Sep2023 sasjxa  file created
 * 10Jun2023 sasjxa  correct verbiage for dialog
 \******************************************************************************/
import {Button, Modal} from "react-bootstrap";
import React from "react";
import {DialogContent, DialogHeader} from "../styled/StyledComponents";

interface DialogProps {
    show: boolean,
    showHide:any,
    messageType:string,
}

export const NCIRequestDialog: React.FC<DialogProps> = ({show, showHide, messageType}: DialogProps) => {
        let title:string = "Dataset added to NCI request";
        let message:string = "The dataset you selected has been added to your request. When you are ready, click on your user name to " +
            "reveal a dropdown menu. Select 'NCI Requests' from the dropdown menu to complete the necessary forms " +
            "to submit your request."
        if (messageType === "REMOVE") {
            message = "The dataset has been successfully removed from your request."
            title = "Dataset removed from request";
        } else if (messageType === "TO_MANY") {
            title = "Unable to add dataset to request";
            message = "You are unable to add this dataset to your request, as you have requested a maximum of 5 " +
                "clinical trials. Some datasets contain more than one clinical trial and adding this dataset to your " +
                "request would put you over the limit established by NCI."
        }

        return (
            <Modal show={show}>
                <Modal.Header closeButton={true} onClick={showHide}>
                    <DialogHeader>{title}</DialogHeader>
                </Modal.Header>
                <Modal.Body>
                    <DialogContent>{message}</DialogContent>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} onClick={showHide}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

