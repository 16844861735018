const LSAF_DIR = "/videos";

export interface ILSAFVideo {
    id : number,
    link: string,
    title: string
}
export const LSAF_VIDEOS:ILSAFVideo[] = [
    {
        id: 1,
        link:  LSAF_DIR + "/Introduction_to_Project_Data_Sphere.mp4",
        title: "Introduction"
    },
    {
        id: 2,
        link:  LSAF_DIR + "/Data_Explorer.mp4",
        title: "Data Explorer"
    },

    {
        id: 3,
        link:  LSAF_DIR + "/Workspace_and_Repository.mp4",
        title: "Workspace and Repository"
    },

    {
        id: 4,
        link:  LSAF_DIR + "/Searching.mp4",
        title: "Searching"
    },

    {
        id: 5,
        link:  LSAF_DIR + "/Running_SAS_Code.mp4",
        title: "Running SAS Code"
    },

    {
        id: 6,
        link:  LSAF_DIR + "/Libname_and_Filename_Statements.mp4",
        title: "Libname and Filename Statements"
    },

    {
        id: 7,
        link:  LSAF_DIR + "/Libname_and_Filename_Statements.mp4",
        title: "Libname and Filename Statements"
    },

];

