/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ClinDrugAdditivityProject
 *
 * Purpose: special PDS project
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 02Aug2024 sasjxa file created
 * 25Aug2024 sasjxa use new component for therapy selectors
 * 27Aug2024 sasjxa add check for multiple files before initial form display
 * 29Aug2024 sasjxa add support for deleting files
 * 08Oct2024 sasjxa remove correlation since not used in calculations
 \******************************************************************************/
import React, {Fragment, useMemo, useState} from "react";
import {Button, Col, Form, Row, Spinner, Table} from "react-bootstrap";
// @ts-ignore
import styled from 'styled-components';
import {FormLayoutColumn, PDS_P_BOLD} from "../../components/styled/StyledComponents";
import {useRequest} from "../../helper/useRequest";

import {IClinicalDrugProject, IClinicalDrugProjectFile} from "../../model/clinicalDrug/IClinicalDrug";
import {UploadClinicalDrugProjectDialog} from "../../components/dialogs/UploadClinicalDrugProjectDialog";
import {Formik} from "formik";
import {ClinicalDrugAdditivityTherapySelector} from "./ClinicalDrugAdditivtyTherapySelector";
import * as Yup from "yup";

import {DeleteClinicalDrugFileDialog} from "../dialogs/DeleteClinicalDrugFileDialog";
import {IClinicalDrugProjectResults} from "../../model/clinicalDrug/IClinicalDrugProjectResults";
import {ClinicalDrugKaplanMeierCurve} from "./ClinicalDrugKaplanMeierCurve";
import {IClinicalDrugProjectRecords} from "../../model/clinicalDrug/IClinicalDrugProjectRecords";


const ProjectInformationRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;

    span {
        display: inline;
    }
`;

const ImgContainer = styled.div`
    display: flex;
    align-items: center;

    span {
        margin-left: 5px;

        :hover {
            text-decoration: underline;
        }
    }
`;


const TableImgContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;

    img {
        margin: 0 !important;
    }
`;

const PredictionTitle = styled(PDS_P_BOLD)`
    margin-top: 0;
    height: 30px;
`;

const ProjectsTable = styled(Table)`
    max-height: 180px;

    th.first, tr.first {
        width: 70%;
    }

    th.second, tr.second {
        width: 20%;
    }

    th.third, tr.third {
        width: 10%;
    }
`;


interface ClinicalDrugAdditivityProjectProps {
    projectId: number
}

export const ClinicalDrugAdditivityProject: React.FC<ClinicalDrugAdditivityProjectProps> = ({
                                                                                                projectId
                                                                                            }: ClinicalDrugAdditivityProjectProps) => {




    const IMG_PATH: string = "/images/icons/";
    const [project, setProject] = useState<IClinicalDrugProject>();
    const [showUploadFiles, setShowUploadFiles] = useState<boolean>(false);
    const [showDeleteFiles, setShowDeleteFiles] = useState<boolean>(false);
    const defaultCorrelation: number = 0.3;
    const [therapy1, setTherapy1] = useState("");
    const [therapy2, setTherapy2] = useState("");
    const [therapy1ScanTime, setTherapy1ScanTime] = useState("");
    const [therapy2ScanTime, setTherapy2ScanTime] = useState("");
    const [correlation, setCorrelation] = useState(0.3);
    const [therapy1ScanInterval, setTherapy1ScanInterval] = useState("Weeks");
    const [therapy2ScanInterval, setTherapy2ScanInterval] = useState("Weeks");
    const [selectedFileName, setSelectedFileName] = useState("");
    const [selectedFileId, setSelectedFileId] = useState<number>(-1);
    const [results, setResults] = useState<IClinicalDrugProjectResults>();
    const defaultScanTime = "-1";
    const numericRegExp = /^(0|[1-9]\d*)(\.\d+)?$/;
    const SCAN_TIME_ERROR_MSG: string = "Scan time must be a numeric  value";
    const WEEKS = "Weeks"

    let sampleData: IClinicalDrugProjectRecords[] = [
        {time: 2, survival: 0.8},
        {time: 1, survival: 0.9},
        {time: 0, survival: 1}
    ];




    const handleError = (error: object) => {
        console.log(error);
    }

    const handleSuccess = (results: IClinicalDrugProjectResults) => {
        setResults(results);
    }

    // close dialog, get project list
    const handleUploadFiles = () => {
        setShowUploadFiles(!showUploadFiles);
        getUserProject();
    }

    // close dialog, get project list
    const handleDeleteFiles = () => {
        setShowDeleteFiles(!showDeleteFiles);
        getUserProject();
    }

    const handleDeleteDialog = (fileId: number, fileName: string) => {
        setSelectedFileId(fileId);
        setSelectedFileName(fileName);
        setShowDeleteFiles(true);
    };

    const toggleUploadFilesDialog = () => {
        setShowUploadFiles(!showUploadFiles);
    }

    const toggleDeleteFilesDialog = () => {
        setShowDeleteFiles(!showDeleteFiles);
    }

    const projectHasFiles = () => {
        return (project?.files?.length ? true : false)
    }

    const projectHasRequiredFiles = () => {
        if (project?.files?.length  != null && project?.files?.length > 1){
            return true;
        }
        else {
            return false;
        }
    }




    const getSelectedProjectTitle = () => {
        if (project != null) {
            if (projectHasFiles()) {
                return project.projectName + " Parameters ";
            } else {
                return "Please upload 2 different files (.csv filetype only) for: " + project.projectName;
            }
        } else {
            return "Please create a new project"
        }
    }

    // get data
    const getProjectsUrl = process.env.REACT_APP_API_URL + "/api/clinicaldrug/getProject/" + projectId;
    const [requestState, getUserProject] = useRequest({
        url: getProjectsUrl,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,

    })
    const {isLoading, data, error} = requestState;

    // save data
    const saveProjectParametersURL = process.env.REACT_APP_API_URL + "/api/clinicaldrug/saveProjectParameters/" + projectId;
    const [requestActionState, setProjectParameters] = useRequest({
        url: saveProjectParametersURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })

    useMemo(() => {
        getUserProject(projectId);
    }, [projectId]);

    useMemo(() => {
        if (data !== null ) {
            setProject(data);
            setTherapy1(data.parameters?.therapy1);
            setTherapy2(data.parameters?.therapy2);

            setTherapy1ScanTime(data.parameters?.therapy1ScanTime  !=  null && data.parameters?.therapy1ScanTime  !=  defaultScanTime
                ? data.parameters?.therapy1ScanTime.toString() : "");
            setTherapy2ScanTime(data.parameters?.therapy2ScanTime  !=  null && data.parameters?.therapy2ScanTime  !=  defaultScanTime
                ? data.parameters?.therapy2ScanTime.toString() : "");

            setTherapy1ScanInterval(data.parameters?.therapy1ScanInterval != null ? data.parameters?.therapy1ScanInterval: WEEKS);
            setTherapy2ScanInterval(data.parameters?.therapy2ScanInterval != null ? data.parameters?.therapy2ScanInterval: WEEKS);
            setCorrelation(data.parameters?.correlation != null ? data.parameters?.correlation: 0.3);
        }
    }, [data])


    const setCorrelationValue = (value:string) => {
        const numValue = Number(value);
        setCorrelation(numValue);
    }

    const createTableImages = (fileId: number, fileName: string) => {
        return (
            <TableImgContainer>
                <img className="mx-auto "
                     src={IMG_PATH + "delete.png"} title="Delete"
                     onClick={() => {
                         {
                             handleDeleteDialog(fileId, fileName);
                         }
                     }}/>
            </TableImgContainer>
        )
    }

    const formatDate = (date: Date) => {
        return (
            new Date(date).toLocaleDateString('en-us', {
                month: "2-digit",
                day: "2-digit",
                year: "numeric"
            })
        )
    }

    const createTableRow = (file: IClinicalDrugProjectFile) => {
        return (
            <tr>
                <td className="first">{file.filename}</td>
                <td className="second">{formatDate(file.createdAt)}</td>
                <td className="third">
                    {createTableImages(file.id, file.filename)}
                </td>
            </tr>
        )
    }

    const createTable = () => {
        return (
            <ProjectsTable bordered>
                <thead>
                <tr>
                    <th className="first">File name</th>
                    <th className="second">Upload Date</th>
                    <th className="third">Actions</th>
                </tr>
                </thead>
                <tbody>
                {project?.files?.map((file: IClinicalDrugProjectFile) => (
                    createTableRow(file)
                ))}
                </tbody>
            </ProjectsTable>
        )
    }

    const buildProject = () => {
        return (
            <>
                <ProjectInformationRow className="mt-2  ">
                    <PDS_P_BOLD>{getSelectedProjectTitle()}</PDS_P_BOLD>
                    <ImgContainer>
                        <img className="mx-auto"
                             src={IMG_PATH + "upload.png"}
                             onClick={() => {
                                 {
                                     toggleUploadFilesDialog()
                                 }
                             }}/>
                        <span onClick={() => {
                            {
                                toggleUploadFilesDialog()
                            }
                        }}
                        >Upload Data</span>
                    </ImgContainer>
                </ProjectInformationRow>
                {projectHasFiles() && (
                    <>
                        <Col>
                            {createTable()}
                        </Col>
                        {projectHasRequiredFiles() && (
                        <Col>
                            {buildForm()}
                        </Col>
                        )}
                    </>
                )}
            </>
        )
    }


    const schema = Yup.object().shape({
        therapy1: Yup.string().required("Please select a file for Therapy 1."),
        therapy2: Yup.string().required("Please select a file for Therapy 2.").notOneOf([Yup.ref('therapy1')], 'Therapy 2 must be' +
            ' different than Therapy1'),
        therapy1ScanTime: Yup.string().matches(numericRegExp, SCAN_TIME_ERROR_MSG),
        therapy2ScanTime: Yup.string().matches(numericRegExp, SCAN_TIME_ERROR_MSG),
    });


    const buildForm = () => {
        return (
            <Formik
                enableReinitialize
                validationSchema={schema}
                onSubmit={async (values) => {
                    console.log("hello");
                    setProjectParameters(JSON.stringify({
                        therapy1: values.therapy1,
                        therapy2: values.therapy2,
                        therapy1ScanTime: values.therapy1ScanTime,
                        therapy2ScanTime: values.therapy2ScanTime,
                        therapy1ScanInterval: values.therapy1ScanInterval,
                        therapy2ScanInterval: values.therapy1ScanInterval,
                        correlation: values.correlation
                    }));
                }}
                initialValues={{
                    therapy1: therapy1 ?? '',
                    therapy2: therapy2 ?? '',
                    therapy1ScanTime: therapy1ScanTime ?? '',
                    therapy2ScanTime: therapy2ScanTime ?? '',
                    therapy1ScanInterval: therapy1ScanInterval ?? '',
                    therapy2ScanInterval: therapy2ScanInterval ?? '',
                    correlation: correlation

                }}

                validateOnChange={false}
                validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      resetForm,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                      setFieldValue,
                      setFieldTouched
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn className="" lg={12}>
                            {projectHasFiles() && (
                                <>
                                <ClinicalDrugAdditivityTherapySelector project={project}  scanTime1={therapy1ScanTime}
                                                                       scanTime2 = {therapy2ScanTime} valueHandlerTherapy1={setTherapy1}
                                valueHandlerTherapy2={setTherapy2}  scanTimeTherapy1Handler={setTherapy1ScanTime}
                                                                   scanTimeTherapy2Handler={setTherapy2ScanTime}  handleChange={handleChange}
                                                                       touched={touched} errors={errors}/>
                                </>
                                )}
                        </FormLayoutColumn>
                        {projectHasFiles() && (
                            <Button variant="primary" className=" mt-0 mb-3 " type="submit">Calculate Results</Button>
                        )}

                        {results && (
                            <Row>
                            <ClinicalDrugKaplanMeierCurve combinationPredictedAdd={results.experimentalClean}/>
                            </Row>
                        )}

                    </Form>
                )}
            </Formik>
        )
    }

    return (
        <>
            <UploadClinicalDrugProjectDialog projectName={project?.projectName} projectId={projectId}
                                             show={showUploadFiles} showHide={toggleUploadFilesDialog}
                                             handleUpload={handleUploadFiles}
            />
            <DeleteClinicalDrugFileDialog  projectId = {projectId} fileName={selectedFileName} fileId={selectedFileId}
                                             show={showDeleteFiles} showHide={toggleDeleteFilesDialog}
                                             handleDelete={handleDeleteFiles}
            />
            {isLoading &&
                <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}
            {error && <p>Error</p>}
            {data && buildProject()}
        </>
    );

}