/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: NCIAttestation
 *
 * Purpose: data for NCI attestation
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 05May2023 sasjxa file created.
 \********************************************************************************/
export interface IAttestation {
    id : number,
    title: string,
    sectionText: string;
}


export const ATTESTATION_TITLE:string ="<i>Project Data Sphere</i> Cancer Research Agreement";
export const ATTESTATION_SUBTITLE:string ="NCI SUPPLEMENTAL TERMS For Datasets Made Available by the National Cancer Institute";
export const ATTESTATION_VERSION:string ="(v. March 2022)";
export const ATTESTATION_DESCRIPTION:string ="<em>You have submitted a request to access and use, through the Project Data Sphere Cancer Research Platform," +
     " one or more datasets for clinical trials that were conducted by network groups (each, a \"Network Group\") in the National Clinical Trials Network" +
    " (NCTN) or NCI Community Oncology Research Program (NCORP) that the National Cancer Institute (\"NCI\") makes available through the" +
    " Project Data Sphere Cancer Research Platform. These NCI Supplemental Terms are applicable to your access to and use of" +
    " these datasets IN ADDITION TO the terms, covenants and conditions of the Project Data Sphere" +
    " Cancer Research Platform Agreement to which you have already assented. PLEASE" +
    " read these NCI Supplemental Terms carefully. If you click [\"I ACCEPT\"], you assent to, and agree to be bound by, these" +
    " NCI Supplemental Terms and the National Cancer Institute's principles, policies and procedures for use of these" +
    " datasets in addition to the terms, covenants and conditions of Project Data Sphere Cancer Research Platform Agreement</em>."




export const ATTESTATION:IAttestation[] = [
{
    id: 1,
    title:"1. Use of NCI Data",
    sectionText:"The NCI Data is \"Data\" for purposes of the <em>Project Data Sphere</em>" +
          " Cancer Research Platform Agreement and is subject to all terms, covenants and conditions" +
          " of that Agreement. In addition to those terms, covenants and conditions, you may use the" +
          " datasets identified in your data request (\"NCI Data\") solely in connection with the research" +
          " plan that you submitted in your data request for NCI Data and subject to the exceptions in" +
          " Section III.B(ii) of the <em>Project Data Sphere</em> Cancer Research Platform Agreement," +
          " you are prohibited from disclosing the NCI Data to anyone other than an Authorized User from" +
          " your Institution who is included in the submitted NCI Data request. If you aggregate any NCI Data" +
          " with Data that is not subject to these NCI Supplemental Terms, you must use the resulting aggregated" +
          " Data subject to the terms, covenants and conditions of these NCI Supplemental Terms and the terms," +
          " covenants and conditions of <em>Project Data Sphere</em> Cancer Research Platform Agreement. If" +
          " your request for the use of NCI Data are the subject of a Freedom of Information Act (FOIA) request" +
          " then you must follow applicable federal disclosure laws and regulations."
    },

    {
    id: 2,
    title:"2.  Duration",
    sectionText:"Your right to access and use the NCI Data begins on the date that your data request for NCI" +
            " Data is approved and expires on (a) the third anniversary of the date NCI countersigns this agreement" +
            " or (b) any earlier date on which you use the NCI Data for purposes within the scope of your employment" +
            " by any Entity other than the Entity, if any, that you identify in your data request for NCI Data. Upon" +
            " the expiration of your right to use the NCI Data, you are obligated to destroy all copies of the NCI" +
            " Data, and all derivatives that contain individual-level information (for example, you are obligated" +
            " to shred any copies of any of the NCI Data at the time you destroy your electronic copies). If your" +
            " research requires you to retain the rights to access and use any NCI Data after the expiration of" +
            " those rights, you must submit a new data request for NCI Data with an accompanying research plan," +
            " updated as appropriate, and you must again assent to these NCI Supplemental Terms."
    },

    {
    id: 3,
    title:"3.  Publication of Research",
    sectionText:
            "<ul class='no-decoration' >" +
            "<li>a.   <u><em>Attribution</u></em></li>" +
            "<p class='extra-text'>The <em>Project Data Sphere</em> Cancer Research Platform Agreement obligates" +
            " you to" +
            " acknowledge the <em>Project Data Sphere</em> Cancer Research Platform in any publication or presentation" +
            " of research results that are based on your use of any Data. In addition to that obligation, you must" +
            " acknowledge the National Cancer Institute's NCTN/NCORP Data Archive in any publication or presentation" +
            " of research results that are based on your use of any NCI Data. You must use the following acknowledgement," +
            " INSTEAD OF the acknowledgment specified in the <em>Project Data Sphere</em> Cancer Research Platform Agreement," +
            " to satisfy your acknowledgment obligations:</p>" +
            "<p class='extra-text'>This [publication or presentation, as applicable] is based on information obtained from" +
            "<a href= \"https://data.projectdatasphere.org\"  target={\"_blank\"}>https://data.projectdatasphere.org,</a> " +
            " which is maintained by <em>Project Data Sphere</em>, and includes" +
            " information that has been made available by the National Cancer Institute and is also available through" +
            " the National Clinical Trials Network (NCTN)/NCI Community Oncology Research Program (NCORP) Data Archive." +
            " The information was collected from the following clinical trials: [include trial titles and, where" +
            " applicable, NCT numbers]. All analyses and conclusions in this [publication or presentation] are" +
            " the sole responsibility of the authors and do not necessarily reflect the opinions of the owners of the" +
            " information, the clinical trial investigators, the NCTN, the NCORP, the National Cancer Institute," +
            " or <em>Project Data Sphere</em>. Neither the owners of the information, the clinical trial investigators," +
            " the NCTN, the NCORP, the National Cancer Institute, nor <em>Project Data Sphere</em> have contributed" +
            " to, approved or are in any way responsible for the contents of this [publication/presentation]." +
            "</p>"+
            "<li>b.   <u><em>Submission for Review</u></em></li>" +
            "<p class='extra-text'>If any NCI Data that you used in preparation of a publication was identified at the" +
            " time of your data request as data that was collected under a binding collaborative agreement," +
            " you are obligated to submit the manuscript, including revised manuscripts being submitted to a different" +
            " journal, at least thirty (30) days before you submit the manuscript for publication, directly to the" +
            " National Cancer Institute at " +
            "<a href='mailto:NCINCTNDataArchive@mail.nih.gov'>NCINCTNDataArchive@mail.nih.gov</a></p>" +
            " which will forward your manuscript to each collaborator whose information you have used for a thirty (30)" +
            " day review and comment period. Abstracts and other public releases or public presentations should also be" +
            " sent five (5) business days prior to submission or release for forwarding to the collaborator for a three" +
            " (3) business day review and comment period.</p>"+
            "<li>c.   <u><em>Publication Report</u></em></li>" +
            "<p class='extra-text'>If you use any NCI Data in the preparation of any manuscript, you are obligated to" +
            " report the PubMed ID number and a copy of the publication directly to the National Cancer Institute at" +
            " NCINCTNDataArchive@mail.nih.gov within sixty (60) days of publication.<p>"+
            "</ul>"
    },

    {
    id: 4,
    title: "4. Third-Party Beneficiaries",
    sectionText:
            "<p class='extra-text'>You and Project Data Sphere are parties to the agreement represented by the" +
            " <em>Project Data Sphere</em> Cancer Research Platform Agreement and these NCI Supplemental Terms. You acknowledge" +
            " and agree that each licensor of technology or Data, including NCI Data, to <em>Project Data Sphere</em>," +
            " ProJect Data Sphere's hosting service provider, any affiliate of any of the foregoing, or to the" +
            " National Cancer Institute is a third-party beneficiary of the agreement represented by the" +
            " <em>Project Data Sphere</em. Cancer Research Platform Agreement and these NCI Supplemental Terms with the" +
            " right to enforce the terms, covenants and conditions in its own name. This includes each collaborator that" +
            " has signed a binding collaborative agreement with NCI or the Network Group that supported the NCI Data." +
            "<p>"
    },

];

