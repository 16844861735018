/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: RelatedDataSources
 *
 * Purpose: Related Data Sources  for Share Data (Real World Data)
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 28Aug2023 sasjxa file created.
 * 20Oct2023 sasjxa add handler to interface for saving value
 \******************************************************************************/
import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import {FormSectionTitle} from "../styled/StyledComponents";


interface RelatedDataSourcesProps {
    value: any,
    valueHandler: any,
    disabled: boolean,
}

export const RelatedDataSources: React.FC<RelatedDataSourcesProps> =
    ({
         value, valueHandler, disabled
     }) => {
        return (
            <>
                <FormSectionTitle>Related Data Sources</FormSectionTitle>
                <Row className="mt-1 mb-3">
                    <Form.Group as={Col} className=""
                                controlId="">

                        <Form.Label className="bold-label" column="sm">Other RWD such as genomics this data can link
                            to) </Form.Label>
                        <Form.Control size="sm"
                                      name={"relatedDataSources"}
                                      type="text"
                                      disabled={disabled}
                                      value={value}
                                      onChange={(e) => {
                                          valueHandler(e.target.value)
                                      }}/>
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                </Row>
            </>
        )

    }