/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Regions
 *
 * Purpose:  Regions for Share Data
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 30Aug2023 sasjxa File created.
 * 19Jun2024 sasjxa remove labelClassName parameter
 \******************************************************************************/
import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import {ErrorMessage} from "formik";
import {AGE_RANGE} from "../../data/formList";
import {labelIfReadOnly} from "../../constants/CommonFunctions";


interface AgeRangeProps {
    value: any,
    valueHandler: any,
    listHandler: any,
    disabled: boolean,
    touched: any,
    errors: any
}


export const AgeRange: React.FC<AgeRangeProps> =
    ({
          value,  valueHandler, listHandler,
           disabled, touched, errors

     }) => {
        return (
            <>
                <Row className="mt-3 mb-3 ">
                    <Form.Group as={Col} lg={6}
                                controlId="formAgeRange">
                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Age Ranges(s) </Form.Label>
                        <Form.Select size="sm" required multiple={true}
                                     name={"ageRange"}
                                     value = {value}
                                     disabled = {disabled}
                                     isValid={touched.ageRange && !errors.ageRange}
                                     onChange={(e) => {
                                         const options = e.target.options;
                                         let value = [];
                                         for (let i = 0, l = options.length; i < l; i++) {
                                             if (options[i].selected) {
                                                 value.push(options[i].value);
                                             }
                                         }
                                         valueHandler(value)
                                     }}>
                            {listHandler(AGE_RANGE)}
                        </Form.Select>
                        <Form.Text id="studyPhasesHelpBlock" muted>
                            Ctrl + click to select multiple
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                            Please select a study phase.
                        </Form.Control.Feedback>
                        <ErrorMessage name={"ageRange"}
                                      render={msg => <div
                                          className={"form-error-msg"}>{msg}</div>}/>
                    </Form.Group>
                </Row>
            </>
        )

    }