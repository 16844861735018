/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Share Dataset
 *
 * Purpose: Share Dataset page
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 11May2023 sasjxa file created
 * 23May2023 sasjxa add character count for text area
 * 31May2023 sasjxa add values to select components
 * 02Jun2023 sasjxa support for displaying other fields
 * 23Jun2023 sasjxa refactor to use new aside component
 * 10Oct2023 sasjxa set initial value for numeric fields to 0
 * 20Oct2023 sasjxa modify validators for comparator and active arms
 * 23Feb2024 sasjxa add clear button functionality (MPM-5425)
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 12Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 04Apr2024 craig  switched to react-error-boundary
 * 19Jun2024 sasjxa remove label classname parm from specialized components
 * 20Jun2024 sasjxa correct navigate link
 * 02Jul2024 craig  moved decode to api
 * 04Nov2024 sasjxa added yup max to text fields
 \******************************************************************************/
import React, {useMemo, useRef, useState} from "react";
import {Col, Container, Form} from "react-bootstrap";
import * as Yup from 'yup';
import {Formik, FormikProps} from "formik";
// @ts-ignore
import styled from 'styled-components';
import {
    ContentPage,
    FormLayoutColumn,
    FormSectionTitle,
    PageWrapper,
    PDS_H5,
    PDS_P
} from "../../components/styled/StyledComponents";

import "../forms/forms.css";
import {useNavigate, useParams} from "react-router-dom";
import {
    COMPARATOR_ONLY,
    EXPERIMENTAL_ONLY,
    FormList,
    max2000Count,
    max5000Count,
    OTHER_OPTION
} from "../../data/formList";

import {Aside} from "../../components/Aside";
import {useRequest} from "../../helper/useRequest";
import {IDonationMetadata} from "../../model/donations/IDonations";
import {BackClearAndSubmitButtonContainer} from "../../components/buttonContainer/BackClearAndSubmitButtonContainer";
import {DataDisplayType} from "../../components/share/DataDisplayType";
import {StudyTypes} from "../../components/share/StudyTypes";
import {TumorTypes} from "../../components/share/TumorTypes";
import {TextFieldRequired} from "../../components/forms/TextFieldRequired";
import {TextAreaRequired} from "../../components/forms/TextAreaRequired";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";
import {enableClearButton, getEncodedValue} from "../../constants/CommonFunctions";
import {
    activeDesc,
    COMPARATOR_ENROLLED_ERROR_MSG,
    comparatorDesc,
    dataSummaryDesc,
    EXPERIMENTAL_ENROLLED_ERROR_MSG,
    objectivesDesc,
    TOTAL_ENROLLED_ERROR_MSG
} from "../../constants/ShareDataFieldsText";


export const ShareDataset: React.FC = () => {
    const {donationId} = useParams();
    const navigate = useNavigate();
    const { showBoundary } = useErrorBoundary();
    const [studyObjectivesCount, setStudyObjectivesCount] = useState(0);
    const [dataSummaryCount, setDataSummaryCount] = useState(0);
    const [showTumorTypesOther, setShowTumorTypesOther] = useState(false);
    const [showComparatorArm, setShowComparatorArm] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [showActiveArm, setShowActiveArm] = useState(false);
    const [dataSummary, setDataSummary] = useState("");
    const [studyObjectives, setStudyObjectives] = useState("");
    const [displayType, setDisplayType] = useState("");
    const [sampleSize, setSampleSize] = useState("");
    const [comparatorSize, setComparatorSize] = useState("");
    const [activeSize, setActiveSize] = useState("");
    const [tumorTypeOther, setTumorTypeOther] = useState("");
    const [selectedStudyType, setSelectedStudyType] = useState<string[]>([]);
    const [selectedTumorType, setSelectedTumorType] = useState<string[]>([]);
    const numericRegExp = /^[0-9]+$/
    const formikRef = useRef<FormikProps<any>>(null);
    const [shareData, setShareData] = useState<IDonationMetadata>();


    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    // handle successful save of data
    const handleSuccess = () => {
        navigate(BASE_PATH + 'ShareDatasetSuppInfo/' + donationId);
    }

    // get data
    const shareDataUrl = process.env.REACT_APP_API_URL + "/api/share/shareDataset/" + donationId;
    const [requestState, getShareDataData] = useRequest({
        url: shareDataUrl,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })
    const {isLoading, data, error} = requestState;

    // save data
    const [requestActionState, setShareDataset] = useRequest({
        url: shareDataUrl,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })

    useMemo(() => {
        if ((donationId !== undefined && Number(donationId) > 0)) {
            getShareDataData();
        }
    }, [])

    useMemo(() => {
        if (data != null) {
            setShareData(data);
        }
    }, [data])

    useMemo(() => {
        if (shareData != null) {
            setDataSummary(shareData.dataSummary);
            setStudyObjectives(shareData.studyObjectives);
            setDisplayType(shareData.dataDisplayType);
            setSampleSize(shareData.sampleSize != null ? shareData.sampleSize.toString() : "");
            setComparatorSize(shareData.comparatorArmPatients != null ? shareData.comparatorArmPatients.toString() : "");
            setActiveSize(shareData.activeArmPatients != null ? shareData.activeArmPatients.toString() : "");
            setTumorTypeOther(shareData.tumorTypeOther);
            if (shareData.dataShareAgreement?.dataTypes != COMPARATOR_ONLY) {
                setShowActiveArm(true);
            }
            if (shareData.dataShareAgreement?.dataTypes != EXPERIMENTAL_ONLY) {
                setShowComparatorArm(true);
            }
            //------------------
            let tmpStudyTypes = new Array<string>();
            if (shareData.studyType != null && shareData.studyType.length > 0) {
                shareData.studyType.map((t: any) => tmpStudyTypes.push(t));
            }
            setSelectedStudyType(tmpStudyTypes);
            //-------------------------

            //------------------
            let tmpTumorTypes = new Array<string>();
            if (shareData.tumorType != null && shareData.tumorType.length > 0) {
                shareData.tumorType.map((t: any) => tmpTumorTypes.push(t));
            }
            setSelectedTumorType(tmpTumorTypes);
            //-------------------------

            if (shareData.tumorTypeOther != null && shareData.tumorTypeOther != "") {
                setShowTumorTypesOther(true);
            }
        }
    }, [shareData])

    // validation schema
    const baseSchema = Yup.object().shape({
        dataSummary: Yup.string().required("Please provide a data summary."),
        studyObjectives: Yup.string().required("Please provide objectives."),
        sampleSize: Yup.string().required(TOTAL_ENROLLED_ERROR_MSG).matches(numericRegExp, TOTAL_ENROLLED_ERROR_MSG).max(10, "Sample size should be no longer than 10 characters."),
        studyType: Yup.array()
            .of(Yup.string())
            .min(1, 'Please select a study phase.')
            .required('Please select a study phase.'),
        tumorType: Yup.array()
            .of(Yup.string())
            .min(1, 'Please select a tumor type.')
            .required('Please select a tumor type.'),
        tumorTypeOther: Yup.string()
            .max(200, "Tumor Type should be no longer than 200 characters.")
            .when(['tumorType'], {
            is: (tumorType: string[]) => {
                if (tumorType == null) {
                    return false
                } else if (tumorType.includes(OTHER_OPTION)) {
                    return true // validate and go to then function
                } else return false // for all other cases is not required
            },
            then: (schema) =>
                schema
                    .required("Please enter a tumor type")
        }),

    })

    // validation schema when active arm is present
    const activeArmSchema = baseSchema.concat(
        Yup.object().shape({
            activeArmPatients: Yup.string().required(TOTAL_ENROLLED_ERROR_MSG).matches(numericRegExp, EXPERIMENTAL_ENROLLED_ERROR_MSG).max(10, "Active arm should be no longer than 10 characters."),

        }));

    // validation schema when comparator arm is present
    const comparatorArmSchema = baseSchema.concat(
        Yup.object().shape({
            comparatorArmPatients: Yup.string().required(TOTAL_ENROLLED_ERROR_MSG).matches(numericRegExp, COMPARATOR_ENROLLED_ERROR_MSG).max(10, "Comparator arm should be no longer than 10 characters."),
        }));

    // validation schema when both arms are present
    const bothArmsSchema = baseSchema.concat(
        Yup.object().shape({
            comparatorArmPatients: Yup.string().required(TOTAL_ENROLLED_ERROR_MSG).matches(numericRegExp, COMPARATOR_ENROLLED_ERROR_MSG).max(10, "Comparator arm should be no longer than 10 characters."),
            activeArmPatients: Yup.string().required(TOTAL_ENROLLED_ERROR_MSG).matches(numericRegExp, EXPERIMENTAL_ENROLLED_ERROR_MSG).max(10, "Active arm should be no longer than 10 characters.")

        }));


    // return the validation schema based on the arm value
    const getValidationSchema = () => {
        if (showComparatorArm && showActiveArm) {
            return bothArmsSchema;
        } else if (showComparatorArm) {
            return comparatorArmSchema;
        } else if (showActiveArm) {
            return activeArmSchema;
        } else return baseSchema;
    }

    // clear form fields
    const handleReset = () => {
        if (formikRef != null && formikRef.current) {
            formikRef.current.resetForm();
            reinitializeValues();
        }
    }

    // re-initialize values after clear
    const reinitializeValues = () => {
        setDataSummary("");
        setStudyObjectives("");
        setDisplayType("");
        setSampleSize( "");
        setComparatorSize( "");
        setActiveSize( "");
        setTumorTypeOther("");
        setSelectedStudyType( new Array<string>());
        setSelectedTumorType( new Array<string>());
        setShowTumorTypesOther(false);
    }

    // show other field when 'Other" selected from list
    const handleTumorTypesChanged = (value: string) => {
        if (value.includes(OTHER_OPTION)) {
            setShowTumorTypesOther(true);
        } else if (showTumorTypesOther) {
            setShowTumorTypesOther(false);
        }
    }

    // build select list
    const buildSelectList = (listType: FormList[]) => {
        let optionTemplate = listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
        return optionTemplate;
    }


    const buildForm = () => {
        return (
            <Formik  innerRef={formikRef}
                enableReinitialize
                initialValues={{
                    dataSummary: dataSummary ?? '',
                    studyObjectives: studyObjectives ?? '',
                    sampleSize: sampleSize ?? '',
                    comparatorArmPatients: comparatorSize ?? '',
                    activeArmPatients: activeSize ?? '',
                    dataDisplayType: displayType ?? '',
                    studyType: selectedStudyType ?? '',
                    tumorType: selectedTumorType ?? '',
                    tumorTypeOther: tumorTypeOther ?? '',
                }}

                validationSchema={getValidationSchema}
                onSubmit={(values) => {
                    setShareDataset(JSON.stringify({
                        dataSummary: getEncodedValue(values.dataSummary),
                        studyObjectives:  getEncodedValue(values.studyObjectives),
                        sampleSize: values.sampleSize,
                        comparatorArmPatients: values.comparatorArmPatients,
                        activeArmPatients: values.activeArmPatients,
                        dataDisplayType: values.dataDisplayType,
                        studyType: values.studyType,
                        tumorType: values.tumorType,
                        tumorTypeOther: values.tumorTypeOther
                    }))
                }}

                validateOnChange={false}
                validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      resetForm,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn className="mt-5 mb-5" lg={12}>
                            <FormLayoutColumn lg={8}>
                                <FormSectionTitle>Descriptive Information</FormSectionTitle>

                                <TextAreaRequired fieldName={"dataSummary"} label={"Data Summary"}
                                                  value={dataSummary} valueHandler={setDataSummary}
                                                  currentCharCount={dataSummaryCount} maxTextAreaCount={max2000Count}
                                                  countHandler={setDataSummaryCount} disabled={disabled} touched={touched} errors={errors}
                                                  handleChange={handleChange} formText={dataSummaryDesc}/>

                                <TextAreaRequired fieldName={"studyObjectives"} label={"Objectives"}
                                                  value={studyObjectives} valueHandler={setStudyObjectives}
                                                  currentCharCount={studyObjectivesCount} maxTextAreaCount={max5000Count}
                                                  countHandler={setStudyObjectivesCount} disabled={disabled} touched={touched} errors={errors}
                                                  handleChange={handleChange} formText={objectivesDesc}/>

                                <FormSectionTitle>Design Information</FormSectionTitle>
                                <TextFieldRequired fieldName={"sampleSize"} label="Total Study Enrolled Patients"
                                                   value={sampleSize}
                                                   valueHandler={setSampleSize} disabled={disabled} touched={touched}
                                                   errors={errors} size={6}/>


                                {showComparatorArm && (
                                    <TextFieldRequired fieldName={"comparatorArmPatients"}
                                                       label="Comparator (Control) Arm Enrolled Patients" value={comparatorSize}
                                                       valueHandler={setComparatorSize} disabled={disabled}
                                                       touched={touched} errors={errors}
                                                       formText={comparatorDesc} size={6}/>
                                )}
                                {showActiveArm && (
                                    <TextFieldRequired fieldName={"activeArmPatients"}
                                           label="Experimental (Active) Arm Enrolled Patients" value={activeSize}
                                                       valueHandler={setActiveSize} disabled={disabled}
                                                       touched={touched} errors={errors}
                                                       formText={activeDesc} size={6}/>
                                )}
                                <DataDisplayType value={displayType}
                                                 valueHandler={setDisplayType} listHandler={buildSelectList}
                                                 disabled={disabled} handleChange={handleChange} touched={touched}
                                                 errors={errors}/>
                                <StudyTypes  value={selectedStudyType}
                                            valueHandler={setSelectedStudyType} listHandler={buildSelectList}
                                            disabled={disabled} touched={touched} errors={errors}/>
                                <TumorTypes  value={selectedTumorType}
                                            valueHandler={setSelectedTumorType}
                                            handleValueChanged={handleTumorTypesChanged}
                                            listHandler={buildSelectList}
                                            disabled={disabled} touched={touched} errors={errors}/>


                                {showTumorTypesOther && (
                                    <TextFieldRequired fieldName={"tumorTypeOther"}
                                    label="Other Tumor Types (multiple entries include ','"
                                    value={tumorTypeOther}
                                    valueHandler={setTumorTypeOther} disabled={disabled} touched={touched}
                                    errors={errors} size={11}/>
                                )}

                            </FormLayoutColumn>
                        </FormLayoutColumn>
                        <BackClearAndSubmitButtonContainer handleReset={handleReset} enableClear={enableClearButton(donationId)} backNavigate={"ShareDatasetBasics/" + donationId}
                        />
                    </Form>

                )}
            </Formik>
        )
    }


    return (
        <Container className="form-container" fluid>

            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn md={8} lg={9} name={"formLayoutColumn"}>
                        <PDS_H5>Trial Data/Information - Dataset Form - Page 2 of 3</PDS_H5>
                        <PDS_P>Please provide basic details for your data contribution including key information
                            such as objectives, unique keys, and volume of records. We accept many supporting data files
                            so
                            please offer an explanation for any composite or curations within the 'data summary'
                            section.
                        </PDS_P>
                        {isLoading && <p>Loading ...</p>}
                        {error && <p>Error</p>}
                        {shareData && buildForm()}
                    </FormLayoutColumn>
                    <Col md={4} lg={3}>
                        <Aside needHelp={true} requiredFields={true}/>
                    </Col>
                </PageWrapper>
            </ContentPage>


        </Container>
    );

}
