/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminUserInfo
 *
 * Purpose: display user information
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 23Aug2023 craig  file created
 * 30Oct2023 sasjxa add components
 * 12Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 30Apr2024 sasjxa switched to react-error-boundary
 * 14May2024 sasjxa add decoding for sector other
 * 04Jul2024 craig  removed decode
 \******************************************************************************/
import React, {useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {useRequest} from "../../helper/useRequest";
import {IFullUser} from "../../model/user/IFullUser";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {ContentPage, FormLayoutColumn, PageWrapper} from "../../components/styled/StyledComponents";
import {useErrorBoundary} from "react-error-boundary";


export const AdminUserInfo: React.FC = () => {
    const {userId} = useParams();
    const {showBoundary} = useErrorBoundary();
    const [userInfo, setUserInfo] = useState<IFullUser>();
    const [disabled, setDisabled] = useState(true);
    const [showAffiliationOther, setShowAffiliationOther] = useState(false);
    const [showRoleOther, setShowRoleOther] = useState(false);
    const [showReasonForVisitingOther, setShowReasonForVisitingOther] = useState(false);
    const [showLearnAboutPDSOther, setShowLearnAboutPDSOther] = useState(false);
    const TERMS_OF_USE: string = "I have read the Project Data Sphere " +
        "agreement in full.I agree to comply with the conditions as stated in the agreement.";

    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);    }


    // initialize fields after successful retrieve of data
    const handleGetUserInfo = (userInfo: IFullUser) => {
        if (userInfo !== null && userInfo.userDetails != null) {
            setUserInfo(userInfo);
        }
    }

    // get data
    const userInfoURL = process.env.REACT_APP_API_URL + "/api/admin/users/userInfo/" + userId;
    const [requestState, getUserInfo] = useRequest({
        url: userInfoURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleGetUserInfo
    })
    const {isLoading, data, error} = requestState;

    useMemo(() => {
        if (userId !== undefined) {
            getUserInfo();
        }
    }, [])

    useMemo(() => {
        if (data != null) {
            if (data.userDetails?.sectorOther != null) {
                setShowAffiliationOther(true);
            }
            if (data.userDetails?.roleOther != null) {
                setShowRoleOther(true);
            }
            if (data.userDetails?.reasonForVisitingOther != null) {
                setShowReasonForVisitingOther(true);
            }
            if (data.userDetails?.learnAboutPDSOther != null) {
                setShowLearnAboutPDSOther(true);
            }
            setUserInfo(data);
        }
    }, [data])

    const buildUserInfo = () => {
        return (
            <FormLayoutColumn className="mt-5 mb-5" lg={12}>
                <FormLayoutColumn lg={8}>
                    <Row className="mt-3 mb-3">
                        <Form.Group as={Col} lg={6} controlId="formfirstName">
                            <Form.Label className="bold"
                                        column="sm">First Name </Form.Label>
                            <Form.Control size="sm"
                                          name={"first"}
                                          type="text"
                                          disabled={disabled}
                                          defaultValue={userInfo?.userDetails?.first}
                            />
                        </Form.Group>
                        <Form.Group as={Col} lg={6} controlId="formLastName">
                            <Form.Label className="bold" column="sm">Last
                                Name </Form.Label>
                            <Form.Control size="sm" required
                                          name={"last"}
                                          type="text"
                                          disabled={disabled}
                                          defaultValue={userInfo?.userDetails?.last}/>
                        </Form.Group>
                    </Row>

                    <Row className="mt-3 mb-3 ">
                        <Form.Group as={Col} controlId="formEmail">
                            <Form.Label className="bold" column="sm">Email
                                Address </Form.Label>
                            <Form.Control size="sm" required
                                          name={"email"}
                                          type="email"
                                          disabled={disabled}
                                          defaultValue={userInfo?.userDetails?.email}/>
                        </Form.Group>
                    </Row>


                    <Row className="mt-3 mb-3 ">
                        <Form.Group as={Col} lg={6} controlId="formPhone">
                            <Form.Label className="bold"
                                        column="sm">Phone </Form.Label>
                            <Form.Control size="sm" required
                                          name={"phone"}
                                          type="text"
                                          defaultValue={userInfo?.userDetails?.phoneNumber}
                                          disabled={disabled}/>

                        </Form.Group>
                    </Row>

                    <Row className="mt-3 mb-3 ">
                        <Form.Group as={Col} lg={6}
                                    controlId="formAffiliation">
                            <Form.Label className="bold"
                                        column="sm">Affiliation </Form.Label>

                            <Form.Control size="sm"
                                          name={"affilation"}
                                          type="text"
                                          defaultValue={userInfo?.userDetails?.sector}
                                          disabled={disabled}/>
                        </Form.Group>
                    </Row>

                    {showAffiliationOther && (
                        <Row className="mt-3 mb-3 ">
                            <Form.Group as={Col} className={"otherFieldGroup"} lg={6}
                                        controlId="formAffiliationOther">
                                <Form.Label className="bold" column="sm">Other
                                    Affiliation </Form.Label>
                                <Form.Control size="sm" required
                                              name={"affiliationOther"}
                                              type="text"
                                              defaultValue={userInfo?.userDetails?.sectorOther}
                                              disabled={disabled}/>
                            </Form.Group>
                        </Row>
                    )}

                    <Row className="mt-3 mb-3">
                        <Form.Group as={Col} className=""
                                    controlId="formOrganization">
                            <Form.Label className="bold"
                                        column="sm">Organization </Form.Label>
                            <Form.Control size="sm" required
                                          name={"organization"}
                                          type="text"
                                          defaultValue={userInfo?.userDetails?.organization}
                                          disabled={disabled}/>
                        </Form.Group>
                    </Row>

                    <Row className="mt-3 mb-3 ">
                        <Form.Group as={Col} lg={6}
                                    controlId="formRole">
                            <Form.Label className="bold"
                                        column="sm">Role </Form.Label>


                            <Form.Control size="sm"
                                          name={"role"}
                                          type="text"
                                          defaultValue={userInfo?.userDetails?.role}
                                          disabled={disabled}/>

                        </Form.Group>
                        <Form.Group as={Col} lg={6}
                                    controlId="formDepartment">
                            <Form.Label className="bold"
                                        column="sm">Department </Form.Label>
                            <Form.Control size="sm" required
                                          name={"department"}
                                          type="text"
                                          defaultValue={userInfo?.userDetails?.department}
                                          disabled={disabled}/>
                        </Form.Group>
                    </Row>

                    {showRoleOther && (
                        <Row className="mt-3 mb-3 ">
                            <Form.Group as={Col} className={"otherFieldGroup"} lg={6}
                                        controlId="formRoleOther">
                                <Form.Label className="bold" column="sm">Other
                                    Role </Form.Label>
                                <Form.Control size="sm"
                                              name={"roleOther"}
                                              type="text"
                                              defaultValue={userInfo?.userDetails?.roleOther}
                                              disabled={disabled}/>
                            </Form.Group>
                        </Row>
                    )}

                    <Row className="mt-3 mb-3">
                        <Form.Group as={Col} lg={6} className=""
                                    controlId="formLinkedInURL">
                            <Form.Label className="bold" column="sm">LinkedIn Profile URL </Form.Label>
                            <Form.Control size="sm"
                                          name={"linkedInURL"}
                                          type="text"
                                          defaultValue={userInfo?.userDetails?.linkedinProfileURL}
                                          disabled={disabled}/>
                        </Form.Group>
                    </Row>

                    <Row className="mt-3 mb-3">
                        <Form.Group as={Col}
                                    controlId="formStreetAddress">
                            <Form.Label className="bold" column="sm">Street
                                Address </Form.Label>
                            <Form.Control size="sm" required
                                          name={"streetAddress"}
                                          type="text"
                                          defaultValue={userInfo?.userDetails?.address}
                                          disabled={disabled}/>
                        </Form.Group>
                    </Row>

                    <Row className="mt-3 mb-3">
                        <Form.Group as={Col} lg={5} controlId="formCity">
                            <Form.Label className="bold"
                                        column="sm">City </Form.Label>
                            <Form.Control size="sm" required
                                          name={"city"}
                                          type="text"
                                          defaultValue={userInfo?.userDetails?.city}
                                          disabled={disabled}/>
                        </Form.Group>
                        <Form.Group as={Col} lg={3} controlId="formState">
                            <Form.Label className="bold"
                                        column="sm">State </Form.Label>
                            <Form.Control size="sm" required
                                          name={"state"}
                                          type="text"
                                          defaultValue={userInfo?.userDetails?.state}
                                          disabled={disabled}/>
                        </Form.Group>
                        <Form.Group as={Col} lg={4} controlId="formPostalCode">
                            <Form.Label className="bold" column="sm">Postal
                                Code </Form.Label>
                            <Form.Control size="sm"
                                          name={"postalCode"}
                                          type="text"
                                          defaultValue={userInfo?.userDetails?.postalCode}
                                          disabled={disabled}/>
                        </Form.Group>
                    </Row>

                    <Row className="mt-3 mb-3">
                        <Form.Group as={Col} lg={6}
                                    controlId="formCountry">
                            <Form.Label className="bold" column="sm">Country </Form.Label>
                            <Form.Control size="sm"
                                          name={"country"}
                                          type="text"
                                          defaultValue={userInfo?.userDetails?.country}
                                          disabled={disabled}/>
                        </Form.Group>
                    </Row>

                    <Row className="mt-4 mb-3">
                        <Form.Group as={Col} lg={6} controlId="formReasonForVisiting">
                            <Form.Label className="bold" column="sm">What is your
                                main reason for
                                visiting? </Form.Label>


                            <Form.Control size="sm"
                                          name={"dataProvider"}
                                          type="text"
                                          defaultValue={userInfo?.userDetails?.reasonForVisiting}
                                          disabled={disabled}/>

                        </Form.Group>
                    </Row>

                    {showReasonForVisitingOther && (
                        <Row className="mt-4 mb-5 ">
                            <Form.Group as={Col} className={"otherFieldGroup"} lg={6}
                                        controlId="formReasonForVisitingOther">
                                <Form.Label className="bold" column="sm">Other Reason
                                    For
                                    Visiting </Form.Label>
                                <Form.Control size="sm" required
                                              name={"reasonForVisitingOther"}
                                              type="text"
                                              defaultValue={userInfo?.userDetails?.reasonForVisitingOther}
                                              disabled={disabled}/>


                            </Form.Group>
                        </Row>
                    )}

                    <Row className="mt-4 mb-3">
                        <Form.Group as={Col}
                                    controlId="formLearnAbout">
                            <Form.Label column="sm">How did you learn about Project Data
                                Sphere?
                            </Form.Label>
                            <Form.Control size="sm"
                                          name={"dataProvider"}
                                          type="text"
                                          defaultValue={userInfo?.userDetails?.learnAboutPDS}
                                          disabled={disabled}/>
                            <Form.Text className="text-muted"></Form.Text>
                        </Form.Group>
                    </Row>

                    {showLearnAboutPDSOther && (
                        <Row className="mt-3 mb-3 ">
                            <Form.Group as={Col} className={"otherFieldGroup"} lg={11}
                                        controlId="formLearnAboutPDSOther">
                                <Form.Label column="sm">Please describe in more detail </Form.Label>
                                <Form.Control size="sm" required
                                              name={"learnAboutPDSOther"}
                                              type="text"
                                              defaultValue={userInfo?.userDetails?.learnAboutPDSOther}
                                              disabled={disabled}/>
                                <Form.Text className="text-muted"></Form.Text>
                            </Form.Group>
                        </Row>
                    )}

                    <Row className="">
                        <Form.Group as={Col} controlId="formResearchGoals">

                            <Form.Label className="bold" column="sm">Research
                                Description &
                                Goals </Form.Label>
                            <Form.Control as="textarea" rows={5} size="sm" required
                                          name={"researchGoals"}
                                          type="text"
                                          defaultValue={userInfo?.userDetails?.proposedResearchGoals}
                                          disabled={disabled}/>
                        </Form.Group>
                    </Row>

                    <Row className="mt-3 mb-3 ">
                        <Form.Group as={Col} controlId="formTermsOfUse">
                            <Form.Control as="textarea" rows={2} size="sm"
                                          name={"dataProvider"}
                                          type="text"
                                          disabled={disabled}
                                          defaultValue={"Yes." + TERMS_OF_USE}/>
                        </Form.Group>
                    </Row>
                </FormLayoutColumn>
            </FormLayoutColumn>
        )
    }

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn md={8} lg={9} name={"formLayoutColumn"}>

                    </FormLayoutColumn>

                </PageWrapper>
                {isLoading &&
                    <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>}
                {error && <p>Error</p>}
                {data && buildUserInfo()}
            </ContentPage>

        </Container>
    )

}