
import {BASE_PATH} from "../../constants";


export interface IAside {
    title: string
    text : string,
    link?: string,

}

export const ABOUT_RESOURCES:IAside =
    {
        title: "About Our Resources",
        text: "These resources include user support, reference materials, and external links to help our " +
            "users and data providers get the most out of Project Data Sphere."
    };

export const CONTACT_US:IAside =
    {
        title: "Contact Us",
        text: "If you have questions our resources can't answer, or you feel you've missed a communication from us, " +
            "feel free to contact us at any time.",
        link: BASE_PATH + "contactUs"
    };

export const NEED_HELP:IAside =
    {
        title: "Need Help?",
        text: "The Resources page contains how-to guides and other information to assist you with this process",
        link: BASE_PATH + "resources"
    };

export const REQUIRED_FIELDS:IAside =
    {
        title: "Required Fields",
        text: "Required fields are collected to gain an understanding of the user community and to enhance future " +
            "versions of the platform.<span style='margin-top: 1rem' > Required fields are marked with a red asterisk. *</span>"


    };

export const PREPARE_INFORMATION:IAside =
    {
        title: "Prepare your information",
        text: "Prior to completing the Data Sharing Agreement and data upload forms, please visit the Resources page " +
            "to review the information necessary to complete these forms.",

    };

export const NEW_ORGANIZATION:IAside =
    {
        title: "New Organization or Email?",
        text: "<b>If your organization or email address have changed since you created your Project Data Sphere account, please contact us.</b>" +
            " .<p style='margin-top: 1rem' > You may need to register for a new Project Data Sphere account before you can share data.</p>"


    };


