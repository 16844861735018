/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: DataStandard
 *
 * Purpose: Data Standard for Share Data
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 28Aug2023 sasjxa file created
 * 20Oct2023 sasjxa correct title
 * 19Jun2024 sasjxa remove labelClassName parameter
 \******************************************************************************/
import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import {ErrorMessage} from "formik";
import {DATASET_STANDARD} from "../../data/formList";
import {labelIfReadOnly} from "../../constants/CommonFunctions";


interface DataStandardProps {
    value: any,
    valueHandler: any,
    listHandler: any,
    disabled: boolean,
    handleChange: any,
    touched: any,
    errors: any,
}


export const DataStandard: React.FC<DataStandardProps> =
    ({
          value,  valueHandler, listHandler, disabled,
          touched, errors
     }) => {
        return (
            <Row className="mt-1 mb-4 ">
                <Form.Group as={Col} lg={6} className="dataType"
                            controlId="dataStandard">
                    <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Data
                        Standard </Form.Label>
                    {!disabled && (
                        <Form.Select size="sm" required
                                     name={"dataStandard"}
                                     value={value}
                                     isValid={touched.dataStandard && !errors.dataStandard}
                                     onChange={(e) => {valueHandler(e.target.value)}}>
                            {listHandler(DATASET_STANDARD)}
                        </Form.Select>
                    )}
                    {disabled && (
                        <Form.Control size="sm"
                                      name={"dataStandard"}
                                      type="text"
                                      disabled={disabled}
                                      defaultValue = {value}
                                      />
                    )}

                    <Form.Control.Feedback type="invalid">
                        Please select a data type.
                    </Form.Control.Feedback>
                    <ErrorMessage name={"dataStandard"}
                                  render={msg => <div
                                      className={"form-error-msg"}>{msg}</div>}/>
                </Form.Group>
            </Row>

        )

    }