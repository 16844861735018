/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: FirstLastName
 *
 * Purpose: First and Last Name
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 27Nov2023 sasjxa file created
 * 08Dec2023 sasjxa add parm for change handler
 * 04Nov2024 craig  Added maxlength to field
 \******************************************************************************/
import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import {ErrorMessage} from "formik";


interface FirstLastNameProps {
    first: string,
    last: any,
    disabled: boolean,
    touched: any,
    errors: any,
    handleChange: any,
}


export const FirstLastName: React.FC<FirstLastNameProps> =
    ({
         first,last, disabled, touched, errors, handleChange
     }) => {
        return (
            <>
                <Row className="mt-3 mb-3">
                    <Form.Group as={Col} lg={6} controlId="formfirstName">
                        <Form.Label className="required"
                                    column="sm">First Name </Form.Label>
                        <Form.Control size="sm" required
                                      name={"first"}
                                      type="text"
                                      defaultValue={first}
                                      disabled={disabled}
                                      isValid={touched.first && !errors.first}
                                      onChange={handleChange}/>
                        <ErrorMessage name={"first"}
                                      render={msg => <div
                                          className={"form-error-msg"}>{msg}</div>}/>
                    </Form.Group>
                    <Form.Group as={Col} lg={6} controlId="formLastName">
                        <Form.Label className="required" column="sm">Last
                            Name </Form.Label>
                        <Form.Control size="sm" required
                                      name={"last"}
                                      type="text"
                                      defaultValue={last}
                                      disabled={disabled}
                                      isValid={touched.last && !errors.last}
                                      onChange={handleChange}/>
                        <Form.Text className="text-muted"></Form.Text>
                        <Form.Control.Feedback type="invalid">
                            Please provide a last name.
                        </Form.Control.Feedback>
                        <ErrorMessage name={"last"}
                                      render={msg => <div
                                          className={"form-error-msg"}>{msg}</div>}/>
                    </Form.Group>
                </Row>
            </>
        )

    }