/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Share Dataset Basics
 *
 * Purpose: Share Dataset Basics page for Real World Data
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 11May2023 sasjxa file created
 * 31May2023 sasjxa add values to select components
 * 23Jun2023 sasjxa refactor to use new button container
 * 28Aug2023 sasjxa changes to accommodate form pre-loaded with data
 * 20Oct2023 sasjxa add related data sources to saved fields
 * 23Feb2024 sasjxa add clear button functionality (MPM-5425)
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 12Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 04Apr2024 craig  Switched to react-error-boundary
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 02Jul2024 craig  moved decode to api
 * 04Nov2024 sasjxa added yup max to text fields
 \******************************************************************************/
import React, {useMemo, useRef, useState} from "react";
import {Col, Container, Form, Spinner} from "react-bootstrap";
import * as Yup from 'yup';
import {Formik, FormikProps} from "formik";
// @ts-ignore
import styled from 'styled-components';
import {
    ContentPage,
    FormLayoutColumn,
    FormSectionTitle,
    PageWrapper,
    PDS_H5,
    PDS_P,
} from "../../components/styled/StyledComponents";
import {useNavigate, useParams} from "react-router-dom";
import "../forms/forms.css";
import {FormList, NEXT, OTHER_OPTION,} from "../../data/formList";
import {ClearAndSubmitButtonContainer} from "../../components/buttonContainer/ClearAndSubmitButtonContainer";
import {Aside} from "../../components/Aside";
import {useRequest} from "../../helper/useRequest";
import {IDonationBasicsData} from "../../model/donations/IDonations";
import {UserInfo} from "../../components/forms/UserInfo";
import {SponsorDataProvider} from "../../components/share/SponsorDataProvider";
import {DataType} from "../../components/share/DataType";
import {RelatedDataSources} from "../../components/share/RelatedDataSources";
import {DataStandardRWD} from "../../components/share/DataStandardRWD";
import {TextFieldRequired} from "../../components/forms/TextFieldRequired";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";
import {enableClearButton, getEncodedValue} from "../../constants/CommonFunctions";


export const ShareDatasetBasicsRWD: React.FC = () => {
    const {donationId} = useParams();
    const navigate = useNavigate();
    const { showBoundary } = useErrorBoundary();
    const [disabled, setDisabled] = useState(false);
    const [userFullName, setUserFullName] = useState<string>("");
    const [dataType, setDataType] = useState("");
    const [dataStandard, setDataStandard] = useState("");
    const [dataStandardOther, setDataStandardOther] = useState("");
    const [sponsor, setSponsor] = useState("");
    const [dataProvider, setDataProvider] = useState("");
    const [relatedDataSources, setRelatedDataSources] = useState("");
    const [showDataStandardOther, setShowDataStandardOther] = useState(false);
    const formikRef = useRef<FormikProps<any>>(null);
    const [basicsData, setBasicsData] = useState<IDonationBasicsData>();

    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    // handle successful save
    const handleSuccess = () => {
        //navigate to share data
        navigate(BASE_PATH + 'shareDatasetRWD/' + donationId);
    }

    // get data
    const shareDataBasicsUrl = process.env.REACT_APP_API_URL + "/api/share/shareDatasetBasics/" + donationId;
    const [requestState, getShareDataBasicsData] = useRequest({
        url: shareDataBasicsUrl,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })
    const {isLoading, data, error} = requestState;

    // save data
    const saveDataBasicsURL = process.env.REACT_APP_API_URL + "/api/share/shareDatasetBasics/" + donationId;
    const [requestActionState, setDataSetBasics] = useRequest({
        url: saveDataBasicsURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })


    useMemo(() => {
        if ((donationId !== undefined && Number(donationId) > 0)) {
            getShareDataBasicsData();
        }
        setDisabled(false);
    }, [])
    useMemo(() => {
        if (data != null) {
            setBasicsData(data);
        }
    }, [data])

    useMemo(() => {
        if (basicsData != null) {
            setDataType(basicsData.dataType);
            setDataStandard(basicsData.dataStandard);
            setSponsor(basicsData.sponsor);
            setDataProvider(basicsData.dataProvider);
            setDataStandardOther(basicsData.dataTypeOther);
            setRelatedDataSources(basicsData.relatedDataSources);
            setUserFullName(basicsData.user?.userDetails?.first + " " + basicsData.user?.userDetails?.last);
            if (basicsData.dataTypeOther != null && basicsData.dataTypeOther != "") {
               setShowDataStandardOther(true);
            }


            if (basicsData.request != null) {
                setDisabled(true);
            }

        }
    }, [basicsData])

    // validation schema
    const schema = Yup.object().shape({
        sponsor: Yup.string().required("Please provide a sponsor.").max(200, "Sponsor should be no longer than 200 characters."),
        dataProvider: Yup.string().required("Please provide a data provider."),
        dataType: Yup.string().required(" Please select a data type.").max(200, "Data Provider should be no longer than 200 characters."),
        dataStandard: Yup.string().required("Please select a data standard."),
        relatedDataSources:Yup.string().max(200, "Related Data Sources should be no longer than 1500 characters."),
        dataStandardOther: Yup.string()
            .max(150, "Data Standard should be no longer than 150 characters")
            .when(['dataStandard'], {
            is: (dataStandard: string) => {

                if (dataStandard.includes(OTHER_OPTION)) {
                    return true // validate and go to then function
                } else return false // for all other cases data standard other is not required
            },
            then: (schema) =>
                schema
                    .required("Please enter a data standard.")
        }),

    });

    // build select list
    const buildSelectList = (listType: FormList[]) => {
        let optionTemplate = listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
        return optionTemplate;
    }

    // show other field when 'Other" selected from list
    const handleDataStandardChanged = (value: string) => {
        if (value === OTHER_OPTION) {
            setShowDataStandardOther(true);
        } else if (showDataStandardOther) {
            setShowDataStandardOther(false);
        }
    }

    // clear form fields
    const handleReset = () => {
        if (formikRef != null && formikRef.current) {
            formikRef.current.resetForm();
            reinitializeValues();
        }
    }

    // re-initialize values after clear
    const reinitializeValues = () => {
        setDataType("");
        setDataStandard("");
        setSponsor("");
        setDataProvider("");
        setDataStandardOther("");
        setRelatedDataSources("");
        setShowDataStandardOther(false);
    }

    const buildForm = () => {
        return (
            <Formik innerRef={formikRef}
                enableReinitialize
                initialValues={{
                    firstAndLast: basicsData?.user?.userDetails?.first ?? '',
                    email: basicsData?.user?.userDetails?.email ?? '',
                    phone: basicsData?.user?.userDetails?.phoneNumber ?? '',
                    sponsor: sponsor ?? '',
                    dataProvider: dataProvider ?? '',
                    dataType: dataType ?? '',
                    dataStandard: dataStandard ?? '',
                    dataStandardOther: dataStandardOther ?? '',
                    relatedDataSources: relatedDataSources ?? '',
                }}
                validationSchema={schema}
                onSubmit={(values) => {
                    setDataSetBasics(JSON.stringify({
                        sponsor: getEncodedValue(values.sponsor),
                        dataProvider: getEncodedValue(values.dataProvider),
                        dataType: values.dataType,
                        dataStandard: values.dataStandard,
                        dataTypeOther: getEncodedValue(values.dataStandardOther),
                        relatedDataSources: getEncodedValue(values.relatedDataSources)
                    }))
                }}
                validateOnChange={false}
                validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      resetForm,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn className="mt-5 mb-5 " lg={12}>
                            <FormLayoutColumn lg={8}>
                                <FormSectionTitle>User Information</FormSectionTitle>
                                <UserInfo showUserFullName={userFullName} userEmail={basicsData?.user.userDetails.email}
                                          userPhone={basicsData?.user.userDetails.phoneNumber}/>

                                <SponsorDataProvider  sponsor={sponsor}
                                                     dataProvider={dataProvider}
                                                     sponsorHandler={setSponsor} dataProviderHandler={setDataProvider}
                                                     disabled={disabled}
                                                     handleChange={handleChange} touched={touched} errors={errors}/>

                                <FormSectionTitle>Data Asset Information</FormSectionTitle>

                                <DataType  value={dataType}
                                          valueHandler={setDataType}
                                          listHandler={buildSelectList} disabled={disabled}
                                          handleChange={handleChange} touched={touched} errors={errors}/>

                                <DataStandardRWD  value={dataStandard}
                                                 valueHandler={setDataStandard}
                                                 handleDataStandardChanged={handleDataStandardChanged}
                                                 listHandler={buildSelectList} disabled={disabled}
                                                 handleChange={handleChange} touched={touched} errors={errors}/>

                                {showDataStandardOther && (
                                    <TextFieldRequired fieldName={"dataStandardOther"}
                                    label="Other Data Standard"
                                    value={dataStandardOther}
                                    valueHandler={setDataStandardOther} disabled={disabled} touched={touched}
                                    errors={errors} size={6}/>
                                )}


                                <RelatedDataSources value={relatedDataSources} valueHandler={setRelatedDataSources}
                                                    disabled={disabled}/>


                            </FormLayoutColumn>

                        </FormLayoutColumn>
                        {!disabled && (
                            <ClearAndSubmitButtonContainer handleReset={handleReset} enableClear={enableClearButton(donationId)} submitButtonText={NEXT}/>
                        )}
                    </Form>

                )}
            </Formik>
        )
    }


    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn md={8} lg={9} name={"registrationColumn"}>
                        <PDS_H5>Dataset Basics</PDS_H5>
                        <PDS_P>Please confirm and provide the following basic details related to the dataset and study
                            you
                            are sharing with Project Data Sphere. To streamline the data sharing process, some
                            information
                            is auto populated from
                            clinicaltrials.gov. This is based on the associated NCTID(s), use commas (,) to separate
                            multiple NCTID values.</PDS_P>

                        {isLoading &&
                            <Spinner  className ="spinner-center" animation={"border"} variant={"primary"} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>}
                        {error && <p>Error</p>}
                        {basicsData && buildForm()}
                    </FormLayoutColumn>
                    <Col md={4} lg={3}>
                        <Aside needHelp={true} requiredFields={true}/>
                    </Col>
                </PageWrapper>
            </ContentPage>
        </Container>
    );

}
