/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: StyleConstants
 *
 * Purpose: constants to use for styling
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 14Feb2023 craig  file created
 * 25Apr2024 sasjxa refactoring of methods and styled components
 * 26Oct2024 craig  fixed some styling issues
 \******************************************************************************/
export const COLORS = {
    lightblue: '#63ceff',
    cerulean: '#0694d5',
    dark_grey_blue: '#34485e',
    middle_grey_blue: '#7b98b7',
    light_grey_blue: '#bdccdb',
    dark: '#273746',
    medium: '#728596',
    light: '#ACBDCC',
    middle_grey: '#697682',
    turtle_green: '#7abe43',
    hover_green: '#5C8F32',
    white: '#FFFFFF',
    black: '#000000',
    light_grey: '#F1F1F1',
    very_light_grey: '#D3D3D3',
    turtle_green_hover: '#5C8F32',
    sign_in_gray: '#909090',
    filter_gray_medium: '#E4E4E4',
    select_file_gray: '#E2E2E2',
    filter_gray: '#F3F3F3',
    highlight_error: '#FF0000',
    gray: '#929292',
    access_data_gray: '#929292',
    form_button_grey: '#929292',
    upload_form_label: '#0694d5',
    remove_red: '#DB462A',
    hover_remove_red: '#C53F25'
};

export const SIZES = {
    MAX_WIDTH: '1440px;',
};

export const FONT_SIZES = {
    info_area_font_size: '1rem',
    px12: '.75rem',
    px13: '.8125rem',
    px14: '.875rem',
    px16: '1rem',
    px18: '1.125rem'
};
