/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Share Data Supplemental Information
 *
 * Purpose: Share Data Supplemental Information for RWDpage
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 30Aug2023 sasjxa file created
 * 23Feb2024 sasjxa add clear button functionality (MPM-5425)
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 12Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 04Apr2024 craig  Switched to react-error-boundary
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 02Jul2024 craig  removed decoding (moved to api)
 * 04Nov2024 sasjxa added yup max to text fields
 \******************************************************************************/
import React, {useMemo, useRef, useState} from "react";
import {Col, Container, Form, Spinner} from "react-bootstrap";
import * as Yup from 'yup';
import {Formik, FormikProps} from "formik";
// @ts-ignore
import styled from 'styled-components';

import {
    ContentPage,
    FormLayoutColumn,
    FormSectionTitle,
    PageWrapper,
    PDS_H5,
    PDS_P
} from "../../components/styled/StyledComponents";

import "../forms/forms.css";
import {useNavigate, useParams} from "react-router-dom";
import {Aside} from "../../components/Aside";
import {IDonationsSupplementalData} from "../../model/donations/IDonations";
import {useRequest} from "../../helper/useRequest";
import {BackClearAndSubmitButtonContainer} from "../../components/buttonContainer/BackClearAndSubmitButtonContainer";
import {TextFieldRequired} from "../../components/forms/TextFieldRequired";
import {TextAreaRequired} from "../../components/forms/TextAreaRequired";
import {TextAreaOptional} from "../../components/forms/TextAreaOptional";
import {max600Count} from "../../data/formList";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";
import {enableClearButton, getEncodedValue} from "../../constants/CommonFunctions";
import {domainVarsDesc, efficacyDesc, patientDesc} from "../../constants/ShareDataFieldsText";

export const ShareDatasetSuppInfoRWD: React.FC = () => {
    const {donationId} = useParams();
    const navigate = useNavigate();
    const { showBoundary } = useErrorBoundary();
    const [suppData, setSuppData] = useState<IDonationsSupplementalData>();
    const [disabled, setDisabled] = useState(false);
    const [uniquePatientIdentifier, setUniquePatientIdentifier] = useState("");
    const [dateTimeVars, setDateTimeVars] = useState("");
    const [efficacyEndpoints, setEfficacyEndpoints] = useState("");
    const [domainVars, setDomainVars] = useState("");
    const [patientBreakdown, setPatientBreakdown] = useState("");
    const [efficacyEndpointsCount, setEfficacyEndpointsCount] = useState(0);
    const [dateTimeVariablesCount, setDateTimeVariablesCount] = useState(0);
    const [domainsCount, setDomainsCount] = useState(0);
    const [patientBreakdownCount, setPatientBreakdownCount] = useState(0);
    const formikRef = useRef<FormikProps<any>>(null);


    // handle error - unsuccessful retrieve  or save of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    // handle successful save of data
    const handleSuccess = () => {
        //go back to share page
        navigate(BASE_PATH + 'share');
    }

    //get data
    const suppDataUrl = process.env.REACT_APP_API_URL + "/api/share/shareDatasetSuppData/" + donationId;
    const [requestState, getSuppData] = useRequest({
        url: suppDataUrl,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })
    const {isLoading, data, error} = requestState;

    // save data
    const [requestActionState, setSuppDataset] = useRequest({
        url: suppDataUrl,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })

    // validation schema
    const schema = Yup.object().shape({
        uniquePatientIdentifier: Yup.string().required("Please enter a unique patient identifier.").max(200, "Unique patient identifier should be no longer than 200 characters."),
        efficacyEndpoints: Yup.string().required("Please enter the efficacy endpoints."),
         efficacyEndpointsDomainVars: Yup.string().required("Please enter the domains and variables."),
    });


    // clear form fields
    const handleReset = () => {
        console.log(error);
        if (formikRef != null && formikRef.current) {
            formikRef.current.resetForm();
            reinitializeValues();
        }
    }

    // re-initialize values after clear
    const reinitializeValues = () => {
        setUniquePatientIdentifier("");
        setDateTimeVars("");
        setEfficacyEndpoints("");
        setDomainVars("");
        setPatientBreakdown("");
    }

    useMemo(() => {
        if ((donationId !== undefined && Number(donationId) > 0) ) {
            getSuppData();
        }
    }, [])
    useMemo(() => {
        if (data != null) {
            setSuppData(data);
        }
    }, [data])

    useMemo(() => {
        if (suppData != null) {
            setUniquePatientIdentifier(suppData.uniquePatientIdentifier);
            setDateTimeVars(suppData.dateTimeVars);
            setEfficacyEndpoints(suppData.efficacyEndpoints);
            setDomainVars(suppData.efficacyEndpointsDomainVars);
            setPatientBreakdown(suppData.patientBreakdown);
        }
    }, [suppData])


    const buildForm = () => {
        return (
            <Formik  innerRef={formikRef}
                enableReinitialize
                initialValues={{
                    uniquePatientIdentifier: uniquePatientIdentifier?? '',
                    dateTimeVars: dateTimeVars?? '',
                    efficacyEndpoints: efficacyEndpoints?? '',
                    efficacyEndpointsDomainVars: domainVars??'',
                    patientBreakdown: patientBreakdown?? '',
                }}
                validationSchema={schema}
                onSubmit={(values) => {
                    setSuppDataset(JSON.stringify({
                        uniquePatientIdentifier: getEncodedValue(values.uniquePatientIdentifier),
                        dateTimeVars: getEncodedValue(values.dateTimeVars),
                        efficacyEndpoints: getEncodedValue(values.efficacyEndpoints),
                        efficacyEndpointsDomainVars: getEncodedValue(values.efficacyEndpointsDomainVars),
                        patientBreakdown: getEncodedValue(values.patientBreakdown)
                    }))
                }}

                validateOnChange={false}
                validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      resetForm,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn className="mt-5 mb-5" lg={12}>
                            <FormLayoutColumn lg={8}>
                                <TextFieldRequired fieldName={"uniquePatientIdentifier"}
                                                   label="Unique Patient Identifier Field"
                                                   value={uniquePatientIdentifier}
                                                   valueHandler={setUniquePatientIdentifier} disabled={disabled} touched={touched}
                                                   errors={errors} size={12}/>
                                <TextAreaOptional fieldName={"dateTimeVars"} label={"Date/Time Variables"}
                                                  value={dateTimeVars} valueHandler={setDateTimeVars}
                                                  currentCharCount={dateTimeVariablesCount}
                                                  maxTextAreaCount={max600Count}
                                                  countHandler={setDateTimeVariablesCount}
                                                  disabled={disabled} handleChange={handleChange}/>

                                <FormSectionTitle>Descriptive Information</FormSectionTitle>
                                <TextAreaRequired fieldName={"efficacyEndpoints"} label={"Efficacy Endpoints"}
                                    value={efficacyEndpoints} valueHandler={setEfficacyEndpoints}
                                                  currentCharCount={efficacyEndpointsCount} maxTextAreaCount={max600Count}
                                                  countHandler={setEfficacyEndpointsCount} disabled={disabled} touched={touched} errors={errors}
                                                  handleChange={handleChange} formText={efficacyDesc}/>
                                <TextAreaRequired fieldName={"efficacyEndpointsDomainVars"} label={"Domains and Variables"}
                                                  value={domainVars} valueHandler={setDomainVars}
                                                  currentCharCount={domainsCount} maxTextAreaCount={max600Count}
                                                  countHandler={setDomainsCount} disabled={disabled} touched={touched} errors={errors}
                                                  handleChange={handleChange} formText={domainVarsDesc}/>

                                <FormSectionTitle>Patient Breakdown</FormSectionTitle>
                                <TextAreaOptional fieldName={"patientBreakdown"} label={"Patient Breakdown"}
                                                  value={patientBreakdown} valueHandler={setPatientBreakdown}
                                                  currentCharCount={patientBreakdownCount}
                                                  maxTextAreaCount={max600Count}
                                                  countHandler={setPatientBreakdownCount}
                                                  disabled={disabled} handleChange={handleChange}
                                                  formText={patientDesc}/>
                            </FormLayoutColumn>
                        </FormLayoutColumn>
                        <BackClearAndSubmitButtonContainer handleReset={handleReset} enableClear={enableClearButton(donationId)}
                                                           backNavigate={"ShareDatasetRWD/" + donationId}/>
                    </Form>

                )}
            </Formik>
        )
    }


    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                <FormLayoutColumn md={8} lg={9} name={"formLayoutColumn"}>
                    <PDS_H5>Dataset Information - Dataset Form - Page 3 of 3</PDS_H5>
                    <PDS_P>While each contribution requires a supporting data dictionary, PDS will verify the
                        structure and content. Please confirm the critical details which define these data including
                        unique keys and definitions used within this data collection.
                    </PDS_P>
                    {isLoading &&
                        <Spinner  className ="spinner-center" animation={"border"} variant={"primary"} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>}
                    {error && <p>Error</p>}
                    {suppData && buildForm()}
                </FormLayoutColumn>
                <Col md={4} lg={3}>
                    <Aside needHelp={true} requiredFields={true}/>
                </Col>
                </PageWrapper>
            </ContentPage>
        </Container>
    );

}
