/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminDataFieldEditSelect
 *
 * Purpose: Edit dataset fields for components that support one or more values *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 04Oct2023 sasjxa  file created.
 \******************************************************************************/
import React, {useMemo, useState} from "react";

import {
    ContentPage, PageWrapper,
    TextAreaCountRow
} from "../../components/styled/StyledComponents";
import {Col, Container, Form, Row} from "react-bootstrap";


// @ts-ignore
import styled from "styled-components";
import {ErrorMessage, Formik} from "formik";
import {
    FormList, OTHER_OPTION,

} from "../../data/formList";
import * as Yup from "yup";
import {COLORS} from "../styled/StyleConstants";
import {useRequest} from "../../helper/useRequest";

import {AdminDataFieldEditButtonContainer} from "./AdminDataFieldEditButtonContainer";

const EditRow = styled.div` 
  display: flex;
  align-items:center;
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
  width: 100%; 
  margin-top: 0;
  
  font-size: .875rem;
  
  span {
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
  
  span.bold {
    font-weight: bold;
  }
  
  button {
   padding-top: 0;
   padding-left: 1%;
   padding-bottom: 0;
   color: ${COLORS.cerulean};
   font-weight: bold;
  }
  
`;



const DisplayCol = styled(Col)` 
     background-color: ${COLORS.white} !important;
     margin: 0;
     padding: 0;
`;

interface AdminDataFieldEditSelectProps {
    label: string,
    fieldName: string,
    donationId: any,
    initialValues: string[],
    listValues: FormList[],
    otherFieldName?: any,
    otherValue?: string,
    adminEditForm: boolean
};


export const AdminDataFieldEditSelect: React.FC<AdminDataFieldEditSelectProps> = ({
                                                                                      label, fieldName, donationId,
                                                                                      initialValues,
                                                                                      listValues,
                                                                                      otherFieldName,
                                                                                      otherValue,adminEditForm
                                                                                  }: AdminDataFieldEditSelectProps) => {

    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const [adminEditable, setAdminEditable] = useState<boolean>(adminEditForm);
    const [commentsCount, setCommentsCount] = useState(0);
    const [tmpFieldName, setTmpFieldName] = useState<string[]>(initialValues);
    const [valueOther, setValueOther] = useState<string>("");
    const [showValueOther, setShowValueOther] = useState<boolean>(false);
    const maxCommentCount = 1000;

    const handleSuccess = () => {
        //navigate to share data
        //navigate('/shareDataset/' + donationId);
    }

    const handleError = (error: object) => {
        console.log(error);
    }

    const saveAdminEditURL = process.env.REACT_APP_API_URL + "/api/admin/adminEdit/" + donationId + "/" + fieldName;

    const [requestActionState, setAdminEdit] = useRequest({
        url: saveAdminEditURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })

    useMemo(() => {
        setAdminEditable(adminEditForm);

        if (initialValues.includes(OTHER_OPTION)) {
            setShowValueOther(true);
            setValueOther(otherValue != null ? otherValue: "");
        }
    }, [adminEditForm])

    const toggleShowEditForm = () => {
        setShowEditForm(!showEditForm);
    }

    const buildSelectList = (listType: FormList[]) => {
        let optionTemplate = listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
        return optionTemplate;
    }

    //TODO add check for Other
    const handleFieldChanged = (value: string[]) => {
        if (value.includes(OTHER_OPTION)) {
            setShowValueOther(true);
        } else if (showValueOther) {
            setShowValueOther(false);
        }
    }

    const hasOtherField = () => {
        if (tmpFieldName.includes(OTHER_OPTION) && otherFieldName!= null && otherValue != null) {
            return true;
        }
        else {
            return false;
        }
    }


    const schema = Yup.object().shape({
        changeComments: Yup.string().required('Please enter comments'),
    });


    const buildForm = () => {
        return (
            <Formik
                enableReinitialize
                validationSchema={schema}
                onSubmit={(values) => {
                    setAdminEdit(JSON.stringify({
                        fieldName: tmpFieldName,
                        changeComments: null,
                    }));
                    toggleShowEditForm();


                }}
                initialValues={{
                    fieldName:  tmpFieldName,
                    otherFieldName: "",
                    changeComments: "",
                }}

                validateOnChange={false}
                validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      resetForm,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                      setFieldValue,
                      setFieldTouched
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <Row className="mt-3 mb-3  ">
                            {showEditForm &&  adminEditable && (
                                <>
                                    <Row className="w-100 ">
                                        <Form.Group as={Col} lg={6} className="tumorType"
                                                    controlId="formTumorType">
                                            <Form.Label className="required" column="sm">{label} </Form.Label>
                                            <Form.Select size="sm" required multiple={true}
                                                         name={fieldName}
                                                         defaultValue={initialValues}
                                                         isValid={touched.fieldName && !errors.fieldName}
                                                         onChange={e => {
                                                             const options = e.target.options;
                                                             let value = [];
                                                             for (let i = 0, l = options.length; i < l; i++) {
                                                                 if (options[i].selected) {
                                                                     value.push(options[i].value);
                                                                 }
                                                             }
                                                             setTmpFieldName(value);
                                                             handleFieldChanged(value);
                                                             handleChange(e);
                                                         }}>
                                                {buildSelectList(listValues)}
                                            </Form.Select>
                                            <Form.Text id="tumorTypesHelpBlock" muted>
                                                Ctrl + click to select multiple
                                            </Form.Text>

                                            <ErrorMessage name={fieldName}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>

                                    {(hasOtherField() || showValueOther) && (
                                        <Row className="mt-3 mb-3 ">
                                            <Form.Group as={Col}  lg={11}
                                                        controlId="formTumorTypeOther">
                                                <Form.Label className="required"
                                                            column="sm">{otherFieldName}
                                                    (multiple
                                                    entries include ',') </Form.Label>
                                                <Form.Control size="sm" required
                                                              name={otherFieldName}
                                                              type="text"
                                                              defaultValue={valueOther}
                                                              isValid={touched.otherFieldName && !errors.otherFieldName}
                                                              onChange={handleChange}/>

                                                <Form.Text className="text-muted"></Form.Text>
                                                <ErrorMessage name={otherFieldName}
                                                              render={msg => <div
                                                                  className={"form-error-msg"}>{msg}</div>}/>
                                            </Form.Group>
                                        </Row>
                                    )}

                                    <Row className=" pb-3">
                                        <Form.Group as={Col} controlId="">
                                            <TextAreaCountRow>
                                                <Form.Label className="required"
                                                            column="sm">Comments </Form.Label>

                                            </TextAreaCountRow>
                                            <Form.Control as="textarea" rows={5} size="sm" required
                                                          name={"changeComments"}
                                                          type="text"
                                                          maxLength={maxCommentCount}
                                                          isValid={touched.changeComments && !errors.changeComments}
                                                          onChange={e => {
                                                              setCommentsCount(e.target.value.length);
                                                              handleChange(e);
                                                          }}/>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide research goals.
                                            </Form.Control.Feedback>
                                            <ErrorMessage name={"changeComments"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>


                                </>
                            )}
                            {!showEditForm && (
                                <DisplayCol className=" mb-4">
                                    <EditRow>
                                        <span className="bold">{label}</span>
                                        {adminEditable && (
                                            <button onClick={toggleShowEditForm}
                                                    className="btn btn-link select-files-link">
                                                edit
                                            </button>
                                        )}
                                    </EditRow>
                                    <EditRow>
                                        <span>{initialValues.join(',')}</span>
                                    </EditRow>
                                </DisplayCol>
                            )}
                        </Row>

                        {showEditForm && (
                            <AdminDataFieldEditButtonContainer toggleMethod={toggleShowEditForm}/>
                        )}
                    </Form>
                )}
            </Formik>
        )
    }


    return (
        <>
            <Container className="form-container" fluid>
                <ContentPage name={"content-page"}>
                    <PageWrapper name={"page-wrapper"}>
                        <Col md={10} lg={12} name={"shareDataColumn"}>
                            {buildForm()}
                        </Col>
                    </PageWrapper>
                </ContentPage>
            </Container>
        </>
    );

}
