/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Layout
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 09Feb2023 craig  file created
 * 04Aug2023 sasjxa correction to main container
 * 05Mar2024 craig  Added timeout stuff
 * 04Jun2024 craig  moved logout stuff to layout
 * 05Aug2024 craig  Added error method
 * 21Aug2024 craig  moved to useContext(PDSContext) for user session
 * 22Aug2024 craig  Move PDSLogoff to this component (was getting error in own file)
 * 23Aug2024 craig  Moved auto logoff timer code to TimeoutDialog component
 \******************************************************************************/
import React, {useEffect, useLayoutEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {getGuestCookie, setSessionCookie} from "../context/context";
import {Outlet, useLocation} from "react-router-dom";
import {ErrorBoundary} from "react-error-boundary";
import {Col, Container} from "react-bootstrap";

// @ts-ignore
import styled from 'styled-components';
import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {SIZES} from "../components/styled/StyleConstants";
import {TimeoutDialog} from "../components/dialogs/TimeoutDialog";
import {PDSError} from "../components/errors/PDSError";
import {usePDSContext} from "../context/PDSContext";
import axios from "axios";

// styles
// wrapper for whole app
const Wrapper = styled(Container)`
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0 auto;
    position: relative;
    background-color: white;

`;

// main container for body of page
const MainContainer = styled(Col)`
    width: 100%;
        //max-width: ${SIZES.MAX_WIDTH};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-right: 0;
    margin-left: 0;

`;


export const Layout: React.FC = (props) => {
    const location = useLocation();
    const [path, setPath] = useState(location.pathname);
    const {sessionUser, setSessionUser} = usePDSContext();
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);




    /*
        to get the error boundary to reset you need to do the location/path thing
        see: https://stackoverflow.com/questions/71762507/reset-error-boundary-on-back-journey-using-useerrorhandler-hook-from-react-error
    */
    useLayoutEffect(() => setPath(location.pathname), [location.pathname]);

    /*
        log off from the application
     */
    const PDSLogoff = async () => {
        const logoffURL = process.env.REACT_APP_API_URL + "/api/logoff";
        try {
            const response = await axios(logoffURL, {method: "POST",withCredentials: true})
            setSessionUser(getGuestCookie);
            //setSessionCookie(getGuestCookie);
            window.location.href = "/";

        } catch (error) {
            setSessionUser(getGuestCookie);
            //setSessionCookie(getGuestCookie);
            window.location.href = "/";
        }
    }

    /**
     * LogoutHandler - process a logout click
     *
     * @param event
     * @constructor
     */
    const LogoutHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        if (sessionUser?.userID > 0) {
            console.log('user = ' + sessionUser?.username);
            PDSLogoff();
        }
    }

    const logError = (error: Error, info: { componentStack: string }) => {
        console.error("Caught an error:", error, info);
    }

    return (
        <Wrapper fluid>
            <TimeoutDialog logout={PDSLogoff}/>
            <Header logoffHandler={LogoutHandler}/>
            <ErrorBoundary key={location.pathname} FallbackComponent={PDSError} onError={logError}>
                <MainContainer name={"main-container"}>
                    <Outlet/>
                </MainContainer>
            </ErrorBoundary>
            <Footer/>
        </Wrapper>
    )
}