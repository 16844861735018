/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminNCIRequestsByUser
 *
 * Purpose: display nci requests by user
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 11Jul2024 sasjxa file created
 * 25Aug2024 craig  change href to NavLink
 * 23Sep2024 sasjxa remove unused method
 \******************************************************************************/
import React, {useMemo, useState} from "react";
import {useRequest} from "../../helper/useRequest";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {INCIRequestsByUser} from "../../model/admin/nci/INCIRequestsByUser";
import {BASE_PATH} from "../../constants";
import styled from "styled-components";
import {COLORS, FONT_SIZES} from "../../components/styled/StyleConstants";
import {AdminNCIUserRequestInfo} from "../../components/admin/AdminNCIUserRequestInfo";
import {NavLink} from "react-router-dom";
import {NavMenuItem} from "../../components/styled/StyledComponents";

const TableHeader = styled(Col)`
    font-size: ${FONT_SIZES.px14};
    font-weight: bold;
    color: ${COLORS.dark_grey_blue};
    padding: 1% 0;
`;



export const AdminNCIRequestsByUser: React.FC = () => {
    const [nciUsers, setNCIUsers] = useState<INCIRequestsByUser[]>([]);


    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        console.log(error);
    }

    // get data
    const userRequestsURL = process.env.REACT_APP_API_URL + "/api/admin/nci/getRequestsByUser";
    const [requestState, getUserRequests] = useRequest({
        url: userRequestsURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
    })
    const {isLoading, data, error} = requestState;

    useMemo(() => {
            getUserRequests();
    }, [])

    useMemo(() => {
        if (data != null) {
           setNCIUsers(data);
        }
    }, [data])

    const buildUsersAndRequests = () => {
        return (
            <>
                <Col className="pe-0">
                    <Row >
                        <TableHeader lg={6}>Name</TableHeader>
                        <TableHeader lg={6}>Email</TableHeader>
                    </Row>
                    {nciUsers?.map((user : INCIRequestsByUser) => (
                        <AdminNCIUserRequestInfo user ={user} />
                    ))}
                </Col>
            </>
        )
    }

    return (
        <Container className="">
                {isLoading &&
                    <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>}
                {error && <p>Error</p>}
                {data && buildUsersAndRequests()}
        </Container>
    )

}