/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ClearAndSubmitButtonContainer
 *
 * Purpose: Button Container for Clear and Submit Buttons
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 05Sep2023 sasjxa file created
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 03Apr2024 sasjxa remove Save button until 2nd release (MPM-5451)
 \******************************************************************************/
import React from "react";
import {
    BackButton,
    ClearButton,
    PDSSubmitButton,
    ShareDataButtonContainer,
    ShareDataButtonGroup
} from "../styled/StyledComponents";

import {useNavigate} from "react-router-dom";
import {BASE_PATH} from "../../constants";

interface BackClearAndSubmitButtonContainer {
    backNavigate: any,
    handleReset: any,
    enableClear: boolean
}




//TODO add handler when Save becomes functional
export const BackClearAndSubmitButtonContainer: React.FC<BackClearAndSubmitButtonContainer> =
    ({ backNavigate, handleReset, enableClear}: BackClearAndSubmitButtonContainer) => {
        const navigate = useNavigate();
    return (
        <ShareDataButtonContainer>
            <BackButton variant="secondary" className="btn-submit" type="button" onClick={() => {
                navigate(BASE_PATH + backNavigate);
            }}>
                Back
            </BackButton>
            <ShareDataButtonGroup>
                {enableClear && (
                    <ClearButton variant="outline-light" className="btn-submit" type="reset" onClick={() => {
                        handleReset()
                    }}>
                        Clear
                    </ClearButton>
                )}

                {/*<PDSSubmitButton variant="success" className="btn-submit" type="submit">*/}
                {/*    Save*/}
                {/*</PDSSubmitButton>*/}

            <PDSSubmitButton variant="success" className="btn-submit" type="submit">
                Next
            </PDSSubmitButton>
            </ShareDataButtonGroup>
        </ShareDataButtonContainer>
    )
}


