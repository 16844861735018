/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: DataProvider
 *
 * Purpose: Introductory page for user to become data provider
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 15Jun2023 sasjxa file created
 * 23Jun2023 sasjxa refactor to use new aside component
 * 08Mar2024 craig  Added base_path
 \******************************************************************************/
import React from "react";
import {ContentPage, PageWrapper, PDS_P} from "../../components/styled/StyledComponents";
import {Col, Container, Row} from "react-bootstrap";
import {COLORS, } from "../../components/styled/StyleConstants";
import {NavLink} from "react-router-dom";
// @ts-ignore
import styled from "styled-components";
import {Aside} from "../../components/Aside";
import {BASE_PATH} from "../../constants";

const DSAButtonRow  = styled(Row)`      
   color: ${COLORS.dark_grey_blue};
   margin: 3% 0;
   justify-content:center;
`;

const MenuItemDSA= styled(NavLink)`
   display: flex;
   align-items: center;
   justify-content: center; 
   font-size: 1rem;
   font-weight: 700;     
   height: 2rem;
   line-height: 2rem; 
   text-decoration: none;
     
   :hover {
          background-color: ${COLORS.hover_green};
          color: ${COLORS.white};
   }
        
   background-color: ${COLORS.turtle_green};
   color: ${COLORS.white};
           
`;

export const DataProvider: React.FC = () => {
    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                <Col md={8} lg={9} name={"shareDataColumn"}>
                    <PDS_P>The data provider process allows organizations to manage data sharing through a step-by-step process.</PDS_P>
                    <PDS_P>You must first apply, on behalf of the data owner, to become a data provider.</PDS_P>
                    <PDS_P>When your request to become a data provider is accepted, you will have the ability to submit
                        descriptive trial information concerning the data that you wish to share through the upload utility. </PDS_P>
                    <PDS_P>The <a
                            href="/resources" className="linkstyle">Resources</a> page of the website
                        contains how-to guides and other information to help you provide and access data.</PDS_P>
                    <DSAButtonRow  >
                        <Col  className="justify-content-center"  md={8} lg={5}>
                        <MenuItemDSA to={BASE_PATH + "dataProviderRegistration"}>Become a Data Provider</MenuItemDSA>
                        </Col>
                    </DSAButtonRow>

                </Col>
                <Col md={4} lg={3}>
                    <Aside needHelp={true} />
                </Col>
                </PageWrapper>
            </ContentPage>


        </Container>
    )
        ;
}