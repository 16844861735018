/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ErrorDialog
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 03Apr2024 craig File created.
 \******************************************************************************/
import React from "react";
import {Button, Modal} from "react-bootstrap";

interface DialogErrorProps {
    show:boolean,
    showHide: any,
    message:string
}

export const ErrorDialog: React.FC<DialogErrorProps> = ({show, showHide, message}: DialogErrorProps) => {

    return (
        <Modal show={show} keyboard={false} backdrop={"static"} >
            <Modal.Header closeButton={true} >
                <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"secondary"} onClick={showHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}