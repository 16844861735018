/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: SubmissionResponse
 *
 * Purpose: Display Submission Response page
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 13Jun2023 sasjxa file created.
 \******************************************************************************/
import React, {Fragment, useState} from "react";
import {
    PageHeaderWithBackground,
    ResourceItemPage,
    PDS_P, PDS_P_BOLD, PageWrapper, ContentPage

} from "../../components/styled/StyledComponents";

// @ts-ignore
import styled from 'styled-components';
import {useLocation} from "react-router-dom";
import parse from "html-react-parser";
import {Col, Row} from "react-bootstrap";


const SubmissionHeader = styled(PDS_P_BOLD)`   
   font-size: 1.375rem;
   text-align: center;
   margin-top: 1%;
`;

const SubmissionResponseText = styled(PDS_P)`  
   text-align: center;
   margin-bottom: 2%;
   width: 75%;
   margin: 0 auto;
`;


export const SubmissionResponse: React.FC = () => {
    const {state} = useLocation();
    const header: string = state.header;
    const response: string = state.response;

    const hasHeaderMessage = () => {
        let hasHeader: boolean = !(header == "");
        return hasHeader;
    }

    return (
        <>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
            <Col lg={12}>
                <Col className="mt-3 mb-3 mx-auto " lg={8}>
                        {hasHeaderMessage() && (
                            <SubmissionHeader>{header}</SubmissionHeader>
                        )}
                        <SubmissionResponseText> {parse(response)}</SubmissionResponseText>
                    </Col>
                </Col>
                </PageWrapper>
            </ContentPage>
        </>
)
}