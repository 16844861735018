/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: CreateClinicalDrugProjectDialog
 *
 * Purpose: Dialog for creating new clinical drug project
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 22Jul2024 sasjxa file created
 \******************************************************************************/
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import React, {useRef} from "react";
import {DialogHeader} from "../styled/StyledComponents";
// @ts-ignore
import styled from "styled-components";
import {ErrorMessage, Formik, FormikProps} from "formik";
import * as Yup from "yup";
import {useRequest} from "../../helper/useRequest";

interface DialogProps {
    title: string,
    show: boolean,
    handleSave: any,
    showHide: any,
}

export const CreateClinicalDrugProjectDialog: React.FC<DialogProps> = ({
                                                                           title,
                                                                           show,
                                                                           handleSave,
                                                                           showHide
                                                                       }: DialogProps) => {
    const formikRef = useRef<FormikProps<any>>(null);

    const handleError = (error: object) => {
        console.log(error);
    }

    const handleSubmit = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit()
        }
    }

    // save data
    const [requestState, setClinicalDrugProjectSave] = useRequest({
        url: process.env.REACT_APP_API_URL + "/api/clinicaldrug/saveProject",
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSave
    })

    const schema = Yup.object().shape({
        projectName: Yup.string().required("Please provide a project name."),
    });

    const buildForm = () => {
        return (
            <>
                <Formik innerRef={formikRef}
                    onSubmit={(values) => {
                        setClinicalDrugProjectSave(JSON.stringify({
                            projectName: values.projectName
                        }));
                    }}
                    initialValues={{
                        projectName: "",
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          touched,
                          errors
                      }) => (
                        <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                            <Row className="mt-3 mb-3">
                                <Form.Group as={Col} lg={12} controlId="">
                                    <Form.Label className={"required"}
                                                column="sm">Project Name </Form.Label>
                                    <Form.Control size="sm" required
                                                  name={"projectName"}
                                                  type="text"
                                                  isValid={touched.projectName && !errors.projectName}
                                                  onChange={handleChange}/>
                                    <Form.Text className="text-muted"></Form.Text>

                                    <ErrorMessage name={"projectName"}
                                                  render={msg => <div
                                                      className={"form-error-msg"}>{msg}</div>}/>
                                </Form.Group>
                            </Row>
                        </Form>

                    )}
                </Formik>
            </>
        )
    }


    return (
        <Modal show={show}>
            <Modal.Header closeButton={true} onClick={showHide}>
                <DialogHeader>{title}</DialogHeader>
            </Modal.Header>
            <Modal.Body>
                {buildForm()}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" className="btn-submit" type="button"
                                 onClick={() => {
                                     handleSubmit();
                                 }}>
                    Submit
                </Button>
                <Button variant={"secondary"} onClick={showHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
