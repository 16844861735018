/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: NCI Attestation
 *
 * Purpose: NCI Attestation
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 25May2023 sasjxa file created
 * 02Jun2023 sasjxa move styled components out of FC
 * 13Apr2024 craig  Removed <p> around section text (causing error in react)
 \******************************************************************************/
import React from "react";
import {Col} from "react-bootstrap";

// @ts-ignore
import styled from 'styled-components';
import {
    PDS_P, PDS_P_BOLD
} from "./styled/StyledComponents";

import parse from "html-react-parser";

import {
    ATTESTATION,
    ATTESTATION_DESCRIPTION,
    ATTESTATION_TITLE,
    ATTESTATION_VERSION,
    IAttestation
} from "../data/attestation/NCIAttestation";
import {ATTESTATION_SUBTITLE} from "../data/attestation/NCIAttestation";

const SubtitleDiv = styled.div`
    width:95%
`;

const UserAgreementContainer = styled(Col)` 
      padding-left: 0;
      padding-right: 0;  
      margin-right: 0;
      margin-left: 0;        
      height: 250px;
      border: 1px solid #7b828e;
      
    
      p {
        padding-left: 5px;
        padding-right: 5px;
      } 
      
      p.center-text {
        text-align: center;
        margin-top: 5px;
      }   
      
      p.add-margin {
       margin-top: 1%;
      }
      
      p.extra-text{
        margin-top: 10px;
        padding-left: 0;
        padding-right: 0;
      }
      
      p.spacing {
        margin-top: 2%;
      }
      
      li {
        margin-top: 10px;
      }
    
     
      ul.no-decoration {
        list-style-type: none;
      }
     
    
      h6 {
        font-weight: 600;
        text-align: center;
        margin-top: 1%;
        margin-bottom: 0;
      }
    
     h6.subtitle {              
        text-align-last: center;
     }
    
      @media (min-width: 380px){ 
        overflow: auto;    
      }
    
      @media (min-width: 768px){ 
          overflow-x: hidden;        
      }
    `;


export const NCIAttestation: React.FC = () => {

    return (
            <UserAgreementContainer   className="mt-3 mb-3 ">

                    <h6>{parse(ATTESTATION_TITLE)}</h6>
                    <SubtitleDiv>
                    <h6 className={"subtitle"}>{ATTESTATION_SUBTITLE}</h6>
                    </SubtitleDiv>
                    <PDS_P className="center-text">{ATTESTATION_VERSION}</PDS_P>
                    <p>{parse(ATTESTATION_DESCRIPTION)}</p>

                   {
                       ATTESTATION.map((attestation: IAttestation, index:number) => {
                           return <Col key={index}>
                                <PDS_P_BOLD className="spacing">{parse(attestation.title)}</PDS_P_BOLD>
                                {parse(attestation.sectionText)}
                           </Col>
                        })
                   }

            </UserAgreementContainer>
    )

}
