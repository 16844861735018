/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: UploadClinicalDrugProjectDialog
 *
 * Purpose: Dialog for uploading files for clinical drug project
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 22Jul2024 sasjxa file created
 * 29Aug2024 sasjxa add message to header about file overwrite possibility
 \******************************************************************************/
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import React, {useRef} from "react";
import {DialogHeader, FormLayoutColumn, LinkButton} from "../styled/StyledComponents";
// @ts-ignore
import styled from "styled-components";
import {ErrorMessage, Formik, FormikProps} from "formik";
import * as Yup from "yup";
import {useRequest} from "../../helper/useRequest";

interface DialogProps {
    projectName: string | undefined,
    projectId: number,
    show: boolean,
    handleUpload: any,
    showHide: any,
}

export const UploadClinicalDrugProjectDialog: React.FC<DialogProps> = ({  projectName,
                                                                           projectId,
                                                                           show,
                                                                           handleUpload,
                                                                           showHide
                                                                       }: DialogProps) => {
    const formikRef = useRef<FormikProps<any>>(null);

    const handleError = (error: object) => {
        console.log(error);
    }

    const handleSubmit = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit()
        }
    }

    const updateURL = process.env.REACT_APP_API_URL + "/api/clinicaldrug/upload/" + projectId;
    const [requestActionState, setUpdateFileAction] = useRequest({
        url: updateURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        headers: {
            'Accept': "application/json",
            'Content-Type': "multipart/form-data",
            'Access-Control_Allow-Origin': "*",
            'Access-Control-Allow-Credentials': "true",
        },
        onError: handleError,
        onSuccess: handleUpload
    })

    const schema = Yup.object().shape({
        fileUploaded: Yup.mixed().required('Please select a file'),
    });

    const buildForm = () => {
        return (
            <Formik innerRef={formikRef}
                validationSchema={schema}
                onSubmit={(values) => {
                    if (values.fileUploaded !== null) {
                        let data: FormData = new FormData();
                        data.append("file", values.fileUploaded);
                        //console.log(values.fileUploaded);
                        setUpdateFileAction(data);
                    }
                }}
                initialValues={{
                    fileUploaded: null,
                }}

                validateOnChange={false}
                validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      resetForm,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                      setFieldValue,
                      setFieldTouched
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn lg={12}>
                            <FormLayoutColumn lg={8}>
                                <Row className="mx-auto">
                                    <Row className="mx-auto mb-3">
                                        <Row className="mb-3 ">
                                            <Form.Group as={Col} lg={12} required className="mb-3 "
                                                /*controlId="formFileUpload"*/>
                                                <Form.Label className="required" column="sm">
                                                    <LinkButton type="button"
                                                                className="btn btn-link bold-label required"
                                                    >
                                                        Choose A Project File

                                                    </LinkButton>
                                                </Form.Label>

                                                <Form.Control id="fileUploaded " type="file" size="sm"
                                                              name={"fileUploaded"}
                                                              accept=".csv"
                                                              value={undefined}
                                                              onChange={(event:any) => {
                                                                  setFieldValue("fileUploaded", event.currentTarget.files[0]);
                                                              }}
                                                              isValid={touched.fileUploaded && !errors.fileUploaded}
                                                />
                                            </Form.Group>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a subject.
                                            </Form.Control.Feedback>
                                            <ErrorMessage name={"fileUploaded"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>


                                        </Row>

                                    </Row>
                                </Row>
                            </FormLayoutColumn>
                        </FormLayoutColumn>


                    </Form>

                )}
            </Formik>
        )
    }

    return (
        <Modal show={show}>
            <Modal.Header closeButton={true} onClick={showHide}>
                <DialogHeader>Upload Files for Project: {projectName}. If you choose a file that currently exists in your project, it will be replaced
                with the new selection.</DialogHeader>
            </Modal.Header>
            <Modal.Body>
                {buildForm()}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" className="btn-submit" type="button"
                        onClick={() => {
                            handleSubmit();
                        }}>
                    Upload File
                </Button>
                <Button variant={"secondary"} onClick={showHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
