/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ServiceTicketsAction
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code) *
 * 18Apr2024 sasjxa file created
 * 23May2024 sasjxa navigate to user's service tickets page after successful commit
 * 06Jun2024 craig  encode the comments
 \*********************************************************************************/
import React, {useMemo, useState} from "react";
import {useRequest} from "../../../helper/useRequest";
import {useErrorBoundary} from "react-error-boundary";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {
    ButtonRow,
    ContentPage,
    FormLayoutColumn,
    PageWrapper,
    PDSSubmitButton,
    TextAreaCountRow
} from "../../../components/styled/StyledComponents";
import {Formik} from "formik";
import * as Yup from "yup";
import {ADMIN_TICKETS_ACTIONS_CLOSED, CLOSED, FormList, SERVICE_TICKETS_OPEN} from "../../../data/formList";
import {useNavigate, useParams} from "react-router-dom";
import {ITicket} from "../../../model/tickets/ITickets";
import {TicketEventsAndContactUs} from "../../../components/forms/TicketEventsAndContactUs";
import {BASE_PATH} from "../../../constants";
import {getEncodedValue} from "../../../constants/CommonFunctions";


export const ServiceTicketAction: React.FC = () => {
    const {ticketId, requestType} = useParams();
    const navigate = useNavigate();
    const {showBoundary} = useErrorBoundary();
    const [ticket, setTicket] = useState<ITicket>();
    const [action, setAction] = useState("");
    const [commentCount, setCommentCount] = useState(0);
    const maxCommentCount = 8000;

    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    // handle successful save of data
    const handleSuccess = () => {
         navigate(BASE_PATH + 'serviceTickets/' );
    }


    const requestsURL = process.env.REACT_APP_API_URL + "/api/user/tickets/processTicket/" + ticketId;
    const [requestActionState, getTicket] = useRequest({
        url: requestsURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    });
    const {isLoading, data, error} = requestActionState;

    const saveTicketEventURL = process.env.REACT_APP_API_URL + "/api/user/tickets/ticketEvent/" + ticketId;
    const [requestAction, setTicketEvent] = useRequest({
        url: saveTicketEventURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })

    useMemo(() => {
        getTicket();
    }, [])


    useMemo(() => {
        if (data !== null) {
            setTicket(data);
        }
    }, [data])
    const schema = Yup.object().shape({
        action: Yup.string()
            .required("Please select an action"),

    });

    const buildSelectList = () => {
        let listType: FormList[] = new Array();
         if (requestType != CLOSED) {
            listType = SERVICE_TICKETS_OPEN;
        }
        else  {
            listType = ADMIN_TICKETS_ACTIONS_CLOSED;
        }

        let optionTemplate = listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
        return optionTemplate;
    }

    const buildForm = () => {
        return (
            <>
                <Formik
                        // validationSchema={schema}
                        onSubmit={(values) => {
                            setTicketEvent(JSON.stringify({
                                event: values.action, comment: getEncodedValue(values.comment)
                            }));
                        }}
                        initialValues={{
                            action: '',
                            comment: ""
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}>
                    {({
                          handleSubmit,
                          handleChange,
                          resetForm,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                          isSubmitting,
                      }) => (
                        <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                            <FormLayoutColumn className="mx-auto" lg={12}>
                                <FormLayoutColumn className="me-5 " lg={8}>
                                    <Row className=" mt-3 mb-3 w-100">
                                        <Form.Group as={Col} lg={6}
                                                    controlId="formAction">
                                            <Form.Label className="required" column="sm">Actions </Form.Label>
                                            <Form.Select size="sm"
                                                         name={"action"}
                                                         onChange={e => {
                                                             handleChange(e);
                                                         }}>
                                                {buildSelectList()}
                                            </Form.Select>

                                        </Form.Group>
                                    </Row>
                                    <Row className="mt-3 mb-5 ">
                                        <Form.Group as={Col} lg={8}
                                                    controlId="formComments">
                                            <TextAreaCountRow>
                                            <Form.Label  column="sm">Comments</Form.Label>
                                                <span>{commentCount} of {maxCommentCount} characters</span>

                                        </TextAreaCountRow>
                                            <Form.Control as="textarea" rows={5} size="sm" required
                                                          name={"comment"}
                                                          type="text"
                                                          maxLength={maxCommentCount}
                                                          onChange={e => {
                                                              setCommentCount(e.target.value.length);
                                                              handleChange(e);
                                                          }}/>
                                        </Form.Group>
                                    </Row>
                                </FormLayoutColumn>
                            </FormLayoutColumn>
                            <ButtonRow  >
                                <PDSSubmitButton variant="success"   className="btn-submit" type="submit">
                                    Submit
                                </PDSSubmitButton>
                            </ButtonRow>
                        </Form>

                    )}
                </Formik>
            </>
        )
    }

    const buildSection = () => {
        return (
            <>
                {buildForm()}
                <Col>
                <TicketEventsAndContactUs  ticket={ticket}/>
                </Col>
            </>
        )
    }


    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn>
                        {isLoading &&
                            <Spinner  className ="spinner-center" animation={"border"} variant={"primary"} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>}
                        {error && <p>Error</p>}
                        {data && buildSection()}
                    </FormLayoutColumn>
                </PageWrapper>
            </ContentPage>
        </Container>
    );

}