/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: CustomFormats
 *
 * Purpose: Custom Formats for Share Data
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 20Oct2023 sasjxa file created
 \******************************************************************************/
import React from "react";
import {Col, Form,  Row} from "react-bootstrap";
import {ErrorMessage} from "formik";
import {NO_OPTION, YES_NO, YES_OPTION} from "../../data/formList";



interface CustomFormatsProps {


    customFormatsUsed: string,
    customFormatsUsedHandler: any,

    customFormatsDecoded: string,
    customFormatsDecodedState: boolean
    customFormatsDecodedValueHandler: any,
    customFormatsDecodedStateHandler: any,

    customFormatsUploaded: string,
    customFormatsUploadedState: boolean,
    customFormatsUploadedStateHandler: any,
    customFormatsUploadedValueHandler: any,

    selectListHandler: any,
    disabled: boolean,
    handleChange: any,
    touched: any,
    errors: any,

}


export const CustomFormats: React.FC<CustomFormatsProps> = ({
                                                                customFormatsUsed,
                                                                customFormatsUsedHandler,
                                                                customFormatsDecoded,
                                                                customFormatsDecodedState,
                                                                customFormatsDecodedValueHandler,
                                                                customFormatsDecodedStateHandler,
                                                                customFormatsUploaded,
                                                                customFormatsUploadedState,
                                                                customFormatsUploadedStateHandler,
                                                                customFormatsUploadedValueHandler,
                                                                selectListHandler,
                                                                disabled,
                                                                handleChange,
                                                                touched,
                                                                errors,

                                                            }: CustomFormatsProps) => {


    const handleCustomFormatsUsedChanged = (value: string) => {
        value === YES_OPTION ? customFormatsDecodedStateHandler(true) : customFormatsDecodedStateHandler(false);

    }

    const handleCustomFormatDecodedChanged = (value: string) => {
        (value === NO_OPTION) ? customFormatsUploadedStateHandler(true) : customFormatsUploadedStateHandler(false);
    }


    return (
        <>
            <Row className="mt-1 mb-5 ">
                <Row className="mt-3  ">
                    <Form.Group as={Col} className="customFormats"
                                controlId="formCustomFormatsUsed">
                        <Form.Label className="required" column="sm">Does this dataset use custom
                            formats? </Form.Label>
                        <Form.Select size="sm" required
                                     name={"customFormatsUsed"}
                                     value={customFormatsUsed}
                                     disabled={disabled}
                                     aria-describedby="customFormatsHelpBlock"
                                     isValid={touched.customFormatsUsed && !errors.customFormatsUsed}
                                     onChange={e => {
                                         customFormatsUsedHandler(e.target.value);
                                         handleCustomFormatsUsedChanged(e.target.value);
                                         handleChange(e);
                                     }}>
                            {selectListHandler(YES_NO)}
                        </Form.Select>
                        <Form.Text id="customFormatsHelpBlock" muted>
                            It is preferred that no custom formats be applied to datasets uploaded
                            to the Project Data Sphere platform; however, if it is necessary to use custom formats,
                            please provide the format catalog as
                            a SAS dataset using the proc format control option so that the dataset
                            can be converted into a format library.
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                            Please indicate whether this dataset uses custom formats.
                        </Form.Control.Feedback>
                        <ErrorMessage name={"customFormatsUsed"}
                                      render={msg => <div
                                          className={"form-error-msg"}>{msg}</div>}/>
                    </Form.Group>
                </Row>

                {customFormatsDecodedState && (
                    <Row className="mt-3 ">
                        <Form.Group as={Col} className={"otherFieldGroup"}
                                    controlId="formCustomFormatsDataDecoded">
                            <Form.Label className="required" column="sm">
                                If yes, is the dataset decoded (i.e. format built in)? </Form.Label>
                            <Form.Select size="sm" required
                                         name={"customFormatsDataDecoded"}
                                         value={customFormatsDecoded}
                                         isValid={touched.customFormatsDataDecoded && !errors.customFormatsDataDecoded}
                                         onChange={e => {
                                             customFormatsDecodedValueHandler(e.target.value);
                                             handleCustomFormatDecodedChanged(e.target.value);
                                             handleChange(e);
                                         }}>
                                {selectListHandler(YES_NO)}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Please indicate whether PRO data was captured.
                            </Form.Control.Feedback>
                            <ErrorMessage name={"customFormatsDataDecoded"}
                                          render={msg => <div
                                              className={"form-error-msg"}>{msg}</div>}/>
                        </Form.Group>
                    </Row>
                )}
                {customFormatsUploadedState && (
                    <Row className="mt-3 ">
                        <Form.Group as={Col} className={"otherFieldGroup"}
                                    controlId="formCustomFormatsUploaded">
                            <Form.Label className="required" column="sm">
                                If no, is the custom format being provided as part of this
                                upload? </Form.Label>
                            <Form.Select size="sm" required
                                         name={"customFormatsUploaded"}
                                         value={customFormatsUploaded}
                                         aria-describedby="customFormatsProvidedHelpBlock"
                                         isValid={touched.customFormatsUploaded && !errors.customFormatsUploaded}
                                         onChange={e => {
                                             customFormatsUploadedValueHandler(e.target.value);
                                             handleChange(e);
                                         }}>
                                {selectListHandler(YES_NO)}
                            </Form.Select>
                            <Form.Text id="customFormatsProvidedHelpBlock" muted>
                                If the custom format is provided as part of the upload, please make
                                sure the format is provided as a transport file (.XPT file) instead
                                of a .SAS7bdat file.
                            </Form.Text>
                            <Form.Control.Feedback type="invalid">
                                Please indicate whether PRO data was captured.
                            </Form.Control.Feedback>
                            <ErrorMessage name={"customFormatsUploaded"}
                                          render={msg => <div
                                              className={"form-error-msg"}>{msg}</div>}/>
                        </Form.Group>
                    </Row>
                )}
            </Row>

        </>

    )

}