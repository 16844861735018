/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: CommonFunctions
 *
 * Purpose: functions common to multiple components
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 25Apr2024 sasjxa file created
 * 07Jun2024 sasjxa getTicketEventStatusDate: use updatedAt
 * 20Jun2024 sasjxa add hasClinGovId
 * 24Jun2024 craig  removed console.log from getDecodedValue
 * 28Jun2024 sasjxa add getFileImage and isSASFile
 * 24Jul2024 craig  fixed null error
 * 16Oct2024 craig  Added check for 'NA' in clinicalGovId
 \********************************************************************************/
import {ITicket} from "../model/tickets/ITickets";
import {
    ADMIN_TICKETS_STATUS,
    FILE_ICONS,
    FormList,
    ICON_UNDEFINED,
    IFileIcons,
    IMG_PATH,
    SAS_FILE_TYPE
} from "../data/formList";
import React from "react";
import {IAccessData} from "../model/donations/IAccessData";


export const getDecodedValue = (value: string) => {
    if (value === null || value.length === 0 ) {
        return value;
    } else {
        try {
            return decodeURIComponent(value);
        } catch (e: any) {
            return value;
        }

    }
}

export const getEncodedValue = (value: string) => {
    if (value === null || value.length === 0 ) {
        return value;
    } else {
        return encodeURIComponent(value);
    }
}


export const labelIfReadOnly = (disabled: boolean, className?: string) => {
    if (disabled) {
        return "bold-label";
    }
    else if (className !== null){
        return className;
    }
}

// enable clear only if donation not saved
export const enableClearButton = (donationId: string | undefined) => {
    if (donationId !== undefined) {
        return false;
    }
    else {
        return true;
    }
}


export const getTicketSubject = (ticket: ITicket | undefined) => {
    if (ticket?.contactUs?.subjectOther !== null) {
        return ticket?.contactUs?.subjectOther;
    } else {
        return ticket?.contactUs?.subject;
    }
}

export const getTicketStatus = (status: string) => {
    if (status !== null) {
        let item: FormList | undefined = ADMIN_TICKETS_STATUS.find(s => s.value === status);
        if (item != undefined) {
            return item?.label;
        }
    }
}

export const getTicketEventStatusDate = (ticket: ITicket) => {
    if (ticket.techSupportEvents !== null) {
        let date: Date = ticket.techSupportEvents[0].updatedAt;
        return (new Date(date).toLocaleDateString('en-us', {
            month: "numeric",
            day: "numeric",
            year: "numeric"
        }));
    }
}

export const hasClinGovId = (donation: IAccessData) => {
    if (donation.subHeader != null && ((donation.subHeader.clinicalGovId != null
        && donation.subHeader.clinicalGovId.length == 0) || (donation.subHeader.clinicalGovId[0] === 'NA'))) {
        return false;
    } else {
        return true;
    }
}


export const getFileImage = (filename: string) => {
    if (filename !== null) {
        const fileTypeSuffix = filename.split('.').pop()
        let item: IFileIcons | undefined = FILE_ICONS.find(s => s.fileType === fileTypeSuffix);
        if (item !== undefined) {
            return IMG_PATH + item.icon;
        } else {
            return ICON_UNDEFINED;
        }
    }
    return ICON_UNDEFINED;
}

// return boolean indicating whether the file is a SAS filetype
export const isSASFile = (filename: string) => {
    if (filename !== null) {
        const fileTypeSuffix = filename.split('.').pop();
        return (fileTypeSuffix === SAS_FILE_TYPE ? true : false);
    }
    return false;
}


