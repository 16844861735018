/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminNCIUserRequestInfo
 *
 * Purpose: component for displaying individual nci access data
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 16Ju12024 sasjxa file created
 * 23Sep2024 sasjxa changed href to NavLink
 \******************************************************************************/
import React, {useState} from "react";
import styled from "styled-components";
import {COLORS, FONT_SIZES} from "../styled/StyleConstants";
import {Col, Row} from "react-bootstrap";
import {INCIRequestHistory, INCIRequestsByUser, INCIStatusAndDate} from "../../model/admin/nci/INCIRequestsByUser";
import {BASE_PATH} from "../../constants";
import {NavMenuItem} from "../styled/StyledComponents";


const UserInfoCol = styled(Col)`
    font-size: ${FONT_SIZES.px14};
    color: ${COLORS.dark_grey_blue};
    padding: .5% 0;
    
`;

const NCTCol = styled(UserInfoCol)`
    overflow-wrap: break-word;
    
`;


const TableHeader = styled(Col)`
    font-size: ${FONT_SIZES.px14};
    font-weight: bold;
    color: ${COLORS.dark_grey_blue};
    padding: 1% 0;
`;

const NCIRequestTableHeader = styled(TableHeader)`
    padding-left: 20px !important;
`;

interface AdminNCIUserRequestInfoProps {
    user: INCIRequestsByUser

}


export const AdminNCIUserRequestInfo: React.FC<AdminNCIUserRequestInfoProps> = ({
                                                                                    user

                                                                                }: AdminNCIUserRequestInfoProps) => {
    const [showUserRequest, setShowUserRequest] = useState(false);
    const [showStudyDetailsArrow, setStudyDetailsArrow] = useState<string>("/images/arrow_down.png");


    // return user first and last and first name
    const getUserName = (user: INCIRequestsByUser) => {
        if (user?.userId != undefined) {
            return (
                <NavMenuItem to={BASE_PATH + "admin/userInfo/" + user.userId}>
                    {user.lastName + ", " + user.firstName}
                </NavMenuItem>
            )
        }
    }

    const getImage = () => {
        return (
            <img
                onClick={() => toggleNCIInfo()}
                src={showStudyDetailsArrow}
                className="text-center"></img>
        )
    }

    const getNCTIds = (nctIds: string[]) => {
        return (
            nctIds.join(",")
        )
    }

    const formatDate = (statusAndDate: INCIStatusAndDate) => {
        if (statusAndDate?.createdAt != null) {
            return (
                new Date(statusAndDate?.createdAt).toLocaleDateString('en-us', {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric"
                })
            )
        }
    }

    // toggle request info
    const toggleNCIInfo = () => {
        setShowUserRequest(!showUserRequest);
        setStudyDetailsArrow(showUserRequest ? "/images/arrow_down.png" : "/images/arrow_up.png")
    }

    const buildToggleComponent = () => {
        return (
            <>
                <Row className={""}>
                    <UserInfoCol className="ps-0 " lg={6}>{getUserName(user)}{getImage()}</UserInfoCol>
                    <UserInfoCol className="ps-0" lg={6}>{user.email}</UserInfoCol>
                </Row>
            </>
        )
    }

    const buildStatusAndDate = (statusDate: INCIStatusAndDate) => {
        return (
            <>
                <Row className={""}>
                    <UserInfoCol className="ps-0 " lg={6}>{statusDate.status}</UserInfoCol>
                    <UserInfoCol className="ps-0" lg={6}>{formatDate(statusDate)}</UserInfoCol>
                </Row>

            </>
        )
    }

    // build the requests section
    const buildUserNCIRequests = () => {
        return (
            <>
                <Col className={"border-blue pb-1"}>
                    <Row className={"w-100 pe-4 ps-4 "}>
                        <TableHeader className="ps-0" lg={1}>Request ID</TableHeader>
                        <TableHeader className="ps-0" lg={5}>Clinical Trials</TableHeader>
                        <NCIRequestTableHeader className="ps-0" lg={2}>NCI Request</NCIRequestTableHeader>
                        <TableHeader className="ps-0 " lg={2}>Status</TableHeader>
                        <TableHeader className="ps-0" lg={2}>Status Date</TableHeader>
                    </Row>
                    {user?.histories.map((history: INCIRequestHistory) => (
                        <Row className={"w-100 pe-4 ps-4"}>
                            <UserInfoCol className="ps-3  " lg={1}>{history.requestId}</UserInfoCol>
                            <NCTCol className="ps-0" lg={5}>{getNCTIds(history.nctTrialNumbers)}</NCTCol>
                            <UserInfoCol className="ps-0" lg={2}>
                                <NavMenuItem to={BASE_PATH +  "nciRequest/" + history.requestId}>
                                    View NCI Request
                                </NavMenuItem>
                            </UserInfoCol>

                            <UserInfoCol className="ps-2" lg={4}>
                                {history.statusAndDate.map((statusDate: INCIStatusAndDate) => (
                                    buildStatusAndDate(statusDate))
                                )}
                            </UserInfoCol>

                        </Row>


                    ))}
                </Col>
            </>
        )

    }


    return (
        <>
            {buildToggleComponent()}
            {showUserRequest && (
                buildUserNCIRequests()
            )}

        </>
    )
}