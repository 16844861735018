/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ContributionContentsStudies
 *
 * Purpose: component to show access data studies
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 29Feb2024 sasjxa file created
 \******************************************************************************/
import React, {useState} from "react";

import {Col, Row} from "react-bootstrap";
import styled from "styled-components";
import {IAccessData} from "../../model/donations/IAccessData";
import {IUser} from "../../model/user/IUser";
import {AccessDataStudyInfo} from "./AccessDataStudyInfo";


const AccessDataCol = styled(Col)`
  padding: 0;
  margin: 0;  
  /*  max-width: 1440px; */
`;



interface ContributionContentStudiesProps {
    donation: IAccessData
}


export const ContributionContentStudies: React.FC<ContributionContentStudiesProps> = ({
                                                                                donation
                                                                            }: ContributionContentStudiesProps) => {

    const [dashboardMsg, setDashboardMsg] = useState<string>("");

    return (
        <>
            <Row>
                <div className={"error-message"}>{dashboardMsg}</div>
            </Row>
            <AccessDataCol  name={"formLayoutColumn"}>
                <AccessDataStudyInfo donation={donation} isAccessData={false}/>
            </AccessDataCol>
        </>

    );

}
