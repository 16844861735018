/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: DataType
 *
 * Purpose: Data Type for Share Data
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 28Aug2023 sasjxa File created.
 * 20Oct2023 sasjxa add parm for label creation class
 * 19Jun2024 sasjxa remove labelClassName parameter
 \******************************************************************************/
import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import {ErrorMessage} from "formik";
import {DATASET_TYPES} from "../../data/formList";
import {labelIfReadOnly} from "../../constants/CommonFunctions";


interface DataTypeProps {
    value: any,
    valueHandler: any,
    listHandler: any,
    disabled: boolean,
    handleChange: any,
    touched: any,
    errors: any,
}


export const DataType: React.FC<DataTypeProps> =
    ({
         value,  valueHandler, listHandler, disabled,
          touched, errors
     }) => {
        return (
            <Row className="mt-1 mb-3 ">
                <Form.Group as={Col} lg={6} className="dataType"
                            controlId="dataType">
                    <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Data
                        Type </Form.Label>
                    {!disabled && (
                        <Form.Select size="sm" required
                                     name={"dataType"}
                                     value={value}
                                     disabled={disabled}
                                     isValid={touched.dataType && !errors.dataType}
                                     onChange={(e) => {valueHandler(e.target.value)}}>
                            {listHandler(DATASET_TYPES)}
                        </Form.Select>
                    )}
                    {disabled && (
                        <Form.Control size="sm"
                                      name={"dataType"}
                                      type="text"
                                      disabled={disabled}
                                      defaultValue = {value}
                                      />
                    )}

                    <Form.Control.Feedback type="invalid">
                        Please select a data type.
                    </Form.Control.Feedback>
                    <ErrorMessage name={"dataType"}
                                  render={msg => <div
                                      className={"form-error-msg"}>{msg}</div>}/>
                </Form.Group>
            </Row>

        )

    }