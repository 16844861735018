export interface IFaq {
    id : number,
    question: string,
    answer: string
}
export const FAQ_QUESTIONS:IFaq[] = [
    {
        id: 1,
        question: "What is Project DataSphere?",
        answer: "<em>Project Data Sphere (PDS)</em> is an independent, not-for-profit initiative of the" +
            " <a href=\"https://www.ceoroundtableoncancer.org/\"  target={\"_blank\"}>  <em> CEO Roundtable on Cancer</em></a>." +
            "  In 2001, the <em>CEO Roundtable on Cancer</em> was founded by President George H. W. Bush and is comprised of" +
            "  chief executives from more than 30 U.S.-based organizations representing diverse industries. " +
            "  Their charge was to accomplish together what no single company might consider alone " +
            "  to address issues in cancer prevention, diagnosis, and treatment. To accomplish this, PDS was launched " +
            "   as an initiative to convene experts from all sectors of oncology; foster collaboration and innovation in cancer" +
            "   research; and serve as a catalyst for openly sharing patient-level clinical trial data. Today, the PDS data sharing " +
            "   platform hosts over 150,000 patient-lives of clinical trial data from various trials across industry sponsors." + "                            Further, PDS has embarked on diversifying the data on its" +
            "   platform through unique partnerships with academia and research institutes, as well as through its" +
            "   own research programs."

    },
    {
        id: 2,
        question: "What types of data does PDS share?",
        answer: "Currently the PDS platform shares clinical trial data, curated clinical trial datasets that"
            + " include socioeconomic data, and curated"
            + " datasets from prostate cancer clinical trials that were part of the DREAM Crowdsource Challenges."
            + " Further, unique data is being"
            + " driven to the PDS platform through its four research programs focusing on machine learning algorithms"
            + " in imaging, external control"
            + " arms for various indications and trial designs, registries for rare cancers and bio-marker-driven"
            + " indications, and immune-related"
            + " adverse events. Soon, PDS will have available annotated images and associated data, patient-level"
            + " data in the form of synthetic"
            + " controls, and real-world patient-level data from rare cancer and immune-related adverse event"
            + " registries. All data on the PDS data"
            + " sharing platform are specific to the field of oncology. Visit the PDS website to learn more about the"
            + " <a href= \" https://www.ceoroundtableoncancer.org/data-platform-boosts-publications-research-advances-in-treatment/\" target={\"_blank\"}>Prostate Cancer DREAM Challenge</a> "
            + " and"
            + " <a href=\"https://www.ceoroundtableoncancer.org/project-data-sphere/research-programs/\" target={\"_blank\"}>these programs</a>. "
    },
    {
        id: 3,
        question: "How is PDS sharing this data?",
        answer:"PDS shares data through this open-access data sharing platform that is hosted in partnership"
            + " with SAS Institute (SAS). Through this"
            + " truly collaborative partnership, SAS helps PDS to maintain the quality and integrity of the data and"
            + " provides all PDS platform users free access to some of its most powerful data analytics tools."
            + " This platform enables users to share, access, analyze, and download data for"
            + " secondary research."
    },
    {
        id: 4,
        question: "Where does the de-identified patient-level data come from?",
        answer: "All data on the PDS platform is provided by industry sponsors (e.g. Pharma), collaborative"
            + " research partners partners (e.g. RTI), and"
            + " the PDS research programs and their collaborators. PDS continues to explore other opportunities"
            + " for hosting more and unique data."
            + " Learn more about our valued "
            + " <a href=\"https://www.ceoroundtableoncancer.org/project-data-sphere/collaborators-and-supporters/\" target={\"_blank\"}> data providers, partners, and collaborators. </a> "
    },
    {
        id: 5,
        question:"Who can register as a user and access the PDS platform?",
        answer:"This platform is available to anyone who applies for access.  "
    },
    {
        id: 6,
        question:"Can I share de-identified patient-level oncology data with PDS?",
        answer:"Yes! If you have the right to share data by the trial sponsor or governing institution you may"
            + " share data with PDS. First, you must"
            + " register as a user for our data sharing platform. Then you will have to"
            + " apply to be a data provider. "
            + " Once approved, you can begin the"
            + " data sharing process starting with our Data Sharing Agreement."
    },

    {
        id: 7,
        question:"Is there a cost to use the PDS platform and SAS analytics tools?",
        answer:"No! The platform, SAS analytics tools, and all other services are completely free for all"
            + " registered users and data providers."
    },

    {
        id: 8,
        question:"What information is needed to register as a user?",
        answer:"Basic identification information is required to register: name, e-mail, address, goals of"
            + " research, and how you learned about PDS."
            + " PDS does not share our users' personal information with any outside vendors or parties without user consent."
    },
    {
        id: 9,
        question:"Do I have to submit a research proposal?",
        answer:"No! You DO NOT need to submit a formal research proposal to access the platform. However, we"
            + " do request new users to submit a"
            + " summary of research goals during the application process."
    },
    {
        id: 10,
        question:"Will I need to request access for each dataset?",
        answer:"No! Once registered, you will have access to all data on the platform with exception of data"
            + " provided by the National Cancer"
            + "Institute (NCI). However, you can make batch requests for multiple NCI datasets at the same time."
            + " This approval process goes through the NCI."
    },
    {
        id: 11,
        question:"How long will it take to be approved as a new user?",
        answer:"Typically, new user requests are approved within 24hrs. However, some requests can take up"
            + " to one week. All user requests are"
            + " approved once verified that the user isn't subject to certain geographical or limited regulatory"
            + " restrictions."
    },
    {
        id: 12,
        question:"How long will I have access to the PDS platform?",
        answer:"Once your new user request is approved you will have access to the platform for one year. Users"
            + " will be notified to \"reactivate\""
            + " their account before it expires. This allows PDS to ensure it has all our users' most up-to-date information."
    },

    {
        id: 13,
        question:"What analytics tools are available on the PDS platform?",
        answer:
           "The following SAS analytics tools are available for use within the PDS platform at"
            + " no cost to registered users:"
            + " <ul>"
             + "<li><em>SAS&reg; Visual Data Mining and Machine Learning</em> </li>"
             +  " <ul>"
            + "      <li><em><em>SAS&reg; Viya</em> </li>"
            + "      <li><em>Compatible with Python and R via Jupyter Notebook</em></li>"
            + "   </ul>"
            + " <li><em><em>SAS&reg; Life Science Analytics Framework</em></em> </li>"
            + "</ul>"

    },
    {
        id: 14,
        question:"Does the PDS platform require any data standards?",
        answer:"Yes. The Clinical Data Interchange Standards Consortium (CDISC) Study Data Tabulation Model (SDTM)"
            + " format is the recommended"
            + " format to share data. Specific details about any format used will be provided in the data descriptor document."
    },
    {
        id: 15,
        question:"What legal agreements are in place to govern how data will be shared?",
        answer:"All new users enter into an Online Services User Agreement with PDS. Additionally, all data"
            + " providers enter into a Data Sharing"
            + " Agreement for each data provision. Copies of these agreements can be found on the "
            + " <a href=\"/projectdatasphere/html/resources\">Resources</a> page.  "
    },
    {
        id: 16,
        question:"Will users be required to submit research results?",
        answer:"No! However, PDS encourages users to publish and/or present their research how they see fit."
            + " Users are required to acknowledge"
            + " Project Data Sphere in their publications or presentations if necessary."

    },
    {
        id: 19,
        question:"Where can I find additional information about PDS?",
        answer:"Learn more about PDS on our "
            + "<a href= \"https://www.ceoroundtableoncancer.org/project-data-sphere\"  target={\"_blank\"}>website</a> "
            + " and follow us on "
            + "<a href= \"https://twitter.com/ProjDataSphere\"  target={\"_blank\"}>twitter</a> "
            + " and "
            + "<a href= \"https://www.linkedin.com/company/project-data-sphere-llc\"  target={\"_blank\"}>Linkedin</a> "
            + " Inquiries should be sent through the"
            + " Contact Us feature on the website or email at"
            + " info@projectdatasphere.org."
    }


];

