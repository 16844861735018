/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminTickets
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 18Aug2023 sasjxa file created
 * 29Nov2023 sasjxa add link to display user info
 * 07Dec2023 sasjxa use AdminConvertUser componet for users who need to be converted
 * 11Dec2023 sasjxa correct id field
 * 15Dec2023 sasjxa correct url field and add sort capability
 * 10Jan2024 sasjxa use new common component for header
 * 08Mar2024 craig  added base_path
 * 12Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 25Apr2024 sasjxa refactoring of methods and styled components
 * 23May2024 sasjxa add message for no in-progress tickets to
 * 07Jun2024 sasjxa fix getTicketEventStatusDate to return latest tech support date
 * 02Jul2024 craig  moved decode to api
 * 25Aug2024 craig  changed href to NavLink
 * 25Sep2024 sasjxa remove table border
 * 31Oct2024 sasjxa add component for displaying active directory/login info
 \************************************************************************************/
import React, {useMemo, useState} from "react";
import {Container, Row, Spinner, Table} from "react-bootstrap";
import {useRequest} from "../../helper/useRequest";
import {NavLink, useParams} from "react-router-dom";
// @ts-ignore
import styled from "styled-components";

import {
    ADMIN_TICKETS_STATUS,
    ASC,
    CLOSED,
    DESC,
    FormList,
    IN_PROGRESS,
    OPEN,
    TICKET_HEADERS
} from "../../data/formList";
import {AdminTableHeader, PDS_P_BOLD, UserInfoCol} from "../../components/styled/StyledComponents";
import {ITicket} from "../../model/tickets/ITickets";
import {AdminConvertUser} from "../../components/admin/AdminConvertUser";
import {AdminCountsHeader} from "../../components/admin/AdminCountsHeader";
import {BASE_PATH} from "../../constants";
import {getTicketEventStatusDate, getTicketStatus, getTicketSubject} from "../../constants/CommonFunctions";
import {useErrorBoundary} from "react-error-boundary";
import {FONT_SIZES} from "../../components/styled/StyleConstants";
import {AdminUserLoginsInfo} from "../../components/admin/AdminUserLoginsInfo";


const OpenClosedTicketsTable = styled(Table)`
    border: 0;
    th, td {
        font-size: ${FONT_SIZES.px14};
        vertical-align: top;
        padding: .5% 0;
    }

    th.small, td.small {
        width: 8%;
        padding-left: 1%;
    }

    th.requestDate, td.requestDate {
        width: 10%;
        padding-left: 1%;
    }

    th.status, td.status {
        width: 17%;
        padding-left: 1%;
    }

    th.lastEmail, td.lastEmail {
        width: 20%;
        padding-left: 2%;
    }

    th {
        padding-left: 0;
        padding-bottom: 0;
    }
`;


export const AdminTickets: React.FC = () => {
    const {requestType} = useParams();
    const [tickets, setTickets] = useState<ITicket[]>([]);
    const OPEN_TICKETS: string = "Open Tickets";
    const CLOSED_TICKETS: string = "Closed Tickets";
    const NO_OPEN_TICKETS: string = "No open tickets at this time";
    const NO_IN_PROGRESS_TICKETS: string = "No tickets in progress at this time";
    const NO_CLOSED_TICKETS: string = "No closed tickets at this time";
    const [sortOrder, setSortOrder] = useState("DESC");
    const {showBoundary} = useErrorBoundary();
    const url = process.env.REACT_APP_API_URL + "/api/admin/tickets/" + requestType;
    const [requestsURL, setRequestURL] = useState(url + "/" + sortOrder);

    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    // get data

    const [ticketsState, getTickets] = useRequest({
        url: requestsURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })
    const {isLoading, data, error} = ticketsState;

    useMemo(() => {
        getTickets();
    }, [requestsURL]);

    useMemo(() => {
        if (data != null) {
            setTickets(data);
        }
    }, [data])


    // sort date
    const sortDate = (e: any) => {
        e.preventDefault();
        if (sortOrder === DESC) {
            setSortOrder(ASC);
        } else {
            setSortOrder(DESC);
        }


        setRequestURL(url + "/" + sortOrder);
    }

    // return user first and last name
    const getUserName = (ticket: ITicket) => {
        const fullName = ticket.contactUs?.first + " " + ticket.contactUs?.last;
        if (ticket.user?.id != null) {
            return (
                <UserInfoCol className={""}>
                    <AdminUserLoginsInfo userId={ticket.user?.id}/>
                <NavLink to={BASE_PATH + "admin/userInfo/" + ticket.user?.id} className={"linkstyle"}>{fullName}</NavLink>
                </UserInfoCol>
            )
        } else {
            return (
                <AdminConvertUser ticketId={ticket.id} fullName={fullName}/>
            )
        }
    }

    // return email associated with ticket
    const getTicketEventEmail = (ticket: ITicket) => {
        if (ticket.techSupportEvents != null) {
            return ticket.techSupportEvents[0].user?.userDetails?.email;
        }
    }

    // return email associated with ticket
    const getTicketEventStatus = (ticket: ITicket) => {
        if (ticket.techSupportEvents != null) {
            let event:string = ticket.techSupportEvents[0].event;
            let item: FormList | undefined = ADMIN_TICKETS_STATUS.find(s => s.value === event);
            if (item != undefined) {
                return item?.label;
            }
        }
    }

    // return table header based on request type
    const getTableHeader = (requestType: string) => {
        if (requestType === OPEN) {
            return OPEN_TICKETS;
        } else if (requestType === CLOSED) {
            return CLOSED_TICKETS
        }

        return "";
    }

    // return message about no tickets based on request type
    const getNoTicketsMessage = (requestType: string) => {
        if (requestType === OPEN) {
            return NO_OPEN_TICKETS;
        } else if (requestType === CLOSED) {
            return NO_CLOSED_TICKETS;
        } else if (requestType === IN_PROGRESS) {
            return NO_IN_PROGRESS_TICKETS;
        }
        return "";
    }

    const buildSection = () => {
        if (requestType === IN_PROGRESS) {
            if (tickets !== null && tickets?.length > 0) {
                return (
                    <>
                        <Row><AdminTableHeader className="mt-3">{getTableHeader(requestType)}</AdminTableHeader></Row>
                        <OpenClosedTicketsTable borderless className="w-100 ">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Subject</th>
                                <th className="status">Status</th>
                                <th className="lastEmail">Last Email For Event</th>
                                <th className="small">Status Date</th>
                                <th className="requestDate"><a className={"convert"} onClick={(e) => sortDate(e)}>Request
                                    Date</a></th>
                            </tr>
                            </thead>
                            <tbody>
                            {tickets?.map((ticket: ITicket) => (
                                <tr>
                                    <td>{getUserName(ticket)}</td>
                                    <td>{ticket.user?.userDetails?.email}</td>
                                    <td>{getTicketSubject(ticket)}</td>
                                    <td className="status"><a
                                        href={BASE_PATH + "admin/tickets/processTicket/" + ticket.id + "/" + requestType}
                                    >{getTicketEventStatus(ticket)}
                                    </a></td>
                                    <td className="lastEmail">{getTicketEventEmail(ticket)}</td>
                                    <td className="small"> {getTicketEventStatusDate(ticket)}</td>
                                    <td className="small"> {new Date(ticket.createdAt).toLocaleDateString('en-us', {
                                        month: "numeric",
                                        day: "numeric",
                                        year: "numeric"
                                    })}</td>
                                </tr>
                            ))}
                            </tbody>
                        </OpenClosedTicketsTable>

                    </>

                )
            }
            else {
                return (
                    <PDS_P_BOLD>{getNoTicketsMessage(requestType)}</PDS_P_BOLD>
                )
            }
        } else if (requestType === OPEN || requestType === CLOSED) {
            if (tickets !== null && tickets?.length > 0) {
                return (
                    <>
                        <Row><AdminTableHeader className="mt-3">{getTableHeader(requestType)}</AdminTableHeader></Row>
                        <OpenClosedTicketsTable borderless className="w-100 ">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Subject</th>
                                <th className="small">Status</th>
                                <th className="requestDate"><a className={"convert"} onClick={(e) => sortDate(e)}>Request
                                    Date</a></th>
                            </tr>
                            </thead>
                            <tbody>
                            {tickets?.map((ticket: ITicket) => (
                                <tr>
                                    <td>{getUserName(ticket)}</td>
                                    <td>{ticket.contactUs?.email}</td>
                                    <td>{getTicketSubject(ticket)}</td>
                                    <td className="small"><a
                                        href={BASE_PATH + "admin/tickets/processTicket/" + ticket.id + "/" + requestType}
                                    >{getTicketStatus(ticket.status)}
                                    </a></td>
                                    <td className="requestDate"> {new Date(ticket.createdAt).toLocaleDateString('en-us', {
                                        month: "numeric",
                                        day: "numeric",
                                        year: "numeric"
                                    })}</td>
                                </tr>
                            ))}
                            </tbody>
                        </OpenClosedTicketsTable>

                    </>
                )
            } else {
                return (
                    <PDS_P_BOLD>{getNoTicketsMessage(requestType)}</PDS_P_BOLD>
                )
            }
        }

    }

    return (
        <Container className={"w-100 "}>
            <Row className={"mt-3 "}></Row>
            <AdminCountsHeader countsList={TICKET_HEADERS} requestType={requestType}
                               countsUrl={"/api/admin/dashboard/counts/tickets"}/>
            {isLoading &&
                <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}
            {error && <p>Error</p>}
            {tickets && buildSection()}
        </Container>
    )
}