/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: EmailPreferences
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 14Apr2024 craig  File created.
 * 18Apr2024 sasjxa add sections, field validation, and controller call (MPM-5422)
 * 25Apr2024 sasjxa refactoring of methods and styled components
 \*********************************************************************************/
import React, {useMemo, useState} from "react";
import {IMyAccountUserInfo} from "../../../model/user/IMyAccountUserInfo";
import {useRequest} from "../../../helper/useRequest";
import {useErrorBoundary} from "react-error-boundary";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {
    ButtonRow,
    ContentPage,
    FormLayoutColumn,
    PageWrapper,
    PDSSubmitButton, SectionHeader
} from "../../../components/styled/StyledComponents";
import styled from "styled-components";
import {Formik} from "formik";
import {SigninDropdownOptionsDialog} from "../../../components/dialogs/SigninDropdownOptionsDialog";


const PasswordHeaderColumn = styled(Col)` 
    margin: 0 auto;  
   
    label {        
        font-weight: normal;
    }
`;

const PreferencesButton = styled(PDSSubmitButton)` 
    width:200px;  
`;

export const EmailPreferences: React.FC = () => {
    const {showBoundary} = useErrorBoundary();
    const [emailPreferences, setEmailPreferences] = useState<boolean>(false);
    const [buildTheForm, setBuildTheForm] = useState<boolean>(false);
    const [showDialog, setShowDialog] = useState<boolean>(false);

    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    // handle successful call to API
    const handleSuccess = (optOutEmail: boolean) => {
            setEmailPreferences(optOutEmail);
    }

    // display dialog to user upon successful email preferences change
    const handleSaveSuccess = (userAccountInfo: IMyAccountUserInfo) => {
        setShowDialog(true);
    }

    // get data
    const [requestActionState, getEmailPreferencesValue] = useRequest({
        url: process.env.REACT_APP_API_URL + "/api/user/myAccount/emailPreferences",
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onSuccess: handleSuccess,
        onError: handleError
    });
    const {isLoading, data, error} = requestActionState;

    // save data
    const [requestState, setOptOutEmailChange] = useRequest({
        url: process.env.REACT_APP_API_URL + "/api/user/myAccount/optOutEmail",
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSaveSuccess
    })


    useMemo(() => {
        getEmailPreferencesValue();
    }, []);

    useMemo(() => {
        setBuildTheForm(true);
    }, [emailPreferences]);

    // show/hide  dialog
    const toggleDialog = () => {
        setShowDialog(!showDialog);
    }

    const handleOptOutChanged = (value: boolean) => {
        setEmailPreferences(value);
    }

    const buildForm = () => {
        return (
            <>
                <Formik
                        onSubmit={(values) => {
                            setOptOutEmailChange(JSON.stringify({
                                optOutEmail: values.optOutEmail
                            }));
                        }}
                        initialValues={{
                            optOutEmail: emailPreferences,
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}>
                    {({
                          handleSubmit,
                          handleChange
                      }) => (
                        <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                            <Row className="mt-3 mb-3">
                                <PasswordHeaderColumn md={4} lg={4} className="mb-3 ">
                                    <Row className="mt-3 mb-3"><SectionHeader>Email Preferences</SectionHeader></Row>
                                <Form.Group as={Col} controlId="formOptOutEmail">
                                    <Form.Check required type={"checkbox"}
                                                name={"optOutEmail"}
                                                checked={emailPreferences}
                                                label={"Opt out of Emails"}
                                                onChange={e => {
                                                    handleOptOutChanged(!emailPreferences);
                                                    handleChange(e);
                                                }}>
                                    </Form.Check>
                                </Form.Group>
                                </PasswordHeaderColumn>
                                <ButtonRow>
                                    <PreferencesButton variant="success"   className="btn-submit" type="submit">
                                        Save Preferences
                                    </PreferencesButton>
                                </ButtonRow>
                            </Row>
                        </Form>

                    )}
                </Formik>
            </>
        )
    }

    return (
        <Container className="form-container" fluid>
            <SigninDropdownOptionsDialog title={"Change Email Preferences"} body={<p>Your email preferences have been saved.</p>}
                        show={showDialog} showHide={toggleDialog}/>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn name={"registrationColumn"}>
                        {isLoading &&
                            <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>}
                        {error && <p>Error</p>}
                        {buildTheForm && buildForm()}
                    </FormLayoutColumn>
                </PageWrapper>
            </ContentPage>
        </Container>
    )
}