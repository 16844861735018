/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminDSARequests
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 03Aug2023 sasjxa file created.
 * 11Aug2023 sasjxa remove table header
 * 10Oct2023 sasjxa add more fields to approved DSA
 * 10Jan2024 sasjxa use new common component for header
 * 08Mar2024 craig  added basepath
 * 12Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 04Apr2024 craig  switched to react-error-boundary
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 25Apr2024 sasjxa refactoring of methods and styled components
 * 02Jul2024 craig  removed decode (now down in api)
 * 14Aug2024 craig  added download link for the dsa
 * 16Aug2024 sasjxa added cursor pointer to download link
 * 25Aug2024 criag  change href to NavLink
 \******************************************************************************/
import React, {useMemo, useState} from "react";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {useRequest} from "../../helper/useRequest";
import {NavLink, useParams} from "react-router-dom";

import {APPROVED, DSA_HEADERS, NA,} from "../../data/formList";
import {GridRow, NavMenuItem, PDS_P_BOLD,} from "../../components/styled/StyledComponents";
import {IDataShareAgreement} from "../../model/donations/IDonations";
import {AdminCountsHeader} from "../../components/admin/AdminCountsHeader";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";
import axios from "axios";


export const AdminDSARequests: React.FC = () => {
    const { showBoundary } = useErrorBoundary();
    const {requestType} = useParams();
    const [dsaRequests, setDSARequests] = useState<IDataShareAgreement[]>([]);


    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    // get data
    const requestsURL = process.env.REACT_APP_API_URL + "/api/admin/donations/dsa/" + requestType;
    const [requestState, getDSARequests] = useRequest({
        url: requestsURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })
    const {isLoading, data, error} = requestState;


    useMemo(() => {
        getDSARequests();
    }, [])

    useMemo(() => {
        if (data != null) {
            setDSARequests(data);
        }
    }, [data])

    const sortDate = () => {
    }

    // build open requests header
    const buildOpenDSAHeaders = () => {
        return (
            <>
                <GridRow className="mt-4">
                    <Col className="grid-header grid-entry space-3 ">Title</Col>
                    <Col className="grid-header grid-entry space-3 ">Name</Col>
                    <Col className="grid-header grid-entry  space-3">Email</Col>
                    <Col className="grid-header grid-entry space-3 ms-5"> Provider</Col>
                    <Col className="grid-header grid-entry space-2">Request Date</Col>
                    <Col className="grid-header grid-entry space-3 ">Current Status</Col>
                    <Col className="grid-header grid-entry">Delete</Col>
                </GridRow>
            </>
        )
    }

    /**
     * handleSuccess - we were able to get the file, now simulate the download click (very kludgy, but it seems to be
     * the way to do this in react (at least I could not find another way)
     *
     * @param response - response from axios
     * @param filename - filename
     */
    const handleSuccess = (response: any, filename:string) => {
        //const file = new File(response.data, response.file);
        //const url = window.URL.createObjectURL(new Blob([response]));
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode !== null) {
            link.parentNode.removeChild(link);
        }
    }

    // handle error - unsuccessful retrieve of data
    const handleDownloadError = (error: object) => {
        showBoundary(error);
    }
    /**
     * call the url to download the file
     */
    const downloadDsaUrl:string = process.env.REACT_APP_API_URL + "/api/admin/donations/dsa/download/"
    const downloadDsa = async (id:number, filename:string) => {
        try {
            const response = await axios({
                url: downloadDsaUrl + id,
                method: 'GET',
                withCredentials: true,
                responseType: 'blob', // important
                headers: {
                    'Accept': "application/json",
                    'Content-Type': "application/json",
                    'Access-Control_Allow-Origin': "*",
                    'Access-Control-Allow-Credentials': "true"
                }
            })
            handleSuccess(response, filename);
        } catch (error:any) {
            handleDownloadError(error);
        }
    }

    // build open requests body
    const buildOpenDSARequests = () => {
        return (
            <>
                {dsaRequests?.map((request: IDataShareAgreement) => (
                    <GridRow className="">
                        <Col className="grid-entry space-3">{request.workingTitle}</Col>
                        <Col className="grid-entry space-3">{request.contactName}</Col>
                        <Col className="grid-entry space-3">{request.contactEmail}</Col>
                        <Col className="grid-entry space-3 ms-5">{request.companyName}</Col>
                        <Col className="grid-entry space-2 ">
                            {new Date(request.createdAt).toLocaleDateString('en-us', {
                                month: "numeric",
                                day: "numeric",
                                year: "numeric"
                            })}
                        </Col>
                        <Col className="grid-entry space-3">
                            <NavMenuItem to={BASE_PATH +  "admin/request/dsa/details/" + request.id}>
                                Pending Verification
                            </NavMenuItem>

                        </Col>
                        <Col className="grid-entry">
                            <NavMenuItem to={BASE_PATH +  "admin/request/dsa"}>
                                Delete
                            </NavMenuItem>
                        </Col>
                    </GridRow>
                ))}
            </>
        )
    }

    // build approved requests header
    const buildApprovedDSAHeaders = () => {
        return (
            <>
                <GridRow className="mt-3">
                    <Col className="grid-header grid-entry space-5 ">Title</Col>
                    <Col className="grid-header grid-entry space-3 ">Name</Col>
                    <Col className="grid-header grid-entry  space-3">Email</Col>
                    <Col className="grid-header grid-entry space-3"> Provider</Col>
                    <Col className="grid-header grid-entry space-2">Request Date</Col>
                    <Col className="grid-header grid-entry space-2">Date
                        Approved</Col>
                    <Col className="grid-header grid-entry">Download</Col>
                </GridRow>
            </>
        )
    }

    const getWorkingTitleLink = (request: IDataShareAgreement) => {
        return BASE_PATH + "dataShareAgreement/" + request.id;

    }


    // build approved requests body
    const buildApprovedDSARequests = () => {
        return (
            <>
                {dsaRequests?.map((request: IDataShareAgreement) => (
                    <GridRow className="mb-3">
                        <Col className="grid-entry space-5">
                            <GridRow>
                                <NavMenuItem to={getWorkingTitleLink(request)}>
                                    {request.workingTitle}
                                </NavMenuItem>

                            </GridRow>
                            <GridRow>Unique Dataset ID: {request.uniqueDatasetId != null ?
                                request.uniqueDatasetId : NA}</GridRow>
                            <GridRow>Clinical Trials ID(s): {(request.clinicalTrialId?.length > 0)?
                                request.clinicalTrialId : NA}
                            </GridRow>
                            <GridRow>Dataset Status:
                                {request.requestStatus != null ?
                                    request.requestStatus : NA}</GridRow>
                            </Col>
                        <Col className="grid-entry space-3">{decodeURIComponent(request.contactName)}</Col>
                        <Col className="grid-entry space-3">{request.contactEmail}</Col>
                        <Col className="grid-entry space-3 ">{decodeURIComponent(request.companyName)}</Col>
                        <Col className="grid-entry space-2 ">
                            {new Date(request.createdAt).toLocaleDateString('en-us', {
                                month: "numeric",
                                day: "numeric",
                                year: "numeric"
                            })}
                        </Col>
                        <Col className="grid-entry space-2 ">
                            {new Date(request.updatedAt).toLocaleDateString('en-us', {
                                month: "numeric",
                                day: "numeric",
                                year: "numeric"
                            })}
                        </Col>

                        <Col className="grid-entry">
                            <a onClick={() => downloadDsa(request.id, request.fileName)} className="linkstyle dsa" target={"_blank"}>Download
                        </a></Col>
                    </GridRow>
                ))}
            </>
        )
    }

    const buildSection = () => {
        if (requestType === APPROVED) {
            if (dsaRequests !== undefined && dsaRequests?.length > 0) {
                return (
                    <>
                        <Col className="mb-3">
                            {buildApprovedDSAHeaders()}
                            {buildApprovedDSARequests()}
                        </Col>
                    </>
                )
            } else {
                return (
                    <PDS_P_BOLD>No approved DSAs at this time</PDS_P_BOLD>
                )
            }
        } else {
            if (dsaRequests !== undefined && dsaRequests?.length > 0) {
                return (
                    <>
                        <Col className="mb-3">
                            {buildOpenDSAHeaders()}
                            {buildOpenDSARequests()}
                        </Col>

                    </>
                )
            } else {
                return (
                    <PDS_P_BOLD>No open DSA requests at this time</PDS_P_BOLD>
                )
            }
        }
    }


    return (
        <Container className={"w-100 "}>
            <Row className={"mt-3 "}></Row>
            <AdminCountsHeader countsList={DSA_HEADERS} requestType={requestType} countsUrl={"/api/admin/dashboard/counts/dsa"}/>
            {isLoading &&
                <Spinner  className ="spinner-center" animation={"border"} variant={"primary"}  role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}
            {error && <p>Error</p>}
            {data && buildSection()}

        </Container>
    )
}