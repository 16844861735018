/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminConvertUser
 *
 * Purpose: component to convert a user to valid user if already have an account
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 07Dec2023 craig  file created
 * 08Mar2024 craig  added basepath
 * 23May2024 sasjxa correct input to handleConvertSuccess
 \******************************************************************************/
import React, {useState} from "react";
import {Container} from "react-bootstrap";
import {useRequest} from "../../helper/useRequest";
import {ITicket} from "../../model/tickets/ITickets";
import {ConvertUserDialog} from "../dialogs/ConvertUserDialog";
import {BASE_PATH} from "../../constants";
import {AdminUserLoginsInfo} from "./AdminUserLoginsInfo";
import {UserInfoCol} from "../styled/StyledComponents";

interface AdminConvertUserProps {
    ticketId: number,
    fullName:string,

}

export const AdminConvertUser:  React.FC<AdminConvertUserProps> =
    ({
        ticketId, fullName
     }) => {
    const [userId, setUserId] = useState<number>(-1);
    const [showError, setShowError] = useState<boolean>(false);
    const CONVERT: string = "(convert)";

    const handleConvertError = (error: object) => {
       toggleErrorDialog();
    }

        /**
         * toggleDeleteDialog - show/hide the delete file dialog
         */
        const toggleErrorDialog = () => {
            setShowError(!showError);
        }



    const handleConvertSuccess = (ticket: ITicket) => {
        if (ticket != null) {
            setUserId(ticket?.user?.id);
            buildRow(ticketId, ticket?.user?.id, fullName);
        }
    }

    const [convertUser, getConvertUser] = useRequest({
        url: process.env.REACT_APP_API_URL + "/api/admin/tickets/convert/" + ticketId,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleConvertError,
        onSuccess: handleConvertSuccess
    })

    const {isLoading, data, error} = convertUser;



    const callConvertUser = (ticketId:number) => {
        getConvertUser();
    }

    const buildRow =(ticketId:number, userId:number, fullName:string) => {
        if (userId > 0) {
            return  (
                <UserInfoCol className={""}>
                    <AdminUserLoginsInfo userId={ticketId}/>
                <a href={BASE_PATH + "admin/userInfo/" + userId}>{fullName}</a>
                </UserInfoCol>
            )
        } else {
            return (
                <a className={"convert" } onClick={() => callConvertUser(ticketId)}>{fullName}{CONVERT}</a>
            )
        }
    }

    return (
        <Container className={"ps-0" }>
            <ConvertUserDialog
                show={showError} showHide={toggleErrorDialog} />
            {buildRow(ticketId,userId,fullName)}
        </Container>
    )
}