/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ProcContents
 *
 * Purpose: component for building the proc contents for sas file types
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 27Jun2024 sasjxa file created
 * 06Aug2024 craig  Added call to proc contents controller
 * 16Aug2024 craig  Added ProcContentsDialog
 \******************************************************************************/
import React, {useState} from "react";
import {IAccessData, IFile} from "../../model/donations/IAccessData";
import styled from "styled-components";
import {COLORS, FONT_SIZES} from "../styled/StyleConstants";
import {useRequest} from "../../helper/useRequest";
import {ProcContentsDialog} from "../dialogs/ProcContentsDialog";

const ProcContentsCol = styled.div`                                         
                                                              
  padding: 0;                                                                      
  margin: 0;                                                                       
  text-align: right;

  a {
    font-size: ${FONT_SIZES.px13};
    text-decoration: underline !important;  
    color:  ${COLORS.cerulean} !important;
  }
`;

interface ProcContentsProps {
    donation: IAccessData,
    file: IFile
}

export const ProcContents: React.FC<ProcContentsProps> = ({
                                                                  donation,
                                                                  file

                                                              }: ProcContentsProps) => {

    const [procContentsBody, setProcContentsBody] = useState<string>("");
    const [showProcContents, setShowProcContents] = useState<boolean>(false);
    const [procContentsTitle, setProcContentsTitle] = useState<string>("");

    /**
     * handleError - from axios download file request
     * TODO - file out proper error message
     *
     * @param error - error object
     */
    const handleError = (error: any) => {
        console.log(error);
    }

    /**
     * handleSuccess -
     *
     * @param response - response from axios
     */
    const handleSuccess = (response: string) => {
        setProcContentsBody(response);
        setShowProcContents(true);
        setProcContentsTitle("Proc Contents - " + file.filename);
    }


    const appDataURL = process.env.REACT_APP_API_URL + "/api/file/download/proccontents/" + donation.donationId + "/" + file.id;
    const [requestState, getProcContents] = useRequest({
        url: appDataURL,
        method: "post",
        initialIsLoading: true,
        withCredentials: true,
        onError: handleError,
        onSuccess: handleSuccess,
    })
    const {isLoading, data, error} = requestState;

    /**
     * toggleProcContentsDialog  - toggle the proc contents dialog
     */
    const toggleProcContentsDialog = () => {
        setShowProcContents(!showProcContents);
    }

    /**
     * buildDownloadLink - build the "download" link
     */
    const buildContentsLink = () => {

             return (
                 <>
                     <ProcContentsDialog title={procContentsTitle} body={procContentsBody}
                                 show={showProcContents} showHide={toggleProcContentsDialog}/>
                     <ProcContentsCol className={"pe-4"}>
                         <a onClick={() => getProcContents()}>Contents</a>
                     </ProcContentsCol>
                 </>
             )

    }

    /**
     * return the link
     */
    return (
        <>
            {buildContentsLink()}
        </>
    )
}