/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Admin Trial Years
 *
 * Purpose: Trial Years display for Admin purposes
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 03Nov2023 sasjxa file created
 \******************************************************************************/
import React from "react";
import {Col, Form, Row} from "react-bootstrap";

interface AdminTrialYearsProps {

    trialStartYear: any,
    trialEndYear: any,
    adminEditForm: boolean,

}


export const AdminTrialYears: React.FC<AdminTrialYearsProps> =
    ({
         trialStartYear, trialEndYear, adminEditForm,

     }) => {
        return (
            <>
                <Row className="mt-3 mb-3 ">
                    <Form.Group as={Col} lg={6}
                                controlId="formTrialStartYear">
                        <Form.Label className="bold"
                                    column="sm"> Start Year
                        </Form.Label>


                        <Form.Control size="sm"
                                      name={"trialStartYear"}
                                      type="text"
                                      defaultValue={trialStartYear}
                                      disabled={true}
                        />

                    </Form.Group>


                    <Form.Group as={Col} lg={6}
                                controlId="formTrialEndYear">
                        <Form.Label className="bold"
                                    column="sm"> End Year
                        </Form.Label>
                        <Form.Control size="sm"
                                      name={"trialEndYear"}
                                      type="text"
                                      defaultValue={trialEndYear}
                                      disabled={true}
                        />


                    </Form.Group>

                </Row>
            </>
        )

    }