/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminDatasetInfo
 *
 * Purpose: display dataset info
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 31Oct2023 sasjxa  file created
 * 02Nov2023 craig   Added call to API
 * 12Mar2024 sasjxa  encode/decode special chars (MPM-5389)
 * 04Apr2024 craig  Switched to react-error-boundary
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 25Apr2024 sasjxa refactoring of methods and styled components
 * 02Jul2024 craig  removed decoding (moved to api)
 \******************************************************************************/
import React, {useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {useRequest} from "../../helper/useRequest";
import {Container, Spinner} from "react-bootstrap";
import {ContentPage, FormLayoutColumn, FormSectionTitle, PageWrapper,} from "../../components/styled/StyledComponents";
import {IDonationMetadata} from "../../model/donations/IDonations";
import {COMPARATOR_ONLY, EXPERIMENTAL_ONLY, REAL_WORLD, STUDY_PHASES, TUMOR_TYPES} from "../../data/formList";
import {UserInfo} from "../../components/forms/UserInfo";
import {AdminDataFieldEdit} from "../../components/admin/AdminDataFieldEdit";
import {AdminTrialYears} from "../../components/admin/AdminTrialYears";
import {AdminDataFieldEditSelect} from "../../components/admin/AdminDataFieldEditSelect";
import {AdminCascadedData} from "../../components/admin/AdminCascadedData";
import {useErrorBoundary} from "react-error-boundary";

export const AdminDatasetInfo: React.FC = () => {
    const { showBoundary } = useErrorBoundary();
    const {donationId} = useParams();
    const [donationMetadata, setDonationMetadata] = useState<IDonationMetadata>();
    const [showComparatorArm, setShowComparatorArm] = useState(false);
    const [showActiveArm, setShowActiveArm] = useState(false);
    const [showRWDFields, setShowRWDFields] = useState(false);
    const [adminEditable, setAdminEditable] = useState(false);
    const [userFullName, setUserFullName] = useState<string>("");
    const APPROVED_STATUS = "Approved";

    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    // initialize fields after successful retrieve of data
    const handleGetDatasetInfo = (donationMetadata: IDonationMetadata) => {
        if (donationMetadata !== null) {
            setDonationMetadata(donationMetadata);
        }
    }

    // get data
    const metadataURL = process.env.REACT_APP_API_URL + "/api/admin/donations/metadata/" + donationId;
    const [requestState, getMetadata] = useRequest({
        url: metadataURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleGetDatasetInfo
    })
    const {isLoading, data, error} = requestState;

    useMemo(() => {
        if (donationId !== undefined) {
            getMetadata();
        }
    }, [])

    useMemo(() => {
        if (data != null) {
            setDonationMetadata(data);
        }
    }, [data])

    useMemo(() => {
        if (donationMetadata != null) {
            if (donationMetadata.dataShareAgreement.dataTypes === REAL_WORLD) {

                setShowRWDFields(true);
            } else {
                if (donationMetadata.dataShareAgreement?.dataTypes !== COMPARATOR_ONLY) {
                    setShowActiveArm(true);
                }
                if (donationMetadata.dataShareAgreement?.dataTypes !== EXPERIMENTAL_ONLY) {
                    setShowComparatorArm(true);
                }
            }

            if (donationMetadata.request?.requestStatus === APPROVED_STATUS) {
                setAdminEditable(true);
            }


            setUserFullName(donationMetadata.user?.userDetails?.first
                + " " + donationMetadata.user?.userDetails?.last);


        }
    }, [donationMetadata])

    const buildDatasetInfo = () => {
        return (
            <FormLayoutColumn className="mt-5 mb-5" lg={12}>
                <FormLayoutColumn lg={8}>

                </FormLayoutColumn>
            </FormLayoutColumn>
        )
    }

    const buildDatasetBasics = () => {
        return (
            <>
                <FormLayoutColumn className="mt-5 mb-5 " lg={12}>
                    <FormLayoutColumn lg={8} className="ps-3">
                        <FormSectionTitle>User Information</FormSectionTitle>
                        <UserInfo showUserFullName={userFullName} userEmail={donationMetadata?.user?.userDetails?.email}
                                  userPhone={donationMetadata?.user?.userDetails?.phoneNumber}/>
                        <AdminDataFieldEdit label={"Sponsor"} fieldName={"sponsor"} donationId={donationId}
                                            initialValue={donationMetadata?.sponsor} adminEditForm={adminEditable}/>
                        <AdminDataFieldEdit label={"Data Provider"} fieldName={"dataProvider"}
                                            initialValue={donationMetadata?.dataProvider} donationId={donationId}
                                            adminEditForm={adminEditable}/>
                        <FormSectionTitle>Data Asset Information</FormSectionTitle>
                        <AdminDataFieldEdit label={"Data Standard"} fieldName={"dataType"} donationId={donationId}
                                            initialValue={donationMetadata?.datasetType} adminEditForm={false}/>
                        {!showRWDFields && (
                            <>
                                <FormSectionTitle>CLINICALTRIAL.GOV Information</FormSectionTitle>
                                <AdminDataFieldEdit label={"CLINICALTRIAL.GOV ID (multiple entries include ',')"}
                                                    fieldName={"clinicalGovId"} donationId={donationId}
                                                    initialValue={donationMetadata?.clinicalGovId}
                                                    adminEditForm={false}/>
                            </>
                        )}
                        {showRWDFields && (
                            <>
                                <FormSectionTitle>Related Data Sources</FormSectionTitle>
                                <AdminDataFieldEdit label={"Other RWD such as genomics this data can link to"}
                                                    fieldName={"relatedDataSources"}
                                                    donationId={donationId}
                                                    initialValue={donationMetadata?.relatedDataSources}
                                                    adminEditForm={false}/>
                            </>
                        )}
                    </FormLayoutColumn>
                </FormLayoutColumn>


            </>
        )
    }

    const buildDataset = () => {
        return (
            <>
                <hr></hr>
                <FormLayoutColumn className="mt-5 mb-5 " lg={12}>
                    <FormLayoutColumn lg={8} className="ps-3">
                        {showRWDFields && (
                            <>
                                <FormSectionTitle>Data Collection Period</FormSectionTitle>
                                <AdminTrialYears trialStartYear={donationMetadata?.trialStartYear}
                                                 trialEndYear={donationMetadata?.trialEndYear}
                                                 adminEditForm={false}/>
                                <AdminDataFieldEdit label={"Outcome Measures"} fieldName={"outcomeMeasures"}
                                                    initialValue={donationMetadata?.outcomeMeasures}
                                                    donationId={donationId}
                                                    adminEditForm={false}/>
                            </>
                        )}
                        <FormSectionTitle>Descriptive Information</FormSectionTitle>
                        {showRWDFields && (
                            <>
                                <AdminDataFieldEdit label={"Trial or Data Collection Title"} fieldName={"trialTitle"}
                                                    initialValue={donationMetadata?.title}
                                                    donationId={donationId} adminEditForm={false}/>
                                <AdminDataFieldEdit label={"Data Summary and Conditions"} fieldName={"trialSummary"}
                                                    initialValue={donationMetadata?.trialSummary}
                                                    donationId={donationId} adminEditForm={false}/>
                            </>
                        )}
                        <AdminDataFieldEdit label={"Data Summary"} fieldName={"dataSummary"}
                                            initialValue={donationMetadata?.dataSummary} donationId={donationId}
                                            adminEditForm={false}/>
                        <AdminDataFieldEdit label={"Objectives"} fieldName={"studyObjectives"}
                                            initialValue={donationMetadata?.studyObjectives} donationId={donationId}
                                            adminEditForm={false}/>
                        <FormSectionTitle>Design Information</FormSectionTitle>
                        <AdminDataFieldEdit label={showRWDFields ? "Total Patients" : "Total Study Enrolled Patients"}
                                            fieldName={"sampleSize"}
                                            initialValue={donationMetadata?.sampleSize} donationId={donationId}
                                            adminEditForm={false}/>
                        {showComparatorArm && (
                            <AdminDataFieldEdit label={"Comparator (Control) Arm Enrolled Patients"}
                                                fieldName={"comparatorArmPatients"}
                                                initialValue={donationMetadata?.comparatorArmPatients}
                                                donationId={donationId} adminEditForm={false}/>
                        )}
                        {showActiveArm && (
                            <AdminDataFieldEdit label={"Experimental (Active) Arm Enrolled Patients"}
                                                fieldName={"activeArmPatients"}
                                                initialValue={donationMetadata?.activeArmPatients}
                                                donationId={donationId} adminEditForm={false}/>
                        )}
                        {showRWDFields && (
                            <>
                                <AdminDataFieldEdit label={"Population Grouping or Subsets By"}
                                                    fieldName={"populationGrouping"}
                                                    initialValue={donationMetadata?.populationGrouping}
                                                    donationId={donationId} adminEditForm={false}/>
                                <AdminDataFieldEdit label={"Single or Multi-institutional Sites"}
                                                    fieldName={"institutionalSites"}
                                                    initialValue={donationMetadata?.institutionalSites}
                                                    donationId={donationId} adminEditForm={false}/>
                                <AdminDataFieldEdit label={"Instrument or Data Collection"} fieldName={"dataCollection"}
                                                    initialValue={donationMetadata?.dataCollection}
                                                    donationId={donationId} adminEditForm={false}/>
                            </>
                        )}
                        <AdminDataFieldEditSelect label="Study Phase(s)" fieldName={"studyPhases"}
                                                  initialValues={donationMetadata?.studyType == null ? [] : donationMetadata?.studyType}
                                                  donationId={donationId} listValues={STUDY_PHASES}
                                                  adminEditForm={adminEditable}/>
                        <AdminDataFieldEditSelect label="Tumor Type(s)" fieldName={"tumorTypes"}
                                                  initialValues={donationMetadata?.tumorType == null ? [] : donationMetadata?.tumorType}
                                                  donationId={donationId} listValues={TUMOR_TYPES}
                                                  otherFieldName={"tumorTypeOther"}
                                                  otherValue={donationMetadata?.tumorTypeOther}
                                                  adminEditForm={adminEditable}/>
                        {showRWDFields && (
                            <>
                                <AdminDataFieldEdit label={"Region(s)"}
                                                    fieldName={"region"}
                                                    initialValue={donationMetadata?.region == null ? [] : donationMetadata?.region.join(",")}
                                                    donationId={donationId} adminEditForm={false}/>
                                <AdminDataFieldEdit label={"Age Range(s)"}
                                                    fieldName={"ageRange"}
                                                    initialValue={donationMetadata?.ageRange == null ? [] : donationMetadata?.ageRange.join(",")}
                                                    donationId={donationId} adminEditForm={false}/>
                            </>
                        )}

                    </FormLayoutColumn>
                </FormLayoutColumn>


            </>
        )
    }

    const buildDatasetSuppInfo = () => {
        return (
            <>
                <hr></hr>

                <FormLayoutColumn className="mt-5 mb-5" lg={12}>
                    <FormLayoutColumn lg={8} className="ps-3">
                        <AdminDataFieldEdit label={"Unique Patient Identifier"} fieldName={"uniquePatientIdentifier"}
                                            initialValue={donationMetadata?.supplementalInformation?.uniquePatientIdentifier}
                                            donationId={donationId} adminEditForm={false}/>
                        <AdminDataFieldEdit label={"Date/Time Variables"} fieldName={"dateTimeVars"}
                                            initialValue={donationMetadata?.supplementalInformation?.dateTimeVars}
                                            donationId={donationId} adminEditForm={false}/>
                        <FormSectionTitle>Descriptive Information</FormSectionTitle>
                        <AdminDataFieldEdit label={"Efficacy Endpoints"} fieldName={"efficacyEndpoints"}
                                            initialValue={donationMetadata?.supplementalInformation?.efficacyEndpoints}
                                            donationId={donationId} adminEditForm={false}/>
                        <AdminDataFieldEdit label={"Domains and Variables"} fieldName={"efficacyEndpointsDomainVars"}
                                            initialValue={donationMetadata?.supplementalInformation?.efficacyEndpointsDomainVars}
                                            donationId={donationId} adminEditForm={false}/>
                        {!showRWDFields && (
                            <>
                                <AdminCascadedData fieldlabel1={"Was PRO data captured as part of this study?"}
                                                   fieldName1={"patientReportedOutcomesCaptured"}
                                                   fieldValue1={donationMetadata?.supplementalInformation?.patientReportedOutcomesCaptured}
                                                   fieldlabel2={"If yes, are you providing them as part of this upload?"}
                                                   fieldName2={"patientReportedOutcomesProvided"}
                                                   fieldValue2={donationMetadata?.supplementalInformation?.patientReportedOutcomesProvided}
                                                   fieldlabel3={"If yes, please indicate which file(s) contain the PRO data"}
                                                   fieldName3={"patientReportedOutcomesFiles"}
                                                   fieldValue3={donationMetadata?.supplementalInformation?.patientReportedOutcomesFiles}/>
                                <FormSectionTitle>Custom Formats and Patient Breakdown</FormSectionTitle>
                                <AdminCascadedData fieldlabel1={"Does this dataset use custom formats?"}
                                                   fieldName1={"customFormatsUsed"}
                                                   fieldValue1={donationMetadata?.supplementalInformation?.customFormatsUsed}
                                                   fieldlabel2={"If yes, is the dataset decoded (i.e. format built in)?"}
                                                   fieldName2={"customFormatsDataDecoded"}
                                                   fieldValue2={donationMetadata?.supplementalInformation?.customFormatsDataDecoded}
                                                   fieldlabel3={"If no, is the custom format being provided as part of this upload?"}
                                                   fieldName3={"customFormatsUploaded"}
                                                   fieldValue3={donationMetadata?.supplementalInformation?.customFormatsUploaded}/>
                                <AdminDataFieldEdit label={"Patient Breakdown"} fieldName={"patientBreakdown"}
                                                    initialValue={donationMetadata?.supplementalInformation?.patientBreakdown}
                                                    donationId={donationId} adminEditForm={false}/>
                            </>
                        )}
                        {showRWDFields && (
                            <>
                                <FormSectionTitle>Patient Breakdown</FormSectionTitle>
                                <AdminDataFieldEdit label={"Patient Breakdown"} fieldName={"patientBreakdown"}
                                                    initialValue={donationMetadata?.supplementalInformation?.patientBreakdown}
                                                    donationId={donationId} adminEditForm={false}/>
                            </>
                        )}

                    </FormLayoutColumn>
                </FormLayoutColumn>
            </>
        )
    }


    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>

                    <FormLayoutColumn lg={8}>
                        {buildDatasetBasics()}
                        {buildDataset()}
                        {buildDatasetSuppInfo()}
                    </FormLayoutColumn>
                </PageWrapper>
                {isLoading &&
                    <Spinner  className ="spinner-center" animation={"border"} variant={"primary"} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>}
                {error && <p>Error</p>}
                {donationMetadata && buildDatasetInfo()}
            </ContentPage>

        </Container>
    )

}