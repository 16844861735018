/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminUserRequestAction
 *
 * Purpose: Admin User Approval
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 11Jul2023 sasjxa file created
 * 11Aug2023 sasjxa add previous dispositions
 * 08Feb2024 craig  updated url to api
 * 23Feb2024 sasjxa add clear button functionality (MPM-5425)
 * 08Mar2024 craig  added base_path
 * 12Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 04Apr2024 craig  Switched to react-error-boundary
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 25Apr2024 sasjxa refactoring of methods and styled components
 * 02Jul2024 craig  removed decode (moved to api)
 * 21Aug2024 craig  removed unused sessionUser
 * 29Sep2024 sasjxa correct error message field for RMI reason
 * 31Oct2024 craig  Added waitingForResponse to prevent new submit before response
 * 01Nov2024 craig  cleaned up warnings
 \******************************************************************************/
import React, {Fragment, useMemo, useRef, useState} from "react";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import * as Yup from 'yup';
import {ErrorMessage, Formik, FormikProps} from "formik";
// @ts-ignore
import {
    ContentPage,
    FormLayoutColumn, GridRow,
    PageWrapper,
    PDS_H5,
    TextAreaCountRow
} from "../../components/styled/StyledComponents";

import {
    ACCOUNT_DECLINE_REASONS,
    APPROVED,
    DECLINE_USER,
    DECLINED,
    FormList,
    NEW,
    OTHER_OPTION,
    RMI_ENROLLMENT_REASONS,
    RMI_USER,
    SUBMIT,
    USER_APPROVAL,
} from "../../data/formList";
import {useNavigate, useParams} from "react-router-dom";
import {ClearAndSubmitButtonContainer} from "../../components/buttonContainer/ClearAndSubmitButtonContainer";
import {useRequest} from "../../helper/useRequest";
import {IAdminDispositionUser, IAdminRequestUser} from "../../model/admin/requests/IAdminRequestUser";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";


export const AdminUserRequestAction: React.FC = () => {

    const {requestId, requestType} = useParams();
    const { showBoundary } = useErrorBoundary();

    const navigate = useNavigate();
    const [showRMIReason, setShowRMIReason] = useState(false);
    const [showDeclinedReason, setShowDeclinedReason] = useState(false);
    const [commentsCount, setCommentsCount] = useState(0);
    const [comments, setComments] = useState("");
    const [actions, setActions] = useState("");
    const [initialCommentsLabel, setInitialCommentsLabel] = useState("Comments ");
    const [showDeclinedOther, setShowDeclinedOther] = useState(false);
    const maxCommentsCount = 1000;
    const externalComments = "Comments (External to User)";
    const internalComments = "Comments (Internal Only)";
    const formikRef = useRef<FormikProps<any>>(null);
    const [userRequest, setUserRequest] = useState<IAdminRequestUser>();

    //this will disable the submit button while waiting for the response after submit
    const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);

    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        setIsWaitingForResponse(false);
        showBoundary(error);
    }

    // initialize fields after successful retrieve of data
    const handleGetUserRequest = (userRequest: IAdminRequestUser) => {
        if (userRequest !== null && userRequest.user != null && userRequest.user.userDetails != null) {
            userRequest.user.userDetails.first = userRequest.user.userDetails.first;
            userRequest.user.userDetails.last = userRequest.user.userDetails.last;
            setUserRequest(userRequest);
        }
    }

    // handle successful save of data
    const handleSuccess = () => {
        setIsWaitingForResponse(false);
        navigate(BASE_PATH + 'admin/request/users/' + requestType)
    }

    //get data
    const requestURL = process.env.REACT_APP_API_URL + "/api/admin/users/request/" + requestId;
    const [requestState, getUserRequest] = useRequest({
        url: requestURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleGetUserRequest
    })
    const {isLoading, data, error} = requestState;

    // save data
    const actionURL = process.env.REACT_APP_API_URL + "/api/admin/users/requestaction/" + requestId;
    const [requestActionState, setUserRequestAction] = useRequest({
        url: actionURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })


    useMemo(() => {
        getUserRequest();
    }, [])

    useMemo(() => {
        if (data != null) {
            setUserRequest(data);
        }
    }, [data])

    const getStatus = () => {
        if (requestType === NEW) {
            return "Request is Pending";
        } else if (requestType === APPROVED) {
            return "Request has been approved";
        } else if (requestType === DECLINED) {
            return "Request has been declined";
        }
    }

    // create the select list
    const buildSelectList = (listType: FormList[]) => {
        let optionTemplate = listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
        return optionTemplate;
    }

    // show other field when 'Other" selected from list
    const handleDeclinedChanged = (value: string) => {
        if (value === OTHER_OPTION) {
            setShowDeclinedOther(true);
        } else if (showDeclinedOther) {
            setShowDeclinedOther(false);
        }
    }

    // change label and dropdown depending on selection
    const handleActionsChanged = (value: string) => {
        setActions(value);
        setComments(value);
        if (value === RMI_USER || value === DECLINE_USER) {
            setInitialCommentsLabel(externalComments);
        } else {
            setInitialCommentsLabel(internalComments);
        }

        if (value === RMI_USER) {
            setShowRMIReason(true);
            setShowDeclinedReason(false);


        } else if (value === DECLINE_USER) {
            setShowDeclinedReason(true);
            setShowRMIReason(false);

        } else {
            setShowDeclinedReason(false);
            setShowRMIReason(false);
        }
    }

    // clear form fields
    const handleReset = () => {
        if (formikRef != null && formikRef.current) {
            formikRef.current.resetForm();
            reinitializeValues();
        }
    }

    // re-initialize values after clear
    const reinitializeValues = () => {
        setShowDeclinedReason(false);
        setShowRMIReason(false);
    }

    // validation schema
    const schema = Yup.object().shape({
        actions: Yup.string().required("Please select an action."),

        rmiReason: Yup.string().when(['actions'], {
            is: (actions: string) => {
                return actions === RMI_USER; // for all other cases is not required
            },
            then: (schema) =>
                schema
                    .required("Please select a reason for request more information")
        }),

        declinedReason: Yup.string().when(['actions'], {
            is: (actions: string) => {
                return actions === DECLINE_USER; // for all other cases is not required
            },
            then: (schema) =>
                schema
                    .required("Please select a reason for request more information")
        }),
        declinedOther: Yup.string().when(['declinedReason'], {
            is: (declinedReason: string) => {
                return declinedReason === OTHER_OPTION; // for all other cases not required
            },
            then: (schema) =>
                schema
                    .required("Please enter a reason for declining.")
        }),


        comments: Yup.string().required("Please enter comments"),
    });


    const buildSection = () => {
        if (requestType === DECLINED) {
            return (
                <>
                    <Col className="mb-5">
                        <PDS_H5 className="mt-2 mb-3 ms-4">Current Status: {getStatus()}</PDS_H5>
                        <Row className="justify-content-between ms-3 w-50 ">
                            <Col className="">Name: {userRequest?.user.userDetails.first + " " + userRequest?.user.userDetails.last}</Col>
                            <Col>Email: {userRequest?.user.userDetails.email}</Col>
                        </Row>
                    </Col>
                    <Col className="mt-5">
                    {buildDispositions()}
                    </Col>

                </>
            )
        } else {
            return (
                <>
                    <Col className="">
                        <PDS_H5 className="mt-2 mb-2 ms-4">Current Status: {getStatus()}</PDS_H5>
                        <Row className="justify-content-between ms-3 w-50 ">
                            <Col className="">Name: {userRequest?.user.userDetails.first + " " + userRequest?.user.userDetails.last}</Col>
                            <Col>Email: {userRequest?.user.userDetails.email}</Col>
                        </Row>
                    </Col>
                    {buildForm()}
                    {buildDispositions()}
                </>
            )
        }
    }

    const buildDispositions = () => {
        if (userRequest !== undefined && userRequest?.dispositions !== undefined && userRequest?.dispositions?.length > 0) {
            return (
                <>
                    <PDS_H5 className= "mb-2 ms-4">Previous Dispositions</PDS_H5>
                    {buildPreviousDispositionHeaders ()}
                    {buildPreviousDispositionRows ()}
                </>
            )
        }
    }

    const buildPreviousDispositionHeaders = () => {
        return (
            <>
                <GridRow className="ms-4">
                    <Col  lg={1} className="grid-header grid-entry  ">Status</Col>
                    <Col lg={2} className="grid-header grid-entry  "> User</Col>
                    <Col lg={1} className="grid-header grid-entry ">Date</Col>
                    <Col lg={4} className="grid-header grid-entry "> Reason</Col>
                    <Col lg={4} className="grid-header grid-entry">Comments</Col>
                </GridRow>
            </>
        )
    }

    const buildPreviousDispositionRows = () => {
        return (
            <>
                {userRequest?.dispositions?.map((disposition: IAdminDispositionUser) => (
                <GridRow className="ms-4">
                    <Col  lg={1} className=" grid-entry  ">{disposition.status}</Col>
                    <Col  lg={2} className=" grid-entry  ">{disposition.user?.userDetails?.email}</Col>
                    <Col lg={1} className=" grid-entry">
                        {new Date(disposition.createdAt).toLocaleDateString('en-us', {
                            month: "numeric",
                            day: "numeric",
                            year: "numeric"
                        })}
                    </Col>
                    <Col lg={3} className=" grid-entry ">{disposition.reason} </Col>
                    <Col lg={4} className=" grid-entry">{disposition.comments}</Col>
                </GridRow>
                ))}
            </>
        )
    }
    const buildForm = () => {
        return (
            <>
                <Formik innerRef={formikRef}
                    enableReinitialize
                    validationSchema={schema}
                    onSubmit={(values) => {
                        setIsWaitingForResponse(true);
                        setUserRequestAction(JSON.stringify({
                            actions: values.actions, rmiReason: values.rmiReason,
                            declinedReason: values.declinedReason, declinedOther: values.declinedOther,
                            comments: values.comments
                        }));
                    }}
                    initialValues={{
                        actions:  actions ?? "",
                        rmiReason: '',
                        declinedReason: '',
                        declinedOther: '',
                        comments:  comments ?? '',

                    }}
                    validateOnChange={false}
                    validateOnBlur={false}>
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          touched,
                          errors
                      }) => (
                        <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                            <FormLayoutColumn lg={12}>
                                <FormLayoutColumn lg={8}>
                                    <Row className="justify-content-center mt-3 mb-3">
                                        <Form.Group as={Col} lg={8}
                                                    controlId="formAction">
                                            <Form.Label className="required" column="sm">Actions </Form.Label>
                                            <Form.Select size="sm"
                                                         name={"actions"}
                                                         value = {values.actions}
                                                         isValid={touched.actions && !errors.actions}
                                                         onChange={e => {
                                                             handleActionsChanged(e.target.value);
                                                             handleChange(e);
                                                         }}>
                                                {buildSelectList(USER_APPROVAL)}
                                            </Form.Select>
                                            <Form.Text className="text-muted"></Form.Text>
                                            <Form.Control.Feedback type="invalid">
                                                Please select an action.
                                            </Form.Control.Feedback>
                                            <ErrorMessage name={"actions"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>

                                    {showRMIReason && (
                                        <Row className=" justify-content-center mt-3 mb-3 mx-3">
                                            <Form.Group as={Col} lg={8}
                                                        controlId="formAction">
                                                <Form.Label className="required" column="sm">RMI Reason </Form.Label>
                                                <Form.Select size="sm"
                                                             name={"rmiReason"}
                                                             isValid={touched.rmiReason && !errors.rmiReason}
                                                             onChange={handleChange}>
                                                    {buildSelectList(RMI_ENROLLMENT_REASONS)}
                                                </Form.Select>
                                                <Form.Text className="text-muted"></Form.Text>
                                                <Form.Control.Feedback type="invalid">
                                                    Please select an action
                                                </Form.Control.Feedback>
                                                <ErrorMessage name={"rmiReason"}
                                                              render={msg => <div
                                                                  className={"form-error-msg"}>{msg}</div>}/>
                                            </Form.Group>
                                        </Row>
                                    )}

                                    {showDeclinedReason && (
                                        <Row className=" justify-content-center mt-3 mb-3 mx-3">
                                            <Form.Group as={Col} lg={8}
                                                        controlId="formAction">
                                                <Form.Label className="required" column="sm">Decline
                                                    Reason </Form.Label>
                                                <Form.Select size="sm"
                                                             name={"declinedReason"}
                                                             isValid={touched.declinedReason && !errors.declinedReason}
                                                             onChange={e => {
                                                                 handleDeclinedChanged(e.target.value);
                                                                 handleChange(e);
                                                             }}>
                                                    {buildSelectList(ACCOUNT_DECLINE_REASONS)}
                                                </Form.Select>
                                                <Form.Text className="text-muted"></Form.Text>
                                                <Form.Control.Feedback type="invalid">
                                                    Please select an action
                                                </Form.Control.Feedback>
                                                <ErrorMessage name={"declinedReason"}
                                                              render={msg => <div
                                                                  className={"form-error-msg"}>{msg}</div>}/>
                                            </Form.Group>
                                        </Row>
                                    )}

                                    {showDeclinedOther && (
                                        <Row className="justify-content-center mt-3 mb-3 mx-4">
                                            <Form.Group as={Col} className={"otherFieldGroup"} lg={8}
                                                        controlId="formDeclinedOther">
                                                <Form.Label className="required" column="sm">Other
                                                    Declined </Form.Label>
                                                <Form.Control size="sm" required
                                                              name={"declinedOther"}
                                                              type="text"
                                                              isValid={touched.declinedOther && !errors.declinedOther}
                                                              onChange={handleChange}/>
                                                <Form.Text className="text-muted"></Form.Text>
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a reason for declining.
                                                </Form.Control.Feedback>
                                                <ErrorMessage name={"declinedOther"}
                                                              render={msg => <div
                                                                  className={"form-error-msg"}>{msg}</div>}/>
                                            </Form.Group>
                                        </Row>
                                    )}

                                    <Row className="mt-3 mb-3 justify-content-center">
                                        <Form.Group as={Col} lg={8} className="mb-3"
                                                    controlId="formComments">
                                            <TextAreaCountRow>
                                                <Form.Label className="required"
                                                            column="sm">{initialCommentsLabel} </Form.Label>
                                                <span>{commentsCount} of {maxCommentsCount} characters</span>
                                            </TextAreaCountRow>

                                            <Form.Control as="textarea" rows={5} size="sm" required
                                                          name={"comments"}
                                                          type="text"
                                                          value={values.comments}
                                                          isValid={touched.comments && !errors.comments}
                                                          onChange={e => {
                                                              setCommentsCount(e.target.value.length);
                                                              handleChange(e);
                                                          }}/>
                                            <Form.Control.Feedback type="invalid">
                                                Please select an action
                                            </Form.Control.Feedback>
                                            <ErrorMessage name={"comments"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>
                                </FormLayoutColumn>
                            </FormLayoutColumn>
                            <ClearAndSubmitButtonContainer handleReset={handleReset} enableClear={true}
                                                            isSubmitting={isWaitingForResponse}
                                                           submitButtonText={SUBMIT}/>
                        </Form>

                    )}
                </Formik>
            </>
        )
    }

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn>
                        {isLoading &&
                            <Spinner  className ="spinner-center" animation={"border"} variant={"primary"}  role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>}
                        {error && <p>Error</p>}
                        {data && buildSection()}
                    </FormLayoutColumn>
                </PageWrapper>
            </ContentPage>
        </Container>
    );

}
