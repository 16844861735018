/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Share
 *
 * Purpose: NCI Download Approval
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 20Jun2023 sasjxa  file created.
 * 17Jan2024 sasjxa  add logic for downloading nci agreement file
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 \******************************************************************************/
import React from "react";
import {
    BackButton,
    ContentPage,
    PageWrapper,
    PDS_H5,
    PDS_P,
    PDSSubmitButton,
    ShareDataButtonContainer,
    ShareDataButtonGroup
} from "../../components/styled/StyledComponents";

import {Col, Container, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
// @ts-ignore
import styled from "styled-components";
import {Aside} from "../../components/Aside";
import axios from "axios";
import {BASE_PATH} from "../../constants";

const ButtonSpacingRow = styled(Row)`      
  padding-bottom: 2%;
`;


const DownloadApprovalButton = styled(PDSSubmitButton)`
   width: 100%;
   margin: 5%;
           
`;

const SharedHeader = styled(PDS_H5)`  
   margin-top: 2%;
`;


export const NCIDownloadApproval: React.FC = () => {
    const navigate = useNavigate();
    const {requestId} = useParams();

    /**
     * build the download url
     */
    const downloadUrl = process.env.REACT_APP_API_URL + "/api/file/download/nci/oa";

    /**
     * handleSuccess - from axios download file request
     *
     * @param error - error object
     */
    const handleSuccess = (response: any) => {
        console.log("successful download of oa file");
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "NCTN_Organization_Approval.pdf");
        document.body.appendChild(link);
        link.click();
        if (link.parentNode !== null) {
            link.parentNode.removeChild(link);
        }
    }

    /**
     * handleError - from axios download file request
     * TODO - file out proper error message
     *
     * @param error - error object
     */
    const handleError = (error: any) => {
        console.log(error);
    }

    /**
     * call the url to download the file
     */
    const downloadFile = async () => {
        try {
            const response = await axios({
                url: downloadUrl,
                method: 'GET',
                withCredentials: true,
                responseType: 'blob', // important
                headers: {
                    'Accept': "application/json",
                    'Content-Type': "application/json",
                    'Access-Control_Allow-Origin': "*",
                    'Access-Control-Allow-Credentials': "true"
                }
            })
            handleSuccess(response);
        } catch (error) {
            handleError(error);
        }
    }

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <Col md={8} lg={9} name={"shareDataColumn"}>
                        <SharedHeader>Download Organization Approval</SharedHeader>
                        <PDS_P>Please download and print the National Cancer Institute Organization Approval form for
                            approval and signature by the Authorized Representative that you previously identified in this
                            data request.
                            To download the form, use the button below.
                        </PDS_P>
                        <Row>
                            <Col md={8} lg={5}>
                                <DownloadApprovalButton onClick={() => {
                                    downloadFile()
                                }}>Download Organizational Approval</DownloadApprovalButton>
                            </Col>
                        </Row>
                        <ButtonSpacingRow>
                            <ShareDataButtonContainer>
                                <BackButton variant="secondary" className="btn-submit" type="button" onClick={() => {
                                    navigate(BASE_PATH +"NCIRequest/" + requestId)
                                }}>
                                    Back
                                </BackButton>
                                <ShareDataButtonGroup>
                                    <PDSSubmitButton variant="success" className="btn-submit" type="button"
                                                     onClick={() => {
                                                         navigate(BASE_PATH + "NCIUploadApproval/" + requestId)
                                                     }}>
                                        Next
                                    </PDSSubmitButton>
                                </ShareDataButtonGroup>

                            </ShareDataButtonContainer>
                        </ButtonSpacingRow>
                    </Col>
                    <Col md={4} lg={3}>
                        <Aside />
                    </Col>
                </PageWrapper>
            </ContentPage>
        </Container>
    )
        ;
}