/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Share Dataset
 *
 * Purpose: Share Dataset page for Real World Data
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 29Aug2023 sasjxa file created
 * 20Oct2023 sasjxa add more field to useMemo and submit
 * 26Oct2023 sasjxa add more fields for rwd
 * 31Oct2023 sasjxa add trial title
 * 23Feb2024 sasjxa add clear button functionality (MPM-5425)
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 12Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 19Jun2024 sasjxa remove label classname parm from specialized components
 * 02Jul2024 craig  moved decode to api
 * 04Nov2024 sasjxa added yup max to text fields
 \******************************************************************************/
import React, {useMemo, useRef, useState} from "react";
import {Col, Container, Form, Spinner} from "react-bootstrap";
import * as Yup from 'yup';
import {Formik, FormikProps} from "formik";
// @ts-ignore
import styled from 'styled-components';
import {
    ContentPage,
    FormLayoutColumn,
    FormSectionTitle,
    PageWrapper,
    PDS_H5,
    PDS_P,
} from "../../components/styled/StyledComponents";

import "../forms/forms.css";
import {useNavigate, useParams} from "react-router-dom";
import {FormList, max1000Count, max2000Count, max5000Count, max600Count, OTHER_OPTION} from "../../data/formList";
import {Aside} from "../../components/Aside";
import {useRequest} from "../../helper/useRequest";
import {IDonationMetadata} from "../../model/donations/IDonations";
import {TrialYears} from "../../components/share/TrialYears";
import {Region} from "../../components/share/Region";
import {AgeRange} from "../../components/share/AgeRange";
import {BackClearAndSubmitButtonContainer} from "../../components/buttonContainer/BackClearAndSubmitButtonContainer";
import {StudyTypes} from "../../components/share/StudyTypes";
import {TumorTypes} from "../../components/share/TumorTypes";
import {TextFieldRequired} from "../../components/forms/TextFieldRequired";
import {TextAreaRequired} from "../../components/forms/TextAreaRequired";
import {TextAreaOptional} from "../../components/forms/TextAreaOptional";
import {BASE_PATH} from "../../constants";
import {enableClearButton, getEncodedValue} from "../../constants/CommonFunctions";
import {useErrorBoundary} from "react-error-boundary";
import {
    dataCollectionDesc,
    dataSummaryDesc,
    institutionDesc,
    objectivesDesc,
    outcomesDesc,
    populationDesc,
    therapyDesc,
    TOTAL_ENROLLED_ERROR_RWD_MSG,
    trialSummaryDesc
} from "../../constants/ShareDataFieldsText";


export const ShareDatasetRWD: React.FC = () => {
    const {donationId} = useParams();
    const navigate = useNavigate();
    const { showBoundary } = useErrorBoundary();
    const [disabled, setDisabled] = useState(false);
    const [trialTitleCount, setTrialTitleCount] = useState(0);
    const [trialSummaryCount, setTrialSummaryCount] = useState(0);
    const [dataSummaryCount, setDataSummaryCount] = useState(0);
    const [outcomesMeasuresCount, setOutcomeMeasuresCount] = useState(0);
    const [populationGroupingCount, setPopulationGroupingCount] = useState(0);
    const [institutionalSitesCount, setInstitutionalSitesCount] = useState(0);
    const [dataCollectionCount, setDataCollectionCount] = useState(0);
    const [therapyDrugCount, setTherapyDrugCount] = useState(0);
    const [studyObjectivesCount, setStudyObjectivesCount] = useState(0);
    const [showTumorTypesOther, setShowTumorTypesOther] = useState(false);
    const [trialStartYear, setTrialStartYear] = useState("");
    const [trialEndYear, setTrialEndYear] = useState("");
    const [trialTitle, setTrialTitle] = useState("");
    const [trialSummary, setTrialSummary] = useState("");
    const [dataSummary, setDataSummary] = useState("");
    const [outcomeMeasures, setOutcomeMeasures] = useState("");
    const [populationGrouping, setPopulationGrouping] = useState("");
    const [institutionalSites, setInstitutionalSites] = useState("");
    const [dataCollection, setDataCollection] = useState("");
    const [therapyDrug, setTherapyDrug] = useState("");
    const [sampleSize, setSampleSize] = useState("");
    const [tumorTypeOther, setTumorTypeOther] = useState("");
    const [studyObjectives, setStudyObjectives] = useState("");
    const [selectedStudyType, setSelectedStudyType] = useState<string[]>([]);
    const [selectedTumorType, setSelectedTumorType] = useState<string[]>([]);
    const [selectedRegion, setSelectedRegion] = useState<string[]>([]);
    const [selectedAgeRange, setSelectedAgeRange] = useState<string[]>([]);
    const [shareData, setShareData] = useState<IDonationMetadata>();



    const formikRef = useRef<FormikProps<any>>(null);

    const numericRegExp = /^[0-9]+$/

    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    const handleSuccess = () => {
        navigate(BASE_PATH + 'ShareDatasetSuppInfoRWD/' + donationId);
    }

    // get data
    const shareDataUrl = process.env.REACT_APP_API_URL + "/api/share/shareDataset/" + donationId;
    const [requestState, getShareDataData] = useRequest({
        url: shareDataUrl,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })
    const {isLoading, data, error} = requestState;

    // save data
    const [requestActionState, setShareDataset] = useRequest({
        url: shareDataUrl,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })

    useMemo(() => {
        if ((donationId !== undefined && Number(donationId) > 0)) {
            getShareDataData();
        }
    }, [])
    useMemo(() => {
        if (data != null) {
            setShareData(data);
        }
    }, [data])

    useMemo(() => {
        if (shareData != null) {
            setTrialStartYear(shareData.trialStartYear);
            setTrialEndYear(shareData.trialEndYear);
            setOutcomeMeasures(shareData.outcomeMeasures);
            setDataSummary(shareData.dataSummary);
            setTrialTitle(shareData.title);
            setTrialSummary(shareData.trialSummary);
            setStudyObjectives(shareData.studyObjectives);
            setSampleSize(shareData.sampleSize!= null ? shareData.sampleSize.toString(): "");
            setPopulationGrouping(shareData.populationGrouping);
            setInstitutionalSites(shareData.institutionalSites);
            setDataCollection(shareData.dataCollection);
            setTherapyDrug(shareData.therapyDrug);
            setTumorTypeOther(shareData.tumorTypeOther);

            //------------------
            let tmpStudyTypes = new Array<string>();
            if (shareData.studyType != null && shareData.studyType.length > 0) {
                shareData.studyType.map((t: any) => tmpStudyTypes.push(t));
            }
             setSelectedStudyType(tmpStudyTypes);
            //-------------------------

            //------------------
            let tmpTumorTypes = new Array<string>();
            if (shareData.tumorType != null && shareData.tumorType.length > 0) {
                shareData.tumorType.map((t: any) => tmpTumorTypes.push(t));
            }
            setSelectedTumorType(tmpTumorTypes);
            //------------------
            let tmpAgeRange = new Array<string>();
            if (shareData.ageRange != null && shareData.ageRange.length > 0) {
                shareData.ageRange.map((t: any) => tmpAgeRange.push(t));
            }
            setSelectedAgeRange(tmpAgeRange);
            //------------------
            let tmpRegion = new Array<string>();
            if (shareData.region != null && shareData.region.length > 0) {
                shareData.region.map((t: any) => tmpRegion.push(t));
            }
            setSelectedRegion(tmpRegion);


            if (shareData.tumorTypeOther != null && shareData.tumorTypeOther != "") {
                setShowTumorTypesOther(true);
            }
        }
    }, [shareData])

    // validation schema
    const schema = Yup.object().shape({
        trialStartYear: Yup.string().required("Please select the trial start year."),
        trialEndYear: Yup.string().required("Please select the trial end year."),
        trialTitle: Yup.string().required("Please provide the trial title."),
        trialSummary: Yup.string().required("Please provide the data summary and conditions."),
        dataSummary: Yup.string().required("Please provide a data summary."),
        studyObjectives: Yup.string().required("Please provide objectives."),
        sampleSize: Yup.string().required(TOTAL_ENROLLED_ERROR_RWD_MSG).matches(numericRegExp, TOTAL_ENROLLED_ERROR_RWD_MSG).max(10, "Sample size should be no longer than 10 characters."),
        ageRange: Yup.array()
            .of(Yup.string())
            .min(1, 'Please select an age range.')
            .required('Please select an age range.'),
        region: Yup.array()
            .of(Yup.string())
            .min(1, 'Please select a region..')
            .required('Please select a region.'),
        studyType: Yup.array()
            .of(Yup.string())
            .min(1, 'Please select a study phase.')
            .required('Please select a study phase.'),
        tumorType: Yup.array()
            .of(Yup.string())
            .min(1, 'Please select a tumor type.')
            .required('Please select a tumor type.'),
        tumorTypeOther: Yup.string()
            .max(200, "Tumor Type should be no longer than 200 characters.")
            .when(['tumorType'], {
            is: (tumorType: string[]) => {
                if (tumorType == null) {
                    return false
                } else if (tumorType.includes(OTHER_OPTION)) {
                    return true // validate and go to then function
                } else return false // for all other cases tumor type other is not required
            },
            then: (schema) =>
                schema
                    .required("Please enter a tumor type.")
        }),

    });


    // clear form fields
    const handleReset = () => {
        console.log(error);
        if (formikRef != null && formikRef.current) {
            formikRef.current.resetForm();
            reinitializeValues();
        }
    }

    // re-initialize values after clear
    const reinitializeValues = () => {
        setTrialStartYear("");
        setTrialEndYear("");
        setDataSummary("");
        setStudyObjectives("");
        setSampleSize( "");
        setTumorTypeOther("");
        setSelectedStudyType( new Array<string>());
        setSelectedTumorType( new Array<string>());
        setShowTumorTypesOther(false);
        setOutcomeMeasures("");
        setDataSummary("");
        setTrialTitle("");
        setTrialSummary("");
        setStudyObjectives("");
        setSampleSize( "");
        setPopulationGrouping("");
        setInstitutionalSites("");
        setDataCollection("");
        setTherapyDrug("");
        setTumorTypeOther("");
        setSelectedAgeRange(new Array<string>());
        setSelectedRegion(new Array<string>());
    }

    // show other field when 'Other" selected from list
    const handleTumorTypesChanged = (value: string) => {
        if (value.includes(OTHER_OPTION)) {
            setShowTumorTypesOther(true);
        } else if (showTumorTypesOther) {
            setShowTumorTypesOther(false);
        }
    }

    // build select list
    const buildSelectList = (listType: FormList[]) => {
        let optionTemplate = listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
        return optionTemplate;
    }

    const buildForm = () => {
        return (
            <Formik innerRef={formikRef}
                enableReinitialize
                initialValues={{
                    trialStartYear: trialStartYear ?? '',
                    trialEndYear: trialEndYear ?? '',
                    outcomeMeasures: outcomeMeasures ?? '',
                    trialTitle: trialTitle?? '',
                    trialSummary: trialSummary ?? '',
                    dataSummary: dataSummary ?? '',
                    studyObjectives: studyObjectives ?? '',
                    sampleSize: sampleSize ?? '',
                    populationGrouping: populationGrouping ?? '',
                    institutionalSites: institutionalSites ?? '',
                    dataCollection: dataCollection?? '',
                    therapyDrug: therapyDrug?? '',
                    studyType: selectedStudyType ?? '',
                    tumorType: selectedTumorType ?? '',
                    ageRange: selectedAgeRange?? '',
                    region: selectedRegion?? '',
                    tumorTypeOther: tumorTypeOther ?? '',
                }}

                validationSchema={schema}
                onSubmit={(values) => {
                    setShareDataset(JSON.stringify({
                        trialStartYear: values.trialStartYear,
                        trialEndYear: values.trialEndYear,
                        outcomeMeasures: getEncodedValue(values.outcomeMeasures),
                        title: getEncodedValue(values.trialTitle),
                        trialSummary: getEncodedValue(values.trialSummary),
                        dataSummary: getEncodedValue(values.dataSummary),
                        studyObjectives: getEncodedValue(values.studyObjectives),
                        sampleSize: values.sampleSize,
                        populationGrouping: getEncodedValue(values.populationGrouping),
                        institutionalSites: getEncodedValue(values.institutionalSites),
                        dataCollection: getEncodedValue(values.dataCollection),
                        therapyDrug: getEncodedValue(values.therapyDrug),
                        studyType: values.studyType,
                        region: values.region,
                        ageRange: values.ageRange,
                        tumorType: values.tumorType,
                        tumorTypeOther: values.tumorTypeOther
                    }))
                }}

                validateOnChange={false}
                validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      resetForm,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn className="mt-5 mb-5" lg={12}>
                            <FormLayoutColumn lg={8}>
                                <FormSectionTitle>Data Collection Period
                                </FormSectionTitle>
                                <TrialYears trialStartYear={trialStartYear}
                                            trialEndYear={trialEndYear} listHandler={buildSelectList}
                                            changeHandlerStartYear={setTrialStartYear}
                                            changeHandlerEndYear={setTrialEndYear} handleChange={handleChange}
                                            disabled={disabled} touched={touched}
                                            errors={errors}/>
                                <TextAreaOptional fieldName={"outcomeMeasures"} label={"Outcome Measures"}
                                                  value={outcomeMeasures} valueHandler={setOutcomeMeasures}
                                                  currentCharCount={outcomesMeasuresCount}
                                                  maxTextAreaCount={max1000Count}
                                                  countHandler={setOutcomeMeasuresCount}
                                                  disabled={disabled}
                                                  handleChange={handleChange} formText={outcomesDesc}/>

                                <FormSectionTitle>Descriptive Information</FormSectionTitle>
                                <TextAreaRequired fieldName={"trialTitle"} label={"Trial or Data Collection Title"}
                                                  value={trialTitle}
                                                  valueHandler={setTrialTitle}
                                                  currentCharCount={trialTitleCount}
                                                  maxTextAreaCount={max600Count} countHandler={setTrialTitleCount}
                                                  disabled={disabled} touched={touched} errors={errors}
                                                  handleChange={handleChange}/>
                                <TextAreaRequired fieldName={"trialSummary"} label={"Data Summary and Conditions"}
                                                  value={trialSummary}
                                                  valueHandler={setTrialSummary}
                                                  currentCharCount={trialSummaryCount}
                                                  maxTextAreaCount={max2000Count} countHandler={setTrialSummaryCount}
                                                  disabled={disabled} touched={touched} errors={errors}
                                                  handleChange={handleChange} formText={trialSummaryDesc}/>
                                <TextAreaRequired fieldName={"dataSummary"} label={"Data Summary"}
                                                  value={dataSummary} valueHandler={setDataSummary}
                                                  currentCharCount={dataSummaryCount} maxTextAreaCount={max2000Count}
                                                  countHandler={setDataSummaryCount} disabled={disabled} touched={touched} errors={errors}
                                                  handleChange={handleChange} formText={dataSummaryDesc}/>
                                <TextAreaRequired fieldName={"studyObjectives"} label={"Objectives"}
                                                  value={studyObjectives} valueHandler={setStudyObjectives}
                                                  currentCharCount={studyObjectivesCount} maxTextAreaCount={max5000Count}
                                                  countHandler={setStudyObjectivesCount} disabled={disabled} touched={touched} errors={errors}
                                                  handleChange={handleChange} formText={objectivesDesc}/>

                                <FormSectionTitle>Design Information</FormSectionTitle>
                                <TextFieldRequired fieldName={"sampleSize"} label="Total Patients" value={sampleSize}
                                                   valueHandler={setSampleSize} disabled={disabled} touched={touched} errors={errors} size={6}/>
                                <TextAreaOptional fieldName={"populationGrouping"} label={"Population Grouping or Subsets By"}
                                                  value={populationGrouping} valueHandler={setPopulationGrouping}
                                                  currentCharCount={populationGroupingCount}
                                                  maxTextAreaCount={max2000Count}
                                                  countHandler={setPopulationGroupingCount}
                                                  disabled={disabled}
                                                  handleChange={handleChange} formText={populationDesc}/>
                                <TextAreaOptional fieldName={"institutionalSites"} label={"Single or Multi-institutional Sites"}
                                                  value={institutionalSites} valueHandler={setInstitutionalSites}
                                                  currentCharCount={institutionalSitesCount}
                                                  maxTextAreaCount={max2000Count}
                                                  countHandler={setInstitutionalSitesCount}
                                                  disabled={disabled}
                                                  handleChange={handleChange} formText={institutionDesc}/>

                                <TextAreaOptional fieldName={"dataCollection"} label={"Instrument or Data Collection"}
                                                  value={dataCollection} valueHandler={setDataCollection}
                                                  currentCharCount={dataCollectionCount}
                                                  maxTextAreaCount={max2000Count}
                                                  countHandler={setDataCollectionCount}
                                                  disabled={disabled}
                                                  handleChange={handleChange} formText={dataCollectionDesc}/>

                                <TextAreaOptional fieldName={"therapyDrug"} label={"Therapy or Drug"}
                                                  value={therapyDrug} valueHandler={setTherapyDrug}
                                                  currentCharCount={therapyDrugCount} maxTextAreaCount={max2000Count}
                                                  countHandler={setTherapyDrugCount}
                                                  disabled={disabled}
                                                  handleChange={handleChange}
                                                  formText={therapyDesc}/>

                                <StudyTypes  value={selectedStudyType}
                                            valueHandler={setSelectedStudyType} listHandler={buildSelectList}
                                            disabled={disabled} touched={touched} errors={errors}/>


                                <TumorTypes  value={selectedTumorType}
                                            valueHandler={setSelectedTumorType}
                                            handleValueChanged={handleTumorTypesChanged}
                                            listHandler={buildSelectList}
                                            disabled={disabled} touched={touched} errors={errors}/>


                                {showTumorTypesOther && (
                                    <TextFieldRequired fieldName={"tumorTypeOther"}
                                                       label="Other Tumor Types (multiple entries include ','"
                                                       value={tumorTypeOther}
                                                       valueHandler={setTumorTypeOther} disabled={disabled} touched={touched}
                                                       errors={errors} size={11}/>
                                )}

                                <Region  value={selectedRegion}
                                        valueHandler={setSelectedRegion}
                                        listHandler={buildSelectList} disabled={disabled} touched={touched}
                                        errors={errors}/>

                                <AgeRange  value={selectedAgeRange}
                                          valueHandler={setSelectedAgeRange}
                                          listHandler={buildSelectList} disabled={disabled} touched={touched}
                                          errors={errors}/>

                            </FormLayoutColumn>
                        </FormLayoutColumn>

                        <BackClearAndSubmitButtonContainer handleReset={handleReset} enableClear={enableClearButton(donationId)}
                                                           backNavigate={"ShareDatasetBasicsRWD/" + donationId}/>

                    </Form>

                )}
            </Formik>
        )
    }


    return (
        <Container className="form-container" fluid>

            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn md={8} lg={9} name={"formLayoutColumn"}>
                        <PDS_H5>Trial Data/Information - Dataset Form - Page 2 of 3</PDS_H5>
                        <PDS_P>Please provide basic details for your data contribution including key information such as
                            objectives, unique keys, and volume of records. We accept many supporting data files so
                            please offer an explanation
                            for any composite or curations within the 'data summary' section.
                        </PDS_P>
                        {isLoading &&
                            <Spinner  className ="spinner-center" animation={"border"} variant={"primary"} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>}
                        {error && <p>Error</p>}
                        {shareData && buildForm()}
                    </FormLayoutColumn>
                    <Col md={4} lg={3}>
                        <Aside needHelp={true} requiredFields={true}/>
                    </Col>
                </PageWrapper>
            </ContentPage>


        </Container>
    );

}
