/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: PDSContext
 *
 * Purpose: context for the app to hold session data, (see https://dev.to/madv/usecontext-with-typescript-23ln)
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 14Aug2024 craig File created.
 \******************************************************************************/
import { createContext, useContext } from "react"
import {getGuestCookie, getSessionCookie} from "./context";
import {IUser} from "../model/user/IUser";

export type SessionContent = {
    sessionUser: IUser,
    setSessionUser:(user: IUser) => void
}

export const PDSContext = createContext<SessionContent>({
    sessionUser: getSessionCookie(),
    setSessionUser: (user:IUser) => {}
});

export const usePDSContext = () => useContext(PDSContext)