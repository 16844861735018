/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: LsafVideos
 *
 * Purpose: Display LSAF videos
 *
 * Author: sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 02Mar2023 sasjxa file created.
 * 02Jun2023 sasjxa move styled components out of FC
 \******************************************************************************/
import React, {Fragment, useState} from "react";
import {
    PageHeaderWithBackground,
    ResourceItemPage,
    PDS_P, PageWrapper, ContentPage

} from "../../components/styled/StyledComponents";

// @ts-ignore
import styled from 'styled-components';
import {
    ILSAFVideo, LSAF_VIDEOS
} from "../../data/resources/lsafVideos";

const VideoSection = styled.div`      
   margin-top: 2%;
   width: 90%;
   &:first-child {
        margin-top: 0;
   } 
        
   p {
      font-weight: 600;
      margin-top: 0; 
      margin-bottom: 5px;
   } 
        
   @media (min-width: 768px){ 
      width: 100%;
          
      &:not(:last-child) {
         margin-bottom: 5%;
      }
   }    
`;

const VideoContainer = styled.div`      
   width: 235px;
   height: 150px;
   margin-right: 1%;   
   margin-bottom: 0;
       
   video {
      width: 100%;
      height: 100%;
      object-fit: cover;
   }
       
   @media (min-width: 768px){ 
      width: 680px;
      height: 350px;
      border: 3px solid black;
   }    
`;

export const LsafVideos: React.FC = () => {
    return (
        <>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <PageHeaderWithBackground className={"faq-content-header"}>
                        <h2>LSAF Videos</h2>
                    </PageHeaderWithBackground>
                </PageWrapper>
            </ContentPage>
            <ResourceItemPage className={"lsaf-videos-page"}>
                {
                    LSAF_VIDEOS.map((video: ILSAFVideo) => {
                        return <VideoSection>
                            <PDS_P>{video.title}</PDS_P>
                            <VideoContainer>
                                <video controls>
                                    <source src={video.link} type="video/mp4"></source>
                                </video>
                            </VideoContainer>
                        </VideoSection>

                    })
                }
            </ResourceItemPage>

        </>
    )
}