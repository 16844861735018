/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ConvertUserDialog
 *
 * Purpose: Dialog displayed when error occurs converting user
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 26Sep2023 sasjxa  file created.
 \******************************************************************************/
import {Button, Modal} from "react-bootstrap";
import React from "react";
import {DialogContent, DialogHeader} from "../styled/StyledComponents";

interface DialogProps {
    show: boolean,
    showHide: any,
}

    export const ConvertUserDialog: React.FC<DialogProps> = ({show, showHide}: DialogProps) => {


        return (
            <Modal show={show}>
                <Modal.Header closeButton={true} onClick={showHide}>
                    <DialogHeader>Error</DialogHeader>
                </Modal.Header>
                <Modal.Body>
                    <DialogContent>Could not retrieve user information.</DialogContent>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} onClick={showHide}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

