
export interface IInfoDialog {
    id : number,
    fileTypeValue: string,
}


export const PROTOCOL_CRF:IInfoDialog[] = [
    {
        id: 1,
        fileTypeValue: "pdf"
    },

    {
        id: 2,
        fileTypeValue: "doc"
    },

    {
        id: 3,
        fileTypeValue: "docx"
    },

    {
        id: 4,
        fileTypeValue: "txt"
    },

    {
        id: 5,
        fileTypeValue: "rtf"
    },
];

export const DATA_DICTIONARY:IInfoDialog[] = [
    {
        id: 1,
        fileTypeValue: "pdf"
    },

    {
        id: 2,
        fileTypeValue: "doc"
    },

    {
        id: 3,
        fileTypeValue: "docx"
    },

    {
        id: 4,
        fileTypeValue: "txt"
    },

    {
        id: 5,
        fileTypeValue: "rtf"
    },
    {
        id: 6,
        fileTypeValue: "xls"
    },

    {
        id: 7,
        fileTypeValue: "xlsx"
    },
];

export const DATA_FILES:IInfoDialog[] = [
    {
        id: 1,
        fileTypeValue: "sas7bdat"
    },
    {
        id: 2,
        fileTypeValue: "pdf"
    },

    {
        id: 3,
        fileTypeValue: "doc"
    },

    {
        id: 4,
        fileTypeValue: "docx"
    },

    {
        id: 5,
        fileTypeValue: "txt"
    },

    {
        id: 6,
        fileTypeValue: "rtf"
    },
    {
        id: 7,
        fileTypeValue: "xls"
    },

    {
        id: 8,
        fileTypeValue: "xlsx"
    },
    {
        id: 9,
        fileTypeValue: "xml"
    },
    {
        id: 10,
        fileTypeValue: "xpt"
    },
    {
        id: 11,
        fileTypeValue: "cpt"
    },
    {
        id: 12,
        fileTypeValue: "lst"
    },
    {
        id: 13,
        fileTypeValue: "mdb"
    },
    {
        id: 14,
        fileTypeValue: "csv"
    },
    {
        id: 15,
        fileTypeValue: "ppt"
    },
    {
        id: 16,
        fileTypeValue: "pptx"
    },
    {
        id: 17,
        fileTypeValue: "rar"
    },
    {
        id: 18,
        fileTypeValue: "dtd"
    },
    {
        id: 19,
        fileTypeValue: "numbers"
    },
    {
        id: 20,
        fileTypeValue: "pages"
    },

];

export const NCI_UPLOAD:IInfoDialog[] = [
    {
        id: 1,
        fileTypeValue: "pdf"
    },
];

