import {BASE_PATH} from "../../constants";


const RESOURCES_DIR = "/documents";

export interface IResourceLink {
    id : number,
    link: string,
    title: string
}

export interface CytelVideoLink {
    videoLink: string,
    videoText: string
}

export const GENERAL_RESOURCES:IResourceLink[] = [
    {
        id: 1,
        link:  BASE_PATH +"faq",
        title: "Frequently Asked Questions"
    },

    {
        id: 2,
        link:  RESOURCES_DIR + "/resources_data_user_agreement.pdf",
        title: "PDS Platform Use Agreement"
    },

    {
        id: 3,
        link: RESOURCES_DIR + "/resources_data_sharing_agreement.pdf",
        title: "PDS Data Sharing Agreement"
    },

    {
        id: 4,
        link: RESOURCES_DIR + "/resources_how_to.pdf",
        title: "How To Guide: Accessing and Downloading Data"
    },

    {
        id: 5,
        link: RESOURCES_DIR + "/resources_MEPS_quick_start.pdf",
        title: "MEPS (Medical Expenditure Panel Survey) Linked Datasets: Quick Start"
    },

    {
        id: 6,
        link: RESOURCES_DIR + "/resources_MEPS.zip",
        title: "MEPS (Medical Expenditure Panel Survey) Linked Datasets: Example Program Files"
    },



];

export const DATA_PROVIDERS:IResourceLink[] = [
    {
        id: 1,
        link: RESOURCES_DIR + "/resources_deidentification.pdf",
        title: "De-Identification Strategy Example"
    },

    {
        id: 2,
        link: RESOURCES_DIR + "/resources_sas_macros_deidentification.pdf",
        title: "SAS Macros for Data De-Identification"
    },

    {
        id: 3,
        link: RESOURCES_DIR + "/resources_upload_ref.pdf",
        title: "Dataset Upload Reference"
    }

];

export const SAS_TOOLS:IResourceLink[] = [
    {
        id: 1,
        link: RESOURCES_DIR + "/resources_sdd_sas_programmer_guide.pdf",
        title: "How To Guide: Creating and Running SAS Programs within the Project Data Sphere Platform"
    },

    {
        id: 2,
        link:  BASE_PATH + "lsafVideos",
        title: "LSAF Videos"
    },

    {
        id: 3,
        link: RESOURCES_DIR +"/resources_vdmml_getting_started.pdf",
        title: "SAS&reg; Visual Data Mining and Machine Learning"
    },

    {
        id: 4,
        link: RESOURCES_DIR +"/resources_vdmml_open_source_access.pdf",
        title: "Open Source Access to SAS&reg; Visual Data Mining and Machine Learning "
    },

    {
        id: 5,
        link: RESOURCES_DIR +"/resources_r_api_access.txt",
        title: "R API Access Example Script "
    },

    {
        id: 6,
        link: RESOURCES_DIR +"/resources_python_api_access.txt",
        title: "Python API Access Example Script"
    },

    {
        id: 7,
        link: RESOURCES_DIR +"/trustedcerts.pem",
        title: "Certificate for Python Access"
    },

];

export const EXTERNAL_LINK_TEXT:string =
        "The following links are operated by other parties.  The resources are not under the control of Project Data Sphere, "
        + "and Project Data Sphere is not responsible for any of the content these resources may offer.  "
        + "This list and any links to the listed resources do not imply Project Data Sphere's endorsement of any third-party content, and "
        + "Project Data Sphere disclaims all liability with regard to your access to and use of such content.  "
        + "Access to any of the content these resources may offer is at your own risk.  "
        + "You are responsible for complying with any legal terms that may be applicable to your access to and use of any of content offered by any of these resources.";

export const CYTEL_VIDEO_YOU_TUBE:CytelVideoLink = {
    videoLink: " <a href=\"https://dx.doi.org/10.1016/j.conctc.2019.100446\"  target={\"_blank\"}>Minimizing control group allocation in randomized trials using dynamic borrowing of external control "
        + "data - An application to second line therapy for non-small cell lung cancer</a>",
    videoText: "Cytel published an excellent instructional video on best practices when" +
        "                                        considering the use of synthetic controls to address accelerated treatment" +
        "                                        approvals for rare disease, considering adaptive clinical trial design, and" +
        "                                        researching the effectiveness of new treatments. Leveraging some of the" +
        "                                        published data on PDS platform, this is a great introduction to a handful of" +
        "                                        success stories. In this video, Kristian Thorlund, SVP Real World Analytics at" +
        "                                        Cytel, demonstrates a few key examples of applied use for accelerated approval" +
        "                                        (Blinatumomab 2014) and exploration of dynamic Bayesian borrowing or applying" +
        "                                        methods of different randomization methods."
}
