/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: PROData
 *
 * Purpose: Patient Reported Outcomes for Share Data
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 20Oct2023 sasjxa File created.
 \******************************************************************************/
import React from "react";
import {Col, Form,  Row} from "react-bootstrap";
import {ErrorMessage} from "formik";
import {YES_NO, YES_OPTION} from "../../data/formList";
import {TextAreaCountRow} from "../styled/StyledComponents";


interface PRODataProps {
    proCaptured: string
    proCapturedHandler: any,
    proProvidedState: boolean,
    proProvided: string,
    proProvidedValueHandler: any,
    proProvidedStateHandler: any,
    proFilesState: boolean,
    proFiles: string,
    proFilesValueHandler: any,
    proFilesStateHandler: any,
    maxTextAreaCount: number,
    proFilesCountHandler: any,

    selectListHandler: any,
    disabled: boolean,
    handleChange: any,
    touched: any,
    errors: any,
}


export const PROData: React.FC<PRODataProps> = ({
                                                    proCaptured,
                                                    proCapturedHandler,
                                                    proProvidedState, proProvided, proProvidedValueHandler,
                                                    proProvidedStateHandler,
                                                    proFilesState, proFiles, proFilesValueHandler,
                                                    proFilesStateHandler,maxTextAreaCount,
                                                    proFilesCountHandler, selectListHandler, disabled, handleChange,
                                                    touched, errors,

                                                }: PRODataProps) => {


    const handlePROCaptureChanged = (value: string) => {
        value === YES_OPTION ? proProvidedStateHandler(true) : proProvidedStateHandler(false);

    }
    const handlePROProvidedChanged = (value: string) => {
        value === YES_OPTION ? proFilesStateHandler(true) : proFilesStateHandler(false);
    }

    return (
        <>
            <Row className="mt-3 mb-5 ">
                <Row className="mt-3  ">
                    <Form.Group as={Col} className="proCaptured"
                                controlId="formPatientReportedOutcomesCaptured">
                        <Form.Label className="required" column="sm">Was PRO data captured as part
                            of
                            this study? </Form.Label>
                        <Form.Select size="sm" required
                                     name={"patientReportedOutcomesCaptured"}
                                     value={proCaptured}
                                     disabled={disabled}
                                     isValid={touched.patientReportedOutcomesCaptured && !errors.patientReportedOutcomesCaptured}
                                     onChange={e => {
                                         proCapturedHandler(e.target.value)
                                         handlePROCaptureChanged(e.target.value);
                                         handleChange(e);
                                     }}>
                            {selectListHandler(YES_NO)}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            Please indicate whether PRO data was captured.
                        </Form.Control.Feedback>
                        <ErrorMessage name={"patientReportedOutcomesCaptured"}
                                      render={msg => <div
                                          className={"form-error-msg"}>{msg}</div>}/>
                    </Form.Group>
                </Row>

                {proProvidedState && (
                    <Row className="mt-3 ">
                        <Form.Group as={Col} className={"otherFieldGroup"}
                                    controlId="formPatientReportedOutcomesProvided">
                            <Form.Label className="required" column="sm">
                                If yes, are you providing them as part of this upload? </Form.Label>
                            <Form.Select size="sm" required
                                         name={"patientReportedOutcomesProvided"}
                                         value={proProvided}
                                         disabled={disabled}
                                         isValid={touched.patientReportedOutcomesProvided && !errors.patientReportedOutcomesProvided}
                                         onChange={e => {
                                             proProvidedValueHandler(e.target.value)
                                             handlePROProvidedChanged(e.target.value);
                                             handleChange(e);
                                         }}>
                                {selectListHandler(YES_NO)}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Please indicate whether PRO data was captured.
                            </Form.Control.Feedback>
                            <ErrorMessage name={"patientReportedOutcomesProvided"}
                                          render={msg => <div
                                              className={"form-error-msg"}>{msg}</div>}/>
                        </Form.Group>
                    </Row>
                )}

                {proFilesState && (
                    <Row className="mt-3 ">
                        <Form.Group as={Col} className={"otherFieldGroup"}
                                    controlId="formPatientReportedOutcomesFiles">
                            <TextAreaCountRow>
                                <Form.Label className="required" column="sm">If yes, please indicate
                                    which file(s) contain the PRO data </Form.Label>
                                <span>{proFilesCountHandler} of {maxTextAreaCount} characters</span>
                            </TextAreaCountRow>

                            <Form.Control as="textarea" rows={5} size="sm" required
                                          name={"patientReportedOutcomesFiles"}
                                          type="text"
                                          value={proFiles}
                                          disabled={disabled}
                                          maxLength={maxTextAreaCount}
                                          isValid={touched.patientReportedOutcomesFiles && !errors.patientReportedOutcomesFiles}
                                          onChange={e => {
                                              proFilesValueHandler(e.target.value);
                                              proFilesCountHandler(e.target.value.length);
                                              handleChange(e);
                                          }}/>
                            <Form.Text className="text-muted"></Form.Text>
                            <Form.Control.Feedback type="invalid">
                                Please indicate which file(s) contain the PRO data.
                            </Form.Control.Feedback>
                            <ErrorMessage name={"patientReportedOutcomesFiles"}
                                          render={msg => <div
                                              className={"form-error-msg"}>{msg}</div>}/>
                        </Form.Group>
                    </Row>
                )}
            </Row>

        </>

    )

}