/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ProcessPasswordReset
 *
 * Purpose: process a user password reset
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 06Feb2024 craig  file created
 * 26Feb2024 sasjxa add clear button functionality (MPM-5425)
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 21May2024 sasjxa switched to react-error-boundary (MPM-5454)
 * 04Oct2024 craig  Added error message
 * 31Oct2024 craig  Added waitingForResponse to prevent new submit before response
 \******************************************************************************/
import React, {useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Col, Container, Form, Row} from "react-bootstrap";
import {
    ContentPage,
    FormLayoutColumn,
    FormSectionTitle,
    PageWrapper, PDS_P_BOLD
} from "../../../components/styled/StyledComponents";
import * as Yup from "yup";
import {SUBMIT} from "../../../data/formList";
import {ErrorMessage, Formik, FormikProps} from "formik";
import {ClearAndSubmitButtonContainer} from "../../../components/buttonContainer/ClearAndSubmitButtonContainer";
import {useRequest} from "../../../helper/useRequest";
import {PasswordRules} from "../../../components/forms/PasswordRules";
import {BASE_PATH} from "../../../constants";
import {useErrorBoundary} from "react-error-boundary";
import axios, {AxiosError} from "axios";
import styled from "styled-components";
import {COLORS} from "../../../components/styled/StyleConstants";


const PDS_P_ERROR_MSG = styled(PDS_P_BOLD)`
    font-weight: 600;
    color: ${COLORS.remove_red};
`;

export const ProcessPasswordReset: React.FC = () => {
    const navigate = useNavigate();
    const {dept, title} = useParams();
    const [resetMsg, setLoginMsg] = useState<string>("");
    const formikRef = useRef<FormikProps<any>>(null);
    const [errorMsg, setErrorMsg] = useState<string | undefined>("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    //this will disable the submit button while waiting for the response after submit
    const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);

    /**
     *
     */
    const handleSuccess = () => {
        setIsWaitingForResponse(false);
        navigate(BASE_PATH + "home");
    }

    /**
     * handle unsuccessful password reset
     *
     * @param error - error
     */
    const handleError = (error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response !== undefined) {
            setErrorMsg(error.response.data.detail);
        } else {
            setErrorMsg("An error has occurred and the Project Data Sphere Administrator has been notified");
        }
        setShowErrorMessage(true);
        setIsWaitingForResponse(false);
    }


    // handle reset
    const handleReset = () => {
        if (formikRef != null && formikRef.current) {
            formikRef.current.resetForm();
        }
    }

    // request setup
    const [requestState, setPassword] = useRequest({
        url: process.env.REACT_APP_API_URL + "/api/public/password/resetpassword/" + dept + "/" + title,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })

    //form schema
    const resetSchema = Yup.object().shape({
        password: Yup.string().required("Please enter a password")
            .min(12, "Password must be at least 12 characters")
            .max(15, "Password must be no more than 15 characters"),
        repeatPassword: Yup.string().required("Please repeat your password.")
            .oneOf([Yup.ref('password')], 'Passwords must match'),
    })

    /**
     * build the form
     */
    const buildForm = () => {
        return (
            <Formik innerRef={formikRef}
                    enableReinitialize
                    initialValues={{
                        password: '',
                        repeatPassword: ''
                    }}
                    validationSchema={resetSchema}
                    onSubmit={(values) => {
                        setIsWaitingForResponse(true);
                        setPassword(JSON.stringify({password: values.password, repeatPassword: values.repeatPassword}));
                    }}

                    validateOnChange={false}
                    validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn className="mt-5 mb-5" lg={12}>
                            <FormLayoutColumn lg={8}>
                                <FormSectionTitle>New password</FormSectionTitle>
                                <Row className="mt-3 mb-3 ">
                                    <Form.Group as={Col} lg={255} className="" controlId="password">
                                        <Form.Label className="required" column="sm">{"New password"}
                                        </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"password"}
                                                      type="password"
                                                      value={values.password}
                                                      disabled={false}
                                                      placeholder="Password"
                                                      isValid={touched.password && !errors.password}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a password
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"password"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>
                                <Row className="mt-3 mb-3 ">
                                    <Form.Group as={Col} lg={255} className="" controlId="repeatPassword">
                                        <Form.Label className="required" column="sm">{"Repeat password"}
                                        </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"repeatPassword"}
                                                      type="password"
                                                      value={values.repeatPassword}
                                                      disabled={false}
                                                      placeholder="Repeat password"
                                                      isValid={touched.repeatPassword && !errors.repeatPassword}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a password
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"repeatPassword"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>
                            </FormLayoutColumn>
                        </FormLayoutColumn>
                        <ClearAndSubmitButtonContainer handleReset={handleReset} enableClear={true}
                                                       submitButtonText={SUBMIT} isSubmitting={isWaitingForResponse}/>
                    </Form>

                )}
            </Formik>
        )
    }

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn md={8} lg={9} name={"formLayoutColumn"}>
                        <PasswordRules/>
                        {showErrorMessage && (
                            <PDS_P_ERROR_MSG>{errorMsg}</PDS_P_ERROR_MSG>
                        )}
                        {buildForm()}
                    </FormLayoutColumn>
                </PageWrapper>
            </ContentPage>
        </Container>
    )
}