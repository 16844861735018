/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: formList
 *
 * Purpose: data for select lists and widely-used constants for forms
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 05May2023 sasjxa file created.
 * 27Jan2024 craig  added RESPONSE_REACTIVATE
 * 21Feb2024 sasjxa remove Request in Progress from user approval list (MPM-5409)
 * 14Jun2024 sasjxa added header and response for reissue token
 * 20Jun2024 sasjxa correct dsa headers
 * 28Jun2024 sasjxa add constants for access data images
 * 23Aug2024 craig  Added RESPONSE_DISABLED_CONTACT_US and HEADER_DISABLED_CONTACT_US
 * 24Aug2024 craig  Added HEADER_DISABLED_CONTACT_US_FAILED and RESPONSE_DISABLED_CONTACT_US_FAILED
 * 03Oct2024 sasjxa remove Belarus and Russia from country selector list
 * 07Oct2024 sasjxa correct Contact Us link for forgot password
 \********************************************************************************/
import {BASE_PATH} from "../constants";

export const EMPTY_OPTION_LABEL = "-Select an Option-";
export const EMPTY_OPTION_VALUE = "";
export const OTHER_OPTION = "Other";
export const PUBLICATION_OPTION = "Publication";
export const CONFERENCE_OPTION = "Conference/Symposium/Presentation";
export const YES_OPTION = "Yes";
export const NO_OPTION = "No";
export const SUBMIT = "Submit";
export const CLEAR = "Clear";
export const NEXT = "Next";
export const UPLOAD = "Upload";
export const RMI_USER = "Request more Information";
export const DECLINE_USER = "Declined";
export const APPROVE_USER = "Approved";
export const NEW = "NEW"
export const APPROVED = "APPROVED"
export const NEW_MIXED='New';
export const APPROVED_MIXED='Approved';
export const DECLINED_MIXED='Declined';
export const CRMI:String = "Completed Request more Information";
export const RMI_NEW_TOKEN="Request new RMI Token";

export const NA = "NA"

export const ICON_MISSING: string = "/images/icons/icon-missing.png";
export const ICON_UNDEFINED: string = "/images/icons/icon-undefined.png";
export const IMG_PATH: string = "/images/icons/";
export const SAS_FILE_TYPE = "sas7bdat";

export const PENDING = "PENDING"
export const RMI = "RMI"
export const WORKING = "WORKING"
export const ACTIVE = "ACTIVE"
export const NCI = "NCI"
export const QUARANTINED = "QUARANTINED"
export const QUARANTINED_MIXED = "Quarantined"
export const DECLINED = "DECLINED"
export const UNKNOWN = ""
export const OPEN = "OPEN"
export const IN_PROGRESS = "IN_PROGRESS"
export const CLOSED = "CLOSED"
export const STARTED = "STARTED"
export const SPAM = "SPAM"

const OPEN_TICKETS: string = "Open Tickets";
const TICKETS_IN_PROGRESS: string = "Tickets in Progress";
const CLOSED_TICKETS: string = "Closed Tickets";
const OPEN_REQUESTS: string = "Open Requests";
const ACTIVE_ACCOUNTS: string = "Active Users";
const DECLINED_USERS: string = "Declined Users";

const OPEN_DATASETS: string = "Open/Active Datasets";
const ACTIVE_DATASETS: string = "Active Datasets";
const NCI_DATASETS: string = "NCI Datasets";
const QUARANTINED_DATASETS: string = "Quarantined Datasets";
const DECLINED_DATASETS: string = "Declined Datasets";

const OPEN_DSA: string = "Open/Active DSA Requests";
const APPROVED_DSA: string = "Approved DSA Requests";


export const BOTH_DSA = "BOTH"
export const COMPARATOR_ONLY = "COMPARATOR_ONLY";
export const EXPERIMENTAL_ONLY = "EXPERIMENTAL_ONLY";
export const REAL_WORLD="REAL_WORLD";

export const BOTH_STUDY_CONTENT = "Both";
export const RAW_ORIGINAL = "Original Data";
export const CURATED_COMPOSITE = "Composite";

export const PENDING_VERIFICATION="Pending Verification";
export const ASC="ASC";
export const DESC="DESC";


 export const NAME_LAST_ASC = "NAME_LAST_ASC";
export const   NAME_LAST_DESC=  "NAME_LAST_DESC";

export const  DATE_ASC=  "DATE_ASC";
 export const DATE_DESC=  "DATE_DESC";

export const NCI_UNKNOWN:number=-1;
export const NCI_NOT_SUBMITTED:number=1;
export const NCI_PENDING_APPROVAL:number=2;

export const HEADER_CONTACT_US:string ="You have successfully submitted a question to Project Data Sphere."
export const RESPONSE_CONTACT_US:string ="Your Contact Us inquiry has been submitted and will be responded to as soon as possible."

export const HEADER_DISABLED_CONTACT_US_FAILED:string ="Disabled account request to Project Data Sphere failed."
export const RESPONSE_DISABLED_CONTACT_US_FAILED:string ="Your disabled account request could not be submitted! This is probably because your email address is invalid."


export const HEADER_DISABLED_CONTACT_US:string ="You have successfully submitted a disabled account request to Project Data Sphere."
export const RESPONSE_DISABLED_CONTACT_US:string ="Your disabled account request has been submitted and will be responded to as soon as possible."

export const HEADER_RMI_TOKEN_REISSUE:string ="You have successfully requested a time extension to complete your registration."
export const RESPONSE_RMI_TOKEN_REISSUE:string ="The Project Data Sphere team will review your request and get back to you as soon "
    + "as possible.  The process usually takes less than 7 days. "
    + "You will receive an email when a new registration link is issued. Please keep an eye out for it.  If "
    + "you have any questions in the interim, please "
    + " <a href=\"/projectdatasphere/html/contactUs\" className=\"linkstyle\">Contact Us</a>."


export const HEADER_REGISTRATION:string ="You have successfully applied for access."
export const RESPONSE_REGISTRATION:string ="The Project Data Sphere team will review your registration and get back to you as soon "
    + "as possible.  The process usually takes less than 7 days.  You will receive an e-mail from "
    + "Project Data Sphere indicating whether your request has been accepted, declined or if more "
    + "information is required to review your request.  Please keep an eye out for it.  If "
    + "you have any questions in the interim, please "
    + " <a href=\"/projectdatasphere/html/contactUs\" className=\"linkstyle\">Contact Us</a>."
export const HEADER_PROVIDER:string ="You have successfully applied for access to become a provider."
export const RESPONSE_PROVIDER:string ="The Project Data Sphere team will review your registration and get back to you as soon "
    + "as possible.  The process usually takes less than 7 days.  You will receive an e-mail from "
    + "Project Data Sphere indicating whether your request has been accepted, declined or if more "
    + "information is required to review your request.  Please keep an eye out for it.  If "
    + "you have any questions in the interim, please "
    + " <a href=\"/projectdatasphere/html/contactUs\" className=\"linkstyle\">Contact Us</a>."
export const HEADER_SHARE:string ="Thank you for your contribution to the Project Data Sphere (PDS) platform "
    + "and for being an active member of this open science and data sharing community."
export const RESPONSE_SHARE="This submission is currently under review, and you will receive an automated status "
    + "and acceptance notice with the e-mail you have registered with (check your \"junk mail\" "
    + "folder if you don't see it in your inbox).  If there are any questions in the interim, "
    + "please "
    + " <a href=\"/projectdatasphere/html/contactUs\" className=\"linkstyle\">Contact Us</a>. "
    + "A representative from PDS will reach out if there are points of "
    + "clarification with regard to your submission. "
export const HEADER_NCI:string ="You have submitted a request to have access to NCI datasets."
export const RESPONSE_NCI="You will be notified of NCI's decision as to whether your request has been approved."
export const RESPONSE_FORGOT_PASSWORD="You have been sent an email which contains a link to"
    + " set up your password. If you don't receive this email, "
    + "please check your spam folder. If you have any questions, "
    + "please "
    + " <a href=\"/projectdatasphere/html/contactUs\" className=\"linkstyle\">Contact Us</a>."
export const RESPONSE_REACTIVATE:string = "You have successfully reactivated your account.";

export const max600Count = 600;
export const max1000Count = 1000;
export const max2000Count = 2000;
export const max5000Count = 5000;

export interface FormList {
    id : number,
    label: string,
    value: string
}

export interface SelectFilesList {
    id : number,
    dsaType: string,
    studyContent: string
    valueList: FormList[]
}

export interface IAdminCountsHeaderList {
    id : number,
    label: string,
    className: string,
    type: string,
    navigateUrl: string,
    image: string,
    alt: string,
}

export interface IFileIcons {
    id : number,
    fileType: string,
    icon: string
}



export const AFFILIATION:FormList[] = [
    {id:1,  label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"Academia/University",value:"Academia/University"},
    {id:3, label:"Healthcare Provider (clinic/hospital)",value:"Healthcare Provider (clinic/hospital)"},
    {id:4, label:"Industry (Pharma/Biotech)",value:"Industry (Pharma/Biotech)"},
    {id:5, label:"Government Agency/Entity",value:"Government Agency/Entity"},
    {id:6, label:"Independent Researcher",value:"Independent Researcher"},
    {id:7, label:"Non-Profit",value:"Non-Profit"},
    {id:8, label:"Data/Analytics/AI",value:"Data/Analytics/AI"},
    {id:9, label:"Other (CRO/Consultant/Patient)",value:OTHER_OPTION}

];

export const ROLE:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"Statistician/Programmer",value:"Statistician/Programmer"},
    {id:3, label:"Faculty",value:"Faculty"},
    {id:4, label:"Student/Post Doc",value:"Student/Post Doc"},
    {id:5, label:"Clinician",value:"Clinician"},
    {id:6, label:"Program/Project Manager",value:"Program/Project Manager"},
    {id:7, label:OTHER_OPTION,value:OTHER_OPTION}

];

export const REASON_FOR_VISITING:FormList[] = [
    { id:1,label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    { id:2,label:"Access Data for Trial Research",value:"Access Data for Trial Research"},
    { id:3,label:"Access Data for ML or testing stats Methods",value:"Access Data for ML or testing stats Methods"},
    { id:4,label:"Access Data to support Publications",value:"Access Data to support Publications"},
    { id:5,label:"Learn about Oncology Data",value:"Learn about Oncology Data"},
    { id:6,label:"Participate in Challenge",value:"Participate in Challenge"},
    { id:7,label:"Contribute Data",value:"Contribute Data"},
    { id:8,label:OTHER_OPTION,value:OTHER_OPTION}
];

export const LEARN_ABOUT_PDS:FormList[] = [
    { id:1,label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    { id:2,label:"Publication",value:PUBLICATION_OPTION},
    { id:3,label:"Conference/Symposium/Presentation",value:CONFERENCE_OPTION},
    { id:4, label:"Data Research Challenge (i.e. DREAM)",value:"Data Research Challenge"},
    { id:5,label:"Colleague",value:"Colleague"},
    { id:6,label:"Employer",value:"Employer"},
    { id:7,label:"Academic Setting/University",value:"Academic Setting/University"},
    { id:8,label:"Social Media",value:"Social Media"},
    { id:9,label:"Internet or Google Search",value:"Internet or Google Search"},
    { id:10,label:OTHER_OPTION,value:OTHER_OPTION}
];

export const COUNTRY:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"United States of America",value:"United States of America"},
    {id:3, label:"Afghanistan",value:"Afghanistan"},
    {id:4, label:"Albania",value:"Albania"},
    {id:5, label:"Algeria",value:"Algeria"},
    {id:6, label:"Andorra",value:"Andorra"},
    {id:7, label:"Angola",value:"Angola"},
    {id:8, label:"Antigua and Barbuda",value:"Antigua and Barbuda"},
    {id:9, label:"Argentina",value:"Argentina"},
    {id:10, label:"Armenia",value:"Armenia"},
    {id:11, label:"Australia",value:"Australia"},
    {id:12, label:"Austria",value:"Austria"},
    {id:13, label:"Azerbaijan",value:"Azerbaijan"},
    {id:14, label:"Bahamas, The",value:"Bahamas, The"},
    {id:15, label:"Bahrain",value:"Bahrain"},
    {id:16, label:"Bangladesh",value:"Bangladesh"},
    {id:17, label:"Barbados",value:"Barbados"},
    {id:19, label:"Belgium",value:"Belgium"},
    {id:20, label:"Belize",value:"Belize"},
    {id:21, label:"Benin",value:"Benin"},
    {id:22, label:"Bhutan",value:"Bhutan"},
    {id:23, label:"Bolivia",value:"Bolivia"},
    {id:24, label:"Bosnia and Herzegovina",value:"Bosnia and Herzegovina"},
    {id:25,label:"Botswana",value:"Botswana"},
    {id:26, label:"Brazil",value:"Brazil"},
    {id:27, label:"Brunei",value:"Brunei"},
    {id:28, label:"Bulgaria",value:"Bulgaria"},
    {id:29,label:"Burkina Faso",value:"Burkina Faso"},
    {id:30, label:"Burma",value:"Burma"},
    {id:31, label:"Burundi",value:"Burundi"},
    {id:32,label:"Cambodia",value:"Cambodia"},
    {id:33, label:"Cameroon",value:"Cameroon"},
    {id:34, label:"Canada",value:"Canada"},
    {id:35, label:"Cape Verde",value:"Cape Verde"},
    {id:36, label:"Central African Republic",value:"Central African Republic"},
    {id:37, label:"Chad",value:"Chad"},
    {id:38, label:"Chile",value:"Chile"},
    {id:39, label:"China",value:"China"},
    {id:40, label:"Colombia",value:"Colombia"},
    {id:41, label:"Comoros",value:"Comoros"},
    {id:42, label:"Congo, Democratic Republic of the",value:"Congo, Democratic Republic of the"},
    {id:43, label:"Congo, Republic of the",value:"Congo, Republic of the"},
    {id:44, label:"Costa Rica",value:"Costa Rica"},
    {id:45, label:"Cote d'Ivoire",value:"Cote d'Ivoire"},
    {id:46,label:"Croatia",value:"Croatia"},
    {id:47, label:"Cyprus",value:"Cyprus"},
    {id:48, label:"Czech Republic",value:"Czech Republic"},
    {id:49, label:"Denmark",value:"Denmark"},
    {id:50, label:"Djibouti",value:"Djibouti"},
    {id:51, label:"Dominica",value:"Dominica"},
    {id:52, label:"Dominican Republic",value:"Dominican Republic"},
    {id:53, label:"East Timor (see Timor-Leste)",value:"East Timor (see Timor-Leste)"},
    {id:54, label:"Ecuador",value:"Ecuador"},
    {id:55, label:"Egypt",value:"Egypt"},
    {id:56, label:"El Salvador",value:"El Salvador"},
    {id:57, label:"Equatorial Guinea",value:"Equatorial Guinea"},
    {id:58, label:"Eritrea",value:"Eritrea"},
    {id:59, label:"Estonia",value:"Estonia"},
    {id:60, label:"Ethiopia",value:"Ethiopia"},
    {id:61, label:"Fiji",value:"Fiji"},
    {id:62, label:"Finland",value:"Finland"},
    {id:63, label:"France",value:"France"},
    {id:64, label:"Gabon",value:"Gabon"},
    {id:65,  label:"Gambia, The",value:"Gambia, The"},
    {id:66,  label:"Georgia",value:"Georgia"},
    {id:67,  label:"Germany",value:"Germany"},
    {id:68,  label:"Ghana",value:"Ghana"},
    {id:69,  label:"Greece",value:"Greece"},
    {id:70,  label:"Grenada",value:"Grenada"},
    {id:71,  label:"Guatemala",value:"Guatemala"},
    {id:72,  label:"Guinea",value:"Guinea"},
    {id:73,  label:"Guinea-Bissau",value:"Guinea-Bissau"},
    {id:74,  label:"Guyana",value:"Guyana"},
    {id:75,  label:"Haiti",value:"Haiti"},
    {id:76,  label:"Holy See",value:"Holy See"},
    {id:77,  label:"Honduras",value:"Honduras"},
    {id:78,  label:"Hong Kong",value:"Hong Kong"},
    {id:79,  label:"Hungary",value:"Hungary"},
    {id:80,  label:"Iceland",value:"Iceland"},
    {id:81,  label:"India",value:"India"},
    {id:82,  label:"Indonesia",value:"Indonesia"},
    {id:83,  label:"Iraq",value:"Iraq"},
    {id:84, label:"Ireland",value:"Ireland"},
    {id:85, label:"Israel",value:"Israel"},
    {id:86, label:"Italy",value:"Italy"},
    {id:87, label:"Jamaica",value:"Jamaica"},
    {id:88, label:"Japan",value:"Japan"},
    {id:89, label:"Jordan",value:"Jordan"},
    {id:90, label:"Kazakhstan",value:"Kazakhstan"},
    {id:91, label:"Kenya",value:"Kenya"},
    {id:92, label:"Kiribati",value:"Kiribati"},
    {id:93, label:"Kosovo",value:"Kosovo"},
    {id:94, label:"Kuwait",value:"Kuwait"},
    {id:95, label:"Kyrgyzstan",value:"Kyrgyzstan"},
    {id:96, label:"Laos",value:"Laos"},
    {id:97, label:"Latvia",value:"Latvia"},
    {id:98, label:"Lebanon",value:"Lebanon"},
    {id:99, label:"Lesotho",value:"Lesotho"},
    {id:100, label:"Liberia",value:"Liberia"},
    {id:101, label:"Libya",value:"Libya"},
    {id:102, label:"Liechtenstein",value:"Liechtenstein"},
    {id:103, label:"Lithuania",value:"Lithuania"},
    {id:104,label:"Luxembourg",value:"Luxembourg"},
    {id:105, label:"Macau",value:"Macau"},
    {id:106, label:"Macedonia",value:"Macedonia"},
    {id:107, label:"Madagascar",value:"Madagascar"},
    {id:108, label:"Malawi",value:"Malawi"},
    {id:109, label:"Malaysia",value:"Malaysia"},
    {id:110, label:"Maldive",value:"Maldive"},
    {id:111, label:"Mali",value:"Mali"},
    {id:112, label:"Malta",value:"Malta"},
    {id:113, label:"Marshall Islands",value:"Marshall Islands"},
    {id:114, label:"Mauritania",value:"Mauritania"},
    {id:115, label:"Mauritius",value:"Mauritius"},
    {id:116, label:"Mexico",value:"Mexico"},
    {id:117, label:"Micronesia",value:"Micronesia"},
    {id:118, label:"Moldova",value:"Moldova"},
    {id:119, label:"Monaco",value:"Monaco"},
    {id:120, label:"Mongolia",value:"Mongolia"},
    {id:121, label:"Montenegro",value:"Montenegro"},
    {id:122, label:"Morocco",value:"Morocco"},
    {id:123, label:"Mozambique",value:"Mozambique"},
    {id:124, label:"Namibia",value:">Namibia"},
    {id:125,label:"Nauru",value:"Nauru"},
    {id:126, label:"Nepal",value:"Nepal"},
    {id:127, label:"Netherlands",value:"Netherlands"},
    {id:128, label:"Netherlands Antilles",value:"Netherlands Antilles"},
    {id:129, label:"New Zealand",value:"New Zealand"},
    {id:130, label:"Nicaragua",value:"Nicaragua"},
    {id:131, label:"Niger",value:"Niger"},
    {id:132, label:"Nigeria",value:"Nigeria"},
    {id:133, label:"Norway",value:"Norway"},
    {id:134, label:"Oman",value:"Oman"},
    {id:135, label:"Pakistan",value:"Pakistan"},
    {id:136, label:"Palau",value:"Palau"},
    {id:137, label:"Palestinian Territories",value:"Palestinian Territories"},
    {id:138, label:"Panama",value:"Panama"},
    {id:139, label:"Papua New Guinea",value:"Papua New Guinea"},
    {id:140, label:"Paraguay",value:"Paraguay"},
    {id:141, label:"Peru",value:"Peru"},
    {id:142, label:"Philippines",value:"Philippines"},
    {id:143, label:"Poland",value:"Poland"},
    {id:144, label:"Portugal",value:"Portugal"},
    {id:145,  label:"Qatar",value:"Qatar"},
    {id:146,  label:"Romania",value:"Romania"},
    {id:148,  label:"Rwanda",value:"Rwanda"},
    {id:149,  label:"Saint Kitts and Nevis",value:"Saint Kitts and Nevis"},
    {id:150,  label:"Saint Lucia",value:"Saint Lucia"},
    {id:151,  label:"Saint Vincent and the Grenadines",value:"Saint Vincent and the Grenadines"},
    {id:152,  label:"Samoa",value:"Samoa"},
    {id:153,  label:"San Marino",value:"San Marino"},
    {id:154,  label:"Sao Tome and Principe",value:"Sao Tome and Principe"},
    {id:155,  label:"Saudi Arabia",value:"Saudi Arabia"},
    {id:156,  label:"Senegal",value:"Senegal"},
    {id:157,  label:"Serbia",value:"Serbia"},
    {id:158,  label:"Seychelles",value:"Seychelles"},
    {id:159,  label:"Sierra Leone",value:"Sierra Leone"},
    {id:160,  label:"Singapore",value:"Singapore"},
    {id:161,  label:"Slovakia",value:"Slovakia"},
    {id:162,  label:"Slovenia",value:"Slovenia"},
    {id:163,  label:"Solomon Islands",value:"Solomon Islands"},
    {id:164,  label:"Somalia",value:"Somalia"},
    {id:165,  label:"South Africa",value:"South Africa"},
    {id:166,  label:"South Korea",value:"South Korea"},
    {id:167,  label:"South Sudan",value:"South Sudan"},
    {id:168,  label:"Spain",value:"Spain"},
    {id:169,  label:"Sri Lanka",value:"Sri Lanka"},
    {id:170,  label:"Suriname",value:"Suriname"},
    {id:171,  label:"Swaziland",value:"Swaziland"},
    {id:172,  label:"Sweden",value:"Sweden"},
    {id:173,  label:"Switzerland",value:"Switzerland"},
    {id:174,  label:"Taiwan",value:"Taiwan"},
    {id:175,  label:"Tajikistan",value:"Tajikistan"},
    {id:176,  label:"Tanzania",value:"Tanzania"},
    {id:177,  label:"Thailand",value:"Thailand"},
    {id:178,  label:"Timor-Leste",value:"Timor-Leste"},
    {id:179,  label:"Togo",value:"Togo"},
    {id:180,  label:"Tonga",value:"Tonga"},
    {id:181, label:"Trinidad and Tobago",value:"Trinidad and Tobago"},
    {id:182, label:"Tunisia",value:"Tunisia"},
    {id:183, label:"Turkey",value:"Turkey"},
    {id:184, label:"Turkmenistan",value:"Turkmenistan"},
    {id:185, label:"Tuvalu",value:"Tuvalu"},
    {id:186, label:"Uganda",value:"Uganda"},
    {id:187, label:"Ukraine",value:"Ukraine"},
    {id:188, label:"United Arab Emirates",value:"United Arab Emirates"},
    {id:189, label:"United Kingdom",value:"United Kingdom"},
    {id:190, label:"Uruguay",value:"Uruguay"},
    {id:191, label:"Uzbekistan",value:"Uzbekistan"},
    {id:192, label:"Vanuatu",value:"Vanuatu"},
    {id:193, label:"Venezuela",value:"Venezuela"},
    {id:194, label:"Vietnam",value:"Vietnam"},
    {id:195, label:"Yemen",value:"Yemen"},
    {id:196, label:"Zambia",value:"Zambia"},
    {id:197, label:"Zimbabwe",value:"Zimbabwe"},

];

export const TUMOR_TYPES:FormList[] = [

    {id:1, label:"All",value:"All"},
    {id:2, label:"Bladder",value:"Bladder"},
    {id:3, label:"Breast",value:"Breast"},
    {id:4, label:"CNS",value:"CNS"},
    {id:5, label:"Colorectal",value:"Colorectal"},
    {id:6, label:"Esophageal",value:"Esophageal"},
    {id:7, label:"Eye",value:"Eye"},
    {id:8, label:"Gastrointestinal",value:"Gastrointestinal"},
    {id:9, label:"Germ Cell",value:"Germ Cell"},
    {id:10, label:"Head and Neck",value:"Head and Neck"},
    {id:11, label:"Kidney",value:"Kidney"},
    {id:12, label:"Leukemia/Lymphoma",value:"Leukemia/Lymphoma"},
    {id:13, label:"Liver",value:"Liver"},
    {id:14, label:"Lung",value:"Lung"},
    {id:15, label:"Melanoma",value:"Melanoma"},
    {id:16, label:"Neuroblastoma",value:"Neuroblastoma"},
    {id:17, label:"Neuroendocrine",value:"Neuroendocrine"},
    {id:18, label:"Ovarian",value:"Ovarian"},
    {id:19, label:"Pancreas",value:"Pancreas"},
    {id:20, label:"Prostate",value:"Prostate"},
    {id:21, label:"Sarcoma",value:"Sarcoma"},
    {id:22, label:"Uterine",value:"Uterine"},
    {id:23, label:OTHER_OPTION,value:OTHER_OPTION}
];


export const STUDY_PHASES:FormList[] = [
    {id:1,label:"Phase 1",value:"Phase 1"},
    {id:2, label:"Phase 2",value:"Phase 2"},
    {id:3, label:"Phase 2A",value:"Phase 2A"},
    {id:4, label:"Phase 2B",value:"Phase 2B"},
    {id:5, label:"Phase 3",value:"Phase 3"},
];

export const DATASET_TYPES:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"Raw or Original Data",value:"Original Data"},
    {id:3, label:"Curated or Composite Data",value:"Composite"},
    {id:4, label:"Both",value:"Both"},
];

export const DATASET_STANDARD:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"SDTM",value:"SDTM"},
    {id:3, label:"ADaM",value:"ADaM"},
    {id:4, label:"SEND",value:"PSEND"},
    {id:5, label:"Define XML",value:"Define XML"},
    {id:6, label:"Multiple",value:"Multiple"},
    {id:7, label:"Not CDISC",value:"Not CDISC"},
];

export const RWD_DATASET_TYPES:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"FHIR",value:"FHIR"},
    {id:3, label:"OMOP",value:"OMOP"},
    {id:4, label:OTHER_OPTION,value:OTHER_OPTION}
];

export const TRIAL_YEAR:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"<1990",value:"<1990"},
    {id:3, label:"1991",value:"1991"},
    {id:4, label:"1992",value:"1992"},
    {id:5, label:"1993",value:"1993"},
    {id:6, label:"1994",value:"1994"},
    {id:7, label:"1995",value:"1995"},
    {id:8, label:"1996",value:"1996"},
    {id:9, label:"1997",value:"1997"},
    {id:10, label:"1998",value:"1998"},
    {id:11, label:"1999",value:"1999"},
    {id:12, label:"2000",value:"2000"},
    {id:13, label:"2001",value:"2001"},
    {id:14, label:"2002",value:"2002"},
    {id:15, label:"2003",value:"2003"},
    {id:16, label:"2004",value:"2004"},
    {id:17, label:"2005",value:"2005"},
    {id:18, label:"2006",value:"2006"},
    {id:19, label:"2007",value:"2007"},
    {id:20, label:"2008",value:"2008"},
    {id:21, label:"2009",value:"2009"},
    {id:22, label:"2010",value:"2010"},
    {id:23, label:"2011",value:"2011"},
    {id:24, label:"2012",value:"2012"},
    {id:25, label:"2013",value:"2013"},
    {id:26, label:"2014",value:"2014"},
    {id:27, label:"2015",value:"2015"},
    {id:28, label:"2016",value:"2016"},
    {id:29, label:"2017",value:"2017"},
    {id:30, label:"2018",value:"2018"},
    {id:31, label:"2019",value:"2019"},
    {id:32, label:"2020",value:"2020"},
    {id:33, label:"2021",value:"2021"},
    {id:34, label:"2022",value:"2022"},
    {id:35, label:"2023",value:"2023"},
];

export const REGIONS:FormList[] = [
    {id:2, label:"Africa",value:"Africa"},
    {id:3, label:"Asia",value:"Asia"},
    {id:4, label:"Asia-Pacific",value:"Asia-Pacific"},
    {id:5, label:"Europe",value:"Europe"},
    {id:6, label:"Middle East",value:"Middle East"},
    {id:7, label:"North America",value:"North America"},
    {id:8, label:"South/Central America",value:"South/Central America"},
]

export const AGE_RANGE:FormList[] = [
    {id:2, label:"None",value:"None"},
    {id:3, label:"Child (Birth-17)",value:"Child (Birth-17)"},
    {id:4, label:"Adult (18-65)",value:"Adult (18-65)"},
    {id:5, label:"Elderly (66+)",value:"Elderly (66+)"},
]

export const CONTACT_US_REASONS:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"Registration Help or Login Trouble",value:"ENROLLMENT ISSUE"},
    {id:3, label:"Trouble with site or navigation",value:"TECHNICAL ISSUE"},
    {id:4, label:"Data Sharing or New Contribution",value:"SHARING ISSUE"},
    {id:5, label:"General Data Inquiry or Analysis",value:"DATA INQUIRY"},
    {id:6, label:"Organization or User Info Update",value:"USER INFO CHANGED"},
    {id:7, label:"Publication Planning/Citation Inquiry",value:"PUBLICATION"},
    {id:8, label:"NCTN/NCI Data Archive [Access]",value:"NCI DATA"},
    {id:9, label:OTHER_OPTION,value:OTHER_OPTION}

];


export const YES_NO:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"Yes",value: YES_OPTION},
    {id:3, label:"No",value: NO_OPTION},

];

export const DSA_TYPES:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"Both comparator and experimental arm data",value:BOTH_DSA},
    {id:3, label:"Comparator arm data only",value:COMPARATOR_ONLY},
    {id:4, label:"Experimental arm data only",value:EXPERIMENTAL_ONLY},
    {id:5, label:"Real World Data",value:REAL_WORLD},
];

export const DATA_DISPLAY_PAGE:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"Display on same page",value:"SAME_PAGE"},
    {id:3, label:"Display separately",value:"SEPARATE"},

];

export const NCI_ENTITY_TYPE:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"Non-Profit",value:"Non-Profit"},
    {id:3, label:"Commercial",value:"Commercial"},
    {id:4, label:"Academic",value:"Academic"},
    {id:5, label:"Government",value:"Government"},
    {id:6, label:"Self",value:"Self"},
    {id:7, label:OTHER_OPTION,value:OTHER_OPTION}

];

export const USER_APPROVAL:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:APPROVE_USER,value:APPROVE_USER},
    {id:3, label:RMI_USER,value:RMI_USER},
    {id:4, label:DECLINE_USER,value:DECLINE_USER},
    {id:5, label:RMI_NEW_TOKEN,value:RMI_NEW_TOKEN},
];

export const DATASET_APPROVAL:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:APPROVE_USER,value:APPROVE_USER},
    {id:3, label:RMI_USER,value:RMI_USER},
    {id:4, label:DECLINE_USER,value:DECLINE_USER},
];

export const RMI_CONTRIBUTION_REASONS:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"Information provided not complete - Clinical Trial Title",
        value:"Information provided not complete - Clinical Trial Title"},
    {id:3, label:"Information provided not complete - Sponsor",
        value:"Information provided not complete - Sponsor"},
    {id:4, label:"Information provided not complete - Trial Summary and Conditions",
        value:"Information provided not complete - Trial Summary and Conditions"},
    {id:5, label:"Information provided not complete - Data Summary",
        value:"Information provided not complete - Data Summary"},
    {id:6, label:"Information provided not complete - Study Type",value:"Information provided not complete - Study Type"},
    {id:7, label:"Information provided not complete - Dataset Type",value:"Information provided not complete - Dataset Type"},

];

export const RMI_ENROLLMENT_REASONS:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"Information provided not complete - Name",
        value:"Information provided not complete - Name"},
    {id:3, label:"Information provided not complete - E-mail",
        value:"Information provided not complete - E-mail"},
    {id:5, label:"Information provided not complete - Phone Number",
        value:"Information provided not complete - Phone Number"},
    {id:6, label:"Information provided not complete - Affiliation",
        value:"Information provided not complete - Affiliation"},
    {id:7, label:"Information provided not complete - Organization",
        value:"Information provided not complete - Organization"},
    {id:8, label:"Information provided not complete - Role",
        value:"Information provided not complete - Role"},
    {id:9, label:"Information provided not complete - Department",
        value:"Information provided not complete - Department"},
    {id:10, label:"Information provided not complete - Address, City, State, Country, Postal Code",
        value:"Information provided not complete - Address, City, State, Country, Postal Code"},
    {id:11, label:"Information provided not complete - Reason for Visiting",
        value:"Information provided not complete - Reason for Visiting"},
    {id:12, label:"Information provided not complete - Research Description and Goals",
        value:"Information provided not complete - Research Description and Goals"},
];


export const ACCOUNT_DECLINE_REASONS:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"Duplicate Process", value:"Duplicate Process"},
    {id:3, label:"Existing Debarments/Sanctions", value:"Existing Debarments/Sanctions"},
    {id:4, label:"Insufficient Information provided", value:"Insufficient Information provided"},
    {id:6, label:OTHER_OPTION,value:OTHER_OPTION}
];

export const DATA_TYPE_COMPARATOR_RAW:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"RAW OR ORIGINAL DATA (COMPARATOR ARM)", value:"RAW OR ORIGINAL DATA (COMPARATOR ARM)"},
    {id:3, label:"DATA SUMMARY", value:"Data Summary"},
];

export const DATA_TYPE_COMPARATOR_CURATED:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"CURATED OR COMPOSITE DATA (COMPARATOR ARM)", value:"CURATED OR COMPOSITE DATA (COMPARATOR ARM)"},
    {id:3, label:"DATA SUMMARY", value:"Data Summary"},
];

export const DATA_TYPE_COMPARATOR_BOTH:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"RAW OR ORIGINAL DATA (COMPARATOR ARM)", value:"RAW OR ORIGINAL DATA (COMPARATOR ARM)"},
    {id:3, label:"CURATED OR COMPOSITE DATA (COMPARATOR ARM)", value:"CURATED OR COMPOSITE DATA (COMPARATOR ARM)"},
    {id:4, label:"DATA SUMMARY", value:"Data Summary"},
];

export const DATA_TYPE_EXPERIMENTAL_RAW:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"RAW OR ORIGINAL DATA (ACTIVE ARM)", value:"RAW OR ORIGINAL DATA (ACTIVE ARM)"},
    {id:3, label:"DATA SUMMARY", value:"Data Summary"},

];

export const DATA_TYPE_EXPERIMENTAL_CURATED:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"CURATED OR COMPOSITE DATA (ACTIVE ARM)", value:"CURATED OR COMPOSITE DATA (ACTIVE ARM)"},
    {id:3, label:"DATA SUMMARY", value:"Data Summary"},
];

export const DATA_TYPE_EXPERIMENTAL_BOTH:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"RAW OR ORIGINAL DATA (ACTIVE ARM)", value:"RAW OR ORIGINAL DATA (ACTIVE ARM)"},
    {id:3, label:"CURATED OR COMPOSITE DATA (ACTIVE ARM)", value:"CURATED OR COMPOSITE DATA (ACTIVE ARM)"},
    {id:4, label:"DATA SUMMARY", value:"Data Summary"},
];


export const DATA_TYPE_BOTH_DSA_RAW:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"RAW OR ORIGINAL DATA (COMPARATOR ARM)", value:"RAW OR ORIGINAL DATA (COMPARATOR ARM)"},
    {id:3, label:"RAW OR ORIGINAL DATA (ACTIVE ARM)", value:"RAW OR ORIGINAL DATA (ACTIVE ARM)"},
    {id:4, label:"RAW OR ORIGINAL DATA (BOTH COMPARATOR AND ACTIVE ARMS)", value:"RAW OR ORIGINAL DATA (BOTH COMPARATOR AND ACTIVE ARMS)"},
    {id:5, label:"DATA SUMMARY", value:"Data Summary"},
];

export const DATA_TYPE_BOTH_DSA_CURATED:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"CURATED OR COMPOSITE DATA (COMPARATOR ARM)", value:"CURATED OR COMPOSITE DATA (COMPARATOR ARM)"},
    {id:3, label:"CURATED OR COMPOSITE DATA (ACTIVE ARM)", value:"CURATED OR COMPOSITE DATA (ACTIVE ARM)"},
    {id:4, label:"CURATED OR COMPOSITE DATA (BOTH COMPARATOR AND ACTIVE ARMS)", value:"CURATED OR COMPOSITE DATA (BOTH COMPARATOR AND ACTIVE ARMS)"},
    {id:5, label:"DATA SUMMARY", value:"Data Summary"},
];

export const DATA_TYPE_BOTH_DSA_BOTH:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"RAW OR ORIGINAL DATA (COMPARATOR ARM)", value:"RAW OR ORIGINAL DATA (COMPARATOR ARM)"},
    {id:3, label:"RAW OR ORIGINAL DATA (ACTIVE ARM)", value:"RAW OR ORIGINAL DATA (ACTIVE ARM)"},
    {id:4, label:"RAW OR ORIGINAL DATA (BOTH COMPARATOR AND ACTIVE ARMS)", value:"RAW OR ORIGINAL DATA (BOTH COMPARATOR AND ACTIVE ARMS)"},
    {id:5, label:"CURATED OR COMPOSITE DATA (COMPARATOR ARM)", value:"CURATED OR COMPOSITE DATA (COMPARATOR ARM)"},
    {id:6, label:"CURATED OR COMPOSITE DATA (ACTIVE ARM)", value:"CURATED OR COMPOSITE DATA (ACTIVE ARM)"},
    {id:7, label:"CURATED OR COMPOSITE DATA (BOTH COMPARATOR AND ACTIVE ARMS)", value:"CURATED OR COMPOSITE DATA (BOTH COMPARATOR AND ACTIVE ARMS)"},
    {id:8, label:"DATA SUMMARY", value:"Data Summary"},
];

export const FILES_SELECT:SelectFilesList[] = [
    {id:1, dsaType:COMPARATOR_ONLY, studyContent:RAW_ORIGINAL, valueList:DATA_TYPE_COMPARATOR_RAW},
    {id:2, dsaType:COMPARATOR_ONLY, studyContent:CURATED_COMPOSITE, valueList:DATA_TYPE_COMPARATOR_CURATED},
    {id:3, dsaType:COMPARATOR_ONLY, studyContent:BOTH_STUDY_CONTENT, valueList:DATA_TYPE_COMPARATOR_BOTH},
    {id:4, dsaType:EXPERIMENTAL_ONLY, studyContent:RAW_ORIGINAL, valueList:DATA_TYPE_EXPERIMENTAL_RAW},
    {id:5, dsaType:EXPERIMENTAL_ONLY, studyContent:CURATED_COMPOSITE, valueList:DATA_TYPE_EXPERIMENTAL_CURATED},
    {id:6, dsaType:EXPERIMENTAL_ONLY, studyContent:BOTH_STUDY_CONTENT, valueList:DATA_TYPE_EXPERIMENTAL_BOTH},
    {id:7, dsaType:BOTH_DSA, studyContent:RAW_ORIGINAL, valueList:DATA_TYPE_BOTH_DSA_RAW},
    {id:8, dsaType:BOTH_DSA, studyContent:CURATED_COMPOSITE, valueList:DATA_TYPE_BOTH_DSA_CURATED},
    {id:9, dsaType:BOTH_DSA, studyContent:BOTH_STUDY_CONTENT, valueList:DATA_TYPE_BOTH_DSA_BOTH},
];

export const CLINICAL_DRUG_ADDITIVITY:FormList[] = [
    {id:1, label:"Days",value:"Days"},
    {id:2, label:"Weeks", value:"Weeks"},
    {id:3, label:"Months", value:"Months"},
    {id:3, label:"Years", value:"Years"},
];

export const ADMIN_TICKETS_ACTIONS:FormList[] = [
    {id:1, label:"Close", value:CLOSED},
    {id:2, label:"Spam",value:SPAM},
    {id:3, label:"Start Progress", value:STARTED},
    {id:4, label:"Add Comment", value:"COMMENT"},
    {id:5, label:"Add Internal Only Comment", value:"INTERNAL_COMMENT"},
    {id:6, label:"Closed without Notification", value:"CLOSED_WITHOUT_NOTIFICATION"},
];

export const ADMIN_TICKETS_ACTIONS_WITHOUT_PROGRESS:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"Close", value:CLOSED},
    {id:3, label:"Spam",value:SPAM},
    {id:4, label:"Add Comment", value:"COMMENT"},
    {id:5, label:"Add Internal Only Comment", value:"INTERNAL_COMMENT"},
    {id:6, label:"Closed without Notification", value:"CLOSED_WITHOUT_NOTIFICATION"},
];

export const ADMIN_TICKETS_ACTIONS_CLOSED:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"Re-open", value:"RE_OPENED"},
];

export const ADMIN_TICKETS_STATUS:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"Open", value:OPEN},
    {id:3, label:"Closed", value:CLOSED},
    {id:4, label:"Spam",value:SPAM},
    {id:5, label:"Add Comment", value:"COMMENT"},
    {id:6, label:"Add Internal Only Comment", value:"INTERNAL_COMMENT"},
    {id:7, label:"Closed without Notification", value:"CLOSED_WITHOUT_NOTIFICATION"},
    {id:8, label:"Created",value:"CREATED"},
    {id:9, label:"I" +
            "n Progress",value:"IN_PROGRESS"},
    {id:10, label:"Start Progress",value:"STARTED"},
    {id:11, label:"Re-open",value:"RE_OPENED"},
];

export const SERVICE_TICKETS_OPEN:FormList[] = [
    {id:1, label:EMPTY_OPTION_LABEL,value:EMPTY_OPTION_VALUE},
    {id:2, label:"Add Comment", value:"COMMENT"},
    {id:3, label:"Close", value:CLOSED},
];



export const NCI_ACTIONS:FormList[] = [
    {id:1, label:"Unknown", value:"Unknown"},
    {id:2, label:"Submitted to NCI", value:"1"},
    {id:3, label:"Under review", value:"2"},
    {id:4, label:"Approved", value:"3"},
    {id:5, label:"Rejected", value:"4"},
    {id:6, label:"Expired", value:"5"},
    {id:7, label:"Withdrawn", value:"6"},
];




export const CLIN_DRUG:FormList[] = [
    {id:1, label:"Weeks",value:"Weeks"},
    // {id:2, label:"Days", value:"Days"},
    // {id:3, label:"Months", value:"Months"},
    // {id:4, label:"Years", value:"Years"},
];

export const TICKET_HEADERS:IAdminCountsHeaderList[] = [
    {
        id: 1,
        label: OPEN_TICKETS,
        className: "me-lg-4",
        type: "OPEN",
        navigateUrl: BASE_PATH + "admin/tickets/OPEN",
        image: "/images/icons/icon_waiting.png",
        alt: "Waiting icon",
    },
    {
        id: 2,
        label: TICKETS_IN_PROGRESS,
        className: "me-lg-4",
        type: "IN_PROGRESS",
        navigateUrl: BASE_PATH + "admin/tickets/IN_PROGRESS",
        image: "/images/icons/icon_done.png",
        alt: "Waiting icon",
    },
    {
        id: 3,
        label: CLOSED_TICKETS,
        className: "me-lg-0",
        type: "CLOSED",
        navigateUrl: BASE_PATH + "admin/tickets/CLOSED",
        image: "/images/icons/incomplete_24.png",
        alt: "Done icon",
    }
];
export const ACCOUNT_HEADERS:IAdminCountsHeaderList[] = [
    {
        id: 1,
        label: OPEN_REQUESTS,
        className: "me-lg-4",
        type: NEW_MIXED,
        navigateUrl: BASE_PATH + "admin/request/users/NEW",
        image: "/images/icons/icon_waiting.png",
        alt: "Waiting icon",
    },

    {
        id: 2,
        label: ACTIVE_ACCOUNTS,
        className: "me-lg-4",
        type: APPROVED_MIXED,
        navigateUrl: BASE_PATH + "admin/request/users/APPROVED",
        image: "/images/icons/icon_done.png",
        alt: "Done icon",
    },

    {
        id: 3,
        label: DECLINED_USERS,
        className: "",
        type: "Declined",
        navigateUrl: BASE_PATH + "admin/request/users/DECLINED",
        image: "/images/icons/incomplete_24.png",
        alt: "Done icon",
    },

];

export const DATASETS_HEADERS:IAdminCountsHeaderList[] = [
    {
        id: 1,
        label: OPEN_DATASETS,
        className: "me-lg-1",
        type: PENDING_VERIFICATION,
        navigateUrl: BASE_PATH + "admin/request/datasets/PENDING",
        image: "/images/icons/icon_waiting.png",
        alt: "Waiting icon",
    },

    {
        id: 2,
        label: ACTIVE_DATASETS,
        className: "me-lg-1",
        type: APPROVED_MIXED,
        navigateUrl: BASE_PATH + "admin/request/datasets/APPROVED",
        image: "/images/icons/complete_check_48.png",
        alt: "Complete icon",
    },

    {
        id: 3,
        label: NCI_DATASETS,
        className: "me-lg-1",
        type: NCI,
        navigateUrl: BASE_PATH + "admin/request/datasets/NCI",
        image: "/images/icons/complete_check_48.png",
        alt: "Complete icon",
    },

    {
        id: 4,
        label: QUARANTINED_DATASETS,
        className: "me-lg-1",
        type: QUARANTINED_MIXED,
        navigateUrl: BASE_PATH +  "admin/request/datasets/QUARANTINED",
        image: "/images/icons/incomplete_24.png",
        alt: "Incomplete icon",
    },
    {
        id: 5,
        label: DECLINED_DATASETS,
        className: "me-lg-0",
        type: DECLINED_MIXED,
        navigateUrl: BASE_PATH + "admin/request/datasets/DECLINED",
        image: "/images/icons/incomplete_24.png",
        alt: "Incomplete icon",
    },


];

export const DSA_HEADERS:IAdminCountsHeaderList[] = [
    {
        id: 1,
        label: OPEN_DSA,
        className: "me-lg-4",
        type: PENDING_VERIFICATION,
        navigateUrl: BASE_PATH +  "admin/request/dsa/NEW",
        image: "/images/icons/icon_waiting.png",
        alt: "Waiting icon",
    },

    {
        id: 2,
        label: APPROVED_DSA,
        className: "",
        type: APPROVED_MIXED,
        navigateUrl: BASE_PATH + "admin/request/dsa/APPROVED",
        image: "/images/icons/complete_check_48.png",
        alt: "Complete icon",
    },
]

export const FILE_ICONS:IFileIcons[] = [
    {id:1, fileType:"sas7bdat",icon:"icon-sas7bdat.png"},
    {id:2, fileType:"zip",icon:"icon-zip.png"},
    {id:3, fileType:"pdf", icon:"icon-pdf.png"},
    {id:4, fileType:"doc", icon:"icon-doc.png"},
    {id:5, fileType:"docx", icon:"icon-docx.png"},
    {id:6, fileType:"txt", icon:"icon-txt.png"},
    {id:7, fileType:"rtf", icon:"icon-rtf.png"},
    {id:8, fileType:"xls", icon:"icon-xls.png"},
    {id:9, fileType:"xlsx", icon:"icon-xlsx.png"},
    {id:10, fileType:"xml", icon:"icon-xml.png"},
    {id:11, fileType:"xpt", icon:"icon-xpt.png"},
    {id:12, fileType:"csv", icon:"icon-csv.png"},
    {id:13, fileType:"ppt", icon:"icon-ppt.png"},
    {id:14, fileType:"pptx", icon:"icon-pptx.png"},
];















