/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ConfirmDialog
 *
 * Purpose: common confirm dialog
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input: title, body, show, showhide and function for submit
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 02Oct2023 craig File created. (copied from Gold Standard app
 \******************************************************************************/
import React from "react";
import {Button, Modal} from "react-bootstrap";

interface ConfirmDialogProps {
    title:string,
    body: React.ReactNode,
    show:boolean,
    showHide:any,
    submitAction:any
    primaryButtonText: string,
    secondaryButtonText: string
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({title, body, show, showHide, submitAction,primaryButtonText, secondaryButtonText
}: ConfirmDialogProps) => {
    return (
        <Modal key={"confirm-dialog"} show={show} onHide={showHide} centered>
            <Modal.Header closeButton={true}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                <Button variant={"primary"} onClick={submitAction}>
                    {primaryButtonText}
                </Button>
                <Button variant={"secondary"} onClick={showHide}>
                    {secondaryButtonText}
                </Button>

            </Modal.Footer>
        </Modal>
    )
}