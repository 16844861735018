/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: SubmissionResponse
 *
 * Purpose: Display Submission Response page
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 13Jun2023 sasjxa file created.
 \******************************************************************************/
import React, {Fragment, useState} from "react";
import {
    PageHeaderWithBackground,
    ResourceItemPage,
    PDS_P, PDS_P_BOLD, PageWrapper, ContentPage

} from "../../components/styled/StyledComponents";

// @ts-ignore
import styled from 'styled-components';
import {useLocation} from "react-router-dom";
import parse from "html-react-parser";
import {Col, Row} from "react-bootstrap";
import {COLORS, FONT_SIZES} from "../../components/styled/StyleConstants";




const ClinicalDrugDescriptionTitle = styled(PDS_P_BOLD)`
    text-align: center;
    margin: 2% auto;    
`;

const FootNote = styled.div`
   

    span, p {
        font-size: ${FONT_SIZES.px13};
    }
`;


export const ClinicalDrugAdditivityDescription: React.FC = () => {

    const DOI_URL: string = "https://doi.org/10.1038/s43018-023-00667-z";

    const buildDescription = () => {
        return (
            <>
                <ClinicalDrugDescriptionTitle>The Palmer Algorithm Application</ClinicalDrugDescriptionTitle>
                <PDS_P> This application provides an estimate of Progression Free Survival (PFS) for a combination
                    therapy
                    of two independent cancer agents. The estimate is based on a published algorithm
                    (called the Palmer Algorithm or Palmer-Sorger Algorithm) that has been validated against clinical
                    trial performance data [1].
                </PDS_P>
                <PDS_P>The inputs provided by the user for this algorithm are </PDS_P>
                <ul>
                    <li>Two comma-separated value files (.csv), one for each agent, denoting PFS values of Time in weeks
                        (column 1) and Survival (column 2, stated as an integer reflecting the percentage of patients
                        who
                        have survived at that given Time; that is, 85% survival means 85 is entered as the value)
                        <ul>
                            <li>One of the inputs is labeled as ‘Experiment’ and the other ‘Control’; note that these
                                terms conserve
                                the original language of the algorithm, but for purposes of this application both agents
                                can
                                be assigned to one or the other category without any change in outcome
                            </li>
                        </ul>
                    </li>
                    <li>For each agent, the First Radiology Scan time should be provided; this is the time
                        (usually specified in weeks) that the first post-drug-administration radiology scan (CT or PET/CT)
                        was performed to assess drug efficacy, usually derived from the protocol used in the clinical trial or study.  If no value was specified, an integer value for the mean or median time in weeks of the scan can be used (the default is 0 weeks, but this will not give accurate results for the Additivity projection)</li>

                </ul>
                <PDS_P>The outputs provided by the algorithm are </PDS_P>
                <ul>
                    <li>Two .csv files showing estimated PFS curve results for</li>
                    <ul>
                        <li>Highest Single Agent (HSA).  This curve is based on the assumption there is no interaction between the agents; that is, for a drug combination A and B, the performance is based on the
                            best value for A or B at that point in time
                        </li>
                        <li>
                           Additivity.  This curve projects the combination effect of the drugs; this is, for a drug combination A and B, the performance is based on values for A + B, and projects a better outcome than HSA
                        </li>
                    </ul>
                    <li>A graph demonstrating HSA and Additivity curves</li>
                </ul>
                <PDS_P>As for predicting successful combinations of agents, the HSA model has an AUC of 0.86 and the Additivity model has an AUC of 0.92 when measured against real-world combination results based on PFS curves from the performance of the agents independently.</PDS_P>
                <FootNote className="mt-3 mb-5">
                    <p className=" mb-0">
                        [1] Hwangbo, H., Patterson, S.C., Dai, A. et al. Additivity predicts the efficacy of most
                        approved combination therapies for advanced cancer. Nat Cancer 4, 1693–1704 (2023).
                    </p>
                    <span className="mt-0  ">
                            <a href={DOI_URL} target="_blank"
                               rel={"noopener noreferrer"}>https://doi.org/10.1038/s43018-023-00667-z</a>
                </span>
                </FootNote>

            </>

        )
    }

    return (
        <>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <Col lg={12}>
                        {buildDescription()}
                    </Col>
                </PageWrapper>
            </ContentPage>
        </>
    )
}