/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: IUser
 *
 * Purpose: Interface for user object
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 06Feb2023 craig File created.
 * 18Dec2023 craig added nctTrialNumber to INciRequestCart
 * 19Dec2023 craig Added ncidatarequests to user
 * 04Jan2024 craig Added shortusername to user
 * 23Jan2024 craig Added expired and expiredKey to IUser
 * 16Aug2024 craig Removed jwt from IUser
 \******************************************************************************/
import {ISmallUserDetails} from "./ISmallUser";

export enum PDSGroup {
    Guest = "GUEST",
    NCI_Service = "NCI_SERVICE",
    Consumer = "CONSUMER",
    Provider = "PROVIDER",
    Reviewer = "REVIEWER",
    Approver = "APPROVERS",
    Admin = "ADMINISTRATOR"
}

export type IUser = {
    userID: number,
    name: string,
    username: string,
    shortUsername: string,
    phone: string,
    pdsGroup: PDSGroup,
    passwordExpires: Date,
    userExpiration: IUserExpiration,
    expiredKey: string,
    attested: boolean,
    pdsMember: boolean,
    serviceAccount: boolean,
    nciRequestCart: INciRequestCart[],
    nciDataRequests: INciRequestData[]
}

export type IFullUser = {
    requestID: number,
    timeReceived: Date,

}

export interface ISmallUser {
    userID: number,
    userDetails: ISmallUserDetails
}

export interface INciRequestCart {
    userId:number,
    donationId:number,
    nctTrialNumber:string
}

export interface INciRequestFullCart {
    userId:number,
    donationId:number,
    nctTrialNumber:string,
    description:string,
    numDatasets:string,
}

export interface INciRequestData {
    id:number,
    researchPlanTitle:string,
    submitted:boolean,
    requestStatus:number,
    createdAt:Date,
    trialNumbers: INciDataRequestTrialNumbers[]
}

export interface INciRequestFullData {
    id:number,
    nciRequestId:number,
    researchPlanTitle:string,
    researchPlanDescription:string,
    entity:string,
    entityType:string,
    entityTypeOther:string,
    address:string,
    city:string,
    state:string,
    country:string,
    postalCode:string,
    includesCollaborativeAgreement:boolean,
    authorizedRepEmail:string,
    authorizedRepresentative:string,
    oaStatus:boolean,
    oaUploaded:boolean,
    assentSupplementalTerms:boolean,
    submitted:boolean,
    requestStatus:number,
    createdAt:Date,
    authUsers:INciAuthUsers[],
    trialNumbers: INciDataRequestTrialNumbers[]
}

export interface INciAuthUsers {
    name:string,
    email:string
}
export interface INciDataRequestTrialNumbers {
    requestId:number,
    nctTrialNumber:string
}

export interface INciUserRequests {
    nciRequestCart:INciRequestFullCart[],
    nciDataRequest: INciRequestData[]
}

export interface IUserExpiration {
    expirationDt: Date,
    randomKey: string,
    expired: boolean,
    reactivated: boolean,
    daysToExpired: number,
}


