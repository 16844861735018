/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: SponsorDataProvider
 *
 * Purpose: Sponsor and Data Provider for Share Data
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 27Aug2023 sasjxa file created
 * 20Oct2023 sasjxa add parm for label creation class
 * 19Jun2024 sasjxa remove labelClassName parameter
 \******************************************************************************/
import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import {ErrorMessage} from "formik";
import {TRIAL_YEAR} from "../../data/formList";
import {labelIfReadOnly} from "../../constants/CommonFunctions";


interface ClinTrialsProps {
    trialStartYear: any,
    trialEndYear: any,
    listHandler: any,
    changeHandlerStartYear: any,
    changeHandlerEndYear: any,
    handleChange: any,
    disabled: boolean,
    touched: any,
    errors: any,
}


export const TrialYears: React.FC<ClinTrialsProps> =
    ({
          trialStartYear, trialEndYear, listHandler,disabled, handleChange,
         changeHandlerStartYear, changeHandlerEndYear,touched, errors
     }) => {
        return (
            <>
                <Row className="mt-3 mb-3 ">
                    <Form.Group as={Col} lg={6}
                                controlId="formTrialStartYear">
                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                    column="sm"> Trial Start Year
                        </Form.Label>
                        {!disabled && (
                            <Form.Select size="sm" required
                                         name={"trialStartYear"}
                                         isValid={touched.trialStartYear && !errors.trialStartYear}
                                         value={trialStartYear}
                                         onChange={(e) => {changeHandlerStartYear(e.target.value)}}>
                                {listHandler(TRIAL_YEAR)}
                            </Form.Select>
                        )}
                        {disabled && (
                            <Form.Control size="sm"
                                          name={"trialStartYear"}
                                          type="text"
                                          disabled={disabled}
                                          defaultValue={trialStartYear} />


                        )}
                        <Form.Text className="text-muted"></Form.Text>
                        <Form.Control.Feedback type="invalid">
                            Please select a trial start year
                        </Form.Control.Feedback>
                        <ErrorMessage name={"trialStartYear"}
                                      render={msg => <div
                                          className={"form-error-msg"}>{msg}</div>}/>
                    </Form.Group>


                    <Form.Group as={Col} lg={6}
                                controlId="formTrialEndYear">
                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                    column="sm"> Trial End Year
                        </Form.Label>
                        {!disabled && (
                            <Form.Select size="sm" required
                                         name={"trialEndYear"}
                                         isValid={touched.trialEndYear && !errors.trialEndYear}
                                         value={trialEndYear}
                                         onChange={(e) => {changeHandlerEndYear(e.target.value)}}>
                                {listHandler(TRIAL_YEAR)}
                            </Form.Select>
                        )}
                        {disabled && (
                            <Form.Control size="sm"
                                          name={"trialEndYear"}
                                          type="text"
                                          disabled={disabled}
                                          defaultValue={trialEndYear} />


                        )}
                        <Form.Text className="text-muted"></Form.Text>
                        <Form.Control.Feedback type="invalid">
                            Please select a trial end year
                        </Form.Control.Feedback>
                        <ErrorMessage name={"trialEndYear"}
                                      render={msg => <div
                                          className={"form-error-msg"}>{msg}</div>}/>
                    </Form.Group>

                </Row>
            </>
        )

    }