/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminUserLoginsInfo
 *
 * Purpose: component for building user login ins
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 31Oct2024 sasjxa file created
 \******************************************************************************/
import React, {useState} from "react";
import {useRequest} from "../../helper/useRequest";
import {UserLoginInfoDialog} from "../dialogs/UserLoginInfoDialog";
import {IAdminSamUserInfo} from "../../model/admin/requests/IAdminSamUserInfo";
import {ErrorDialog} from "../dialogs/ErrorDialog";

interface UserLoginsInfoProps {
   userId: number
}

export const AdminUserLoginsInfo: React.FC<UserLoginsInfoProps> = ({
                                                                  userId,


                                                              }: UserLoginsInfoProps) => {


    const [showUserLogins, setShowUserLogins] = useState<boolean>(false);
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<IAdminSamUserInfo>();


    /**
     * handleError - from axios download file request
     * TODO - file out proper error message
     *
     * @param error - error object
     */
    const handleError = (error: any) => {
        setShowErrorDialog(true);
    }

    /**
     * handleSuccess -
     *
     * @param response - response from axios
     */
    const handleSuccess = (response: IAdminSamUserInfo) => {
        setUserInfo(response);
        setShowUserLogins(true);
    }


    const appDataURL = process.env.REACT_APP_API_URL + "/api/admin/users/userLoginsInfo/" + userId;
    const [requestState, getUserLogins] = useRequest({
        url: appDataURL,
        method: "get",
        initialIsLoading: true,
        withCredentials: true,
        onError: handleError,
        onSuccess: handleSuccess,
    })
    const {isLoading, data, error} = requestState;


    // toggle user info dialog
    const toggleUserInfoDialog = () => {
        setShowUserLogins(!showUserLogins);
    }

    // toggle error dialog
    const toggleErrorDialog = () => {
        setShowErrorDialog(!showErrorDialog);
    }

    // get user logins
    const getUserSAMInfo = () => {
        {getUserLogins()}
    }

    /**
     * buildDownloadLink - build the "download" link
     */
    const buildContentsLink = () => {

             return (
                 <>
                     <UserLoginInfoDialog userInfo ={userInfo}
                                 show={showUserLogins} showHide={toggleUserInfoDialog}/>
                     <ErrorDialog message={"User not in Active Directory"}
                                          show={showErrorDialog} showHide={toggleErrorDialog}/>
                         <img
                             onClick={() =>  getUserSAMInfo()}
                             src={"/images/icons/more_info.png"}
                             className="text-center"></img>

                 </>
             )

    }

    /**
     * return the link
     */
    return (
        <>
            {buildContentsLink()}
        </>
    )
}