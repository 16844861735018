/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: DataStandard
 *
 * Purpose: Data Standard for Share Data (Real World Data)
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 29Aug2023 sasjxa file created
 * 20Oct2023 sasjxa add parm for label creation class
 * 19Jun2024 sasjxa remove labelClassName parameter
 \******************************************************************************/
import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import {ErrorMessage} from "formik";
import {RWD_DATASET_TYPES} from "../../data/formList";
import {labelIfReadOnly} from "../../constants/CommonFunctions";


interface DataStandardRWDProps {
    value: any,
    valueHandler: any,
    handleDataStandardChanged: any,
    listHandler: any,
    disabled: boolean,
    handleChange: any,
    touched: any,
    errors: any,

}


export const DataStandardRWD: React.FC<DataStandardRWDProps> =
    ({
         value, valueHandler, handleDataStandardChanged, listHandler,
         disabled, touched, errors
     }) => {
        return (
            <Row className="mt-1 mb-3 ">
                <Form.Group as={Col} lg={6} className="dataType"
                            controlId="dataStandard">
                    <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Data
                        Standard </Form.Label>
                    {!disabled && (
                        <Form.Select size="sm" required
                                     name={"dataStandard"}
                                     isValid={touched.dataStandard && !errors.dataStandard}
                                     value={value}
                                     onChange={(e) => {
                                         handleDataStandardChanged(e.target.value)
                                         valueHandler(e.target.value)
                                     }}>
                            {listHandler(RWD_DATASET_TYPES)}
                        </Form.Select>
                    )}
                    {disabled && (
                        <Form.Control size="sm"
                                      name={"dataStandard"}
                                      type="text"
                                      disabled={disabled}
                                      defaultValue={value}
                        />
                    )}

                    <Form.Control.Feedback type="invalid">
                        Please select a data type.
                    </Form.Control.Feedback>
                    <ErrorMessage name={"dataStandard"}
                                  render={msg => <div
                                      className={"form-error-msg"}>{msg}</div>}/>
                </Form.Group>
            </Row>

        )

    }