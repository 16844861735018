/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: InfoDialog
 *
 * Purpose: Header for PDS application
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 22Jun2023 sasjxa  file created.
 * 08Mar2024 craig   add basepath
 * 25Aug2024 craig  Change href to navlink
 \******************************************************************************/
import React from "react";
import {AsideSection, AsideWrapper, PDS_H5, PDS_P, PDS_P_BOLD} from "./styled/StyledComponents";
import {
    ABOUT_RESOURCES,
    CONTACT_US,
    NEED_HELP,
    NEW_ORGANIZATION,
    PREPARE_INFORMATION,
    REQUIRED_FIELDS
} from "../data/aside/aside";
import parse from "html-react-parser";
import {BASE_PATH} from "../constants";
import {NavLink} from "react-router-dom";

interface DialogProps {
    needHelp?: boolean,
    requiredFields?: boolean,
    aboutResources?: boolean,
    prepareInformation?: boolean,
    newOrganization?: boolean,
}

export const Aside: React.FC<DialogProps> = ({
                                                 needHelp,
                                                 requiredFields,
                                                 aboutResources,
                                                 prepareInformation,
                                                 newOrganization
                                             }: DialogProps) => {
    return (
        <AsideWrapper name={"aside-wrapper"}>
            {prepareInformation && (
                <AsideSection>
                    <PDS_H5>{PREPARE_INFORMATION.title}</PDS_H5>
                    <PDS_P_BOLD> {parse(PREPARE_INFORMATION.text)}</PDS_P_BOLD>
                    <NavLink to={BASE_PATH + "resources"} className={"linkstyle"}>Resources</NavLink>
                </AsideSection>
            )}
            {newOrganization && (
            <AsideSection>
                <PDS_H5>{NEW_ORGANIZATION.title}</PDS_H5>
                <PDS_P> {parse(NEW_ORGANIZATION.text)}</PDS_P>
            </AsideSection>
            )}
            {aboutResources && (
            <AsideSection>
                <PDS_H5>{ABOUT_RESOURCES.title}</PDS_H5>
                <PDS_P>{ABOUT_RESOURCES.text}</PDS_P>
            </AsideSection>
            )}
            {requiredFields && (
            <AsideSection>
                <PDS_H5>{REQUIRED_FIELDS.title}</PDS_H5>
                <PDS_P> {parse(REQUIRED_FIELDS.text)}</PDS_P>
            </AsideSection>
            )}
            {needHelp && (
            <AsideSection>
                <PDS_H5>{NEED_HELP.title}</PDS_H5>
                <PDS_P> {parse(NEED_HELP.text)}</PDS_P>
                <NavLink to={BASE_PATH + "resources"} className={"linkstyle"}>Resources</NavLink>
            </AsideSection>
            )}
            <AsideSection>
                <PDS_H5>{CONTACT_US.title}</PDS_H5>
                <PDS_P>{CONTACT_US.text}</PDS_P>
                <NavLink to={BASE_PATH + "contactUs"} className={"linkstyle"}>Contact Us</NavLink>
            </AsideSection>
        </AsideWrapper>
    )
}
