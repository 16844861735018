/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Share
 *
 * Purpose: Admin DSA Upload
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 04Aug2023 sasjxa  file created.
 * 11Aug2023 sasjxa  rename url
 * 22Aug2023 craig   Add backend to upload
 * 31Aug2023 sasjxa  fix navigation issue
 * 08Mar2024 craig   added basepath
 * 04Apr2024 craig   Switched to react-error-boundary
 * 11Apr2024 sasjxa  display spinner for loading data (MPM-5467)
 \******************************************************************************/
import React, {useMemo, useState} from "react";
import {
    ContentPage,
    FormLayoutColumn,
    LinkButton,
    PageWrapper,
    PDS_H5,
    PDS_P
} from "../../components/styled/StyledComponents";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
// @ts-ignore
import styled from "styled-components";
import {ErrorMessage, Formik} from "formik";
import {UPLOAD} from "../../data/formList";
import * as Yup from "yup";

import {BackAndSubmitButtonContainer} from "../../components/buttonContainer/BackAndSubmitButtonContainer";
import {Aside} from "../../components/Aside";
import {IAdminRequestUser} from "../../model/admin/requests/IAdminRequestUser";
import {useRequest} from "../../helper/useRequest";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";


const SharedHeader = styled(PDS_H5)`  
   margin-top: 2%;
`;

export const AdminDSAUpload: React.FC = () => {
    const { showBoundary } = useErrorBoundary();
    const navigate = useNavigate();
    const {shareId} = useParams();

    const handleSuccess = () => {
        navigate(BASE_PATH + 'admin/request/dsa/NEW')
    }

    const handleError = (error: object) => {
        showBoundary(error);
    }

    //get the request data
    const [dsaRequest, setDSARequest] = useState<IAdminRequestUser>();
    const requestURL = process.env.REACT_APP_API_URL + "/api/admin/donations/dsaRequest/" + shareId;
    const [requestState, getDSARequest] = useRequest({
        url: requestURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })
    const {isLoading, data, error} = requestState;

    useMemo(() => {
        getDSARequest();
    }, [])

    useMemo(() => {
        if (data != null) {
            setDSARequest(data);
        }
    }, [data])

    //submit data
    const actionURL = process.env.REACT_APP_API_URL + "/api/admin/donations/dsaUpload/" + shareId;
    const [requestActionState, setUpdateDSAAction] = useRequest({
        url: actionURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        headers: {
            'Accept': "application/json",
            'Content-Type': "multipart/form-data",
            'Access-Control_Allow-Origin': "*",
            'Access-Control-Allow-Credentials': "true",
        },
        onError: handleError,
        onSuccess: handleSuccess
    })




    const schema = Yup.object().shape({
        fileUploaded: Yup.mixed().required('Please select a file'),
    });



    const buildForm = () => {
        return (
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    if (values.fileUploaded !== null) {
                        let data: FormData = new FormData();
                        data.append("file", values.fileUploaded);
                        //console.log(values.fileUploaded);
                        setUpdateDSAAction(data);
                    }
                }}
                initialValues={{
                    fileUploaded: null,
                }}

                validateOnChange={false}
                validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      resetForm,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                      setFieldValue,
                      setFieldTouched
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn lg={12}>
                            <FormLayoutColumn lg={8}>
                                <Row className="mx-auto">
                                    <Row className="mx-auto mb-3">

                                        <Row className="mb-3 ">
                                            <Form.Group as={Col} lg={12} required className="mb-3 "
                                                        /*controlId="formFileUpload"*/>
                                                <Form.Label className="required" column="sm">
                                                    <LinkButton type="button"
                                                                className="btn btn-link bold-label required"
                                                                >
                                                        Choose Data Share Agreement File

                                                    </LinkButton>
                                                </Form.Label>


                                                <Form.Control id="fileUploaded " type="file" size="sm"
                                                       name={"fileUploaded"}
                                                       accept=".pdf,.doc,.docx"
                                                       value={undefined}
                                                       onChange={(event:any) => {
                                                           setFieldValue("fileUploaded", event.currentTarget.files[0]);
                                                       }}
                                                       isValid={touched.fileUploaded && !errors.fileUploaded}
                                                />
                                            </Form.Group>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a subject.
                                            </Form.Control.Feedback>
                                            <ErrorMessage name={"fileUploaded"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>


                                        </Row>

                                    </Row>
                                </Row>
                            </FormLayoutColumn>
                        </FormLayoutColumn>
                        <BackAndSubmitButtonContainer submitButtonText={UPLOAD} backButtonNavigate={'admin/request/dsa/NEW'}
                        />

                    </Form>

                )}
            </Formik>
        )
    }


    return (
        <Container className="form-container" fluid>

            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                <Col md={8} lg={9} name={"shareDataColumn"}>
                    <SharedHeader>Upload DSA file to approve</SharedHeader>
                    <PDS_P>
                        Select the "Choose File" button. Upload a file. Select "Upload"
                    </PDS_P>
                    <FormLayoutColumn>
                        {isLoading &&
                            <Spinner  className ="spinner-center" animation={"border"} variant={"primary"}  role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>}
                        {error && <p>Error</p>}
                        {data && buildForm()}
                    </FormLayoutColumn>

                </Col>

                <Col md={4} lg={3}>
                    <Aside />
                </Col>
                </PageWrapper>
            </ContentPage>


        </Container>
    )
        ;
}