/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: BackAndSubmitButtonContainer
 *
 * Purpose: Button Container for Back and Submit Buttons
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 22Jun2023 sasjxa  file created
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 \******************************************************************************/
import {Row} from "react-bootstrap";
import React from "react";
import {BackButton, PDSSubmitButton, ShareDataButtonContainer, ShareDataButtonGroup} from "../styled/StyledComponents";

import {useNavigate} from "react-router-dom";
// @ts-ignore
import styled from "styled-components";
import {BASE_PATH} from "../../constants";

interface BackAndSubmitButtonContainerProps {
    submitButtonText:string,
    backButtonNavigate: string,
}

const ButtonSpacingRow = styled(Row)`      
  padding-bottom: 2%;
`;


export const BackAndSubmitButtonContainer: React.FC<BackAndSubmitButtonContainerProps> =
    ({submitButtonText,backButtonNavigate}: BackAndSubmitButtonContainerProps) => {
        const navigate = useNavigate();

    return (
        <ButtonSpacingRow>
            <ShareDataButtonContainer>
                <BackButton variant="secondary" className="btn-submit" type="button" onClick={() => {
                    navigate(BASE_PATH + backButtonNavigate)
                }}>
                    Back
                </BackButton>
                <ShareDataButtonGroup>
                    <PDSSubmitButton variant="success" className="btn-submit" type="submit">
                        {submitButtonText}
                    </PDSSubmitButton>
                </ShareDataButtonGroup>

            </ShareDataButtonContainer>
        </ButtonSpacingRow>
    )
}


