/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: TextAreaOptional
 *
 * Purpose: Common Component for Optional TextArea
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 21Nov2023 sasjxa file created
 * 13Aug2024 sasjxa remove required label
 \******************************************************************************/
import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import {TextAreaCountRow} from "../styled/StyledComponents";


interface  TextAreaOptionalProps {
    fieldName: string,
    label: string,
    value: any,
    valueHandler: any,
    currentCharCount: number,
    maxTextAreaCount: number,
    countHandler: any,
    disabled: boolean,
    handleChange: any
    formText?:string
}


export const TextAreaOptional: React.FC<TextAreaOptionalProps> =
    ({
         fieldName,
         label, value,  valueHandler, currentCharCount, maxTextAreaCount,
         countHandler, handleChange, disabled, formText

     }) => {
        return (
            <>
                <Row className="mt-3 mb-3 ">
                    <Form.Group as={Col} className=""
                                controlId="">
                        <TextAreaCountRow>
                            <Form.Label  column="sm">{label} </Form.Label>
                            <span>{currentCharCount} of {maxTextAreaCount} characters</span>
                        </TextAreaCountRow>
                        <Form.Control as="textarea" rows={5} size="sm" required
                                      name={fieldName}
                                      type="text"
                                      value={value}
                                      disabled={disabled}
                                      maxLength={maxTextAreaCount}
                                      onChange={e => {
                                          valueHandler(e.target.value);
                                          countHandler(e.target.value.length);
                                          handleChange(e);
                                      }}/>
                        {formText != null && (
                            <Form.Text id="" muted>
                                {formText}
                            </Form.Text>
                        )}

                    </Form.Group>
                </Row>

            </>
        )

    }