/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Faq
 *
 * Purpose: Display this faq
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 24Feb2023 craig  file created.
 * 02Jun2023 sasjxa move styled components out of FC
 * 04Aug2023 sasjxa layout changes
 \******************************************************************************/
import React, {Fragment, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";

import parse from "html-react-parser";
import {COLORS} from "../../components/styled/StyleConstants";
// @ts-ignore
import styled from 'styled-components';
import {
    ContentPage,
    PageHeaderWithBackground, PageWrapper,
    PDS_P,
    ResourceItemPage
} from "../../components/styled/StyledComponents";
import {FAQ_QUESTIONS, IFaq} from "../../data/resources/faqData";

const FaqContainer = styled.div`      
   display: flex;
   flex-direction: column;
   
`;

const FaqItem = styled(Col)`
`;

const FaqQuestion = styled(PDS_P)`
   font-weight: bold;
   margin-bottom: 0px;
   line-height: normal;
   font-size: 1rem;
   color:  ${COLORS.dark_grey_blue}; 
`;

const FaqAnswer = styled(PDS_P)`       
   margin-top: 0px;       
   line-height: normal;
   margin-bottom: 1%;
   color:  ${COLORS.dark_grey_blue};      
`;

export const Faq: React.FC = () => {
    return (

            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FaqContainer>
            <PageHeaderWithBackground className={"faq-content-header"}>
                <h2>Frequently Asked Questions</h2>
            </PageHeaderWithBackground>
            <ResourceItemPage>

                {
                    FAQ_QUESTIONS.map((faq: IFaq) => {
                        return <FaqItem>
                            <Row>
                                <FaqQuestion>{parse(faq.question)}</FaqQuestion>
                            </Row>
                            <Row>
                                <FaqAnswer>{parse(faq.answer)}</FaqAnswer>
                            </Row>
                        </FaqItem>
                    })
                }

            </ResourceItemPage>
                    </FaqContainer>
                </PageWrapper>
            </ContentPage>

    )
}