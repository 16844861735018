/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: SponsorDataProvider
 *
 * Purpose: Sponsor and Data Provider for Share Data
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 27Aug2023 sasjxa File created.
 * 19Jun2024 sasjxa remove labelClassName parameter
 \******************************************************************************/
import React from "react";
import {Col, Form,  Row} from "react-bootstrap";import {ErrorMessage} from "formik";
import {labelIfReadOnly} from "../../constants/CommonFunctions";


interface SponsorDataProviderProps {
    sponsor: any,
    dataProvider: any,
    sponsorHandler: any,
    dataProviderHandler: any,
    disabled: boolean,
    handleChange: any,
    touched: any,
    errors: any,
}


export const SponsorDataProvider: React.FC<SponsorDataProviderProps> =
    ({
         sponsor, dataProvider, sponsorHandler, dataProviderHandler, disabled,
         handleChange, touched, errors
     }) => {
        return (
            <>
                <Row className="mt-3 mb-3 ">
                    <Form.Group as={Col} className="sponsor"
                                controlId="sponsor">
                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                    column="sm">Sponsor </Form.Label>
                        <Form.Control size="sm" required
                                      name={"sponsor"}
                                      type="text"
                                      disabled={disabled}
                                      value={sponsor}
                                      isValid={touched.sponsor && !errors.sponsor}
                                      onChange={(e) =>
                                      {sponsorHandler(e.target.value)
                                      handleChange(e)}

                        }/>
                        <Form.Text className="text-muted"></Form.Text>
                        <Form.Control.Feedback type="invalid">
                            Please provide a sponsor.
                        </Form.Control.Feedback>
                        <ErrorMessage name={"sponsor"}
                                      render={msg => <div
                                          className={"form-error-msg"}>{msg}</div>}/>
                    </Form.Group>
                </Row>

                <Row className="mt-3 mb-5 ">
                    <Form.Group as={Col} className="dataProvider"
                                controlId="dataProvider">
                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Data
                            Provider </Form.Label>
                        <Form.Control size="sm" required
                                      name={"dataProvider"}
                                      type="text"
                                      disabled={disabled}
                                      value={dataProvider}
                                      isValid={touched.dataProvider && !errors.dataProvider}
                                      onChange={(e) => {dataProviderHandler(e.target.value)}}/>
                        <Form.Text className="text-muted"></Form.Text>
                        <Form.Control.Feedback type="invalid">
                            Please provide a data provider.
                        </Form.Control.Feedback>
                        <ErrorMessage name={"dataProvider"}
                                      render={msg => <div
                                          className={"form-error-msg"}>{msg}</div>}/>
                    </Form.Group>
                </Row>

            </>

        )

    }