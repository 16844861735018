/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AccessDataAside
 *
 * Purpose: Side bar for Access Data
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 07Sep2023 sasjxa  file created
 * 03Jan2024 sasjxa  display sas username for registered users
 * 08Mar2024 craig   added basepath
 * 12Mar2024 craig   Added env links to lsaf and vdmml (for logged in users)
 * 14Apr2024 craig   Cleaned up some errors that show up in the console
 * 17Jun2024 sasjxa  corrected link for LSAF, Publications, and Share Data
 * 21Aug2024 craig   moved to useContext(PDSContext) for user session
 * 25Aug2024 craig   Changed href to NavLink
 * 23Sep2024 sasjxa  correct LSAF link
 * 02Oct2024 sasjxa  correct styling for sas username and password
 \******************************************************************************/
import React, {useContext} from "react";
import {AsideSection, AsideWrapper, PDS_DIV, PDS_H5, PDS_P} from "./styled/StyledComponents";
import parse from "html-react-parser";
import styled from "styled-components";
import {COLORS} from "./styled/StyleConstants";
import {BASE_PATH} from "../constants";
import {PDSContext, usePDSContext} from "../context/PDSContext";
import {NavLink} from "react-router-dom";


const AccessAsideSection = styled(AsideSection)` 
    @media (min-width: 1024px){       
       margin-bottom: 7%; 
       width: 96%;
       margin-left: auto; 
       margin-right: auto;    
    }
`;

const ToolsContainer = styled.div`    
    margin-left: 10px;
    margin-top: 10%;
    width: 200px;
    border-radius: 8px;
    background-image: linear-gradient(to bottom, #045f94, #024165 100%);
    color: ${COLORS.white};
`;

const ToolsLoginContainer = styled.div`    
    width: 100%;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background-image: none !important;
    background-color: ${COLORS.dark};
    color: ${COLORS.white};
    
    div {
      padding-top: 3%;
      padding-bottom: 2%;
    }
    span {
      font-size: .8125rem;
      display: block;
      padding-left: 3%;
    }
    
    span.title{
      padding-top : 2%;  
      font-weight: bold;
    }
`;

const LogoSection = styled.div` 
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ToolsImage = styled.img`
    width: 55px;
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
`;

const ToolsInstruction = styled.p`
   font-weight: bold;
    font-size: .8125rem;
    margin-top: 0px;
    padding-top: 0px;
    padding-left: 2%;
    padding-right: 2%;
    text-align: center;
    color: ${COLORS.white};
`;

const ToolsLinkSection = styled.div`
   width: 100%;
    display: flex;
    flex-direction: column;
    border-top: solid 1px ${COLORS.white};
    border-bottom: solid 1px ${COLORS.white};
`;

const ToolsLink = styled.div`
    width: 100%;
    display: flex;
    height: 27px;
    padding-left: 4%;
    padding-right: 2%;
    justify-content: space-between;
    align-items: center;
    
    &:first-child {
      border-bottom: solid 1px ${COLORS.white};
    }
   
    a {
      text-decoration: none;
      font-size: 0.8125rem;
      font-weight: bold;
      color: var(--white);
    }
    
    span {
      margin-top: .25rem;
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-left: 8px solid ${COLORS.white};
      border-bottom: 5px solid transparent;
      padding-right: 1%;
    }
`;






export const AccessDataAside: React.FC = () => {
    const {sessionUser} = usePDSContext();
    const MEPS_MSG: string = "These data have been curated or enriched with additional data elements"
        + " such as the"
        + " <a style=\"color: #0694d5;\" href=\"/documents/resources_MEPS_quick_start.pdf\" target={\"_blank\"}>MEPS</a>"
        + " (AHRQ's Medical Expenditure Panel Survey) data prepared by RTI International"
        + " and the Prostate Dream Challenge.)";
    const JOURNAL_PUBLICATIONS: string = "PDS' open access sharing model has generated a significant volume"

        + " known peer-reviewed journal publications and you can explore all of them"
        + " <a style=\"color: #0694d5;\" href=\"https://www.ceoroundtableoncancer.org/project-data-sphere/publications/\" target={\"_blank\"}>here</a>. "

    const VDMML_LSAF:string = "SAS tools include"
        + " <a style=\"color: #0694d5; padding-top:2%\" href=\"/documents/resources_vdmml_getting_started.pdf\" target={\"_blank\"}>VDMML</a>. "
        + " (Visual Data Mining & Machine Learning)"
        + " and"
        + " <a  style=\"color: #0694d5;\"  href=\"/projectdatasphere/html/lsafVideos\"  target={\"_blank\"}>LSAF</a>   "
        + "(Life Sciences Analytics Framework). "
        + "You can access these datasets directly via this secure analytics space. Please contact us with any"
        + " questions/issues in working in this environment"
        + "<ul style=\" padding-left:1%; padding-top:2%;\">"
        + "<li style=\"font-size: .8125rem; padding-left:1%;  padding-top:2%; color:#697682;\">VDMML: SAS Studio, Model Studio, Visual Analytics, Enterprise Miner, embedded support for Python & R </li>"
        + "<li style=\"font-size: .8125rem;padding-left:1%; color:#697682;\">LSAF: Transformations, analytics and reporting of clinical trial data </li>"
        + "</ul>"

    const NCI_LIST:string =
        " <ul style=\"padding-left:1%; \">"
        + "<li style=\"font-size: .8125rem; padding-left:1%;  padding-top:1%; color:#697682;\">Click on \"Request NCI Data Access\" (up to 5 studies at a time). </li>"
        + "<li style=\"font-size: .8125rem;padding-left:1%; color:#697682;\">Navigate to your account (click on username) -> 'NCI Requests'. Review and finalize requests. You will receive an email notice once approved and can access and download data directly via LSAF. </li>"
        + "</ul>"

    const TOOLS_INSTRUCTION_TEXT:string ="SAS&reg; provides free access to powerful tools for Project Data Sphere users."

    const showVDMMLLSAFInfo = () => {
        if (sessionUser!= null &&  sessionUser.userID > 0  && sessionUser.username != null){
            return true;
        }
        else {
            return false;
        }
    }



    const buildSASTools = () => {
        let lsafUrl:string = BASE_PATH + "lsafVideos";
        let vdmmlUrl:string = "/documents/resources_vdmml_getting_started.pdf";
        if (sessionUser.userID > 0) {
            lsafUrl = process.env.REACT_APP_LSAF_URL ||  "/documents/resources_vdmml_getting_started.pdf";
            vdmmlUrl = process.env.REACT_APP_VDMML_URL || BASE_PATH + "lsafVideos";
        }
        return (
            <ToolsContainer>
                <LogoSection>
                    <ToolsImage src={"/images/sas-white.png"}></ToolsImage>
                    <ToolsInstruction>{parse(TOOLS_INSTRUCTION_TEXT)}</ToolsInstruction>
                    <ToolsLinkSection>
                        <ToolsLink>
                             <a href={vdmmlUrl} target={"_blank"} rel={"noopener noreferrer"}>VDMML</a>
                             <span></span>
                        </ToolsLink>
                        <ToolsLink className="">
                            <a href={lsafUrl} target={"_blank"} rel={"noopener noreferrer"}>LSAF</a>
                            <span></span>
                        </ToolsLink>

                    </ToolsLinkSection>
                </LogoSection>
                {showVDMMLLSAFInfo() && (
                    <ToolsLoginContainer>
                        <div>
                        <span className="title">SAS username:</span>
                        <span className="">{sessionUser.username}</span>
                        </div>
                        <div>
                        <span className="title">SAS password:</span>
                        <span>same as your PDS password</span>
                        </div>
                    </ToolsLoginContainer>
                )}
            </ToolsContainer>

        )





    }

    return (
        <AsideWrapper name={"aside-wrapper"}>
            {buildSASTools()}

            <AccessAsideSection>
                <PDS_DIV className="mt-2"> {parse(VDMML_LSAF)}</PDS_DIV>
            </AccessAsideSection>

            <AccessAsideSection>
                <PDS_H5>Request NCI Data</PDS_H5>
                <PDS_P> This dataset is being provisioned through an initiative with the National Cancer
                    Institute (NCI) of the U.S. National Institutes of Health (NIH).</PDS_P>
                <PDS_DIV className="mt-0">  {parse(NCI_LIST)}</PDS_DIV>
            </AccessAsideSection>

            <AccessAsideSection>
                <PDS_H5>Linked Data</PDS_H5>
                <PDS_P>  {parse(MEPS_MSG)}</PDS_P>

            </AccessAsideSection>

            <AccessAsideSection>
                <PDS_H5>Journal Publications</PDS_H5>
                <PDS_P>  {parse(JOURNAL_PUBLICATIONS)}</PDS_P>

            </AccessAsideSection>

            <AccessAsideSection>
                <PDS_H5>Share Data</PDS_H5>
                <PDS_P>Become a data provider to share the results of your cancer-related clinical studies.</PDS_P>
                <NavLink to={BASE_PATH + "share"} className={"linkstyle"}>Share Data</NavLink>
            </AccessAsideSection>
        </AsideWrapper>
    )
}
