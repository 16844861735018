/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Data Share Agreement
 *
 * Purpose: Data Share Agreement page
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 11May2023 sasjxa file created
 * 23May2023 sasjxa add character count for text area
 * 31May2023 sasjxa add values to select components
 * 23Jun2023 sasjxa refactor to use new button container
 * 02Aug2023 craig  Add calls to api
 * 28Aug2023 sasjxa fix navigation path
 * 31Aug2023 sasjxa correction for user phone
 * 10Oct2023 sasjxa fix initialize of working title, remove userId parm
 * 23Feb2024 sasjxa add clear button functionality (MPM-5425)
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 12Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 30Apr2024 sasjxa switched to react-error-boundary
 * 18Jun2024 sasjxa correct navigate path for success
 * 02Jul2024 craig  moved decode to api
 * 14Aug2024 sasjxa change text type to email for data provider contact email
 * 16Aug2024 sasjxa lengthen authorized title
 * 21Aug2024 craig   moved to useContext(PDSContext) for user session
 * 04Nov2024 sasjxa added yup max to text fields
 \******************************************************************************/
import React, {useContext, useMemo, useRef, useState} from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
import * as Yup from 'yup';
import {ErrorMessage, Formik, FormikProps} from "formik";
// @ts-ignore
import styled from 'styled-components';
import {
    ContentPage,
    FormLayoutColumn,
    FormSectionTitle,
    PageWrapper,
    PDS_H5,
    PDS_P,
    TextAreaCountRow
} from "../../components/styled/StyledComponents";
import "../forms/forms.css";
import {COUNTRY, DSA_TYPES, FormList, SUBMIT} from "../../data/formList";
import {ClearAndSubmitButtonContainer} from "../../components/buttonContainer/ClearAndSubmitButtonContainer";
import {Aside} from "../../components/Aside";
import {useRequest} from "../../helper/useRequest";
import {ISmallUser} from "../../model/user/IUser";
import {useNavigate, useParams} from "react-router-dom";
import {IDataShareAgreement} from "../../model/donations/IDonations";
import {BASE_PATH} from "../../constants";
import {enableClearButton, getEncodedValue, labelIfReadOnly} from "../../constants/CommonFunctions";
import {useErrorBoundary} from "react-error-boundary";
import {PDSContext, usePDSContext} from "../../context/PDSContext";


export const DataShareAgreement: React.FC = () => {
    const {donationId} = useParams();
    const {sessionUser} = usePDSContext();
    const navigate = useNavigate();
    const {showBoundary} = useErrorBoundary();
    const [studyTitleCount, setStudyTitleCount] = useState(0);
    const [studyDescriptionCount, setStudyDescriptionCount] = useState(0);

    const [disabled, setDisabled] = useState<boolean>(false)
    const [userFullName, setUserFullName] = useState<string>("");

    const [currentUser, setCurrentUser] = useState<ISmallUser>();
    const formikRef = useRef<FormikProps<any>>(null);
    const [dsaData, setDsaData] = useState<IDataShareAgreement>();
    let maxStudyTitleCount = 1000;
    let maxStudyDescriptionCount = 2000;

    // handle error - unsuccessful retrieve or save of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    // handle successful save of data
    const handleSuccess = () => {
        //go back to share page
        navigate(BASE_PATH + 'share');
    }


    // initialize fields after successful retrieve of data
    const handleGetDSA = (dsaData: IDataShareAgreement) => {
        if (dsaData !== null) {
            setDsaData(dsaData);
        }
    }

    // get data
    const dsaURL = process.env.REACT_APP_API_URL + "/api/share/dataShareAgreement/" + donationId;
    const [requestState, getDsaData] = useRequest({
        url: dsaURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleGetDSA
    })
    const {isLoading, data, error} = requestState;

    // save data
    const saveDsaURL = process.env.REACT_APP_API_URL + "/api/share/dataShareAgreement";
    const [requestActionState, setDataShareAgreement] = useRequest({
        url: saveDsaURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })

     // memo for first load
    useMemo(() => {
        if (donationId !== undefined && Number(donationId) > 0) {
            getDsaData();
        } else {
            //don't have user data so use the current user

        }
    }, [])


    // memo for return and set state dsa var
    useMemo(() => {
        if (data != null) {
            setDsaData(data);
        }
    }, [data])


    // memo for change in state dsa variable
    useMemo(() => {
        if (dsaData != null) {
            if (dsaData.user !== null) {
                setCurrentUser(data.user);
                setUserFullName(data.user?.userDetails?.first + " " + data.user?.userDetails?.last);
            }
            if (dsaData.submitted) {
                setDisabled(true);
            }
        }

    }, [dsaData])



    // validation schema
    const schema = Yup.object().shape({
        workingTitle: Yup.string().required("Please provide a working title.").max(200, "Working Title should be no longer than 200 characters."),
        typesOfData: Yup.string().required("Please select types of data."),
        organization: Yup.string().required("Please provide an organization name.").max(150, "Organization should be no longer than 150 characters."),
        streetAddress: Yup.string().required("Please provide a street address.").max(200, "Address should be no longer than 200 characters."),
        city: Yup.string().required("Please provide a city.").max(100, "City should be no longer than 100 characters."),
        state: Yup.string().required("Please provide a state.").max(100, "State should be no longer than 100 characters."),
        postalCode: Yup.string().required("Please provide a postal code.").max(12, "Postal code should be no longer than 12 characters."),
        country: Yup.string().required("Please select a country."),
        jurisdiction: Yup.string().required("Please provide a jurisdiction.").max(200, "Jurisdiction should be no longer than 200 characters."),
        dataProviderName: Yup.string().required("Please provide a name for the data provider.").max(200, "Data Provider name should be no longer than 200 characters."),
        dataProviderEmail: Yup.string().required("Please provide an email address for the data provider.").max(255, "Email address should be no longer than 255 characters.").email(),
        dataProviderPhone: Yup.string().required("Please provide a phone number for the data provider.").max(30, "Phone number should be no longer than 30 characters."),
        dataProviderStreetAddress: Yup.string().required("Please provide a street address for the data provider. ").max(200, "Address should be no longer than 200 characters."),
        dataProviderCity: Yup.string().required("Please provide a data provider a city for the data provider.").max(100, "City should be no longer than 100 characters."),
        dataProviderState: Yup.string().required("Please provide a state for the data provider.").max(100, "State should be no longer than 100 characters."),
        dataProviderPostalCode: Yup.string().required("Please provide a postal code for the data provider.").max(12, "Postal code should be no longer than 12 characters."),
        dataProviderCountry: Yup.string().required("Please select a country for the data provider`."),
        authorizedName: Yup.string().required("Please provide a name for the authorized approver.").max(200, "Name should be no longer than 200 characters."),
        authorizedEmail: Yup.string().required("Please provide an email address for the authorized approver.").max(255, "Email address should be no longer than 255 characters.").email(),
        authorizedPhone: Yup.string().required("Please provide a phone number for the authorized approver.").max(30, "Phone number should be no longer than 30 characters."),
        authorizedTitle: Yup.string().required("Please provide a title for the authorized approver.").max(200, "Title should be no longer than 30 characters."),
        studyDescription: Yup.string().required("Please provide a study description."),
        studyTitle: Yup.string().required("Please provide a study title.")
    });

    // clear form fields
    const handleReset = () => {
        if (formikRef != null && formikRef.current) {
            formikRef.current.resetForm();
        }
    }

    // build select list
    const buildSelectList = (listType: FormList[]) => {
        let optionTemplate = listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
        return optionTemplate;
    }

    const buildForm = () => {
        return (
            <Formik innerRef={formikRef}
                    validationSchema={schema}
                    onSubmit={(values) => {
                        setDataShareAgreement(JSON.stringify({
                            workingTitle: getEncodedValue(values.workingTitle),
                            dataTypes: values.typesOfData,
                            companyName: getEncodedValue(values.organization),
                            companyAddress: getEncodedValue(values.streetAddress),
                            companyCity: getEncodedValue(values.city),
                            companyState: getEncodedValue(values.state),
                            companyCountry: values.country,
                            companyPostalCode: values.postalCode,
                            companyJurisdiction: getEncodedValue(values.jurisdiction),
                            contactName: getEncodedValue(values.dataProviderName),
                            contactEmail: values.dataProviderEmail,
                            contactAddress: getEncodedValue(values.dataProviderStreetAddress),
                            contactCity: getEncodedValue(values.dataProviderCity),
                            contactState: getEncodedValue(values.dataProviderState),
                            contactCountry: values.dataProviderCountry,
                            contactPostalCode: values.dataProviderPostalCode,
                            contactPhone: values.dataProviderPhone,
                            signatureName: getEncodedValue(values.authorizedName),
                            signatureEmail: values.authorizedEmail,
                            signaturePhone: values.authorizedPhone,
                            signatureTitle: getEncodedValue(values.authorizedTitle),
                            dataSetTitle: getEncodedValue(values.studyTitle),
                            studyRationale: getEncodedValue(values.studyDescription)
                        }))
                    }}
                    initialValues={{
                        workingTitle: '',
                        typesOfData: '',
                        firstAndLast: sessionUser.name,
                        email: sessionUser.username,
                        phone: '',
                        organization: '',
                        streetAddress: "",
                        city: "",
                        state: "",
                        postalCode: "",
                        country: "",
                        jurisdiction: '',
                        dataProviderName: '',
                        dataProviderEmail: '',
                        dataProviderPhone: '',
                        dataProviderStreetAddress: "",
                        dataProviderCity: "",
                        dataProviderState: "",
                        dataProviderPostalCode: "",
                        dataProviderCountry: "",
                        authorizedName: '',
                        authorizedEmail: '',
                        authorizedPhone: '',
                        authorizedTitle: '',
                        studyTitle: "",
                        studyDescription: "",

                    }}
                    validateOnChange={false}
                    validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      resetForm,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn className="mt-5 mb-5" lg={12}>
                            <FormLayoutColumn lg={8}>
                                <Row className="mt-3 mb-3 ">
                                    <Form.Group as={Col} className="workingTitle"
                                                controlId="formWorkingTitle">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Working
                                            Title </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"workingTitle"}
                                                      type="text"
                                                      placeholder=""
                                                      aria-describedby="dataSummaryHelpBlock"
                                                      disabled={disabled}
                                                      defaultValue={dsaData?.workingTitle}
                                                      isValid={touched.workingTitle && !errors.workingTitle}
                                                      onChange={handleChange}/>
                                        {!disabled && (
                                            <Form.Text id="workingTitleHelpBlock" muted>
                                                This should be a meaningful title that will only be visible to you.
                                            </Form.Text>
                                        )}
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a working title.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"workingTitle"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-5 ">
                                    <Form.Group as={Col} lg={6} className="typesOfData"
                                                controlId="formTypesOfData">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Type(s)
                                            of
                                            Data </Form.Label>
                                        {!disabled && (
                                            <Form.Select size="sm" required
                                                         name={"typesOfData"}
                                                         isValid={touched.typesOfData && !errors.typesOfData}
                                                         disabled={disabled}
                                                         onChange={handleChange}>
                                                {buildSelectList(DSA_TYPES)}
                                            </Form.Select>
                                        )}
                                        <Form.Control.Feedback type="invalid">
                                            Please select types of data.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"typesOfData"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                        {disabled && (
                                            <Form.Control size="sm"
                                                          name={"dataProvider"}
                                                          defaultValue={dsaData?.dataTypes}
                                                          type="text"
                                                          disabled={disabled}
                                            />
                                        )}
                                    </Form.Group>
                                </Row>

                                <FormSectionTitle>User Information</FormSectionTitle>
                                <Row className="mt-1 mb-5 ">
                                    <Form.Group as={Col} lg={4} controlId="formName">
                                        <Form.Label column="sm"> Name </Form.Label>
                                        <Form.Control size="sm"
                                                      name={"firstAndLast"}
                                                      type="text"
                                                      defaultValue={sessionUser.name}
                                                      disabled={true}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                    </Form.Group>
                                    <Form.Group as={Col} lg={5} className="email"
                                                controlId="formEmail">
                                        <Form.Label column="sm">Email Address </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"email"}
                                                      type='email'
                                                      defaultValue={sessionUser.username}
                                                      placeholder=""
                                                      disabled={true}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                    </Form.Group>
                                    <Form.Group as={Col} lg={3} controlId="formPhone">
                                        <Form.Label column="sm"> Phone </Form.Label>
                                        <Form.Control size="sm"
                                                      name={"phone"}
                                                      type="text"
                                                      defaultValue={sessionUser.phone}
                                                      disabled={true}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                    </Form.Group>
                                </Row>

                                <FormSectionTitle>Organization Information</FormSectionTitle>
                                <Row className="mt-1 mb-3">
                                    <Form.Group as={Col} className="organization"
                                                controlId="formOrganization">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Organization/Company
                                            Name </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"organization"}
                                                      type="text"
                                                      defaultValue={dsaData?.companyName}
                                                      disabled={disabled}
                                                      isValid={touched.organization && !errors.organization}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide an organization name.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"organization"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-3 ">
                                    <Form.Group as={Col} className="streetAddress"
                                                controlId="formStreetAddress">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Street
                                            Address </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"streetAddress"}
                                                      type="text"
                                                      defaultValue={dsaData?.companyAddress}
                                                      disabled={disabled}
                                                      isValid={touched.streetAddress && !errors.streetAddress}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a street address.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"streetAddress"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col} lg={5} className="city"
                                                controlId="formCity">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">City </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"city"}
                                                      type="text"
                                                      defaultValue={dsaData?.companyCity}
                                                      disabled={disabled}
                                                      isValid={touched.city && !errors.city}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a city
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"city"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                    <Form.Group as={Col} lg={3} className="state"
                                                controlId="formState">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">State </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"state"}
                                                      type="text"
                                                      defaultValue={dsaData?.companyState}
                                                      disabled={disabled}
                                                      isValid={touched.state && !errors.state}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a state.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"state"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                    <Form.Group as={Col} lg={4} className="postalCode"
                                                controlId="formPostalCode">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Postal
                                            Code </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"postalCode"}
                                                      type="text"
                                                      defaultValue={dsaData?.companyPostalCode}
                                                      disabled={disabled}
                                                      isValid={touched.postalCode && !errors.postalCode}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a postal code.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"postalCode"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-3 ">
                                    <Form.Group as={Col} lg={6} className="  col-right"
                                                controlId="formCountry">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Country </Form.Label>
                                        {!disabled && (
                                            <Form.Select size="sm" required
                                                         name={"country"}
                                                         className="text-mid-width"
                                                         disabled={disabled}
                                                         isValid={touched.country && !errors.country}
                                                         onChange={handleChange}>
                                                {buildSelectList(COUNTRY)}
                                            </Form.Select>
                                        )}
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please select a country.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"country"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>

                                        {disabled && (
                                            <Form.Control size="sm"
                                                          name={"dataProvider"}
                                                          defaultValue={dsaData?.companyCountry}
                                                          type="text"
                                                          disabled={disabled}
                                            />
                                        )}


                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-5 ">
                                    <Form.Group as={Col} lg={6} className="jurisdiction"
                                                controlId="formJurisdiction">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Jurisdiction </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"jurisdiction"}
                                                      type="text"
                                                      defaultValue={dsaData?.companyJurisdiction}
                                                      disabled={disabled}
                                                      isValid={touched.jurisdiction && !errors.jurisdiction}
                                                      onChange={handleChange}/>
                                        {!disabled && (
                                            <Form.Text id="jurisdiction" muted>
                                                The state/country where the organization is registered as a legal
                                                entity.
                                            </Form.Text>
                                        )}
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a jurisdiction.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"jurisdiction"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <FormSectionTitle>Data Provider Contact Information</FormSectionTitle>
                                <Row className="mt-1 mb-3">
                                    <Form.Group as={Col} className="dataProviderName"
                                                controlId="formDataProviderName">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Name </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"dataProviderName"}
                                                      type="text"
                                                      defaultValue={dsaData?.contactName}
                                                      disabled={disabled}
                                                      isValid={touched.dataProviderName && !errors.dataProviderName}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide an organization name.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"dataProviderName"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-1 mb-3">
                                    <Form.Group as={Col} className="dataProviderEmail"
                                                controlId="formDataProviderEmail">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Email
                                            Address </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"dataProviderEmail"}
                                                      type="email"
                                                      defaultValue={dsaData?.contactEmail}
                                                      disabled={disabled}
                                                      isValid={touched.dataProviderEmail && !errors.dataProviderEmail}
                                                      onChange={handleChange}/>
                                        <ErrorMessage name={"dataProviderEmail"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col} lg={6}
                                                controlId="formPhone">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Phone </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"dataProviderPhone"}
                                                      type="text"
                                                      defaultValue={dsaData?.contactPhone}
                                                      disabled={disabled}
                                                      isValid={touched.dataProviderPhone && !errors.dataProviderPhone}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a phone number.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"dataProviderPhone"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-3 ">
                                    <Form.Group as={Col} className="dataProviderStreetAddress"
                                                controlId="formDataProviderStreetAddress">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Street
                                            Address </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"dataProviderStreetAddress"}
                                                      type="text"
                                                      defaultValue={dsaData?.contactAddress}
                                                      disabled={disabled}
                                                      isValid={touched.dataProviderStreetAddress && !errors.dataProviderStreetAddress}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a street address for the data provider.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"dataProviderStreetAddress"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col} lg={5} className="dataProviderCity"
                                                controlId="formDataProviderCity">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">City </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"dataProviderCity"}
                                                      type="text"
                                                      defaultValue={dsaData?.contactCity}
                                                      disabled={disabled}
                                                      isValid={touched.dataProviderCity && !errors.dataProviderCity}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a city for the data provider
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"dataProviderCity"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                    <Form.Group as={Col} lg={3} className="dataProviderState"
                                                controlId="formDataProviderState">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">State </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"dataProviderState"}
                                                      type="text"
                                                      defaultValue={dsaData?.contactState}
                                                      disabled={disabled}
                                                      isValid={touched.dataProviderState && !errors.dataProviderState}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a state for the data provider.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"dataProviderState"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                    <Form.Group as={Col} lg={4} className="dataProviderPostalCode"
                                                controlId="formDataProviderPostalCode">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Postal
                                            Code </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"dataProviderPostalCode"}
                                                      type="text"
                                                      defaultValue={dsaData?.contactPostalCode}
                                                      disabled={disabled}
                                                      isValid={touched.dataProviderPostalCode && !errors.dataProviderPostalCode}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a postal code for the data provider.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"dataProviderPostalCode"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-5 ">
                                    <Form.Group as={Col} lg={6} className="dataProviderCountry"
                                                controlId="formDataProviderCountry">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Country </Form.Label>
                                        {!disabled && (
                                            <Form.Select size="sm" required
                                                         name={"dataProviderCountry"}
                                                         disabled={disabled}
                                                         isValid={touched.dataProviderCountry && !errors.dataProviderCountry}
                                                         onChange={handleChange}>
                                                {buildSelectList(COUNTRY)}
                                            </Form.Select>
                                        )}
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please select a country for the data provider.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"dataProviderCountry"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                        {disabled && (
                                            <Form.Control size="sm"
                                                          name={"dataProvider"}
                                                          defaultValue={dsaData?.contactCountry}
                                                          type="text"
                                                          disabled={disabled}
                                            />
                                        )}

                                    </Form.Group>
                                </Row>

                                <FormSectionTitle>Authorized Approver</FormSectionTitle>

                                <PDS_P>The Authorized Approver is the individual who has the authority to release
                                    the
                                    dataset on behalf of the submitting organization. Typically, this is a
                                    senior officer. The named individual will be identified in the completed Data
                                    Sharing Agreement as the person who signs the agreement on behalf of the data
                                    provider.</PDS_P>
                                <Row className="mt-1 mb-3">
                                    <Form.Group as={Col} className="authorizedName"
                                                controlId="formAuthorizedName">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Name </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"authorizedName"}
                                                      type="text"
                                                      defaultValue={dsaData?.signatureName}
                                                      disabled={disabled}
                                                      isValid={touched.authorizedName && !errors.authorizedName}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide an name for the authorized approver.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"authorizedName"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col} className="authorizedEmail"
                                                controlId="formAuthorizedEmail">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Email
                                            Address </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"authorizedEmail"}
                                                      type="email"
                                                      defaultValue={dsaData?.signatureEmail}
                                                      disabled={disabled}
                                                      isValid={touched.authorizedEmail && !errors.authorizedEmail}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid email address for the authorized approver.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"authorizedEmail"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col} lg={6} className="authorizedPhone"
                                                controlId="formAuthorizedPhone">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Phone </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"authorizedPhone"}
                                                      type="text"
                                                      defaultValue={dsaData?.signaturePhone}
                                                      disabled={disabled}
                                                      isValid={touched.authorizedPhone && !errors.authorizedPhone}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a phone number for the authorized approver.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"authorizedPhone"}
                                                      render={msg => <div
                                                          className=
                                                              {"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-5">
                                    <Form.Group as={Col} lg={12} className="authorizedTitle"
                                                controlId="formAuthorizedTitle">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Title </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"authorizedTitle"}
                                                      type="text"
                                                      defaultValue={dsaData?.signatureTitle}
                                                      disabled={disabled}
                                                      isValid={touched.authorizedTitle && !errors.authorizedTitle}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a title for the authorized approver.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"authorizedTitle"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <FormSectionTitle>Exhibit A</FormSectionTitle>
                                <Row className="mt-3 mb-3 ">
                                    <Form.Group as={Col} className="studyTitle"
                                                controlId="formStudyTitle">
                                        <TextAreaCountRow>
                                            <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Study
                                                Title </Form.Label>
                                            {!disabled && (
                                                <span>{studyTitleCount} of {maxStudyTitleCount} characters</span>
                                            )}
                                        </TextAreaCountRow>
                                        <Form.Control as="textarea" rows={5} size="sm" required
                                                      name={"studyTitle"}
                                                      type="text"
                                                      defaultValue={dsaData?.dataSetTitle}
                                                      disabled={disabled}
                                                      maxLength={maxStudyDescriptionCount}
                                                      isValid={touched.studyTitle && !errors.studyTitle}
                                                      onChange={e => {
                                                          setStudyTitleCount(e.target.value.length);
                                                          handleChange(e);
                                                      }}/>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a study title.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"studyTitle"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-3 ">
                                    <Form.Group as={Col} className="studyDescription"
                                                controlId="formStudyDescription">
                                        <TextAreaCountRow>
                                            <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Study
                                                Description </Form.Label>
                                            {!disabled && (

                                                <span>{studyDescriptionCount} of {maxStudyDescriptionCount} characters</span>
                                            )}
                                        </TextAreaCountRow>
                                        <Form.Control as="textarea" rows={5} size="sm" required
                                                      name={"studyDescription"}
                                                      type="text"
                                                      defaultValue={dsaData?.studyRationale}
                                                      disabled={disabled}
                                                      maxLength={maxStudyDescriptionCount}
                                                      isValid={touched.studyDescription && !errors.studyDescription}
                                                      onChange={e => {
                                                          setStudyDescriptionCount(e.target.value.length);
                                                          handleChange(e);
                                                      }}/>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a study description.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"studyDescription"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>
                            </FormLayoutColumn>
                        </FormLayoutColumn>
                        {!disabled && (
                            <ClearAndSubmitButtonContainer handleReset={handleReset}
                                                           enableClear={enableClearButton(donationId)}
                                                           submitButtonText={SUBMIT}/>
                        )}

                    </Form>

                )}
            </Formik>
        )
    }

    const buildIntroductoryText = () => {
        if (!disabled) {
            return (
                <>
                    <PDS_H5>Data Sharing Agreement</PDS_H5>
                    <PDS_P>Thank you for contributing to Project Data Sphere's platform. Your contribution supports
                        expanded and open research initiatives in oncology.</PDS_P>
                    <PDS_P>Please complete the following form to share your dataset; this information will be used
                        to
                        populate Project Data Sphere's Data Sharing Agreement (DSA). Once you submit this
                        information,
                        Project Data Sphere will finalize the DSA which will be e-mailed to you for review and
                        signature. Please keep in mind that it's typical to have a DSA for each contributing data
                        package
                        or dataset, and your dataset cannot be uploaded until this is executed.</PDS_P>
                    <PDS_P>Upon completion of the DSA, Project Data Sphere will perform an intake process and may
                        reach
                        out for specific points of clarification when working through the upload
                        steps. It can take one to two weeks to finalize the DSA.</PDS_P>
                    <PDS_P>While working on the DSA form, you may "Save" at any point after entering
                        the "Working Title," and you must be logged in and navigate back to the Share Data page to
                        finalize.</PDS_P>

                </>
            )
        }
    }

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn md={8} lg={9} name={"formLayoutColumn"}>
                        {buildIntroductoryText()}
                        {buildForm()}
                    </FormLayoutColumn>
                    {!disabled && (
                        <Col md={4} lg={3}>
                            <Aside needHelp={true} requiredFields={true} prepareInformation={true}/>
                        </Col>
                    )}

                </PageWrapper>
            </ContentPage>


        </Container>
    );

}
