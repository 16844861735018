/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: TextFieldRequired
 *
 * Purpose: Common Component for Required TextField
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 21Nov2023 sasjxa file created
 \******************************************************************************/
import React from "react";
import {Col, Form,  Row} from "react-bootstrap";
import {ErrorMessage} from "formik";

interface TextFieldRequiredProps {
    fieldName: string,
    label: string,
    value:string,
    valueHandler: any,
    disabled: boolean,
    touched: any,
    errors: any,
    size:number,
    formText?:string,

}


export const TextFieldRequired: React.FC<TextFieldRequiredProps> =
    ({
        fieldName, label, value, valueHandler, disabled,
         touched, errors, formText,size
     }) => {
        return (
            <>
                <Row className="mt-3 mb-3 ">
                    <Form.Group as={Col} lg={size} className=""
                                controlId="">
                        <Form.Label className="required" column="sm">{label}
                        </Form.Label>
                        <Form.Control size="sm" required
                                      name={fieldName}
                                      type="text"
                                      value={value}
                                      disabled ={disabled}

                                      isValid={touched.fieldName && !errors.fieldName}
                                      onChange={e => {
                                          valueHandler(e.target.value);
                                      }}/>
                        {formText != null && (
                        <Form.Text id="" muted>
                            {formText}
                        </Form.Text>
                        )}
                        <ErrorMessage name={fieldName}
                                      render={msg => <div
                                          className={"form-error-msg"}>{msg}</div>}/>
                    </Form.Group>
                </Row>
            </>
        )

    }