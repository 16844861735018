/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: TextFieldRequired
 *
 * Purpose: Common Component for Required TextField
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 21Nov2023 sasjxa file created
 * 08Dec2023 sasjxa add parm for change handler
 * 03Nov2024 craig  add maxlength to fields
 * 08Nov2024 sasjxa remove maxlength
 \******************************************************************************/
import React from "react";
import {Col, Form} from "react-bootstrap";
import {ErrorMessage} from "formik";

interface EmailProps {
    fieldName: string,
    label: string,
    value:string,
    disabled: boolean,
    touched: any,
    errors: any,
    size:number,
    handleChange: any,


}


export const Email: React.FC<EmailProps> =
    ({
        fieldName, label, value,  disabled,
         touched, errors, size, handleChange
     }) => {
        return (
            <>

                    <Form.Group as={Col} lg={size} className=""
                                controlId="">
                        <Form.Label className="required" column="sm">{label}
                        </Form.Label>
                        <Form.Control size="sm" required
                                      name={fieldName}
                                      type="email"
                                      defaultValue={value}
                                      disabled ={disabled}
                                      isValid={touched.fieldName && !errors.fieldName}
                        onChange={handleChange}/>
                        <ErrorMessage name={fieldName}
                                      render={msg => <div
                                          className={"form-error-msg"}>{msg}</div>}/>
                    </Form.Group>

            </>
        )

    }