/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Privacy
 *
 * Purpose: privacy page
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 27Feb2023 craig File created.
 * 25Apr2024 sasjxa refactoring of methods and styled components
 * 21Aug2024 craig  removed unused usersession
 * 25Sep2024 sasjxa fixed header alignment
 \******************************************************************************/
import React, {Fragment} from "react";
// @ts-ignore
import styled from 'styled-components';
import {PageHeaderWithBackground, PDS_P, PDS_P_BOLD, Ul_disc} from "../../components/styled/StyledComponents";

export const Privacy: React.FC = () => {
    const FaqPage = styled.div`        
       margin-right: 5%; 
       margin-left: 6%;
       margin-bottom: 3%;    
       padding: 0;  
       margin-top: 1%;   
       
       .smaller-top {
         margin-top: 5px;
       }
      
    `;

    const SectionContainer = styled.div`     
      p:nth-of-type(1) {
          padding-top: 1rem;
          padding-bottom: 0px;
          margin-bottom: 0px;               
      }
      p:nth-of-type(2) {
         margin-top: 2px;              
      }     
    `;

    return (
        <>
            <FaqPage className={"faq-page"}>
                <PageHeaderWithBackground className={"privacy-header"}>
                    <h2>Privacy Policy</h2>
                </PageHeaderWithBackground>
                <PDS_P>This Privacy Policy describes the ways in which <em>Project Data Sphere</em>(<em>"Project Data
                    Sphere"</em>, "we," "our," or "us") collects, uses, and discloses information about
                    you through http://data.projectdatasphere.org/ and any other website that is linked to this policy
                    (collectively, the "Site"). By using the Site, you consent to the processing of your
                    information as set forth in this Privacy Policy, now and as amended by us.</PDS_P>
                <SectionContainer>
                    <PDS_P_BOLD className={"p-header"}>What information do we collect?</PDS_P_BOLD>
                    <PDS_P>
                        <u>Information You Provide</u> - We collect information that you voluntarily provide when you
                        use the Site, including when
                        you:</PDS_P>
                    <Ul_disc>
                        <li>contact us with a question, comment or request;</li>
                        <li>apply for authorization to access clinical trial data on the website;</li>
                        <li>visit and access clinical trial data as an authorized user;</li>
                        <li>post a profile or other information on a personal or group page or other interactive feature
                            ("Community Tools");
                        </li>
                        <li>participate in chat rooms;</li>
                        <li>utilize messaging capabilities offered through the Site like email; or</li>
                        <li>participate in sweepstakes, contests or promotions with us or promotions partners.</li>
                    </Ul_disc>
                    <PDS_P>
                        The information that you provide us can include, but is not limited to:</PDS_P>
                    <Ul_disc>
                        <li>your name;</li>
                        <li>contact information such as physical address, email address and phone number;</li>
                        <li>employer name, address and website url;</li>
                        <li>workplace department and position, education and other professional credentials, including
                            professional licenses;
                        </li>
                        <li>username (including a display name used in Community Tools), password, and other
                            registration information; and
                        </li>
                        <li>your photograph or other audiovisual images.</li>
                    </Ul_disc>
                    <PDS_P><u>Information Automatically Collected From You</u> - We and our service providers may
                        automatically collect certain technical information from your computer when you use
                        the Site, such as your Internet Protocol ("IP") address, your browser type, your operating
                        system, the pages you view on the Site, the pages you view immediately before and
                        after you access the Site, and the search terms you enter on the Site. This information allows
                        us to recognize you and personalize your experience if you return to the Site
                        using the same computer, and to improve the Site and the services we provide. We and our service
                        providers may collect this information using "cookies," which are small
                        text files that the Site saves on your computer using your web browser and accesses when you
                        return. If you do not want the Site to collect information through the use
                        of cookies, you can set your web browser to reject cookies from the Site. Each browser is
                        different, so you should check your browser's "Help" menu to learn how to
                        change your cookie preferences. If you reject or block cookies from the Site, however, the Site
                        may not function as intended.</PDS_P>
                </SectionContainer>
                <SectionContainer>
                    <PDS_P_BOLD> How do we use this information?</PDS_P_BOLD>
                    <PDS_P>We may use the information we collect for a number of purposes, including, but not limited
                        to:</PDS_P>
                    <Ul_disc>
                        <li>providing you information if you have submitted a comment or question;</li>
                        <li>communicating to you information about the Site, our service or your account;</li>
                        <li>evaluating and processing applications for authorization to access clinical data;</li>
                        <li>facilitating access to clinical trial data by authorized users;</li>
                        <li>customizing your experience on the Site, such as by recognizing you as an authorized user
                            for more efficient account access;
                        </li>
                        <li>allowing us and our service providers to improve the Site and the services we provide, such
                            as by better tailoring our content to our users' needs and preferences;
                        </li>
                        <li>enabling participation in various Community Tools;</li>
                        <li>generating and analyzing statistics about your use of the Site; and</li>
                        <li>detecting, preventing, analyzing and responding to fraud, intellectual property
                            infringement, violations of obligations and restrictions you have agreed to in
                            our Online Services User Agreement, violations of the website Terms of Use, violations of
                            law, other misuse of the Site.
                        </li>
                    </Ul_disc>
                    <PDS_P>We also may combine or aggregate any of the information we collect through the Site with
                        information we collect elsewhere including from third parties
                        for any of these purposes.</PDS_P>
                </SectionContainer>
                <SectionContainer>
                    <PDS_P_BOLD>Under what circumstances do we disclose this information?</PDS_P_BOLD>
                    <PDS_P>We may disclose the information we collect from you through the Site:</PDS_P>
                    <Ul_disc>
                        <li>to affiliates and service providers who we work closely with, or who work on our behalf, and
                            have agreed to use the information solely in furtherance
                            of our operations, including, but not limited to, our Site hosting service provider and its
                            contractors and subcontractors;
                        </li>
                        <li>to third party clinical trial data providers to enable such providers to enforce contractual
                            obligations and restrictions you have agreed to in
                            our Online Services User Agreement;
                        </li>
                        <li>pursuant to a subpoena, court order or other lawful process, including to law enforcement;
                        </li>
                        <li>when we believe in good faith that disclosure is necessary (a) to protect our rights, the
                            rights
                            of others, the integrity of the Site, or your safety
                            or the safety of others, or (b) to detect, prevent, or respond to fraud, intellectual
                            property
                            infringement, violations of the website Terms of Use, violations
                            of law, or other misuse of the Site; and
                        </li>
                        <li>to third parties to the extent reasonably necessary to proceed with the negotiation or
                            completion of a merger, acquisition, or sale of all or a portion
                            of our assets.
                        </li>
                    </Ul_disc>
                    <PDS_P>In addition, we may share aggregate reports on user demographics and traffic patterns to
                        better
                        understand Site usage. We may also share de-identified information
                        with third parties for various purposes, including research, studies and marketing.</PDS_P>
                </SectionContainer>
                <SectionContainer>
                    <PDS_P_BOLD>What About Community Tools?</PDS_P_BOLD>
                    <PDS_P>You may be able to contribute to interactive Community Tools on the Site such as message
                        boards, chat
                        rooms, personal web pages or profiles, forums, groups
                        and bulletin boards. We may display your contributions to Community Tools to the public or to
                        others
                        within a group in which you may participate. We also may store
                        your contributions to Community Tools. Note that if you include photos or other information that
                        identifies you in your profile or in contributions to Community Tools,
                        it can be used and viewed by others. Therefore, you understand that you have no expectation of
                        privacy
                        or confidentiality in the content you submit for profiles or to
                        Community Tools, whether or not it identifies you. We are not responsible for the information
                        you choose
                        to include or view in any Community Tools. In addition, by
                        participating in Community Tools, you may receive communications, such as emails or other
                        messages, from
                        other users.</PDS_P>
                </SectionContainer>
                <SectionContainer>
                    <PDS_P_BOLD>Where is this information stored?</PDS_P_BOLD>
                    <PDS_P>The Site is a United States-based website. Information collected through the Site will be
                        processed
                        in and subject to the laws of the United States,
                        which may not provide the same level of protection for your personal information as your home
                        country
                        provides, and may be available to the United States government
                        or its agencies under a lawful order made in the United States. However, our Site hosting
                        service
                        provider is certified under the U.S.-E.U. and the U.S.-Swiss safe
                        harbor framework. In addition, we may transfer your information outside of the United States to
                        our
                        affiliates, data providers and service providers who may be
                        located in other countries. By using and submitting information to the Site, you consent to such
                        transfer to, and processing in, the United States and other
                        countries.</PDS_P>
                </SectionContainer>
                <SectionContainer>
                    <PDS_P_BOLD>How is your information secured?</PDS_P_BOLD>
                    <PDS_P>We maintain reasonable administrative, technical, and physical safeguards designed to
                        safeguard the
                        information collected by the Site. However, no information
                        system can be 100% secure, so we cannot guarantee the absolute security of your information.
                        Moreover,
                        we are not responsible for the security of information you transmit
                        to the Site over networks that we do not control, including the Internet and wireless networks.
                        We
                        maintain the information we collect consistent with legitimate business
                        purposes.</PDS_P>
                </SectionContainer>
                <SectionContainer>
                    <PDS_P_BOLD>What if there are changes to this Privacy Policy?</PDS_P_BOLD>
                    <PDS_P>We reserve the right to update this Privacy Policy from time to time by posting a new Privacy
                        Policy on this page. You are advised to consult this Privacy
                        Policy regularly for any changes, and your continued use of the Site after such changes have
                        been
                        made constitutes acceptance of those changes. If we make any
                        revisions that materially change the ways in which we use or share the information previously
                        collected from you through the Site, we will give you the opportunity
                        to consent to such changes before applying them to that information.</PDS_P>
                </SectionContainer>
                <SectionContainer>
                    <PDS_P_BOLD>Contact us</PDS_P_BOLD>
                    <PDS_P>If you have any questions about this Privacy Policy or our use of your information collected
                        through the Site,
                        please contact us at info@projectdatasphere.org.</PDS_P>
                    <PDS_P>Effective July 2013</PDS_P>
                    <PDS_P>Updated September 2014</PDS_P>
                </SectionContainer>
            </FaqPage>
        </>
    )
}
