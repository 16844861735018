export interface IAttestation {
    id : number,
    title: string,
    sectionText: string;
}


export const ATTESTATION_TITLE:string ="Cancer Research Platform Agreement";
export const ATTESTATION_VERSION:string ="(v. May 13, 2020)";
export const ATTESTATION_DESCRIPTION:string ="<em>PLEASE read this Project Data Sphere&reg;" +
    " Cancer Research Platform Agreement (this \"Agreement\") carefully."+
    " If you click [<b>\"I ACCEPT\"</b>], you assent to, and agree to be bound by the terms,"+
    " covenants and conditions of this Agreement.</em>";


export const ATTESTATION:IAttestation[] = [
{
    id: 1,
      title:"I. Project Data Sphere Overview",
      sectionText:"<em>Project DS, Inc.</em>, a North Carolina non-profit company with tradename \"Project Data Sphere\"" +
        " manages an online platform that includes a database (the \"Database\") containing data (\"Data\") from completed studies" +
        " in the field of cancer research, social media tools, analytical tools and other technology through which the foregoing" +
        " are made available for your access and use (collectively, the \"Platform\"). Certain research driven entities have" +
        " sponsored or conducted studies (\"Data Providers\") and have agreed to share Data with <em>Project Data Sphere" +
        " </em> to allow for" +
        " the creation of the Database and to facilitate and encourage secondary research in the scientific community for the" +
        " public good. Because <em>Project Data Sphere</em> and the Data Providers share the goal that all" +
        " research using the Platform" +
        " under this Agreement will be open research, you are encouraged to publish and present the results of your research" +
        " using the Data, and you must agree to certain restrictions on your rights to seek and enforce patent protection for" +
        " the results of your research using the Data."+

        " <p class='extra-text'>Access to the Platform will be provided to bona fide researchers who submit a completed online application and agree" +
        " to the terms, covenants and conditions in this Agreement, upon receiving access authorization from" +
        " <em>Project Data Sphere</em> (each, including you, an \"Authorized User\").  For purposes of this Agreement," +
        " the term \"research\" means \"one or more systematic investigations, each including research development, testing and evaluation," +
        " and designed to develop or contribute to generalizable knowledge.\"" +
        "</p>"

},

    {
        id: 2,
        title:"II. Parties to the Agreement",
        sectionText:"You and <em>Project Data Sphere</em> are the parties to this Agreement; however, you acknowledge and agree" +
            " that each licensor of technology or Data to <em>Project Data Sphere</em>," +
            " <em>Project Data Sphere</em>>'s hosting Platform provider, and each affiliate of" +
            " <em>Project Data Sphere</em> or of a licensor of technology or Data, is a third-party beneficiary" +
            " of this Agreement with the legal right to enforce in its own name the terms, covenants and conditions of this Agreement."

    },

    {
        id: 3,
        title:"III. Terms of Access and Use",
        sectionText:
            "<ul class='no-decoration' >" +
              "<li>A.   <u><em>License Grant</u></em></li>" +
               "<ol class='lower-roman-list'>" +
                "<li class='roman-list-1'><em>&nbsp;&nbsp;Project Data Sphere</em> hereby grants to you a non-exclusive, revocable, limited, non-transferable, non-sublicensable" +
                " license to use the Platform only for purposes of accessing and using Data for one or more systematic investigations that you conduct," +
                " including research, development, testing and evaluation designed to develop or contribute to generalizable knowledge" +
                " in the field of cancer research (the \"Purpose\"), and solely in accordance with the terms, covenants and conditions of this Agreement." +
                " </li>" +
                "<li class='roman-list-2'>&nbsp;&nbsp;You acknowledge and agree that you may have the opportunity to use the Platform for purposes of accessing and using Data" +
                " that is subject to terms, covenants and conditions in addition to those set forth in this Agreement.</li>"+
                 "<ul class='asterisk-list'>" +
                  "<li class='asterisk-list'>&nbsp;You are expressly prohibited from copying (manually, electronically or otherwise) to your personal computer or to an external storage device any Data that is " +
                    "identified to you as non-downloadable.</li>"+
                  "<li class='asterisk-list'>&nbsp; In addition, you may be notified at the time of your request for particular Data that such Data (including but not necessarily" +
                  " limited to data made available through the Platform by the National Cancer Institute)" +
                  " is subject to additional terms, covenants and conditions (\"Supplemental Terms\") " +
                  "to which you are required to agree as a condition of your receiving access to such Data. Upon your agreement to Supplemental Terms for particular Data, " +
                  "you may access and use such Data solely in accordance with the terms, covenants and conditions of this Agreement and the applicable Supplemental Terms." +
                  " If you aggregate Data that is not subject to any Supplemental Terms with any Data that is subject to any Supplemental Terms, your use of the resulting aggregated Data will be subject to the terms, covenants " +
                  "and conditions of this Agreement and the applicable Supplemental Terms.</li>"+
               "</ul>" +
            "<li class='no-marker'>Neither the license granted herein nor your use of any Data entitles you to assistance from any personnel associated with the clinical trial(s)" +
            " from which the Data was derived.</li>" +


           " <li>B.   <u><em>Restrictions on Use of the Platform</u></em></li>" +
              "<ol class='lower-roman-list'>" +
              "<li  class='roman-list-1'>&nbsp;&nbsp;You may not use the Platform or any component thereof, either alone or in concert with any other information, to identify or contact individuals whose protected information may have been" +
             " instrumental in developing the Data. </li>" +
            "<li class='roman-list-2'>&nbsp;&nbsp;You understand and agree that the Platform is for your personal use and that," +
            " except as permitted by Articles IV, VII and VIII, you are prohibited from disclosing any portion of " +
            "the Data available through the Platform to, or otherwise sharing any portion of the Data with, any third party." +
            " You may not publish the Data in any news release, article, manuscript, blog, website or any other release except as permitted by Article IV, Article VII and Article VIII. If disclosure of any Data is necessary for completion of your research, you may disclose such Data to a third party only after that third party has submitted a completed online application to be an Authorized User that describes the proposed research, has agreed to the terms, covenants and conditions in this Agreement, and has received " +
            "access authorization from <em>Project Data Sphere</em>. </li>" +
            "<li class='roman-list-3'>&nbsp;&nbsp;Your use of the Platform shall comply with all applicable laws and regulations of all jurisdictions, including, without limitation," +
            " international, national, federal, state and local laws and regulations, and all data protection laws and regulations applicable to" +
            " protected health information or other personally identifiable information.  Information available through the Platform may include without" +
            " limitation individuals' names, addresses, other contact information, financial account information, social security numbers, dates of birth," +
            " passwords, protected health information, biometrics, personal identification numbers and codes and/or other information or data that may be" +
            " protected by applicable laws and/or can be used for identity theft. </li>" +
            "<li class='roman-list-4'>&nbsp;&nbsp;You are responsible for obtaining review and approval of research you conduct through your use of the Platform by an Ethics Committee or Institutional Review Board, if and as required of you by law or " +
            " applicable institutional policy. </li>" +
            "<li class='roman-list-5'>&nbsp;&nbsp;You shall keep the Data in secure storage, and you shall adequately account for the Data while it is in your possession or control. You are responsible for using reasonable safeguards (based on industry standard practices) to secure, protect, and manage the Data, which safeguards shall be no less extensive and effective than those you use" +
            " to protect your own confidential information. </li>" +
            "<li class='roman-list-6'>&nbsp;&nbsp; You shall promptly report to <em>Project Data Sphere</em> any unauthorized Data sharing, breach of Data security, inadvertent Data release, or other unauthorized access to or use of the Platform, " +
            "within 24 hours of the time you become aware of such incident.</li>" +
            "</ol>" +

            "<li>C.   <u><em>Usage Rules</u></em></li>" +

            "<li>These usage rules (the \"Rules\") cover your access to and use of the Platform. You must adhere to and abide by the Rules " +
            "and other guidelines provided to you by <em>Project Data Sphere</em> from time to time.<em> Project Data Sphere</em>" +
            " reserves the right to terminate or limit use of the Platform without notice in " +
            "the event of any unauthorized access to or use of the Platform.</li>" +
            "<ol class='lower-roman-list'>" +
              "<li  class='roman-list-1'>&nbsp;&nbsp;Only you may access the Platform. You shall not allow any other person to have access to" +
              " the Platform or your password. You are responsible for maintaining the confidentiality of your password, " +
              "and you must notify <em>Project Data Sphere</em> immediately upon becoming aware of " +
              "the loss or theft of your password or any unauthorized use of your password. </li>" +
              "<li class='roman-list-2'>&nbsp;&nbsp;You shall not: </li>" +

               "<ul class='asterisk-list'>" +
                  "<li class='asterisk-list'>&nbsp;use the Platform for any purpose other than the Purpose (as identified in Section III(A) of this Agreement); or</li>" +
                  "<li class='asterisk-list'>&nbsp; upload to the Platform or otherwise make available to <em>Project Data Sphere</em> any credit or debit card" +
                    " number, personal identification number, password or other electronic identification number that you use for " +
                    " payment or to access personal or financial information or resources; or</li>" +
                  "<li class='asterisk-list'>&nbsp;use the Platform to misuse or abuse any third-party property (including, without limitation, software, equipment, networks, and network devices); or</li>" +
                  "<li class='asterisk-list'>&nbsp; reverse engineer, reverse assemble or decompile any software that you may access through the Platform or in any way attempt to recreate the source code for any such software, except and only to the extent that applicable laws specifically prohibit such restriction; or</li>" +
                  "<li class='asterisk-list'>&nbsp;make any unauthorized use of or interfere with any property of SAS Institute, Inc. (\"SAS\"), which hosts the Platform for <em>Project Data Sphere</em>, or of any customer of SAS; or</li>" +
                  "<li class='asterisk-list'>&nbsp;delete, obscure or modify any copyright notice or proprietary rights notice of <em>Project Data Sphere</em> or any of its licensors that is displayed through the Platform; or</li>" +
                 "<li class='asterisk-list'>&nbsp;engage in any activity that is in violation of the law or aids or assists any criminal activity in connection with your access to or use of the Platform; or</li>" +
                 "<li class='asterisk-list'>&nbsp;impair or disrupt any connections to the Platform; or</li>" +
                 "<li class='asterisk-list'>&nbsp;upload to the Platform any software or application(s).</li>" +
               "</ul>" +
            "<li class='roman-list-3'>&nbsp;&nbsp;You will not use the Platform: </li>" +
              "<ul class='asterisk-list'>" +
                "<li class='asterisk-list'>&nbsp; to send unsolicited marketing material or unsolicited communications " +
                  "in any form (commonly referred to as \"SPAM\"); or</li>" +
                 "<li class='asterisk-list'>&nbsp; to engage in any activities or actions that infringe or misappropriate the intellectual property rights of others, including but not limited to, using third party copyrighted " +
            "      materials without appropriate permission, using third party trademarks without appropriate permission or attribution, or using or distributing third party information protected as trade secret" +
            "      information in violation of a duty of confidentiality; or</li>" +
            "     <li class='asterisk-list'>&nbsp;to engage in any activities or actions that would violate the personal privacy rights of others; or</li>" +
            "     <li class='asterisk-list'>&nbsp;to send or transmit harassing, offensive, abusive, libelous, or obscene materials or assist in any similar related activities; or</li>" +
            "     <li class='asterisk-list'>&nbsp;to intentionally omit, delete, forge, or misrepresent transmission information, including headers, return mailing, and Internet protocol addresses; or</li>" +
            "     <li class='asterisk-list'>&nbsp;to engage in any activities or actions intended to misuse or abuse other users' passwords or other confidential information; or</li>" +
            "     <li class='asterisk-list'>&nbsp;for any illegal purpose, in violation of any applicable laws or regulations, or in violation of the rules of any other Platform provider engaged by or for your benefit; or</li>" +
            "     <li class='asterisk-list'>&nbsp; to endorse, advertise, or promote any political cause or candidate, or without the prior written consent of <em>Project Data Sphere</em>, which <em>Project Data Sphere</em> may grant or withhold in its sole discretion," +
            "      to endorse, advertise, or promote a product or a Platform, " +
            "      or to advertise or solicit employment; or</li>" +
            "     <li class='asterisk-list'>&nbsp;to assist or permit any person to engage in any of the prohibited activities described above.</li>" +
               "</ul>" +
            "<li class='roman-list-4'>&nbsp;&nbsp;<em>Project Data Sphere</em> may change the Rules upon thirty (30) days' notice to you, which notice may be provided by posting such new changes through the Platform. </li>" +
            "</ol>" +

        "</ol>"+
        "</ul>"
    },

    {
        id: 4,
        title: "IV. Community Tools",
        sectionText:
            "<ul class='no-decoration' >" +
            "<li>A.   <u><em>User Contributions</u></em></li>" +
            "<li>The Platform may contain message boards, chat rooms, personal web pages or profiles, forums, groups," +
            " bulletin boards, personal workspaces and other interactive features, (collectively, excluding the Database, " +
            "\"Community Tools\") that allow you and other Authorized Users to post, submit, publish, display or transmit" +
            " to you and other Authorized Users (hereinafter, \"post\") content or materials" +
            " (collectively, \"User Contributions\") on or through the Platform. Before posting User Contributions, " +
            "it is your responsibility to review any policies that your employer may have adopted relating to your " +
            "employer's intellectual property and your use of social media. You shall not use the Community Tools in" +
            " any manner that violates or is inconsistent with any such policies, this Agreement, any applicable " +
            "Supplemental Terms or the Terms of Use that are applicable to the Platform. If you delete your " +
            "User Contributions from the Community Tools, copies of your User Contributions may remain viewable in " +
            "cached and archived pages," +
            " or might have been copied or stored by other Authorized Users.</li>"+

            "<li>B.   <u><em>User Information</u></em></li>" +
            "<li> <em>Project Data Sphere</em> collects additional demographic information from an Authorized User who wishes " +
            "to use the Community Tools, including a display name and, optionally, a photo image. Display names and " +
            "full names of such Authorized Users will be available to all Authorized Users who use the Community Tools," +
            " and each such Authorized User has the option to make his or her email address and/or optional photo " +
            "available to other Authorized Users who use the Community Tools. You might receive communications from " +
            "other Authorized Users. Although <em>Project Data Sphere</em> attempts to limit access to the Community Tools to " +
            "only Authorized Users, please be aware that no security measures are perfect or impenetrable. Additionally," +
            " <em>Project Data Sphere</em> cannot control the actions of others with whom you may choose to share " +
            "your User Contributions or your personal information. <em>Project Data Sphere</em> shall have no " +
            "obligation to monitor Authorized Users' or other persons' use of any User Contribution or personal information " +
            "that an Authorized User shares." +
            "</li>" +
            "<li>C.   <u><em>Monitoring and Enforcement; Termination</u></em></li>" +
               "<ol class='lower-roman-list'>" +
                "<li class='roman-list-1'>&nbsp;&nbsp;User Contributions do not reflect the views of <em>Project Data Sphere</em> and are not endorsed by" +
                 " <em>Project Data Sphere</em>. </li>" +
                "<li class='roman-list-2'>&nbsp;&nbsp;<em>Project Data Sphere</em> has the right, but not the obligation, to: </li>" +
                   "<ul class='asterisk-list'>" +
                     "<li class='asterisk-list'>&nbsp;review any User Contribution;</li>" +
                     "<li class='asterisk-list'>&nbsp;remove or refuse to post all or any portion of any User Contribution for any or no reason in its sole discretion;</li>" +
                     "<li class='asterisk-list'>&nbsp; take any action with respect to any User Contribution that it deems necessary " +
            "or appropriate in its sole discretion, including if it believes that such User Contribution fails to meet the requirements" +
            " set forth in this Agreement or in any applicable Supplemental Terms, threatens the personal safety of Authorized Users or the public, " +
            "or could create liability for <em>Project Data Sphere</em>; and</li>" +
                      "<li class='asterisk-list'>&nbsp; terminate or suspend your access to all or part of the Community Tools for any or no reason.</li>" +
                   "</ul>" +
            "<li class='roman-list-3'>&nbsp;&nbsp; If for any reason either <em>Project Data Sphere</em> terminates your access to any private message board, group, " +
            "or other Community Tool by which an Authorized User made a User Contribution available to you, then the license granted to you under Section V.B for such" +
            " User Contribution will likewise terminate immediately. For the avoidance of doubt, this paragraph shall not in any way affect the license you have granted " +
            "other Authorized Users or <em>Project Data Sphere</em>" +
            " for any User Contributions you have posted. </li>" +
               "</ol>" +
            "</ul>" +
          "</ul>"
    },
    {
    id: 5,
    title: "V. Ownership of Data and User Contributions",
    sectionText:
        "<ul class='no-decoration' >" +
        "<li>A.   <u><em>Data</u></em></li>" +
        "<li>You acknowledge that each Data Provider retains ownership of, and all intellectual property rights in, " +
        "the Data it has made available in the Database and that you acquire no rights in the Data other than those" +
        " limited rights set forth in this Agreement. You do not obtain any rights under any patents, copyrights or " +
        "other intellectual property rights of any Data Provider - whether implicitly, by estoppel or any other " +
        "legal theory - other than the limited license to use the Data as expressly permitted by this Agreement and " +
        "subject to all terms, covenants" +
        " and conditions set forth herein and in any applicable Supplemental Terms.</li>" +
        "<li>B.   <u><em>User Contributions</u></em></li>" +
        "<li>You retain your intellectual property rights to any User Contributions you post on or through the" +
        " Community Tools. By posting User Contributions on or through the Community Tools, you grant to " +
        "<em>Project Data Sphere</em> and to all Authorized Users to whom you make the User Contributions " +
        "available a worldwide, non-exclusive, transferable, sub-licensable, royalty-free, perpetual and " +
        "irrevocable license to use, copy, reproduce, process, adapt, modify, publish, transmit, perform, " +
        "display and distribute such User Contributions in any and all media and distribution methods " +
        "(now known or hereafter developed) for the Purpose. You hereby waive your moral " +
        "rights in any User Contributions.</li>" +
        "</ul>"
    },
    {
        id: 6,
        title: "VI. Patents and Trademarks",
        sectionText:
            "<ul class='no-decoration' >" +
             "<li>A.   <u><em>Patents</u></em></li>" +
              "<li>You acknowledge that Data Providers have elected to make their Data, and Authorized Users have elected" +
              " to make their User Contributions, available in the Platform to foster open research for the public " +
              " Accordingly, in consideration of your being given access to the Data and User Contributions," +
              " you covenant and agree that:</li>" +

              "<ol class='lower-roman-list'>" +
                "<li class='roman-list-1'>&nbsp;&nbsp;you will not seek, and will not support any third party's effort to seek," +
                " patent protection in any jurisdiction for any research procedure or research design " +
                 "that results from your use of the Data or User Contributions; </li>" +
                "<li class='roman-list-2'>&nbsp;&nbsp;you will not assert, and will not support any third party's assertion, against any Data Provider(s), " +
                 "of any patent right that results from use of the Data; and </li>" +
                 "<li class='roman-list-3'>&nbsp;&nbsp; you agree to allocation of intellectual property resulting from " +
                   "your use of the Data in accordance with the provisions set forth at " +
                   "<a href= \"http://ctep.cancer.gov/industryCollaborations2/guidelines_for_collaboration.htm\"  target={\"_blank\"}>http://ctep.cancer.gov/industryCollaborations2/guidelines_for_collaboration.htm</a> " +
            " Although the applicable provisions accessible" +
            " via the foregoing link are controlling, and you are responsible for reviewing and complying with such provisions," +
            " they may be summarized as follows:</li>" +
            " <li class='no-marker'>For any invention that you conceive or first reduce to practice, alone or with others, pursuant to research using any Data that is not publicly available, you agree to grant to the pharmaceutical company sponsor of the clinical trial from which the Data was derived, or in the case of Data from a clinical trial that was sponsored by the National Cancer Institute or by a National Clinical Trial Network cooperative group, " +
            " any pharmaceutical company collaborator (in either case, the \"Collaborator\"):</li>" +
            "<ul class='asterisk-list'>" +
              "<li class='asterisk-list'>&nbsp;a paid-up, nonexclusive, nontransferable, royalty-free, world-wide license to such invention for research purposes only; and</li>" +
              "<li class='asterisk-list'>&nbsp;a nonexclusive, royalty-free, worldwide license to (a) disclose such invention to a regulatory authority when seeking marketing authorization of the experimental therapy for which the clinical trial was conducted (the \"Agent\"), and (b) disclose such invention on a product insert or other promotional material regarding the Agent after the Collaborator" +
                 " has obtained marketing authorization from a regulatory authority.</li>" +
            "</ul>" +
            "<li class='no-marker'>Notwithstanding the above, you are under no obligation to file or maintain" +
            " patent prosecution for any such invention.<li>"+
            "<li class='no-marker'>The foregoing are the only restrictions on prosecution and enforcement of patents on inventions " +
            "arising out of your use of the Data and User Contributions.<li>"+
               "</ol>" +
            "<li>B.   <u><em>Trademarks</u></em></li>" +
            "<li>The words \"<em>Project Data Sphere</em>\" and the following <em>Project Data Sphere</em> logo are registered trademarks of " +
            "<em>Project Data Sphere</em>.</li>"+
            "<img src='/images/PDSLogo_small.png' >" +
            "</ul>"
    },
    {
        id: 7,
        title: "VII. Publications and Presentations",
        sectionText:"<p>" +
            "You are encouraged to publish any results of your research in any peer-reviewed publication, and to " +
            "report the results of your research in any presentation, provided that your publication or presentation " +
            "does not disclose any Data or User Contributions other than Data or User Contributions that are reasonably" +
            " necessary to publish or report your research results. You agree to include the following acknowledgment " +
            "in any publication or presentation of the results of research you conducted, or to which you contributed," +
            " using Data or User Contributions from the Platform: \"This [publication or presentation, as applicable] is " +
            "based on research using information obtained from " +
            "<a href= \"data.projectdatasphere.org\"  target={\"_blank\"}>data.projectdatasphere.org</a> " +
             " which is maintained by" +
            "<em></em> Project Data Sphere</em>. Neither <em>Project Data Sphere</em> nor the owner(s) of any " +
            "information from the web site have contributed to, approved" +
            " or are in any way responsible for the contents of this [publication or presentation].\"" +
            "</p>"
    },

    {
        id: 8,
        title: "VIII. Confidentiality",
        sectionText:"<p>You acknowledge and agree that the Data is nonpublic information that is proprietary" +
            " to the Data Providers and has great value to their business. Subject to the limited rights to " +
            "publish, and present the results of research you conduct using the Data, you covenant and agree" +
            " that you shall: (i) maintain the Data in strict confidence: (ii) not, directly or indirectly," +
            " disclose, or permit the disclosure of, the Data other than in accordance with Section III.B(2)," +
            " Article VII or this Article VIII; and (iii) not use the Data for any purpose other than the Purpose." +
            " The foregoing covenant shall not apply to any portion of the Data that you can demonstrate: (a) was" +
            " known to you, without restriction on disclosure, prior to your retrieving it from the Database; " +
            "(b) was lawfully obtained by you from a third party under no obligation of confidentiality with" +
            " respect to such portion of the Data; or (c) was or has become publicly available other than as a" +
            " result of a violation of this Agreement or another obligation of confidentiality. In addition," +
            " you are permitted to disclose Data if so required by law, regulation, rule, act, or order of any" +
            " government authority or agency, provided that you give <em>Project Data Sphere</em> sufficient advance" +
            " written notice to permit <em>Project Data Sphere</em> to notify the applicable Data Providers so that they" +
            " may seek a protective order or other similar order with respect to Data, and that thereafter" +
            " you disclose only the minimum Data that you, in your reasonable " +
            "judgment, believe is required to be disclosed in order to comply.</p>"

    },

    {
        id: 9,
        title: "IX. Warranties and Disclaimer",
        sectionText:
            "<ul class='no-decoration'  >" +
              "<li>A. <u><em>User Warranties</em></u></li>" +
              "<li class='no-marker'>You hereby warrant that:</li>"+
                 "<ol class='lower-roman-list'>" +
                   "<li class='roman-list-1'>&nbsp;&nbsp;you will not introduce any virus into the Platform; and </li>" +
                   "<li class='roman-list-2'>&nbsp;&nbsp; you will comply with all applicable laws, including all federal, state, and local laws and regulations regarding the use of the " +
            "Data and User Contributions; and </li>" +
                    "<li class='roman-list-3'>&nbsp;&nbsp; you have reviewed any policies that your employer has adopted regarding your employer's intellectual property and your use of social media, and your User Contributions do" +
            " not violate, and are consistent with, any such policies; and </li>" +
                    "<li class='roman-list-4'>&nbsp;&nbsp;you own or control all rights in and to your User Contributions and have the right to grant the license granted in Section V.B of this Agreement to fellow Authorized Users, " +
            "<em>Project Data Sphere</em>, and <em>Project Data Sphere</em>'s respective licensees, successors " +
            "and assigns; and </li>" +
            "<li class='roman-list-5'>&nbsp;&nbsp;all of your User Contributions will comply with the requirements set forth in this Agreement, any applicable Supplemental Terms and " +
            "the Terms of Use that are applicable to the Platform; and </li>" +
            "<li class='roman-list-6'>&nbsp;&nbsp;you are not located in, ordinarily resident in, or controlled by any entity or person located in or ordinarily resident in Iran, Syria, Sudan, Cuba, North Korea or any other country that is, at any time that you use the Platform, subject to sanctions by the U.S. Government or other applicable government or is identified on any list of restricted parties maintained by the United States government or other applicable government, including, but not limited to, the Specially Designated Nationals List administered by the U.S. Treasury Department's Office of Foreign Assets Control or the Denied Persons List, Unverified List or Entity List" +
            " maintained by the U.S. Commerce Department's Bureau of Industry and Security. </li>" +
                 "</ol>" +

              "<li>B. <u><em>Warranty Disclaimer</em></u></li>" +
              "<li>NEITHER PROJECT DATA SPHERE NOR ANY OF ITS LICENSORS OF TECHNOLOGY OR DATA, NOR ITS HOSTING " +
            "PLATFORM PROVIDER MAKES ANY WARRANTY, EXPRESS, IMPLIED OR STATUTORY, REGARDING THE DATABASE, THE DATA, " +
            "THE COMMUNITY TOOLS, THE USER CONTRIBUTIONS OR THE PLATFORM. <em>PROJECT DATA SPHERE</em> (ON BEHALF OF" +
            " ITSELF, EACH LICENSOR OF TECHNOLOGY OR DATA AND ITS HOSTING PLATFORM PROVIDER) SPECIFICALLY DISCLAIMS" +
            " ALL WARRANTIES WITH RESPECT TO THE DATA, THE COMMUNITY TOOLS, THE USER CONTRIBUTIONS AND THE PLATFORM," +
            " INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND ANY" +
            " WARRANTIES ARISING AS A RESULT OF CUSTOM OR USAGE IN THE TRADE OR BY COURSE OF DEALING. " +
            "<em>PROJECT DATA SPHERE</em>, ITS LICENSORS OF TECHNOLOGY OR DATA AND ITS HOSTING PLATFORM PROVIDER" +
            " DO NOT GUARANTEE THAT THE DATA, THE COMMUNITY TOOLS, THE USER CONTRIBUTIONS OR THE PLATFORM WILL MEET" +
            " YOUR REQUIREMENTS OR NEEDS OR THAT THE DATA OR THE USER CONTRIBUTIONS ARE ACCURATE, CURRENT, OR COMPLETE. THE DATA, THE COMMUNITY TOOLS, THE USER CONTRIBUTIONS AND THE PLATFORM ARE PROVIDED \"AS IS,\" AND YOUR USE OF THE DATA, " +
            "THE COMMUNITY TOOLS, THE USER CONTRIBUTIONS AND THE PLATFORM IS AT YOUR SOLE RISK.</li>" +

            "</ul>"
    },
    {
        id: 10,
        title: "X.  Term and Termination",
        sectionText:
            "<ul class='no-decoration'  >" +
              "<li>A.   <u><em>Term</u></em></li>" +
              "<li>This Agreement becomes effective on the date that you first receive access to the Platform from" +
            " <em>Project Data Sphere</em> (\"Effective Date\") and shall expire one (1) year after such date," +
            " unless sooner terminated in accordance with Section X(B)(i) or (ii). You may extend the term of this" +
            " Agreement for additional one (1) year periods without interruption of your access to the Platform" +
            " by completing and submitting a new online application and agreeing to the terms, covenants and conditions" +
            " in this Agreement within ten (10) business days before" +
            " the expiration date of the initial term or any renewal term of this Agreement.</li>" +
            "<li>B.   <u><em>Termination</u></em></li>" +
            "<ol class='lower-roman-list'>" +
            "<li class='roman-list-1'>&nbsp;&nbsp;Either party may terminate this Agreement at any time with or without cause upon 30-days' written notice to the other party. </li>" +
            "<li class='roman-list-2'>&nbsp;&nbsp;<em>Project Data Sphere</em> may immediately terminate this Agreement" +
            " and your access to the Platform if you use or disclose any of the Data or User Contributions in any manner that is not permitted by this Agreement or by any applicable Supplemental Terms," +
            " or if you fail to meet the requirements set forth in Articles VI, VII or IX. </li>" +
            "</ol>" +
            "<li>C.   <u><em>Effect of Termination</u></em></li>" +
            "<li>Upon expiration of this Agreement or upon its termination for any reason, your access to the " +
            "Platform will immediately terminate. If this Agreement has been terminated by " +
            "<em>Project Data Sphere</em> in accordance with Section X(B)(ii), the Data license grant in " +
            "Section III(A) and licenses granted by other Authorized Users to their User Contributions " +
            "shall likewise terminate immediately, and you shall promptly destroy all copies of the " +
            "Data and User Contributions then in your possession and certify to <em>Project Data Sphere</em>" +
            " in writing that you have complied with this obligation.</li>" +

        "</ul>"
    },
    {
        id: 11,
        title: "XI.   Survival",
        sectionText:
            "<ul class='no-decoration'  >" +
            "<li>Articles IV- IX, Section X(C), and Articles XI- XVIII shall survive termination or " +
            "expiration of this Agreement.</li>" +
            "</ul>"
    },

    {
        id: 12,
        title: "XII.   Removal of Data and User Contributions",
        sectionText:
            "<ul class='no-decoration'  >" +
            "<li>You acknowledge that each Data Provider has the right to direct <em>Project Data Sphere</em> to " +
            "remove all or any portion of the Data that it has provided for the Database and that " +
            "<em>Project Data Sphere</em> shall remove a Data Provider's Data from the Database promptly " +
            "upon receipt of any such direction from a Data Provider. You further acknowledge that each " +
            "Authorized User may remove any User Contribution that he or she has posted to the Platform during the time when such Authorized User has access to the component of the Community Tools to which the User Contribution was posted. In the event that you have accessed any Data or User Contribution prior to its removal from the Platform, " +
            "you may continue to use such Data or User Contribution in accordance with this Agreement unless and until:</li>" +
            "<ol class='lower-roman-list'>" +
            "<li class='roman-list-1'>&nbsp;&nbsp;<em>Project Data Sphere</em> terminates your access to the Platform in " +
            "accordance with Section X(B)(ii); </li>" +
            "<li class='roman-list-2'>&nbsp;&nbsp;  the Data or User Contribution was removed from the " +
            "Platform by reason of failure to comply with applicable any law or regulation, in which case " +
            "<em>Project Data Sphere</em> shall notify you that you are required to destroy such " +
            "Data or User Contribution and you shall promptly comply with such notification;</li>" +
            "<li class='roman-list-3'>&nbsp;&nbsp;any Supplemental Terms applicable to the Data require you to " +
            "cease access to or use of the Data and to purge and destroy any copies of the " +
            "Data that you have made (manually, electronically or otherwise); or </li>" +
            "<li class='roman-list-4'>&nbsp;&nbsp;unless your use of the Data is otherwise restricted by applicable law or regulation. </li>" +
            "</ol>" +
            "</ul>"

    },

    {
        id: 13,
        title: "XIII.   Equitable Relief",
        sectionText:
            "<p >You acknowledge and agree that: (i) the restrictions contained in Sections III(B) and III(C), " +
            "and in Articles IV, VI, VII and VIII are reasonable and to protect the legitimate business interests of " +
            "<em>Project Data Sphere</em>, its licensors of technology and Data and its hosting Platform provider; and (ii) any violation of these restrictions" +
            " would cause irreparable injury for which remedies at law would be inadequate. Accordingly, " +
            "<em>Project Data Sphere</em>, its licensors of technology and Data and its hosting Platform provider" +
            " shall each be entitled to seek immediate injunctive relief and any other equitable relief as a result" +
            " of any violation hereunder without posting a bond or other security; provided, however that if the posting" +
            " of a bond is a prerequisite to obtaining injunctive relief, then a bond in the amount of $1,000 " +
            "shall be sufficient. Notwithstanding the foregoing, this paragraph shall not in any way limit the " +
            "remedies at law or in equity otherwise available to " +
            "<em>Project Data Sphere</em>, any Data Provider or the hosting Platform provider." +
            "</p>"
    },

    {
        id: 14,
        title: "XIV.   Indemnification",
        sectionText:
            "<p >You shall defend, indemnify and hold harmless <em>Project Data Sphere</em>, " +
            "each of its licensors of technology or Data, its hosting service provider, and their " +
            "respective members, affiliates, officers, directors, agents, employees, and " +
            "subcontractors (collectively, the \"Indemnified Parties\") from and against any " +
            "and all damages, costs, losses and expenses, including reasonable attorneys' fees, " +
            "that they incur in connection with any claim, action, or proceeding arising out of or in " +
            "connection with your use of the Platform, with the exception of any claim, action, or " +
            "proceeding arising out of an allegation that the licensor of technology or Data, or the " +
            "hosting service provider, as applicable, " +
            "did not have the right or authority to make any Data or technology available through the Platform." +
            "</p>"


    },

    {
        id: 15,
        title: "XV.   Limitation of Liability",
        sectionText:
            "<p >YOU ACKNOWLEDGE THAT YOU HAVE BEEN GIVEN ACCESS TO THE DATA, USER CONTRIBUTIONS AND THE" +
            " PLATFORM WITHOUT CHARGE. ACCORDINGLY, EXCEPT FOR CLAIMS ARISING OUT OF ALLEGATIONS THAT " +
            "<em>PROJECT DATA SPHERE</em>, ANY OF ITS LICENSORS OF TECHNOLOGY OR DATA OR ITS HOSTING PLATFORM" +
            " PROVIDER DID NOT HAVE THE RIGHT TO MAKE THE PLATFORM, ANY TECHNOLOGY OR ANY DATA, ACCESSIBLE" +
            " THROUGH THE PLATFORM AVAILABLE TO YOU IN ACCORDANCE WITH THE TERMS OF THIS AGREEMENT AND ANY" +
            " APPLICABLE SUPPLEMENTAL TERMS, IN NO EVENT SHALL <em>PROJECT DATA SPHERE</em>, ANY OF ITS " +
            "LICENSORS OF TECHNOLOGY OR DATA OR ITS HOSTING PLATFORM PROVIDER BE LIABLE TO YOU FOR ANY " +
            "INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT " +
            "LIMITED TO LOST PROFITS, LOSS OF DATA, LOSS OF BUSINESS, OR LOSS OF OPPORTUNITY. " +
            "<em>PROJECT DATA SPHERE</em>'S, ITS LICENSORS' OF TECHNOLOGY OR DATA AND ITS HOSTING PLATFORM" +
            " PROVIDER'S MAXIMUM AGGREGATE LIABILITY TO YOU FOR CLAIMS ARISING OUT OF OR IN CONNECTION" +
            " WITH THIS AGREEMENT" +
            " SHALL IN NO EVENT EXCEED TWENTY-FIVE THOUSAND DOLLARS ($25,000)." +
            "</p>"
    },

    {
        id: 16,
        title: "XVI.   Governing Law",
        sectionText:
            "<p >This Agreement shall be deemed to have been executed and delivered in the State of North Carolina, and " +
            "shall be governed and construed in accordance with the laws" +
            " of the State of North Carolina, without regard to its conflict of laws principles." +
            "</p>"
    },

    {
        id: 17,
        title: "XVII.   Notice",
        sectionText:
            "<p >All notices or other communications must be provided for in writing by certified mail, postage prepaid, and " +
            "shall be deemed duly given upon the date of receipt as recorded by the U.S. Postal Platform." +
            "</p>" +

            "<p class='no-margin-bottom'>If to <em>Project Data Sphere</em>: </p>" +

               "<p class='indent'><em>Project Data Sphere</em></p>" +
               "<p class='indent'>1204 Village Market Pl.</p>" +
               "<p class='indent'>Suite 288</p>" +
               "<p class='indent'>Morrisville, NC 27560</p>" +
               "<p class='indent'>Attn: Nicole Williford Hayes</p>" +
               "<p class='indent'>Email: " +
               "<a href='mailto:nicole.hayes@projectdatasphere.org'>nicole.hayes@projectdatasphere.org</a></p>" +

               "<p class='add-margin'>If to you, in the name, and at the mailing address, e-mail address, and telephone number " +
                   "set forth in your application for access to the Platform.</p>"

    },

    {
        id: 18,
        title: "XVIII.   Publicity",
        sectionText:
            "<p >Except for the attribution required by Article VII, neither party will use any " +
            "trademark, service mark, trade name, or other name or logo of the other party or the name of the " +
            "other party's employer or any of the other party's employees for promotional or advertising purposes, " +
            "nor issue any public statement concerning this Agreement, without permission from the other party." +
            " However, <em>Project Data Sphere</em> reserves the right to identify you and your employer in listings " +
            "maintained by <em>Project Data Sphere</em> identifying researchers to whom it has given access to the Data" +
            " (including, without limitation, on listings posted on any <em>Project Data Sphere</em> web site)." +
            "</p>"
    }

];

