/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminUserRequestDetails
 *
 * Purpose: component for displaying details about user registration
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 29Oct2024 sasjxa file created
 \******************************************************************************/
import React, {useState} from "react";
import styled from "styled-components";
import {COLORS, FONT_SIZES} from "../styled/StyleConstants";
import {Col, Row} from "react-bootstrap";
import {BASE_PATH} from "../../constants";
import {GridRow, NavMenuItem} from "../styled/StyledComponents";
import {IAdminRequestUser} from "../../model/admin/requests/IAdminRequestUser";
import {Link} from "react-router-dom";
import {OTHER_OPTION} from "../../data/formList";


const UserRequestCol = styled(Col)`
    font-size: ${FONT_SIZES.px13};
    color: ${COLORS.dark_grey_blue};
    padding: .1% 0;    
`;

const ResearchGoalsCol = styled(UserRequestCol)`
    overflow-wrap: break-word;    
`;

const UserRequestColBold = styled(UserRequestCol)`
    font-weight: bold;    
`;

const UserInfoCol = styled(Col)`
    border: 1px solid ${COLORS.cerulean}; 
    width: 85%;    
`;

const UserRequestNavMenuItem = styled(NavMenuItem)`
    padding-right: 2px;    
`;

const GoogleImg = styled.img`
    margin-left: 10px;   
    width: 50px;
    height: 25px; 
    text-align: center;    
`;

interface AdminUserRequestDetailProps {
    request: IAdminRequestUser,
    requestType: any
}


export const AdminUserRequestDetails: React.FC<AdminUserRequestDetailProps> = ({
                                                                                    request, requestType

                                                                                }: AdminUserRequestDetailProps) => {
    const [showUserRequest, setShowUserRequest] = useState(false);
    const [showUserRequestArrow, setShowUserRequestArrow] = useState<string>("/images/arrow_down.png");


    // return user first and last and first name
    const getUserName = (request: IAdminRequestUser) => {
        if (request.user.id != undefined) {
            return (
                <UserRequestNavMenuItem to={BASE_PATH + "admin/userInfo/" + request.user.id}>
                    {request.user.userDetails?.first + " " + request.user.userDetails?.last}
                </UserRequestNavMenuItem>
            )
        }
    }

    const getArrowImage = () => {
        return (
            <img
                onClick={() => toggleUserRequestInfo()}
                src={showUserRequestArrow}
                className="text-center"></img>
        )
    }

    const getGoogleImage = () => {
        let googleSearchString=request.user.userDetails?.first + " " + request.user.userDetails?.last
            + " "  + request.user.userDetails?.organization  + " " +request.user.userDetails?.country;

        return (
            <Link to={"http://www.google.com/search?q=" + googleSearchString} target={"_blank"}>
                <GoogleImg
                    onClick={() => toggleUserRequestInfo()}
                    src="http://www.google.com/logos/Logo_25wht.gif"
                    alt = "Web Search for User"
                    className=""></GoogleImg>
            </Link>

        )
    }


    // learn about PDS
    const getLearnedAboutPDS = () => {
     if (request.user.userDetails?.learnAboutPDS != null && request.user.userDetails?.learnAboutPDS != OTHER_OPTION) {
         return request.user.userDetails?.learnAboutPDS;
     }
     else  return (request.user.userDetails?.learnAboutPDSOther != null?  request.user.userDetails?.learnAboutPDSOther:
         "N/A");

    }

    // linked in profile
    const getLinkedInProfileUrl= () => {
        return (request.user.userDetails?.linkedinProfileURL != null?  request.user.userDetails?.linkedinProfileURL:
            "N/A");

    }

    // toggle request info
    const toggleUserRequestInfo = () => {
        setShowUserRequest(!showUserRequest);
        setShowUserRequestArrow(showUserRequest ? "/images/arrow_down.png" : "/images/arrow_up.png")
    }

    const buildToggleComponent = () => {
        return (
            <>
                <GridRow className="" key={request.requestId}>

                    <Col className="grid-entry space-3 ">
                        {getUserName(request)}{getArrowImage()}
                    </Col>

                    <Col className="grid-entry space-3">{request.user.userDetails.email}</Col>
                    <Col className="grid-entry">{request.requestType}</Col>
                    <Col className="grid-entry">
                        {new Date(request.createdAt).toLocaleDateString('en-us', {
                            month: "numeric",
                            day: "numeric",
                            year: "numeric"
                        })}
                    </Col>

                    <Col className="grid-entry">
                        <NavMenuItem to={BASE_PATH + "admin/request/accounts/" + request.requestId + "/" + requestType}>
                            {request.requestStatus}
                        </NavMenuItem>
                    </Col>
                </GridRow>
            </>
        )
    }



    // build the requests section
    const buildUserRequestInfo = () => {
        return (
            <>
                <UserInfoCol className={" pb-1 pe-4 ms-4 mb-4"}>
                        <Row className={"w-100 pe-2 ps-2 "}>
                            <UserRequestColBold className="ps-3  " lg={3}>Name:</UserRequestColBold>
                            <UserRequestCol className="ps-3  " lg={9}>{request.user.userDetails?.first + " " + request.user.userDetails?.last }{getGoogleImage()}</UserRequestCol>
                        </Row>
                    <Row className={"w-100 pe-2 ps-2"}>
                        <UserRequestColBold className="ps-3  " lg={3}>Organization or University:</UserRequestColBold>
                        <UserRequestCol className="ps-3  " lg={9}>{request.user.userDetails?.organization}</UserRequestCol>
                    </Row>
                    <Row className={"w-100 pe-2 ps-2"}>
                        <UserRequestColBold className="ps-3  " lg={3}>Country:</UserRequestColBold>
                        <UserRequestCol className="ps-3  " lg={9}>{request.user.userDetails?.country}</UserRequestCol>
                    </Row>
                    <Row className={"w-100 pe-2 ps-2"}>
                        <UserRequestColBold className="ps-3  " lg={3}>How Learned About PDS:</UserRequestColBold>
                        <UserRequestCol className="ps-3  " lg={9}>{getLearnedAboutPDS()}</UserRequestCol>
                    </Row>
                    <Row className={"w-100 pe-2 ps-2"}>
                        <UserRequestColBold className="ps-3  " lg={3}>LinkedIn Profile Url:</UserRequestColBold>
                        <UserRequestCol className="ps-3  " lg={9}>{getLinkedInProfileUrl()}</UserRequestCol>
                    </Row>
                    <Row className={"w-100 pe-2 ps-2"}>
                        <UserRequestColBold className="ps-3  " lg={3}>Research Goals:</UserRequestColBold>
                        <ResearchGoalsCol className="ps-3  " lg={9}>{request.user.userDetails?.proposedResearchGoals}</ResearchGoalsCol>
                    </Row>
                </UserInfoCol>
            </>
        )

    }


    return (
        <>
            {buildToggleComponent()}
            {showUserRequest && (
                buildUserRequestInfo()
            )}

        </>
    )
}