/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: SelectFiles
 *
 * Purpose: Select files to be uploaded for data sharing
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 20Jun2023 sasjxa file created
 * 29Sep2023 craig  added files from api
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 04Apr2024 craig  Switched to react-error-boundary
 * 20Aug2024 sasjxa add .xls, .xlsx as valid file types for protocol and crf
 * 21Aug2024 craig   moved to useContext(PDSContext) for user session
 \******************************************************************************/
import React, {useContext, useMemo, useState} from "react";
import {

    ButtonRow,
    ContentPage, PageWrapper,
    PDS_H5,
    PDS_P,
    PDSSubmitButton
} from "../../components/styled/StyledComponents";
import { Col, Container} from "react-bootstrap";


import {useNavigate, useParams} from "react-router-dom";


// @ts-ignore
import styled from "styled-components";

import {Aside} from "../../components/Aside";

import {DATA_DICTIONARY, DATA_FILES, PROTOCOL_CRF} from "../../data/infoDialog";
import {ShareDataSelectFile} from "../../components/share/ShareDataSelectFile";
import {IShareDataDashboard} from "../../model/share/IShare";
import {IFile} from "../../model/donations/IAccessData";
import {useRequest} from "../../helper/useRequest";
import {SIZES} from "../../components/styled/StyleConstants";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";
import {PDSContext, usePDSContext} from "../../context/PDSContext";



const SharedHeader = styled(PDS_H5)`  
   margin-top: 2%;
`;

const ButtonContainerWrapper = styled.div`
   width: 100%;
   margin-left: 0;
   margin-right: 0;    
   padding-top: 2%;
 
      
   @media (min-width: 768px){         
     width: 100%;    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    height: 75px;
   
    padding-top: 2%;
   
    
   } 

`;

const ButtonContainerBody = styled.div`
  max-width:  ${SIZES.MAX_WIDTH}; 
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 2%;
    padding-left: 2%;
    padding-bottom: 1%; 
`;

const ButtonContainerMain = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column; 
    
`;

export const SelectFiles: React.FC = () => {
    const {donationId} = useParams();
    const navigate = useNavigate();
    const { showBoundary } = useErrorBoundary();
    const {sessionUser} = usePDSContext();

    //get the files (if any)

    //state var for all files
    const [dashboard, setDashboard] = useState<IShareDataDashboard>();
    //state var for protocol
    const [protocol, setProtocol] = useState<IFile[]>([]);
    //state var for crf
    const [crf, setCrf] = useState<IFile[]>([]);
    //state var for data dictionary
    const [dataDictionary, setDataDictionary] = useState<IFile[]>([]);
    //state var for data files
    const [dataFiles, setDataFiles] = useState<IFile[]>([]);

    //--------------------------------------------------------------------------------------
    // LOAD THE DASHBOARD DATA
    //--------------------------------------------------------------------------------------

    const buildError = (error: object) => {
        showBoundary(error);
    }

    const filesURL = process.env.REACT_APP_API_URL + "/api/share/files/" + donationId;
    const [requestState, getDashboard] = useRequest({
        url: filesURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: buildError
    })
    const {isLoading, data, error} = requestState;
    useMemo(() => {
        if (sessionUser.userID > 0) {
            getDashboard();
        }
    }, []);

    useMemo(() => {
        if (data != null) {
            setDashboard(data);
        }
    }, [data]);

    useMemo(() => {
        if (dashboard != null) {
            dashboard.files.map((file:IFile) => {
                if (file.fileType === "PROTOCOL") {
                    setProtocol(current => [...current, file]);
                } else if (file.fileType === "CRF") {
                    setCrf(current => [...current, file]);
                } else if (file.fileType === "Data Dictionary") {
                    setDataDictionary(current => [...current, file]);
                } else {
                    setDataFiles(current => [...current, file])
                }
            })
        }
    }, [dashboard]);

    const buildButtonContainer= () => {
        return (
            <ButtonContainerWrapper className={"footer-wrapper "}>
                <ButtonContainerBody className={"footer-body"}>
                    <ButtonContainerMain className={"footer-main"}>
                        <ButtonRow>
                            <PDSSubmitButton variant="success" className="btn-submit" type="button"
                                             onClick={() => {
                                                 navigate(BASE_PATH + "share")
                                             }}>
                               Done
                            </PDSSubmitButton>
                        </ButtonRow>
                    </ButtonContainerMain>
                </ButtonContainerBody>
            </ButtonContainerWrapper>
        )
    }




    const validCRFProtocolFileTypes: string = ".pdf,.doc,.docx,.txt,.rtf, .xls,.xlsx";
    const validDataDictionaryFileTypes: string = ".pdf,.doc,.docx,.txt,.rtf,.xls,.xlsx,.xpt";
    const validDataFileTypes: string = ".sas7bdat,.pdf,.doc,.docx,.txt,.rtf,.xls,.xlsx,.xml,.xpt,.zip,.cpt,.lst,.mdb,.csv,.ppt,.pptx,.rar,.dtd,.numbers,.pages";


    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                <Col md={8} lg={9} name={"shareDataColumn"}>
                    <SharedHeader>File Upload</SharedHeader>
                    <PDS_P>Please upload the files associated with your study.</PDS_P>
                    <PDS_P>Please set the "Data Type" for all uploaded data files.</PDS_P>
                    <ShareDataSelectFile donationId={donationId} dashboard={dashboard} files={protocol} fileType={"PROTOCOL"} label={"Protocol (See Valid File Types)"} validFileTypes={validCRFProtocolFileTypes} infoDialog={PROTOCOL_CRF}/>
                    <ShareDataSelectFile donationId={donationId} dashboard={dashboard} files={crf} fileType={"CRF"} label={"Case Report Form (See Valid File Types)"} validFileTypes={validCRFProtocolFileTypes} infoDialog={PROTOCOL_CRF}/>
                    <ShareDataSelectFile donationId={donationId} dashboard={dashboard} files={dataDictionary} fileType={"DATA_DICTIONARY"} label={"Data Dictionary (See Valid File Types)"} validFileTypes={validDataDictionaryFileTypes} infoDialog={DATA_DICTIONARY}/>
                    <ShareDataSelectFile donationId={donationId} dashboard={dashboard} files={dataFiles} fileType={"DATA_FILES"} label={"Data Files (See Valid File Types)"} validFileTypes={validDataFileTypes} infoDialog={DATA_FILES}/>
                    {buildButtonContainer()}
                </Col>
                <Col md={4} lg={3}>
                    <Aside needHelp={true} requiredFields={true}/>
                </Col>
                </PageWrapper>
            </ContentPage>
        </Container>
    )
        ;
}