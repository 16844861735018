/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: TumorTypes
 *
 * Purpose:  Study Types for Share Data
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 23Oct2023 sasjxa file created
 * 19Jun2024 sasjxa remove labelClassName parameter
 \******************************************************************************/
import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import {ErrorMessage} from "formik";
import {TUMOR_TYPES} from "../../data/formList";
import {labelIfReadOnly} from "../../constants/CommonFunctions";


interface TumorTypesProps {
    value: any,
    valueHandler: any,
    handleValueChanged: any,
    listHandler: any,
    disabled: boolean,
    touched: any,
    errors: any
}


export const TumorTypes: React.FC<TumorTypesProps> =
    ({
         value,  valueHandler, listHandler, handleValueChanged,
           disabled, touched, errors

     }) => {
        return (
            <>
                <Row className="mt-3 mb-3 ">
                    <Form.Group as={Col} lg={6} className="tumorType"
                                controlId="formTumorType">
                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Tumor Type(s) </Form.Label>
                        <Form.Select size="sm" required multiple={true}
                                     name={"tumorType"}
                                     value={value}
                                     disabled={disabled}
                                     aria-describedby="tumorTypesHelpBlock"
                                     isValid={touched.tumorType && !errors.tumorType}
                                     onChange={e => {
                                         const options = e.target.options;
                                         let value = [];
                                         for (let i = 0, l = options.length; i < l; i++) {
                                             if (options[i].selected) {
                                                 value.push(options[i].value);
                                             }
                                         }
                                         valueHandler(value);
                                         handleValueChanged(value);

                                     }}>
                            {listHandler(TUMOR_TYPES)}
                        </Form.Select>
                        <Form.Text id="tumorTypesHelpBlock" muted>
                            Ctrl + click to select multiple
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                            Please select a tumor type.
                        </Form.Control.Feedback>
                        <ErrorMessage name={"tumorType"}
                                      render={msg => <div
                                          className={"form-error-msg"}>{msg}</div>}/>
                    </Form.Group>
                </Row>
                </>
        )

    }