/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: NoMatch
 *
 * Purpose: 404 page request
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 01May2024 craig File created.
 \******************************************************************************/
import React from "react";
import {Link} from "react-router-dom";

export const NoMatch: React.FC = (props) => {
    return (
        <div>
            <h2>Page not found!</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    );
}